import React, { useEffect } from "react";
import { useState, useRef, useMemo } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { InputLabel, Stack, styled } from "@mui/material";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from '@mui/styles';
import { ListItemIcon } from '@mui/material';
import { useGetClosedCalenderQuery } from "../../service/calender/Calender";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },
  dropmenu: {
    ['@media (max-width:768px)']: {
      flexWrap: "wrap",
    }
  },
  dropmenunew: {
    ['@media (max-width:768px)']: {
      flexWrap: "wrap",
      direction: "column",
      gap: "23px",
      padding: "20px"
    }
  },
}));
const Timeline = styled("div")({
  fontSize: "20px",
  color: "#333333",
  paddingLeft: "20px",
  fontFamily: "Arial",
});
function ClosedCalendarfilter(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    SectionFilter,
    setSectionFilter,
    subSectionFilter,
    setSubSectionFilter,
    navigationFrom,
    divisionFilter,
    setDivisionFilter,
    talentCategoreyFilter,
    settalentCategoreyFilter,
    valueofTab,
    employeeData1,
    setvalueOfActiveCalender,
    valueOfActiveCalender,
    Role,
    appCalId,
    setcalendarValDialog,
    calendarValDialog
  } = props;
  // getting closedcalendar details
  const { data: closedCalendar, isLoading: isTitleLoading } = useGetClosedCalenderQuery('')
  var Calender = closedCalendar?.data?.map((j: any) => {
    return {
      PAlaunch: j?.pa_launch?.substring(0, 4),
      // calendartype :j?.calendar_type
    }
  }).filter((value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  });
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);

  //  setting the year based on palaunch date
  const initialYear = closedCalendar?.data?.map((j: any) => {
    return j?.pa_launch?.substring(0, 4)
  })?.filter((item: any, index: any, array: any) =>
    array?.map((data: any) => { return data?.pa_launch }).indexOf(item?.pa_launch) === index)

  // initial year setting based on selected year
  const filteredCalendar = closedCalendar?.data?.filter((item: any) => {
    const year = item.pa_launch?.substring(0, 4);
    if (selectedYear == null || selectedYear == undefined) {
      setSelectedYear(initialYear[0])
    }
    return year === selectedYear;
  });
  // function for selecting year
  const handleYearChange = (event: any) => {
    setSelectedYear(event.target.value);
  };
  // function for gettig section dropdown data
  let opt = employeeData1?.data
    ?.slice()
    ?.sort(function (a: any, b: any) {
      return a?.section?.localeCompare(b?.section);
    })
    ?.filter(
      (item: any, index: any, array: any) =>
        array
          ?.map((data: any) => {
            return data?.section;
          })
          .indexOf(item?.section) === index
    )
    .map((i: any) => {
      return i?.section;
    });
  if (divisionFilter?.length > 0) {
    opt = employeeData1?.data
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.section?.localeCompare(b?.section);
      })
      ?.filter((i: any) => {
        //@ts-ignore
        return !!divisionFilter?.find(item2 => i?.division === item2)
      })
      ?.filter(
        (item: any, index: any, array: any) =>
          array
            ?.map((data: any) => {
              return data?.section;
            })
            .indexOf(item?.section) === index)
      .map((i: any) => {
        return i?.section;
      });
  }
  // function for gettig section dropdown data
  let optSebSection = employeeData1?.data
    ?.slice()
    ?.sort(function (a: any, b: any) {
      return a?.sub_section?.localeCompare(b?.sub_section);
    })
    ?.filter(
      (item: any, index: any, array: any) =>
        array
          ?.map((data: any) => {
            return data?.sub_section;
          })
          .indexOf(item?.sub_section) === index)
    .map((i: any) => {
      return i?.sub_section;
    });
  if (SectionFilter.length > 0) {
    optSebSection = employeeData1?.data
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.sub_section?.localeCompare(b?.sub_section);
      })
      ?.filter((i: any) => {
        //@ts-ignore
        return !!SectionFilter?.find(item2 => i?.section === item2)
      })
      ?.filter(
        (item: any, index: any, array: any) =>
          array
            ?.map((data: any) => {
              return data?.sub_section;
            })
            ?.indexOf(item?.sub_section) === index)
      ?.map((i: any) => {
        return i?.sub_section;
      });
  }
  if (employeeData1?.data === undefined) {
    opt = employeeData1
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.section?.localeCompare(b?.section);
      })
      ?.filter(
        (item: any, index: any, array: any) =>
          array
            ?.map((data: any) => {
              return data?.section;
            })
            .indexOf(item?.section) === index
      )
      .map((i: any) => {
        return i?.section;
      });
    optSebSection = employeeData1
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.sub_section?.localeCompare(b?.sub_section);
      })
      ?.filter(
        (item: any, index: any, array: any) =>
          array
            ?.map((data: any) => {
              return data?.sub_section;
            })
            .indexOf(item?.sub_section) === index
      )
      .map((i: any) => {
        return i?.sub_section;
      });
    if (SectionFilter.length > 0) {
      optSebSection = employeeData1?.data
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.sub_section?.localeCompare(b?.sub_section);
        })
        ?.filter((i: any) => {
          //@ts-ignore
          return !!SectionFilter?.find(item2 => i?.section === item2)
        })
        ?.filter(
          (item: any, index: any, array: any) =>
            array
              ?.map((data: any) => {
                return data?.sub_section;
              })
              ?.indexOf(item?.sub_section) === index
        )
        ?.map((i: any) => {
          return i?.sub_section;
        });
    }
  }
  // function for division filter dropdown
  const optdivision = employeeData1?.data
    ?.slice()
    ?.sort(function (a: any, b: any) {
      return a?.division?.localeCompare(b?.division);
    })
    ?.filter(
      (item: any, index: any, array: any) =>
        array
          ?.map((data: any) => {
            return data?.division;
          })
          .indexOf(item?.division) === index
    )
    .map((i: any) => {
      return i?.division;
    });
  const categorynames = [
    "Level 1",
    "Level 2",
  ];
  //functions for mapping
  const isAllSelecteddivision =
    optdivision?.length > 0 && divisionFilter?.length === optdivision?.length;
  const isAllSelectedTalentCat =
    categorynames?.length > 0 && talentCategoreyFilter?.length === categorynames?.length;
  const [selected, setSelected] = useState<any>([]);
  const [hide1, setHide1] = useState(true);

  //multiselect
  const [active, setActive] = useState<string>("");
  const [page, setPage] = React.useState(0);
  const [disableButton, setdisableButton] = React.useState(false);
  const [disablesectionButton, setdisablesectionButton] = React.useState(false);
  const [disabledivisionButton, setdisabledivisionButton] = React.useState(false);

  // clearing the localstorage
  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.clear();
    }
  }, [])
  //  onchange for calendar
  const handleChangeforCal = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    setvalueOfActiveCalender(newValue);
    // Store the value in localStorage while coming back from expandviews getting the value
    localStorage.setItem("active", newValue);
  };

  let Localstorage = localStorage.getItem("active") ?? "";
  // function for section onchange
  const isAllSelectedSection =
    opt?.length > 0 && SectionFilter?.length === opt?.length;
  const newsection = opt?.length == SectionFilter?.length
  const handleChangeSelectSection = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSectionFilter(SectionFilter?.length === opt?.length ? [] : opt);
      return;
    }
    setSectionFilter(value);
    setPage(0);
  };
  // function for sub-section onchange
  const isAllSelectedSubSection =
    opt?.length > 0 && subSectionFilter?.length === optSebSection?.length;
  const handleChangeSelectSubSection1 = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSubSectionFilter(
        subSectionFilter?.length === optSebSection?.length ? [] : optSebSection);
      return;
    }
    setSubSectionFilter(value);
    setPage(0);
  };
  // function for disable the select buttons on section,sub-section,division,grade while empty values 
  useEffect(() => {
    const Temp = optSebSection?.filter(function (j: any) {
      return j !== undefined;
    })?.filter(function (j: any) {
      return j !== "";
    });
    if (Temp?.length == null || Temp?.length == "" || Temp?.length == undefined) {
      setdisableButton(true)
    } else {
      setdisableButton(false)
    }
    const Temp2 = opt?.filter(function (j: any) {
      return j !== undefined;
    });
    if (Temp2?.length == null || Temp2?.length == "" || Temp2?.length == undefined) {
      setdisablesectionButton(true)
    } else {
      setdisablesectionButton(false)
    }
    const Temp3 = optdivision?.filter(function (j: any) {
      return j !== undefined;
    });
    if (Temp3?.length == null || Temp3?.length == "" || Temp3?.length == undefined) {
      setdisabledivisionButton(true)
    } else {
      setdisabledivisionButton(false)
    }
  }, [optSebSection, opt])
  // function for onchange division
  const handleChangedivision = (event: any) => {
    const value = event.target.value;
    if (value[value?.length - 1] === "all") {
      setDivisionFilter(
        divisionFilter?.length === optdivision?.length ? [] : optdivision
      );
      return;
    }
    setDivisionFilter(value);
  };
  // function for onchange direct  reports filter
  const handleChangeTalentCategoreyFilter = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      settalentCategoreyFilter(talentCategoreyFilter?.length === categorynames?.length ? [] : categorynames);
      return;
    }
    settalentCategoreyFilter(value);
  };
  // design for selet styles
  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  useEffect(() => {
    // initialy loads closed calendar is empty showing dialogue
    if (filteredCalendar == "" || filteredCalendar == undefined || filteredCalendar == 0 && isTitleLoading) {
      setcalendarValDialog(true)
    }
    // localstorage value empty and  initially loads the page setting initial calendar
    else if (Localstorage == null && filteredCalendar != undefined || Localstorage == "" && filteredCalendar != undefined) {
      setvalueOfActiveCalender(filteredCalendar[0]?.name)
    }
    // initially loads the page setting initial calendar
    else {
      setvalueOfActiveCalender(Localstorage)
      setcalendarValDialog(false);
    }
  }, [valueOfActiveCalender, closedCalendar, active, filteredCalendar, isTitleLoading])

  return (
    <>
      <div style={{ marginTop: "0px", position: "relative" }}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "#fff",
            marginLeft: "25px",
            marginRight: "25px",
            paddingTop: "20px",
            paddingBottom: "20px"
          }}
        >
          <Stack
            className={classes.dropmenu}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Timeline>
              {valueOfActiveCalender}
            </Timeline>
            <Stack
              className={classes.dropmenunew}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              paddingRight="20px"
            ><div>
                {/* calendar filter */}
                <FormControl size="small" sx={{ minWidth: 140 }}>
                  <InputLabel
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                        color: "#333333 !important",
                      },
                    }}
                    id="demo-multiple-checkbox-label"
                  >
                    Calendar
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Calendar" />}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        textTransform: "none",
                        fontFamily: "Arial",
                        color: "#333333",
                      },
                    }}
                    value={valueOfActiveCalender}
                    onChange={handleChangeforCal}
                  >
                    {filteredCalendar
                      ?.map((j: any) => {
                        return (
                          <MenuItem
                            sx={{
                              padding: "0px",
                              paddingLeft: "12px",
                              fontSize: "13px",
                              color: "#333333",
                              fontFamily: "Arial",
                            }}
                            value={j?.name}
                          >
                            {j?.calendar_type}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
              <div>
                {/*year filter  */}
                <FormControl size="small" sx={{ minWidth: 140 }}>
                  <InputLabel
                    sx={{
                      "& .MuiFormLabel-root": {
                        fontSize: "14px",
                        fontFamily: "Arial",
                        color: "#333333",
                      },
                    }}
                    id="demo-multiple-checkbox-label"
                  >
                    Year
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    MenuProps={MenuProps}
                    defaultValue={20}
                    input={<OutlinedInput label="Year" />}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        textTransform: "none",
                        fontFamily: "Arial",
                        color: "#333333",
                      },
                    }}
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    {Calender?.filter((item: any, index: any, array: any) =>
                      array?.map((data: any) => { return data?.PAlaunch }).indexOf(item?.PAlaunch) === index)
                      ?.map((j: any) => {
                        return (
                          <MenuItem
                            sx={{
                              padding: "0px",
                              paddingLeft: "12px",
                              fontSize: "13px",
                              color: "#333333",
                              fontFamily: "Arial",
                            }}
                            value={j?.PAlaunch}
                          >
                            {j?.PAlaunch}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
              {navigationFrom === "Normalizer" && (
                <div>
                  {/* Division filter */}
                  <FormControl size="small" sx={{ width: 140 }}>
                    <InputLabel
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                          color: "#333333 !important",
                        },
                      }}
                      id="demo-multiple-checkbox-label"
                    >
                      Division
                    </InputLabel>
                    <Select
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "14px",
                          textTransform: "none",
                          fontFamily: "Arial",
                          color: "#333333",
                        },
                      }}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      disabled={disabledivisionButton}
                      value={divisionFilter || []}
                      // value={divisionFilter}
                      onChange={handleChangedivision}
                      input={<OutlinedInput label="Division" />}
                      //renderValue={(selected) => selected?.join(", ")}
                      renderValue={(selected) => {
                        if (selected?.length === optdivision?.length) {
                          return "Select All";
                        }
                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      <MenuItem
                        style={{
                          fontSize: "13px",
                          color: "#333333",
                          fontFamily: "Arial",
                          padding: "0px",
                          //paddingLeft: "37px",
                        }}
                        key="all"
                        value="all"
                        classes={{
                          root: isAllSelecteddivision
                            ? classes?.selectedAll
                            : "",
                        }}
                      >
                        {/* <Checkbox size="small" checked={SectionFilter.indexOf("None") > -1} /> */}
                        {/* <ListItemText  primary="None" /> */}
                        <ListItemIcon>
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "13px !important",
                              },
                            }}
                            size="small"
                            style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                            classes={{
                              indeterminate: classes?.indeterminateColor,
                            }}
                            checked={isAllSelecteddivision}
                            indeterminate={
                              divisionFilter?.length > 0 &&
                              divisionFilter?.length < optdivision?.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              paddingRight: "10px"
                            },
                          }}
                          classes={{ primary: classes?.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {optdivision
                        ?.filter((i: any) => {
                          return i != undefined
                        })
                        ?.map((option: any) => (
                          <MenuItem
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                              fontFamily: "Arial",
                              padding: "0px",
                            }}
                            key={option}
                            value={option}
                          >
                            <ListItemIcon>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "13px !important",
                                  },
                                }}
                                size="small"
                                style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                checked={divisionFilter?.indexOf(option) > -1}
                              />
                            </ListItemIcon>
                            <ListItemText sx={{
                              "& .MuiTypography-root": {
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                paddingRight: "10px"
                              },
                            }} primary={option} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div>
                {/* section filter */}
                <FormControl size="small" sx={{ width: 140 }}>
                  <InputLabel
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                        color: "#333333 !important",
                      },
                    }}
                    id="demo-multiple-checkbox-label"
                  >
                    Section
                  </InputLabel>
                  <Select
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        textTransform: "none",
                        fontFamily: "Arial",
                        color: "#333333",
                      },
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    disabled={disablesectionButton}
                    value={SectionFilter}
                    onChange={handleChangeSelectSection}
                    input={<OutlinedInput label="Section" />}
                    renderValue={(selected) => {
                      if (selected?.length === opt?.length) {
                        return "Select All";
                      }
                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    size="small"
                  >
                    <MenuItem
                      style={{
                        fontSize: "13px",
                        color: "#333333",
                        fontFamily: "Arial",
                        padding: "0px",
                      }}
                      key="all"
                      value="all"
                      classes={{
                        root: isAllSelectedSection ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "13px !important",
                            },
                          }}
                          size="small"
                          style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={isAllSelectedSection}
                          indeterminate={
                            SectionFilter?.length > 0 &&
                            SectionFilter?.length < opt?.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "13px",
                            fontFamily: "Arial",
                            color: "#333333",
                            paddingRight: "10px"
                          },
                        }}
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {opt
                      ?.filter((i: any) => { return i != undefined })?.filter((i: any) => { return i != "" })
                      ?.map((option: any) => (
                        <MenuItem
                          style={{
                            fontSize: "13px",
                            color: "#333333",
                            fontFamily: "Arial",
                            padding: "0px",
                          }}
                          key={option}
                          value={option}
                        >
                          {hide1 && (
                            <ListItemIcon>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "13px !important",
                                  },
                                }}
                                size="small"
                                style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                checked={SectionFilter.indexOf(option) > -1}
                              />
                            </ListItemIcon>
                          )}
                          <ListItemText sx={{
                            "& .MuiTypography-root": {
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              paddingRight: "10px"
                            },
                          }} primary={option} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                {/* sub-section filter */}
                <FormControl size="small" sx={{ width: 140 }}>
                  <InputLabel
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                        color: "#333333 !important",
                      },
                    }}
                    id="demo-multiple-checkbox-label"
                  >
                    Sub section
                  </InputLabel>
                  <Select
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        textTransform: "none",
                        fontFamily: "Arial",
                        color: "#333333",
                      },
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    disabled={disableButton}
                    value={subSectionFilter}
                    onChange={handleChangeSelectSubSection1}
                    input={<OutlinedInput label="Sub section" />}
                    renderValue={(selected) => {
                      if (selected?.length === optSebSection?.length) {
                        return "Select All";
                      }
                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    size="small"
                  >
                    <MenuItem
                      style={{
                        fontSize: "13px",
                        color: "#333333",
                        fontFamily: "Arial",
                        padding: "0px",
                      }}
                      key="all"
                      value="all"
                      classes={{
                        root: isAllSelectedSubSection
                          ? classes.selectedAll
                          : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "13px !important",
                            },
                          }}
                          size="small"
                          style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={isAllSelectedSection}
                          indeterminate={
                            subSectionFilter?.length > 0 &&
                            subSectionFilter?.length < opt?.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "13px",
                            fontFamily: "Arial",
                            color: "#333333",
                            paddingRight: "10px"
                          },
                        }}
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {optSebSection?.filter((i: any) => { return i != undefined })?.filter((i: any) => { return i != "" })
                      ?.map((option: any) => (
                        <MenuItem
                          style={{
                            fontSize: "13px",
                            color: "#333333",
                            fontFamily: "Arial",
                            padding: "0px",
                          }}
                          key={option}
                          value={option}
                        >
                          <ListItemIcon>
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: "13px !important",
                                },
                              }}
                              size="small"
                              style={{ padding: "3px", paddingLeft: "14px", height: "0px" }}
                              checked={subSectionFilter.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText sx={{
                            "& .MuiTypography-root": {
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              paddingRight: "10px"
                            },
                          }} primary={option} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              {(valueofTab === 0 && navigationFrom === "Reviewer") && (
                <div>
                  {/* direct reports filter */}
                  <FormControl size="small" sx={{ minWidth: 145 }}>
                    <InputLabel
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                          color: "#333333 !important",
                        },
                      }}
                      id="demo-multiple-checkbox-label"
                    >
                      Direct Reports
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      size="small"
                      MenuProps={MenuProps}
                      input={<OutlinedInput label="Line Manager" />}
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "14px",
                          textTransform: "none",
                          fontFamily: "Arial",
                          color: "#333333",
                        },
                      }}
                      multiple
                      value={talentCategoreyFilter}
                      onChange={handleChangeTalentCategoreyFilter}
                      // renderValue={(selected) => selected.join(", ")}
                      renderValue={(selected) => {
                        if (selected?.length === categorynames?.length) {
                          return "Select All";
                        }
                        return selected.join(", ");
                      }}

                    //defaultValue={10}
                    >
                      <MenuItem
                        style={{
                          fontSize: "13px",
                          color: "#333333",
                          fontFamily: "Arial",
                          padding: "0px",
                          //paddingLeft: "37px",
                        }}
                        key="all"
                        value="all"
                        classes={{
                          root: isAllSelectedTalentCat ? classes.selectedAll : "",
                        }}
                      >
                        {/* <Checkbox size="small" checked={SectionFilter.indexOf("None") > -1} /> */}
                        {/* <ListItemText  primary="None" /> */}
                        <ListItemIcon>
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "13px !important",
                              },
                            }}
                            size="small"
                            style={{
                              padding: "0px",
                              paddingLeft: "14px",
                              height: "0px"
                            }}
                            classes={{
                              indeterminate: classes.indeterminateColor,
                            }}
                            checked={isAllSelectedTalentCat}
                            indeterminate={
                              talentCategoreyFilter?.length > 0 &&
                              talentCategoreyFilter?.length < categorynames?.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              paddingRight: "10px"
                            },
                          }}
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {categorynames?.map((option: any) => (
                        <MenuItem
                          style={{
                            fontSize: "13px",
                            color: "#333333",
                            fontFamily: "Arial",
                            padding: "0px",
                          }}
                          key={option}
                          value={option}
                        >
                          <ListItemIcon>
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: "13px !important",
                                },
                              }}
                              size="small"
                              style={{
                                padding: "0px", paddingLeft: "14px",
                                height: "0px"
                              }}
                              checked={talentCategoreyFilter.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText sx={{
                            "& .MuiTypography-root": {
                              fontSize: "13px",
                              fontFamily: "Arial",
                              color: "#333333",
                              paddingRight: "10px"
                            },
                          }} primary={option} />
                        </MenuItem>
                      ))}
                      {/* {categorynames.map((name) => (
                        <MenuItem
                          style={{
                            fontSize: "14px",
                            color: "#333333",
                            fontFamily: "Arial",
                            padding: "0px",
                          }}
                          key={name}
                          value={name}
                        >
                          <Checkbox
                            style={{ padding: "6px", paddingLeft: "12px" }}
                            size="small"
                            checked={talentCategoreyFilter.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </div>
              )}
            </Stack>
          </Stack>
        </Box>
      </div>
    </>
  );
}

export default ClosedCalendarfilter