import React from 'react'
import PerformanceGoalsView from '../performanceGoals/PerformanceGoalsView'

const PerformanceGoals = (props:any) => {
  const {showIfAppraiser,showIfEmployee,
    performanceGoalsFieldValues,
    performanceGoalsFieldValuesEmployee,
    showPerformanceGoalsFieldValuesEmployee,
    showPerformanceGoalsFieldValues} =props
  return (
    <>
    {showPerformanceGoalsFieldValues &&showIfAppraiser &&
    <PerformanceGoalsView 
    showIfAppraiser={showIfAppraiser}
    performanceGoals={performanceGoalsFieldValues}/>
    }
    {showPerformanceGoalsFieldValuesEmployee &&showIfEmployee &&
    <PerformanceGoalsView 
    showIfEmployee={showIfEmployee}
    performanceGoals={performanceGoalsFieldValuesEmployee}/>
    }
  </>
  )
}

export default PerformanceGoals