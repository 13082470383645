import * as React from "react";
import { useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Badge, FormControl, MenuItem, BadgeProps, Menu, Drawer } from "@mui/material";
import { Stack, Tab, Tabs, Box, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import { ListItemIcon } from '@mui/material';
import {
  useGetActiveCalenderQuery,
  useGetEmployeeByFilterQuery,
} from "../../../../service";
import { useEffect } from "react";
import {
  APPRAISAL_NOT_STARTED,
  VIEWPA,
  APPRAISER,
  NORMALIZER_VIEW_PA,
  REVIEWER_VIEW_PA,
} from "../../../../constants/routes/Routing";
import Eye from "../../../../assets/Images/Eye.svg"
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import Searchlensreview from "../../../../assets/Images/Searchlens.svg";
import Expand from "../../../../assets/Images/Expand.svg";
import Newexcel from "../../../../assets/Images/Newexcel.svg";
import Alert from "@mui/material/Alert";
import TablePagination from "@mui/material/TablePagination";
import Select from "@mui/material/Select";
import * as XLSX from "xlsx";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import { makeStyles } from '@mui/styles';
import GenerateReport from "../../../../components/ExportToExcel/GenerateReport";
import ExportToexcel from "../../../../components/ExportToExcel/ExporttoExcel";
import AlertDialogSuccess from "../../../../components/UI/DialogSuccess";
const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },
  tableRow: {
    ['@media (max-width:768px)']: {
      whiteSpace: "nowrap",
      "& .MuiTableCell-root": {
        padding: "4px",
      },
    }
  },
  tableRowhead: {
    ['@media (max-width:768px)']: {
      whiteSpace: "nowrap",
      "& .MuiTableCell-root": {
        padding: "10px",
      },
    },
    ['@media (max-width:1024px)']: {
      whiteSpace: "nowrap",
      "& .MuiTableCell-root": {
        padding: "6px",
      },
    }
  },
}));
const Mytable = styled("div")({
  background: "#FFFFFF",
  marginLeft: "25px",
  marginRight: "25px",
  position: "relative",
});
const Tabstyles = styled("div")({
  "& .MuiButtonBase-root": {
    color: "#999999",
    textTransform: "none",
    fontWeight: 400,
  },
  "& .Mui-selected": {
    color: "#004C75",
  },
  "&.MuiTabs-indicator": {
    backgroundColor: "#004C75",
  },
  display: "flex",
});
const Heading = styled("div")({
  fontSize: "18px",
  color: "#3e8cb5",
  fontFamily: "Arial",
});
const Searchfeild = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "28px",
    width: "144px",
    borderRadius: "15px",
    background: "#F2F6F8",
  },
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#306D8F",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "13px",
    color: "#306D8F",
  },
});
const TableHeadings = styled("div")({
  "& .MuiTableRow-head ": {
    background: "#eaeced",
  },
  "& .MuiTableCell-root": {
    padding: "0px",
  },
});
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -17,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
const Names = styled("div")({
  color: "#333333",
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//@ts-ignore
const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};
const LineManagersTeamTable = (props: any) => {
  const classes = useStyles();
  const { startAppraisal, employeeData1,setPage,page, SectionFilter, setSectionFilter, subSectionFilter,
    setSubSectionFilter, valueOfCard,GradeFilterr,SectionFilterr,subSectionFilterr,divisionFilterr, setTeamtableExpandActive, Role, Rolee,CalenderName } = props;
  const [tabValue, setTabValue] = React.useState<any>(0);
  const { data: activecalendardata} = useGetActiveCalenderQuery('')
  let CalendarName =activecalendardata?.data[0]?.name
  const [enteredName, setenteredName] = useState("");
  // function for Tab change 
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(0);
    setenteredName("");
  };
  const listInnerRef = useRef<any>();
  const { data: userLoginData } = useLoggedInUser();
  const [users, setUsers] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tablecount, settablecount] = React.useState<any>(0);
  const [pageNumber, setPageNumber] = useState(0)
  const navigate = useNavigate();
  //multiselect
  useEffect(() => {
    if (valueOfCard === "") {
      return setTabValue(0);
    } else if (valueOfCard === "Not-Started") {
      return setTabValue(2);
    } else if (valueOfCard === "In Progress") {
      return setTabValue(3);
    } else if (valueOfCard === "Normalized") {
      return setTabValue(3);
    } else if (valueOfCard === "Rejected") {
      return setTabValue(4);
    } else if (valueOfCard === "Completed") {
      return setTabValue(5);
    }
  }, [valueOfCard]);
  //filter
  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 400,

      },
    },
  };
// function for pagination nextpage 
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    // setPageNumber(pageNumber + 1)
  };
  // const { data: user } = useLoggedInUser();
  // const SELECT_FOR_DASHBOARD = `employee_code,overall_rating,legal_full_name,first_name,position_long_description,section,sub_section,grade,appraisal.appraiser_rating,reviewer.reviewer_rating,normalizer.normalizer_rating,previous_rating,appraisal.status,appraisal.appraiser_status,appraiser_status,appraisal.appraiser_rejected,reviewer.reviewer_status,normalizer.normalizer_status,normalizer?.normalizer_rejected,reviewer.rejection_count,appraisal.objective_description,reviewerIsDisabled,manager_name,talent_category,previous_rating`
  // const { data: employeeData } = useGetEmployeeByFilterQuery(
  //   `?manager_code=${user?.employee_code}&limit=600&select=${SELECT_FOR_DASHBOARD}`
  // );
// function for setting Employeedata get from Dashboard based on dashboard filter
  useEffect(() => {
    setUsers(employeeData1)
  }, [employeeData1]);
// function for Getting status based on status
  const getStatus = (status: any) => {
    if (status == "in-progress") {
      return "In progress"
    } else if (status == "not-started") {
      return "Not started"
    } else if (status == "normalized") {
      return "Normalized"
    } else if (status == "rejected") {
      return "Rejected "
    } else if (status == "completed") {
      return "Completed "
    }
  }
  const viewPAClickHandler = (j: any) => {
 if (j?.appraisal?.status === "not-started") {
      window.open(`${APPRAISAL_NOT_STARTED}/employee/${j._id}`, '_blank')
    } 
    // else {
    //   if (userLoginData) {
    //     if (userLoginData?.current_role === "Reviewer") {
    //       window.open(`${REVIEWER_VIEW_PA}/employee/${j._id}`, '_blank')
    //     } else if (userLoginData?.current_role === "Normalizer") {
    //       window.open(`${NORMALIZER_VIEW_PA}/employee/${j._id}`, '_blank')
    //     } else {
    //       window.open(`${NORMALIZER_VIEW_PA}/employee/${j._id}`, '_blank')
    //     }
    //   }
    // }
    else{
      window.open(`${VIEWPA}/employee/${j._id}`, '_blank')
      // VIEWPA
    }

  };
  const getPAStatus = (j: any) => {
    if (
      j?.appraisal?.objective_description &&
      j?.appraisal?.objective_description.length === 0
    ) return " PA Not Started";
    else if (j?.appraisal?.status == "completed") return "-";
    else if (j?.appraisal?.appraiser_status === "pending")
      return " Pending with Appraiser";
    else if (j?.appraisal?.status === "normalized")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status?.includes("draft")) return " Pending with Appraiser (Draft)";
    else if (
      j?.appraisal?.appraiser_status === "submitted" &&
      (j?.reviewer?.reviewer_status == "pending" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status === "accepted" &&
      (j?.reviewer?.reviewer_status == "pending" || j?.reviewer?.reviewer_status == "appraiser-accepted" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (j?.reviewer?.reviewer_status == "appraiser-rejected" || j?.reviewer?.reviewer_status == "draft") {
      return " Pending with Reviewer";
    } else if (
      (j?.reviewer?.reviewer_status == "accepted") &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "reviewer-rejected")
      return " Pending with Appraiser (Reviewer Rejection)";
    else if (j?.appraisal?.appraiser_status === "normalizer-rejected")
      return " Pending with Appraiser (HR Normalizer Rejection)";
    else if (j?.appraisal?.appraiser_status === "appraiser-rejected-employee")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser (Employee Rejection)";
    else if (
      j?.reviewer.reviewer_status == "accepted" &&
      j?.normalizer.normalizer_status == "employee-rejected"
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "accepted")
      return " Pending with Employee";
    else if (j?.normalizer?.normalizer_status === "draft")
      return " Pending with HR Normalizer";
    else if (
      j?.reviewer?.reviewer_status == "rejected" &&
      j?.reviewer?.rejection_count == 3 &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "employee-rejected")
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser";
    else if (
      j?.reviewer?.reviewer_status == "normalizer-rejected" &&
      j?.reviewer?.reviewer_status == "appraiser-rejected"
    )
      return "Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status == "normalizer-rejected" &&
      j?.appraisal?.appraiser_status == "accepted"
    )
      return "Pending with Reviewer";
    else return "-";
  };
 // function for setting employeedata based on the pastatus and the tabvalue
  const [EmployeeData, setEmployeeData] = React.useState<any>([])
  useEffect(() => {
    let PendingAction = users?.filter((j: any) => {
      return getPAStatus(j)?.includes("Pending with Appraiser");
    });
    let Notstarted = users?.filter((j: any) => {
      return j?.appraisal?.status === "not-started";
    });
    let inprogress = users?.filter((j: any) => {
      return j?.appraisal?.status === "in-progress" || j?.appraisal?.status === "normalized";
    });
    let emprejected = users?.filter((j: any) => {
      return j?.appraisal?.status === "rejected";
    });
    let completed = users?.filter((j: any) => {
      return j?.appraisal?.status === "completed";
    });
    if (tabValue === 0) {
      setEmployeeData(users)
    } else if (tabValue === 1) {
      setEmployeeData(PendingAction)
    } else if (tabValue === 2) {
      setEmployeeData(Notstarted)
    } else if (tabValue === 3) {
      setEmployeeData(inprogress)
    } else if (tabValue === 4) {
      setEmployeeData(emprejected)
    } else if (tabValue === 5) {
      setEmployeeData(completed)
    }
    console.log(EmployeeData, "EmployeeData11")
  }, [users, tabValue])
  // function for Generate report
  const handleExport3 = (columnHeadersDisplay: any) => {
    const filteredEmployeeData = users?.filter((item: any) => {
      return item?.appraisal?.pa_status === "Completed";
    });
      let lastEmployeeCode: string | null = null;
      var wb = XLSX.utils.book_new();
      var wsData: any[][] = [[]];
      wsData.push(
        ["Ecode",
          "Employee Name",
          "Known As",
          "Service Reference Date",
          "Position",
          "Grade",
          "Division",
          "Overall Rating",
          "Potential Level",
          "Talent Category",
          "Section",
          "Sub-section",
          "Appraiser Code",
          "Appraiser Name",
          "Training Category(Appraiser)",
          "Training Name(Appraiser)",
          "Justification(Appraiser)",
          "Training Category(Employee)",
          "Training Name(Employee)",
          "Justification(Employee)",
        ]
      );
      filteredEmployeeData.forEach((employee: any) => {
        const employeeCode =employee?.employee_code 
        let inputDate = employee?.service_reference_date;
        const dateParts = inputDate?.split("-");
        const date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        const formattedDate = `${day}-${month}-${year}`;
        if (lastEmployeeCode !== employeeCode) {
          const appraisalTraining = employee?.appraisal?.training_recommendation[0]
          const employeeTraining = employee?.employee?.training_recommendation[0]
          wsData.push([
            employeeCode,
            employee?.legal_full_name,
            employee?.first_name,
            formattedDate,
            employee?.position_long_description,
            employee?.grade,
            employee?.division,
            employee?.current_rating?.overall_rating?.toFixed(2),
            employee?.appraisal?.potential,
            employee?.talent_category,
            employee?.section,
            employee?.sub_section,
            employee?.appraiser_code,
            employee?.appraiser_name,
            appraisalTraining?.name?.title,
            appraisalTraining?.training_name,
            appraisalTraining?.justification,
            employeeTraining?.name?.title,
            employeeTraining?.training_name,
            employeeTraining?.justification,
          ]);
          lastEmployeeCode = employeeCode;
        }
        const maxTrainingRecommendations = Math.max(employee?.appraisal?.training_recommendation.length, employee?.employee?.training_recommendation.length);
          for (let i = 1; i < maxTrainingRecommendations; i++) {
          const appraisalTraining = employee?.appraisal?.training_recommendation[i] || {};
          const employeeTraining = employee?.employee?.training_recommendation[i] || {};
            wsData.push([
              employeeCode || "",
              employee?.legal_full_name || "",
              employee?.first_name || "",
              formattedDate,
              employee?.position_long_description || "",
              employee?.grade || "",
              employee?.division || "",
              employee?.current_rating?.overall_rating?.toFixed(2) || "",
              employee?.appraisal?.potential || "",
              employee?.talent_category || "",
              employee?.section || "",
              employee?.sub_section || "",
              employee?.appraiser_code || "",
              employee?.appraiser_name || "", // Empty columns for employee details
              appraisalTraining?.name?.title || "",
              appraisalTraining?.training_name || "",
              appraisalTraining?.justification || "",
              employeeTraining?.name?.title || "",
              employeeTraining?.training_name || "",
              employeeTraining?.justification || "",
            ]);
        }
      });
      const sheet2Data = filteredEmployeeData
      ?.map((j: any, emp: any, employee: any) => {
        let inputDate = j?.service_reference_date
        const dateParts = inputDate?.split("-");
        console.log(inputDate, dateParts, "inputDate")
        let date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        const formattedDate = `${day}-${month}-${year}`;
        let exportData: any = {}
        exportData["Ecode"] = j?.employee_code
        exportData["Employee Name"] = j?.legal_full_name
        exportData["Known As"] = j?.first_name
        exportData["Service Reference Date"] = formattedDate
        exportData["Position"] = j?.position_long_description
        exportData["Grade"] = j?.grade
        exportData["Function"] = j?.function
        exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
        exportData["Overall Rating"] = j?.current_rating?.overall_rating?.toFixed(2)
        exportData["Potential Level"] = (j?.appraisal?.potential)
        exportData["Division"] = j?.division
        exportData["Section"] = j?.section
        exportData["Sub-section"] = j?.sub_section
        exportData["Appraiser Code"] = j?.appraiser_code
        exportData["Appraiser Name"] = j?.appraiser_name
        exportData["Further Recommendations"] = j?.appraisal?.other_recommendation_others ?j?.appraisal?.other_recommendation_others :j?.appraisal?.other_recommendation?.map((rec: any) => rec?.name?.name).join(', ')
        return exportData
      });
      var wsData1: any[][] = [[]];
      wsData1.push(
        ["Ecode",
        "Employee name",
        "Known As",
        "Service Reference Date",
        "Position",
        "Grade",
        "Division",
        "Overall Rating",
        "Potential Level",
        "Talent Category",
        "Section",
        "Sub-section",
        "Appraiser Code",
        "Appraiser Name",
        "Specific Area(Appraiser)",
        "Specific Action(Appraiser)",
        "Specific Area(Employee)",
        "Specific Action(Employee)",
        ]
      );
    filteredEmployeeData.forEach((employee: any) => {
      const employeeCode = employee?.employee_code
      let inputDate = employee?.service_reference_date;
      const dateParts = inputDate?.split("-");
      const date = new Date(inputDate);
      const year = date?.getFullYear();
      const month = date?.toLocaleString("default", { month: "short" });
      const day = date?.getDate();
      const formattedDate = `${day}-${month}-${year}`;
      if (lastEmployeeCode !== employeeCode) {
        if (lastEmployeeCode !== null) {
        }
        const maxTrainingRecommendations = Math.max(employee?.appraisal?.training_recommendation.length, employee?.employee?.training_recommendation.length);
        const appraisalTraining = (employee?.appraisal?.area_of_improvement || [])[0] || {};
        const employeeTraining = (employee?.employee?.area_of_improvement || [])[0] || {};
        const appraisalSpecificAction = (appraisalTraining?.specific_actions || [])[0]?.value || "";
        const employeeSpecificAction = (employeeTraining?.specific_actions || [])[0]?.value || "";
        wsData1.push([
          employeeCode ,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate ,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.current_rating?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.value,
          appraisalSpecificAction || "",
          employeeTraining?.value,
          employeeSpecificAction || "",
        ]);
        lastEmployeeCode = employeeCode;
      }
      // Update maxTrainingRecommendations for the current employee
      const maxTrainingRecommendations = Math.max(employee?.appraisal?.training_recommendation.length, employee?.employee?.training_recommendation.length);
      // Add training recommendation details for the subsequent rows (excluding the first row)
      for (let i = 1; i < maxTrainingRecommendations; i++) {
        const appraisalTraining = employee?.appraisal?.area_of_improvement[i] || {};
        const employeeTraining = employee?.employee?.area_of_improvement[i] || {};
        wsData1.push([
          employeeCode,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.current_rating?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.value || "",
          (appraisalTraining?.specific_actions || [])[0]?.value || "",
          employeeTraining?.value || "",
          (employeeTraining?.specific_actions || [])[0]?.value || "",
        ]);
      }
    });
// shift method remove first row of index number
        wsData.shift();
        wsData1.shift();
        const sheet3 = XLSX.utils.aoa_to_sheet(wsData1);
        const sheet2 = XLSX.utils.json_to_sheet(sheet2Data, { header: Object.keys(sheet2Data[0]) });
        const sheet1 = XLSX.utils.aoa_to_sheet(wsData);
        if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.furtherrecommendation && columnHeadersDisplay.Areaofimprovement) {
          XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
          XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
          XLSX.utils.book_append_sheet(wb, sheet2, 'Further Recommendation');
        } else if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.furtherrecommendation) {
          XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
          XLSX.utils.book_append_sheet(wb, sheet2, 'Further Recommendation');
        } else if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.Areaofimprovement) {
          XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
          XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
        }else if (columnHeadersDisplay.furtherrecommendation && columnHeadersDisplay.Areaofimprovement) {
          XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
          XLSX.utils.book_append_sheet(wb, sheet2, 'Further Recommendation');
        }else if(columnHeadersDisplay.TrainingRecommendation){
          XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
        }else if(columnHeadersDisplay.furtherrecommendation){
          XLSX.utils.book_append_sheet(wb, sheet2, 'Further Recommendation');
        }else if( columnHeadersDisplay.Areaofimprovement){
          XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
        }
        XLSX.writeFile(wb, `Performance Appraisal Report -${CalenderName}.xlsx`);
    }
// function for Badge count 
  const [inProgressemp, setinProgressemp] = React.useState<any>(0);
  const [notStartedemp, setnotStartedemp] = React.useState<any>(0);
  const [emprejected, setemprejected] = React.useState<any>(0);
  const [mypendingActions, setmypendingActions] = React.useState<any>(0);
  const [completedemp, setcompletedemp] = React.useState<any>(0);
  const [All, setAll] = React.useState<any>(0);
  useEffect(() => {
    setAll(users?.length);
    let completed = users?.filter((j: any) => {
      return j?.appraisal?.status === "completed";
    });
    setcompletedemp(completed?.length);
    let mypendingAction = users?.filter((j: any) => {
      return getPAStatus(j)?.includes("Pending with Appraiser");
    });
    setmypendingActions(mypendingAction?.length);
    let inprogress = users?.filter((j: any) => {
      return j?.appraisal?.status === "in-progress" || j?.appraisal?.status === "normalized";
    });
    setinProgressemp(inprogress?.length);
    let notstarted = users?.filter((j: any) => {
      return j?.appraisal?.status === "not-started";
    });
    setnotStartedemp(notstarted?.length);
    let rejected = users?.filter((j: any) => {
      return j?.appraisal?.status === "rejected";
    });
    setemprejected(rejected?.length);
  }, [users, employeeData1]);

  const [filData, setfilData] = React.useState<any>([]);
  //  Filter icon
  const [icon2, setIcon2] = React.useState<any>([]);
  const [icon3, setIcon3] = React.useState<any>([]);
  const [positionsFilter, setpositionsFilter] = React.useState<string[]>([]);
  const [GradesFilter, setGradesFilter] = React.useState<string[]>([]);
  useEffect(() => {
    if (positionsFilter?.length == 0) {
      setIcon2(false);
    } else {
      setIcon2(true);
    }
  }, [positionsFilter])
  useEffect(() => {
    if (GradesFilter?.length == 0) {
      setIcon3(false);
    } else {
      setIcon3(true);
    }
  }, [GradesFilter])
//..... filtericon

  // Badge
  //drawer functions
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  const [isDrawerOpen1, setisDrawerOpen1] = React.useState(false);
  const [GenerateReportOpen, setGenerateReportOpen] = React.useState(false);
 const handleGenerateReportOpen = () =>{
  setGenerateReportOpen(false)
 }
  // drawer open function for Export to excel
  const handleExportFunction = () => {
    setisDrawerOpen(true);
  };
  // function for  If no employee is completed showing the popup 
  const handleExportFunction1 = () => {
    const filteredEmployeeData = users?.filter((item: any) => {
      return item?.appraisal?.pa_status === "Completed";
    });
    console.log(filteredEmployeeData?.length,"length")
    if(filteredEmployeeData?.length == 0){
      setGenerateReportOpen(true)
    }else{
      setGenerateReportOpen(false)
      setisDrawerOpen1(true);
    }
  };
  // columnHeaders used for export to excel
  const [columnHeaders, setcolumnHeaders] = useState<any>({
    Ecode: true,
    Ename: true,
    Eposition: true,
    Firstname: true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    OverallRating: true,
    employeerating: true,
    appraiserRating: true,
    reviewerRating: true,
    normalizerRating: true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    appraiserCode: true,
    reviewerCode: true,
    normalizerCode: true,
    status: true,
    ServiceReferenceDate: true,
    Function: true,
    SupervisoryRole: true,
    EmailID: true,
    CalendarName: true,
    SelectAll: true,
    PendingAction:true,
    // FurtherRecommendations:true
  })
 // Totalcount and checkedcount used for Selectall choose field checkbox
  const checkedCount = Object.keys(columnHeaders).reduce((count:any, key:any) => {
    if (key !== 'SelectAll') {
      return count + (columnHeaders[key] ? 1 : 0);
    }
    return count;
  }, 0);

  const totalCount = Object.keys(columnHeaders).length - 1; 
// Totalcount and checkedcount used for Selectall choose field checkbox
// columnHeadersDisplay used for generate report 
  const [columnHeadersDisplay, setcolumnHeadersDisplay] = useState<any>({
    TrainingRecommendation:true,
    Areaofimprovement:true,
    furtherrecommendation:true
  })
  //---------------------------------new drawer function(need to replace)
  const [ServiceReferenceDateData, setServiceReferenceDateData] = React.useState(true);
  const [FunctionData, setFunctionData] = React.useState(true);
  const [SupervisoryRoleData, setSupervisoryRoleData] = React.useState(true);
  const [EmailIDData, setEmailIDData] = React.useState(true);
  const [rejstatusValue, rejsetstatusValue] = React.useState(true);
  const [firstName, setfirstName] = React.useState(true);
  const [heading1, setheading1] = React.useState(true);
  const [heading2, setheading2] = React.useState(true);
  const [heading3, setheading3] = React.useState(true);
  const [heading4, setheading4] = React.useState(true);
  const [appraiserRating, setAppraiserRating] = React.useState(true);
  const [reviewerRating, setReviewerRating] = React.useState(true);
  const [normalizeRating, setNormalizeRating] = React.useState(true);
  const [employeerating, setemployeerating] = React.useState(true);
  const [headingOfEcode, setheadingOfEcode] = React.useState(true);
  const [headingOfSection, setheadingOfSection] = React.useState(true);
  const [headingOfPAStatus, setheadingOfPAStatus] = React.useState(true);
  const [headingOfDivision, setheadingOfDivision] = React.useState(true);
  const [headingOfSubSection, setheadingOfSubSection] = React.useState(true);
  const [headingOfAppraiserName, setheadingOfAppraiserName] = React.useState(true);
  const [headingOfReviewerName, setheadingOfReviewerName] = React.useState(true);
  const [headingOfNormalizerName, setheadingOfNormalizerName] = React.useState(true);
  const [headingOfOverallRating, setheadingOfOverallRating] = React.useState(true);
  const [headingOfPreviousRating, setheadingOfPreviousRating] = React.useState(true);
  const [headingOfPotentialLevel, setheadingOfPotentialLevel] = React.useState(true);
  const [headingOfTalentCategory, setheadingOfTalentCategory] = React.useState(true);
  const [headingOfWorkLocation, setheadingOfWorkLocation] = React.useState(true);
  const [headingOfManagerCode, setheadingOfManagerCode] = React.useState(true);
  const [headingOfManagerName, setheadingOfManagerName] = React.useState(true);
  const [headingOfManagerPosition, setheadingOfManagerPosition] = React.useState(true);
  const [appCodes, setappCodes] = React.useState(true);
  const [revCodes, setrevCodes] = React.useState(true);
  const [norCodes, setnorCodes] = React.useState(true);
  const [statusValue, setstatusValue] = React.useState(true);
  const [Areaofimprovement, setAreaofimprovement] = React.useState(true);
  const [TrainingRecommendation, setTrainingRecommendation] = React.useState(true);
  const [furtherrecommendation, setfurtherrecommendation] = React.useState(true);
  const handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstatusValue(event.target.checked);
    const { name, checked } = event.target;
      setcolumnHeaders((prevColumnHeaders:any) => ({
        ...prevColumnHeaders,
        [name]: checked,
      }));
  };
  const handleAppCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setappCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingcalendar, setheadingcalendar] = React.useState(true);
  const handleheadingCalendar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingcalendar(event.target.checked);
    const { name, checked } = event.target;
      setcolumnHeaders((prevColumnHeaders:any) => ({
        ...prevColumnHeaders,
        [name]: checked,
      }));
  }
  const handleRevCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setrevCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNorCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnorCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAreaofimprovement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAreaofimprovement(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlefurtherrecommendation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfurtherrecommendation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleTrainingRecommendation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrainingRecommendation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleEmployeeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfEcode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading1(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading2(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading3(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleDivision = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfDivision(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSubSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSubSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfAppraiserName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfReviewerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSelectAll = (selectAll:any) => {
    const updatedColumnHeaders = { ...columnHeaders };
    Object.keys(updatedColumnHeaders).forEach((key) => {
      updatedColumnHeaders[key] = selectAll;
    });
    setcolumnHeaders(updatedColumnHeaders);
  };
  const handleNormalizerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfNormalizerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleOverallRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfOverallRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePreviousRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPreviousRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePotentialLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPotentialLevel(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleTalentCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfTalentCategory(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleWorkLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfWorkLocation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerPosition(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleemployeerating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemployeerating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));  };
  const handleAppraiserRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppraiserRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));  };
  const handlefirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfirstName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleRejectionStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    rejsetstatusValue(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));  };
  const handleServiceReferenceDateData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceReferenceDateData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));  };
    const [headingOfPendingAction, setheadingOfPendingAction] = React.useState(true);

    const handlePendingAction = (event: React.ChangeEvent<HTMLInputElement>) => {
        setheadingOfPendingAction(event.target.checked);
        const { name, checked } = event.target;
        setcolumnHeaders((prevColumnHeaders: any) => ({
          ...prevColumnHeaders,
          [name]: checked,
          SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
        }));
      };
  const handleFunctionData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));  };
  const handleSupervisoryRoleData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupervisoryRoleData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));  };
  const handleEmailIDData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailIDData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));  };
  const handleReviewerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewerRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNormalizeRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  //---------------------------------new drawer function(need to replace)
  // function for Export to excel
  const handleExport = () => {
    const mapped = EmployeeData
      ?.filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      ?.filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      ?.filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (
          (j?.employee_code !== undefined &&
            j?.employee_code
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.grade !== undefined &&
            j?.grade
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.appraisal?.status !== undefined &&
            j?.appraisal?.status
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (getPAStatus(j) !== undefined &&
            getPAStatus(j)
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase()))
        ) {
          return j;
        }
      })
      ?.map((j: any, emp: any, employee: any) => {
        let inputDate = j?.service_reference_date
        const dateParts = inputDate?.split("-");
        console.log(inputDate, dateParts, "inputDate")
        let date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        const formattedDate = `${day}-${month}-${year}`;
        let exportData: any = {}
        if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = CalendarName
        if (columnHeaders["Ecode"] == true) exportData["Ecode"] = j?.employee_code
        if (columnHeaders["Ename"] == true) exportData["Employee Name"] = j?.legal_full_name
        if (columnHeaders["Firstname"] == true) exportData["Known As"] = j?.first_name
        if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = formattedDate
        if (columnHeaders["Eposition"] == true) exportData["Position"] = j?.position_long_description
        if (columnHeaders["EGrade"] == true) exportData["Grade"] = j?.grade
        if (columnHeaders["Function"] == true) exportData["Function"] = j?.function
        if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
        if (columnHeaders["EmailID"] == true) exportData["Email ID"] = j?.email
        if (columnHeaders["EDivision"] == true) exportData["Division"] = j?.division
        if (columnHeaders["ESection"] == true) exportData["Section"] = j?.section
        if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = j?.sub_section
        if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = j?.work_location
        if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = j?.appraiser_code
        if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = j?.appraiser_name
        if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = j?.reviewer_code
        if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = j?.reviewer_name
        if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = j?.normalizer_code
        if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = j?.normalizer_name
        if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = j?.employee?.employee_rating?.toFixed(2)
        if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = j?.appraisal?.appraiser_rating?.toFixed(2)
        if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = j?.reviewer?.reviewer_rating?.toFixed(2)
        if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = j?.normalizer?.normalizer_rating?.toFixed(2)
        if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = (j?.current_rating?.overall_rating == 0 || j?.appraisal?.status !== "completed") ? "-" : j?.current_rating?.overall_rating?.toFixed(2)
        if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = j?.previous_rating?.toFixed(2)
        if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = (j?.normalizer?.normalized_overallRating !== 0 ? j?.appraisal?.potential : "")
        if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = j?.talent_category
        if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = j?.manager_code
        if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = j?.manager_name
        if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = j?.manager_position
        if (columnHeaders["status"] == true) exportData["Status"] = getStatus(j?.appraisal?.status)
          if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] = j.appraisal?.status == "not-started" ? "Pending with Appraiser" :j.appraisal?.status == "completed" ? ("-") : j.appraisal.pa_status

        return exportData
      });
    const a = [1]
    const Emptymapped = a.map((j: any) => {
      let exportData: any = {}
      if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = ""
      if (columnHeaders["Ecode"] == true) exportData["Ecode"] = ""
      if (columnHeaders["Ename"] == true) exportData["Employee Name"] = ""
      if (columnHeaders["Firstname"] == true) exportData["Known As"] = ""
      if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date "] = ""
      if (columnHeaders["Eposition"] == true) exportData["Position"] = ""
      if (columnHeaders["EGrade"] == true) exportData["Grade"] = ""
      if (columnHeaders["Function"] == true) exportData["Function"] = ""
      if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = ""
      if (columnHeaders["EmailID"] == true) exportData["Email ID"] = ""
      if (columnHeaders["EDivision"] == true) exportData["Division"] = ""
      if (columnHeaders["ESection"] == true) exportData["Section"] = ""
      if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = ""
      if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = ""
      if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = ""
      if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = ""
      if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = ""
      if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = ""
      if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = ""
      if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = ""
      if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = ""
      if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = ""
      if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = ""
      if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = ""
      if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = ""
      if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = ""
      if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = ""
      if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = ""
      if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = ""
      if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = ""
      if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = ""
      if (columnHeaders["status"] == true) exportData["Status"] = ""
      if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] ="" 

      return exportData
    });
    console.log(mapped, "mapped")
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(mapped == "" ? Emptymapped : mapped);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "MyExcel.xlsx");
  }

  // Function for Close Button on General report 
  const handleCloseGrade1 = () => {
    setisDrawerOpen1(false);
    // function for reseting checkbox state on Generate report
    setcolumnHeadersDisplay({
      TrainingRecommendation:true,
      Areaofimprovement:true,
      furtherrecommendation:true
    })
  }
  // Function for Close button on Export to excel
  const handleCloseGrade = () => {
    setisDrawerOpen(false);
    // Reseting checking while closing the drawer with close button
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Eposition: true,
      Firstname: true,
      ESection: true,
      EGrade: true,
      EDivision: true,
      ESubSection: true,
      AppraiserName: true,
      ReviewerName: true,
      NormalizerName: true,
      OverallRating: true,
      employeerating: true,
      appraiserRating: true,
      reviewerRating: true,
      normalizerRating: true,
      PreviousRating: true,
      PotentialLevel: true,
      TalentCategory: true,
      WorkLocation: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      appraiserCode: true,
      reviewerCode: true,
      normalizerCode: true,
      status: true,
      ServiceReferenceDate: true,
      Function: true,
      SupervisoryRole: true,
      EmailID: true,
      CalendarName: true,
      SelectAll: true,
      PendingAction:true,
    })
  };
  // Function for General Report Apply button
  const handleheadingSortAccept1 = () => {
    setisDrawerOpen1(false);
    handleExport3(columnHeadersDisplay)
    setcolumnHeaders({
      TrainingRecommendation:true,
      Areaofimprovement:true,
      furtherrecommendation:true
    })
  }
  // function for Export to excel apply button
  const handleheadingSortAccept = () => {
    setisDrawerOpen(false);
    handleExport();
    // function for reseting checkbox states
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Eposition: true,
      Firstname: true,
      ESection: true,
      EGrade: true,
      EDivision: true,
      ESubSection: true,
      AppraiserName: true,
      ReviewerName: true,
      NormalizerName: true,
      OverallRating: true,
      employeerating: true,
      appraiserRating: true,
      reviewerRating: true,
      normalizerRating: true,
      PreviousRating: true,
      PotentialLevel: true,
      TalentCategory: true,
      WorkLocation: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      appraiserCode: true,
      reviewerCode: true,
      normalizerCode: true,
      status: true,
      ServiceReferenceDate: true,
      Function: true,
      SupervisoryRole: true,
      EmailID: true,
      CalendarName: true,
      SelectAll: true,
      PendingAction:true,
    })
  };
  //For multislect options
  const [gradesArray, setgradesArray] = React.useState<any>([]);
  const [positionArray, setpositionArray] = React.useState<any>([]);
  useEffect(() => {
    let grades = EmployeeData
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.grade - b?.grade;
      })
      ?.map((i: any) => {
        return i?.grade;
      });
    //for filtering graades options
    if (positionsFilter.length > 0) {
      grades = users
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade - b?.grade;
        })
        ?.filter((i: any) => {
          return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    // search functionality based on grade
    else if (enteredName?.length > 0) {
      grades = EmployeeData
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade?.localeCompare(
            b?.grade
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const enteredTerms = enteredName.toLowerCase().split(" ");
            return enteredTerms.every(term =>
              i?.grade
                ?.toLowerCase()
                .includes(term)
            ) || enteredTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            )
            || enteredTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )|| enteredTerms.every((term:any) =>
            i?.appraisal?.pa_status?.toLowerCase().includes(term)
          )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    const gradeContents = grades
      ?.filter((c: any, index: any) => {
        return grades?.indexOf(c) === index && c != null && c != undefined;
      });
    setgradesArray(gradeContents);
    console.log(grades, gradeContents, gradesArray, "gradeContents")
    let position = EmployeeData
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.position_long_description?.localeCompare(b?.position_long_description);
      })
      ?.map((i: any) => {

        return i?.position_long_description;
      });
    // GradesFilter
    if (GradesFilter?.length > 0) {
      position = EmployeeData
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description - b?.position_long_description;
        })
        ?.filter((i: any) => {
          return !!GradesFilter?.find(item2 => i?.grade === item2)
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    else if (enteredName?.length > 0) {
      position = EmployeeData
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description?.localeCompare(
            b?.position_long_description
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const enteredTerms = enteredName.toLowerCase().split(" ");
            return enteredTerms.every(term =>
              i?.position_long_description
                ?.toLowerCase()
                .includes(term)
            )|| enteredTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            )|| enteredTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            )|| enteredTerms.every((term:any) =>
            i?.appraisal?.pa_status?.toLowerCase().includes(term)
          )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    const positionContents = position?.filter((c: any, index: any) => {
      return position?.indexOf(c) === index && c != null && c != undefined;

    });
    setpositionArray(positionContents);
  }, [EmployeeData, positionsFilter, GradesFilter, enteredName])
  //for section multiselect
  const [sectionsFilter, setsectionsFilter] = React.useState<string[]>([]);
  const isAllGradesFilter =
    gradesArray?.length > 0 && GradesFilter?.length === gradesArray?.length;
  const newsection = gradesArray?.length == GradesFilter?.length
  const handleChangeSelectGrades = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setGradesFilter(GradesFilter?.length === gradesArray?.length ? [] : gradesArray);
      return;
    }
    setGradesFilter(value);
    setPage(0);
  };
  const isAllpositionsFilter =
    positionArray?.length > 0 && positionsFilter?.length === positionArray?.length;
  const handleChangeSelectPositions = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setpositionsFilter(positionsFilter?.length === positionArray?.length ? [] : positionArray);
      return;
    }
    setpositionsFilter(value);
    setPage(0);
  };
// Function for pagination count
  useEffect(() => {
    const paginate = EmployeeData
      ?.filter((item1: any) => {
        if (positionsFilter?.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      ?.filter((item1: any) => {
        if (GradesFilter?.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
       ?.filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (
          (j?.employee_code !== undefined &&
            j?.employee_code
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.grade !== undefined &&
            j?.grade
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              ))||
              (j?.appraisal?.pa_status !== undefined &&
                j?.appraisal?.pa_status
                  ?.toLocaleLowerCase()
                  ?.includes(
                    enteredName?.toLocaleLowerCase()
                  ))  ||
          (j?.appraisal?.status !== undefined &&
            j?.appraisal?.status
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (getPAStatus(j) !== undefined &&
            getPAStatus(j)
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase()))
        ) {
          return j;
        }
      })
    settablecount(paginate?.length)
  }, [tabValue, EmployeeData, positionsFilter, GradesFilter, enteredName])
// function for scrollbar
  const onScroll = () => {
    console.log('running scroll')
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPageNumber(pageNumber + 1);
      }
    }
  };
   const maxLengthForSearch = 30;
  //  function for search 
  const handleSearchBar = (e: any) => {
      if (e.target.value.length > maxLengthForSearch) {
        e.target.value = e.target.value?.slice(0, maxLengthForSearch);
      }
      setenteredName(e.target.value);
      setPage(0);
    }
  const tableDataFilterdLength: any[] = EmployeeData
    ?.filter((item1: any) => {
      if (positionsFilter.includes("None") || positionsFilter.length === 0) {
        return item1;
      } else {
        return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
      }
    })
    ?.filter((item1: any) => {
      if (GradesFilter.includes("None") || GradesFilter.length === 0) {
        return item1;
      } else {
        return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
      }
    })
    ?.filter((j: any) => {
      if (enteredName === "") {
        return j;
      } else if (
        (j?.employee_code !== undefined &&
          j?.employee_code
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.legal_full_name !== undefined &&
          j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j.grade !== undefined &&
          j?.grade
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j.position_long_description !== undefined &&
          j?.position_long_description
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.appraisal?.status !== undefined &&
          j?.appraisal?.status
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
            (j?.appraisal?.pa_status !== undefined &&
              j?.appraisal?.pa_status
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
        (getPAStatus(j) !== undefined &&
          getPAStatus(j)
            ?.toLocaleLowerCase()
            ?.includes(enteredName?.toLocaleLowerCase()))
      ) {
        return j;
      }
    })

  return (
    <Mytable>
      <Stack
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="30px"
        paddingBottom="10px"
      >
        <AlertDialogSuccess
          isAlertOpen={GenerateReportOpen}
          handleAlertClose={handleGenerateReportOpen}
        >
          Unable to Generate Report: No employees have completed appraisals.
        </AlertDialogSuccess>
        <Heading>My Team</Heading>
        <Button
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: "Arial",
            borderColor: "#3E8CB5",
            color: "#3E8CB5",
            background: "transparent",
            height: "35px",
          }}
          variant="outlined"
          onClick={handleExportFunction1}
        >
          Generate Report
        </Button>
      </Stack>
      <Stack
        marginLeft="24px"
        marginRight="24px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderBottom: 1, borderColor: "#E3E3E3", }}
      >
        <Tabstyles>
          <Box sx={{ maxWidth: { xs: 460, md: 730, lg: 1000 } }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label={
                  <StyledBadge badgeContent={All} color="primary" max={999}>
                    All
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "200px", display: "none" }}
                label={

                  <StyledBadge
                    badgeContent={mypendingActions}
                    color="primary"
                    max={999}
                  > My pending actions
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "155px" }}
                label={
                  <StyledBadge
                    badgeContent={notStartedemp}
                    color="primary"
                    max={999}
                  >Not started
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "142px" }}
                label={
                  <StyledBadge
                    badgeContent={inProgressemp}
                    color="primary"
                    max={999}
                  >In progress
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "190px" }}
                label={
                  <StyledBadge badgeContent={emprejected} color="primary" max={999}>
                    Employees rejection
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "125px" }}
                label={
                  <StyledBadge
                    badgeContent={completedemp}
                    color="primary"
                    max={999}
                  > Completed
                  </StyledBadge>}
              />
            </Tabs>
          </Box>
        </Tabstyles>
        <div>
          <Stack direction="row" alignItems="flex-start" >
            <Searchfeild
            >
              <TextField
                id="outlined-basic"
                placeholder="Search Here..."
                autoComplete="off"
                inputProps={{ maxLength: 256 }}
                onChange={handleSearchBar}
                value={enteredName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Searchlensreview} alt="icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </Searchfeild>
            <div>
              <img
                src={Newexcel}
                alt="icon"
                style={{ marginLeft: "15px", marginTop: "5px", cursor: "pointer" }}
                onClick={handleExportFunction}
              />
            </div>
            {Role != "Reviewer" && (
              <img
                src={Expand}
                alt="icon"
                style={{ marginLeft: "15px", marginTop: "5px", cursor: "pointer" }}
                onClick={() => {
                  navigate('/overalldashboardexpandtable', {
                    state: {
                      Role: Rolee, tabValue: tabValue, EmployeeData: EmployeeData
                    }
                  })
                }}
              />
            )}
          </Stack>
        </div>
        <GenerateReport
          isDrawerOpen1={isDrawerOpen1}
          setisDrawerOpen1={setisDrawerOpen1}
          handleTrainingRecommendation={handleTrainingRecommendation}
          handlefurtherrecommendation={handlefurtherrecommendation}
          handleAreaofimprovement={handleAreaofimprovement}
          handleheadingSortAccept1={handleheadingSortAccept1}
          handleCloseGrade1={handleCloseGrade1}
          columnHeadersDisplay={columnHeadersDisplay} />
        <ExportToexcel
          isDrawerOpen={isDrawerOpen}
          setisDrawerOpen={setisDrawerOpen}
          handleSelectAll={handleSelectAll}
          columnHeaders={columnHeaders}
          checkedCount={checkedCount}
          totalCount={totalCount}
          handleheadingCalendar={handleheadingCalendar}
          handleEmployeeCode={handleEmployeeCode}
          handleheading1={handleheading1}
          handlefirstName={handlefirstName}
          handleServiceReferenceDateData={handleServiceReferenceDateData}
          handleheading2={handleheading2}
          handleheading3={handleheading3}
          handleFunctionData={handleFunctionData}
          handleSupervisoryRoleData={handleSupervisoryRoleData}
          handleEmailIDData={handleEmailIDData}
          handleDivision={handleDivision}
          handleSection={handleSection}
          handleSubSection={handleSubSection}
          handleWorkLocation={handleWorkLocation}
          handleAppCodes={handleAppCodes}
          handleAppraiserName={handleAppraiserName}
          handleRevCodes={handleRevCodes}
          handleReviewerName={handleReviewerName}
          handleNorCodes={handleNorCodes}
          handleNormalizerName={handleNormalizerName}
          handleemployeerating={handleemployeerating}
          handleAppraiserRating={handleAppraiserRating}
          handleReviewerRating={handleReviewerRating}
          handleNormalizerRating={handleNormalizerRating}
          handleOverallRating={handleOverallRating}
          handlePreviousRating={handlePreviousRating}
          handlePotentialLevel={handlePotentialLevel}
          handleTalentCategory={handleTalentCategory}
          handleManagerCode={handleManagerCode}
          handleManagerName={handleManagerName}
          handleManagerPosition={handleManagerPosition}
          handleStatus={handleStatus}
          handleheadingSortAccept={handleheadingSortAccept}
          handleCloseGrade={handleCloseGrade}
          handlePendingAction={handlePendingAction}
        />
      </Stack>
      <TableHeadings>
        <TableContainer
        >
          <Table style={{ padding: "24px" }} size="small" aria-label="simple table" stickyHeader >
            <TableHead
              style={{ height: "30px" }}>
              <TableRow
                className={classes.tableRowhead}
                sx={{
                  "& td, & th": {
                    bgcolor: "#eaeced",
                  },
                }}
              >
                <TableCell align="center"
                  sx={{
                    fontFamily: "Arial",
                    color: "#3E8CB5",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  width="6%">
                  <div
                    style={{
                      color: "#3e8cb5",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Arial",
                      border: "none",
                      background: "none",
                    }}
                  >
                    Ecode
                  </div>
                </TableCell>
                <TableCell align="center" width="20%"
                  sx={{
                    fontFamily: "Arial",
                    color: "#3E8CB5",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  <div
                    style={{
                      color: "#3e8cb5",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Arial",
                      border: "none",
                      background: "none",
                    }}
                  >
                    Employee Name
                  </div>
                </TableCell>
                <TableCell align="center" width="15%"
                  sx={{
                    fontFamily: "Arial",
                    color: "#3E8CB5",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                ><div
                    style={{
                      color: "#3e8cb5",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Arial",
                      border: "none",
                      background: "none",
                    }}
                  ><FormControl sx={{ m: 0, width: 80, height: "0" }}>
                      <Stack direction="row">
                        <span>Position</span>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          size="small"
                          sx={{
                            width: "25px", fontSize: "0rem",
                            "& .MuiSvgIcon-root": {
                              color: "#3e8cb5 !important"
                            },
                          }}
                          disableUnderline
                          variant="standard"
                          MenuProps={MenuProps}
                          multiple
                          value={positionsFilter}
                          onChange={handleChangeSelectPositions}
                          renderValue={(selected) => selected.join(', ')}
                        ><MenuItem
                            style={{
                              fontSize: "14px",
                              color: "#333333",
                              fontFamily: "Arial",
                              padding: "0px",
                            }}
                            key="all"
                            value="all"
                            classes={{
                              root: isAllpositionsFilter ? classes.selectedAll : "",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "14px !important",
                                  },
                                }}
                                style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                size="small"
                                classes={{
                                  indeterminate: classes.indeterminateColor,
                                }}
                                checked={isAllpositionsFilter}
                                indeterminate={
                                  positionsFilter?.length > 0 &&
                                  positionsFilter?.length < positionArray?.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "13px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                  paddingRight: "10px"
                                },
                              }}
                              classes={{ primary: classes.selectAllText }}
                              primary="Select All"
                            />
                          </MenuItem>
                          {positionArray?.map((option: any) => (
                            <MenuItem
                              style={{
                                fontSize: "14px",
                                color: "#333333",
                                fontFamily: "Arial",
                                padding: "0px",
                              }}
                              key={option}
                              value={option}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: "14px !important",
                                    },
                                  }}
                                  style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                  size="small"
                                  checked={positionsFilter.indexOf(option) > -1}
                                />
                              </ListItemIcon>
                              <ListItemText sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "13px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                  paddingRight: "10px"
                                },
                              }} primary={option} />
                            </MenuItem>
                          ))}
                        </Select>
                        {icon2 && (
                          <FilterAltTwoToneIcon />)}
                      </Stack>
                    </FormControl>
                  </div>
                </TableCell>
                <TableCell align="center" width="5%">
                  <div
                    style={{
                      color: "#3e8cb5",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Arial",
                      border: "none",
                      background: "none",
                    }}
                  >
                    <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                      <Stack direction="row">
                        <span>Grade</span>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          size="small"
                          sx={{
                            width: "25px", fontSize: "0rem",
                            "& .MuiSvgIcon-root": {
                              color: "#3e8cb5 !important"
                            },
                          }}
                          disableUnderline
                          variant="standard"
                          MenuProps={MenuProps}
                          multiple
                          value={GradesFilter}
                          onChange={handleChangeSelectGrades}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          <MenuItem
                            style={{
                              fontSize: "14px",
                              color: "#333333",
                              fontFamily: "Arial",
                              padding: "0px",
                            }}
                            key="all"
                            value="all"
                            classes={{
                              root: isAllGradesFilter ? classes.selectedAll : "",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "14px !important",
                                  },
                                }}
                                style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                size="small"
                                classes={{
                                  indeterminate: classes.indeterminateColor,
                                }}
                                checked={isAllGradesFilter}
                                indeterminate={
                                  GradesFilter?.length > 0 &&
                                  GradesFilter?.length < gradesArray?.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "14px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                  paddingRight: "10px"
                                },
                              }}
                              classes={{ primary: classes.selectAllText }}
                              primary="Select All"
                            />
                          </MenuItem>
                          {gradesArray?.map((option: any) => (
                            <MenuItem
                              style={{
                                fontSize: "14px",
                                color: "#333333",
                                fontFamily: "Arial",
                                padding: "0px",
                              }}
                              key={option}
                              value={option}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: "14px !important",
                                    },
                                  }}
                                  style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                  size="small"
                                  checked={GradesFilter.indexOf(option) > -1}
                                />
                              </ListItemIcon>
                              <ListItemText sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "13px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                  paddingRight: "10px"
                                },
                              }} primary={option} />
                            </MenuItem>

                          ))}
                        </Select>
                        {icon3 && (
                          <FilterAltTwoToneIcon />)}
                      </Stack>
                    </FormControl>
                  </div>
                </TableCell>
                {tabValue != 0 && tabValue != 5 && (
                  <TableCell align="center" width="15%">
                    <div
                      style={{
                        color: "#3e8cb5",
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "Arial",
                        border: "none",
                        background: "none",
                      }}
                    >
                      Pending Action
                    </div>
                  </TableCell>
                )}
              {tabValue == 4 && (
                  <TableCell align="center" width="8%">
                    <div
                      style={{
                        color: "#3e8cb5",
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "Arial",
                        border: "none",
                        background: "none",
                      }}
                    >
                     Employee<br></br>Rating
                    </div>
                  </TableCell>
                )}
                {tabValue != 1 && tabValue != 2 && tabValue != 3 && tabValue != 4 && (

                  <TableCell
                    align="center"
                    width="8%">
                    <div
                      style={{
                        color: "#3e8cb5",
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "Arial",
                        border: "none",
                        background: "none",
                      }}
                    >
                      Overall <br></br> Rating
                    </div>
                  </TableCell>
                )}
                {tabValue != 0 && tabValue != 5 && (
                  <TableCell
                    align="center"
                    width="7%"
                    text-align="-webkit-center"
                  >
                    <div
                      style={{
                        color: "#3e8cb5",
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "Arial",
                        border: "none",
                        background: "none",
                        wordWrap: "break-word",
                      }}
                    >
                      Appraiser <br></br> Rating
                    </div>
                  </TableCell>
                )}
                {tabValue != 0 && tabValue != 5 && (
                  <TableCell
                    align="center"
                    width="7%"
                    text-align="-webkit-center"
                  >
                    <div
                      style={{
                        color: "#3e8cb5",
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "Arial",
                        border: "none",
                        background: "none",
                        wordWrap: "break-word",
                      }}
                    >
                      Reviewer <br></br> Rating
                    </div>
                  </TableCell>
                )}
                {tabValue != 0 && tabValue != 5 && (
                  <TableCell
                    align="center"
                    width="7%"
                    text-align="-webkit-center"
                  ><div
                      style={{
                        color: "#3e8cb5",
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "Arial",
                        border: "none",
                        background: "none",
                        wordWrap: "break-word",
                      }}
                    >
                      HR Normalizer<br></br> Rating
                    </div>
                  </TableCell>
                )}
                {tabValue != 1 && tabValue != 2 && tabValue != 3 && tabValue != 4 && (
                  <TableCell align="center" width="6%">
                    <div
                      style={{
                        color: "#3e8cb5",
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "Arial",
                        border: "none",
                        background: "none",
                      }}
                    >
                      Previous Period<br></br> Rating
                    </div>
                  </TableCell>
                )}
                {tabValue === 0 && <TableCell align="center" width="6%">
                  <div
                    style={{
                      color: "#3e8cb5",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Arial",
                      border: "none",
                      background: "none",
                    }}
                  >
                    Status
                  </div>
                </TableCell>}
                <TableCell align="center" width="6%">
                  <div
                    style={{
                      color: "#3e8cb5",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Arial",
                      border: "none",
                      background: "none",
                    }}
                  >
                    View<br></br>PA
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            {tableDataFilterdLength?.length > 0 ? (
              <TableBody
                ref={listInnerRef}
                onScroll={onScroll}
              >
                {EmployeeData
                 ?.sort(
                  (a: any, b: any) =>
                    b?.appraisal?.pa_rating - a?.appraisal?.pa_rating // Sort by pa_rating in descending order
                )
                  .filter((item1: any) => {
                    if (positionsFilter.includes("None") || positionsFilter.length === 0) {
                      return item1;
                    } else {
                      return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
                    }
                  })
                  .filter((item1: any) => {
                    if (GradesFilter.includes("None") || GradesFilter.length === 0) {
                      return item1;
                    } else {
                      return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
                    }
                  })
                  .filter((j: any) => {
                    if (enteredName === "") {
                      return j;
                    } else if (
                      (j?.employee_code !== undefined &&
                        j?.employee_code
                          ?.toLocaleLowerCase()
                          ?.includes(
                            enteredName?.toLocaleLowerCase()
                          )) ||
                      (j?.legal_full_name !== undefined &&
                        j?.legal_full_name
                          ?.toLocaleLowerCase()
                          ?.includes(
                            enteredName?.toLocaleLowerCase()
                          )) ||
                      (j.grade !== undefined &&
                        j?.grade
                          ?.toLocaleLowerCase()
                          ?.includes(
                            enteredName?.toLocaleLowerCase()
                          )) ||
                      (j.position_long_description !== undefined &&
                        j?.position_long_description
                          ?.toLocaleLowerCase()
                          ?.includes(
                            enteredName?.toLocaleLowerCase()
                          ))||
                          (j?.appraisal?.pa_status !== undefined &&
                            j?.appraisal?.pa_status
                              ?.toLocaleLowerCase()
                              ?.includes(
                                enteredName?.toLocaleLowerCase()
                              ))  ||
                      (j?.appraisal?.status !== undefined &&
                        j?.appraisal?.status
                          ?.toLocaleLowerCase()
                          ?.includes(
                            enteredName?.toLocaleLowerCase()
                          )) ||
                      (getPAStatus(j) !== undefined &&
                        getPAStatus(j)
                          ?.toLocaleLowerCase()
                          ?.includes(enteredName?.toLocaleLowerCase()))
                    ) {
                      return j;
                    }
                  })
                  ?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  ?.map((j: any) => {
                    return (
                      <TableRow className={classes.tableRow}>
                        <TableCell
                          style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                            color: "#333333",
                          }}
                          align="center"
                        >
                          {j.employee_code}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                            color: "#333333",
                          }}
                          component="th"
                          scope="row"
                        >
                          <p>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1.5}
                            >
                              <Names>
                                {j.legal_full_name}
                              </Names>
                            </Stack>
                          </p>
                        </TableCell>
                        <TableCell align="left"
                          style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                            color: "#333333",
                          }}>
                          {j.position_long_description}
                        </TableCell>
                        <TableCell style={{
                          textAlign: "center", paddingRight: "30px", fontSize: "14px",
                          fontFamily: "Arial",
                          color: "#333333",
                        }} >{j.grade}</TableCell>
                        {tabValue != 0 && tabValue != 5 && (
                          <TableCell align="left"
                            style={{
                              fontSize: "14px",
                              fontFamily: "Arial",
                              color: "#333333",
                            }}>
                            {j.appraisal?.status == "not-started" ? "Pending with Appraiser" :
                              j.appraisal?.status == "completed" ? ("-") : j.appraisal.pa_status}
                          </TableCell>)}
                            {tabValue == 4 && (
                               <TableCell align="center" style={{
                                fontSize: "14px",
                                fontFamily: "Arial",
                                color: (j?.appraisal?.status == "rejected" ||  j.employee.employee_status === "rejected") ? "red" : "#333333",                        // color: j?.employee?.objective_description?.filter((item : any) => item?.rating_rejected)?.length === 0 ?
                                      }} >
                                 {(j?.employee?.employee_rating == 0 ) ? (
                                    <span> - </span>
                                  ) : (j?.employee?.employee_rating?.toFixed(2))}
                              </TableCell>
                            )}
                        {tabValue != 1 && tabValue != 2 && tabValue != 3 && tabValue != 4 && (
                          <TableCell align="center" style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                          }} >
                             {/******************** If overall rating is changed with respect to the normalized rating then 
                      * rating will be in blue else black***************************/}
                            {(j?.appraisal?.status === "completed" && j?.employee?.employee_status === "rejected" && 
                            (j?.normalizer?.normalizer_rating !== j.normalizer?.normalized_overallRating)) ? (
                              <span style={{ color: "#0099FF" }}>{j?.appraisal?.pa_rating?.toFixed(2)}</span>
                            ) : (j?.appraisal?.status === "completed") ? (<span>{j?.appraisal?.pa_rating?.toFixed(2)}</span>) :
                              "-"
                            }
                          </TableCell>
                        )}
                        {tabValue != 0 && tabValue != 5 && (
                          <TableCell
                            style={{
                              fontSize: "14px",
                              fontFamily: "Arial",
                              // color: "#333333",
                            }}
                            align="center"
                            sx={{
                               /******************Appraiser rating should be red if appraiser rejected : should be blue only if there
                         * is change in the rating that is ,if it is different from the normalized rating else it should be black*******************/
                              color:
                                j.appraisal.appraiser_PA_rejected == true ? "red" :
                                  (j.appraisal.status == "rejected" && j.appraisal.appraiser_PA_accepted == true &&
                                  (j.appraisal.appraiser_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333"
                            }}
                          >
                            {(j.appraisal.appraiser_rating != 0) ?
                                (j.appraisal.appraiser_rating?.toFixed(2)) :
                                <span> - </span>}
                          </TableCell>
                        )}
                        {tabValue != 0 && tabValue != 5 && (
                          <TableCell
                            align="center"
                            style={{
                              fontSize: "14px",
                              fontFamily: "Arial",
                            }}
                            sx={{
                               /******************Reviewer rating should be red if Reviewer rejected : should be blue only if there
                       * is change in the rating that is ,if it is different from the normalized rating else it should be black*******************/
                              color:
                                (j?.reviewer?.reviewer_PA_rejected === true) ? "red" :
                                  (j.appraisal.status == "rejected" && j?.reviewer?.reviewer_PA_accepted == true &&
                                  (j.reviewer.reviewer_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333"
                            }}
                          >
                            {(j.reviewer.reviewer_rating == 0) ? (
                                <span> - </span>
                              ) : ( j.reviewer.reviewer_rating?.toFixed(2) )}
                          </TableCell>
                        )}
                        {tabValue != 0 && tabValue != 5 && (
                          <TableCell
                            align="center"
                            style={{
                              fontSize: "14px",
                              fontFamily: "Arial",
                            }}
                            sx={{
                              /******************Normalizer rating should be red if Normalizer rejected : should be blue only if there
                          * is change in the rating that is ,if it is different from the normalized rating else it should be black*******************/
                              color:
                                (j?.normalizer?.normalizer_PA_rejected === true) ? "red" :
                                  (j.appraisal.status == "completed" && j?.normalizer.normalizer_status == "re-normalized" &&
                                  (j?.normalizer?.normalizer_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333" }}
                          >
                            {( j?.normalizer?.normalizer_rating == 0) ? (
                              <span> - </span>
                            ) : (j?.normalizer?.normalizer_rating?.toFixed(2)
                            )}
                          </TableCell>
                        )}
                        {tabValue != 1 && tabValue != 2 && tabValue != 3 && tabValue != 4 && (
                          <TableCell align="center" style={{
                            fontSize: "14px",
                            fontFamily: "Arial",
                          }}>
                            {j.previous_rating ? j.previous_rating?.toFixed(2) : '-'}
                          </TableCell>
                        )}
                        {tabValue === 0 && <TableCell style={{
                          fontSize: "14px",
                          fontFamily: "Arial",
                          color: "#333333",
                        }} align="center">
                          {getStatus(j?.appraisal?.status)}
                        </TableCell>}
                        <TableCell style={{
                          textAlign: "center", fontSize: "14px",
                          fontFamily: "Arial",
                          color: "#333333",
                        }}>
                          {j?.appraisal?.status != "not-started" && (
                            <IconButton
                              onClick={() => viewPAClickHandler(j)}
                            >
                              <img src={Eye} alt="icon" />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="left"
                    style={{
                      fontWeight: '500',
                      border: "none",
                      color: "#808080",
                      fontSize: "18px",
                      fontFamily: "arial",
                      paddingTop: "10px"
                    }}
                  >
                    No data to display
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {tableDataFilterdLength?.length > 0 &&
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
      </TableHeadings>
    </Mytable>
  );
};
export default LineManagersTeamTable;