import {CLIENT_URL} from "./constants/URL";

const config = {
    appId: '45ceb759-f44c-44ce-9ff7-220492887e36',
    tenantId: "45ceb759-f44c-44ce-9ff7-220492887e36",
    redirectUri: CLIENT_URL,
    scopes: [
        'user.read',
    ]
};

export default config;
