import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, Radio, RadioGroup, Snackbar, Stack } from '@mui/material'
import { useGetRatingScaleQuery, useUpdateEmployeeAppraisalMutation } from '../../../../service';
import { useAppraisalContext } from '../../../../context/appraiserOverviewContext';
import { useCheckRoleLogsMutation } from '../../../../service/employee/employee';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AlertDialogSuccess from "../../../UI/DialogSuccess";
import { useAttachmentsAppraiserOverviewMutation } from "../../../../service/employee/appraisal/appraisal";
import { useGetEmailIdsQuery, useSendEmailNotificationMutation } from "../../../../service/email/emailNotification";
import _ from "lodash";
import { APPRAISER_SUBMITS_TO_REVIEWER } from "../../../../constants/AppraisalEmailContents/NotificationForAction/Appraiser";
import AlertDialogOkCancelForUnsavedChanges from "../../../UI/DialogforunsavedChangesAlert";
import AlertYesNo from "../../../UI/DialogYesNo";
import { useAppraiserContext } from "../../../../context/appraisal/AppraiserContext";
import { CHANGES_SAVED_SUCCESSFULLY, LOADER_MESSAGE } from "../../../../constants/DialogContents/DialogContents";
import DialogWithOkButton from "../../../UI/DialogWithOkButton";
import { EMPLOYEE_ACCEPTS_PA_INFO } from "../../../../constants/AppraisalEmailContents/NotificationForInfo/Employee";
import useMediaQuery from "@mui/material/useMediaQuery";
import { EMPLOYEE_REJECTS, EMPLOYEE_REJECT } from "../../../../constants/routes/Routing";
import { useTheme } from "@mui/material/styles";
import DialogCircularProgressLoader from "../../../UI/DialogCircularProgressLoader";


const useStyles = makeStyles(({
    heading: {
        color: "#3E8CB5",
        fontWeight: "400",
        fontSize: "28px",
        fontFamily: "Arial",

        // @media Querry for responsive codes
        ['@media (max-width:868px)']: {
            flexWrap: "wrap",
        }
    },
    customAlert: {
        backgroundColor: '#3e8cb5',
        color: "white",
        height: '60px !important',
        alignItems: "center",
        fontSize: "1rem"
    },
    customSnackbar: {
        paddingBottom: '16px',
        paddingRight: '16px',
    },

}));

const EmployeeLandingFooterButtons = (props: any) => {
    const { employeeData,
        employee_id,
        disableButtons,
        setDisableButtons,
        tabValue,
        setTabValue,
        employeeTrainingRecommendation,
        employeeAreaOfImprovement,
        employeePerformanceGoals,
        updateEmployee,
        employeeComments,
        oneToOneMeetingDate,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        updateLoggedRole,
        talentCategory,
        refetchEmployeeData

    } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [openAlertOk, setOpenAlertOk] = useState(false);
    const [tempTabValue, setTempTabValue] = useState(tabValue);
    const [message, setMessage] = useState("");
    const [successAlertTriger, setSuccessAlertTriger] = useState(false);
    const [successAlertTrigerMSG, setSuccessAlertTrigerMSG] = useState("Changes were successfully saved.");
    const [hadOneToOneMeeting, setHadOneToOneMeeting] = useState(false);
    const [openOneToOneMeetingAlert, setOpenOneToOneMeetingAlert] = useState(false);
    const [openOverallFeedbackAlert, setOpenOverallFeedbackAlert] = useState(false);
    const [openPerformanceGoalsAlert, setOpenPerformanceGoalsAlert] = useState(false);
    const [reviewedOverallFeedback, setReviewedOverallFeedback] = useState(false);
    const [openOneToOneMeetingRejectAlert, setOpenOneToOneMeetingRejectAlert] = useState(false);
    const [openPerformanceGoalsRejectAlert, setOpenPerformanceGoalsRejectAlert] = useState(false);
    const [openAgreedWithRatingAlert, setOpenAgreedWithRatingAlert] = useState(false);
    const [employeeAgreesWithAppraiser, setEmployeeAgreesWithAppraiser] = useState(true);
    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
    const [showLoader, setShowLoader] = useState(false);

    // mutation
    const [sendEmailNotification] = useSendEmailNotificationMutation();
    const { data: emailData } = useGetEmailIdsQuery({ appraiser_code: employeeData?.data?.appraiser_code, reviewer_code: employeeData?.data?.reviewer_code, normalizer_code: employeeData?.data?.normalizer_code })


    //start : common function to check the values and trim
    let trainingRecommendationCategoryNotEmpty = employeeTrainingRecommendation?.filter((item: any) => item.name !== "")
        .filter((item: any) => item.justification?.trim() === "" || item.training_name?.trim() === "");
    let trainingRecommendationNameNotEmpty = employeeTrainingRecommendation?.filter((item: any) => item.training_name?.trim() !== "")
        .filter((item: any) => item.name === "" || item.justification?.trim() === "");
    let trainingRecommendationJustificationNotEmpty = employeeTrainingRecommendation?.filter((item: any) => item.justification?.trim() !== "")
        .filter((item: any) => item.name === "" || item.training_name?.trim() === "");


    /****** check if specific area is not empty and specific action is empty ****************/
    let specificAreaNotEmpty = employeeAreaOfImprovement?.filter((item: any) => {
        return item.value?.trim() !== ""
    }).filter((item: any) => {
        return item.specific_actions[0]?.value?.trim() === ""
    });
    /****** check if specific action is not empty and specific area is empty ****************/
    let specificActionNotEmpty = employeeAreaOfImprovement?.filter((item: any) => {
        return item.specific_actions[0]?.value?.trim() !== ""
    }).filter((item: any) => {
        return item?.value?.trim() === ""
    });

    /** if PerformanceGoal category is empty and one of the performanceGoals field is not empty */
    let performanceGoalsCategoryNotEmpty = employeePerformanceGoals?.filter((item: any) => item.goal_category != "")
        .filter((item: any) =>
            item.description?.trim() === "" || item.keyresult?.trim() === "" ||
            item.due_date == "");
    /** if PerformanceGoal description is empty and one of the performanceGoals field is not empty */
    let performanceGoalsDescriptionNotEmpty = employeePerformanceGoals?.filter((item: any) => item.description?.trim() != "")
        .filter((item: any) => item.goal_category === "" || item.keyresult?.trim() === "" ||
            item.due_date == "")
    /** if PerformanceGoal keyresult is empty and one of the performanceGoals field is not empty */
    let performanceGoalskeyResultNotEmpty = employeePerformanceGoals?.filter((item: any) => item.keyresult?.trim() != "")
        .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
            item.due_date == "")
    /** if PerformanceGoal dueDate is empty and one of the performanceGoals field is not empty */
    let performanceGoalsDueDateNotEmpty = employeePerformanceGoals?.filter((item: any) => (item.due_date != "" && item.due_date != undefined))
        .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
            item.keyresult?.trim() == "")
    /** if PerformanceGoal remarks is empty and one of the performanceGoals field is not empty */
    let performanceGoalsRemarksNotEmpty = employeePerformanceGoals?.filter((item: any) => item.remarks?.trim() != "")
        .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
            item.due_date == "");
    //end : common function to check the values and trim

    let trainingRecommendationValues = employeeTrainingRecommendation?.filter((item: any) => item.name !== "")
        ?.map((item: any) => {
            console.log(item, "newitem")
            return {
                ...item,
                training_name: item?.training_name?.trim(),
                justification: item?.justification?.trim(),
            }
        });

    let areaValues = employeeAreaOfImprovement?.filter((item: any) => item.value.trim() != "").map((item: any) => {
        let trimSpecificAction = item.specific_actions?.map((item: any) => {
            return {
                ...item,
                value: item?.value?.trim()
            }
        })
        return {
            ...item,
            value: item?.value?.trim(),
            specific_actions: trimSpecificAction
        }
    });

    let performancegoalValues = employeePerformanceGoals?.filter((item: any) => item.goal_category != "")?.map((item: any) => {
        return {
            ...item,
            goal_category: item.goal_category,
            description: item?.description?.trim(),
            keyresult: item?.keyresult?.trim(),
            due_date: item?.due_date,
            remarks: item?.remarks?.trim()
        }
    });


    const saveAsDraftValidation = () => {
        let status = false;
        let Message = "";
        let TabValue = tabValue;

        if (specificAreaNotEmpty?.length > 0 || specificActionNotEmpty?.length > 0) {
            status = true;
            Message = "Please add the missing details in the Areas for Improvement.";
            TabValue = 1
        } else if (trainingRecommendationCategoryNotEmpty?.length > 0 || trainingRecommendationNameNotEmpty?.length > 0 || trainingRecommendationJustificationNotEmpty?.length > 0) {
            status = true;
            Message = "Please add the missing details in the Training Recommendations.";
            TabValue = 1
        } else if (performanceGoalsCategoryNotEmpty?.length > 0 || performanceGoalsDescriptionNotEmpty?.length > 0 ||
            performanceGoalskeyResultNotEmpty?.length > 0 || performanceGoalsDueDateNotEmpty?.length > 0 ||
            performanceGoalsRemarksNotEmpty?.length > 0) {
            status = true;
            Message = "Please add the missing details in the Performance Goals.";
            TabValue = 2
        }
        return { status, Message, TabValue }
    }

    const saveAsDraftHandler = () => {
        const { status, Message, TabValue } = saveAsDraftValidation();
        if (status == true) {
            setOpenAlertOk(status);
            setMessage(Message);
            setTempTabValue(TabValue);
        } else {
            updateEmployee({
                id: employee_id,
                "employee.employee_status": "draft",
                "appraisal.pa_status": "Pending with Employee",
                "employee.training_recommendation": trainingRecommendationValues,
                "employee.area_of_improvement": areaValues,
                "employee.comments": employeeComments?.trim(),
                "employee.one_to_one_meeting": oneToOneMeetingDate,
                "employee.performance_goal": performancegoalValues,
            }).then((res: any) => {
                if (!res.error) {
                    setMoveTab(false);
                    setNavPrompt(false);
                    setDisableButtons(false);
                    //new alert 
                    setSuccessAlertTrigerMSG("Changes were successfully saved.")
                    setSuccessAlertTriger(true)
                }
            })
        }
    }



    const handleCloseAlertOk = () => {
        setOpenAlertOk(false);
        setMessage("");
        setTabValue(tempTabValue);
        setDisableButtons(false);
    }

    const handleCloseSnackbar = () => {
        setSuccessAlertTriger(false)
        setSuccessAlertTrigerMSG("")
    }

    const getRatingsfromObjectiveDescription = (data: any) => {
        return data?.map((k: any) => {
            return {
                rating_rejected: false,
                name: k.name,
                value: k.value,
                ratings: k.ratings,
                level_1_isChecked: k.level_1_isChecked,
                level_2_isChecked: k.level_2_isChecked,
                level_3_isChecked: k.level_3_isChecked,
                level_4_isChecked: k.level_4_isChecked,
            };
        });
    };

    const acceptPA = () => {
        setShowLoader(true);
        setMessage(LOADER_MESSAGE);       
        updateEmployee({
            "employee.objective_description": getRatingsfromObjectiveDescription(
                employeeData?.data?.normalizer?.objective_description
            ),
            // "talent_category" : ""
            "appraisal.status": "completed",
            "employee_previous_submission.performance_goal": performancegoalValues,
            "employee.performance_goal": performancegoalValues,
            "talent_category": talentCategory?.data,
            "appraisal.pa_status": "Completed",
            "appraisal_previous_submission.pa_status": "Completed",
            "appraisal.pa_rating": employeeData?.data?.current_rating?.overall_rating,
            "appraisal.show_employee": false,
            "employee.employee_status": "accepted",
            "employee.employee_PA_rejected": false,
            ".employee.employee_rejection": false,
            id: employee_id,
            "employee.attended_one_to_one_meeting": true,
            "employee_previous_submission.attended_one_to_one_meeting": true,
            // "employee.employee_rating": employeeData.data.normalizer.normalizer_rating,
            "employee.training_recommendation": trainingRecommendationValues,
            "employee_previous_submission.training_recommendation": trainingRecommendationValues,
            "employee.area_of_improvement": areaValues,
            "employee_previous_submission.area_of_improvement": areaValues,
            "employee.one_to_one_meeting": oneToOneMeetingDate,
            "employee_previous_submission.one_to_one_meeting": oneToOneMeetingDate,
            "employee.comments": employeeComments?.trim(),
            "employee_previous_submission.comments": employeeComments?.trim(),
            "employee.employee_rating": employeeData?.data?.current_rating?.overall_rating,
            "current_previous_submission.objective_description": employeeData?.data?.current_rating.objective_description,
            "appraisal_previous_submission.objective_description": employeeData?.data?.appraisal?.objective_description,
            "employee_previous_submission.objective_description": getRatingsfromObjectiveDescription(
                employeeData?.data?.normalizer?.objective_description
            ),
        }).then((res: any) => {
            if (!res.error) {
                setMoveTab(false);
                setShowLoader(false);
                setMessage("");
                setNavPrompt(false);
                setDisableButtons(false);
                updateLoggedRole({
                    pa_action_by: `Employee (Accepted) : ${new Date()}`,
                    id: employee_id
                })
                setSuccessAlertTrigerMSG("The performance appraisal was completed.")
                setSuccessAlertTriger(true)

                // Notification info Employee accepts PA - (for Appraiser)
                // console.log(employeeData?.data,"dataaa")
                let appraiserName = employeeData?.data?.appraiser_name;
                let employeeName = employeeData?.data?.first_name;
                let calendarName = employeeData?.data?.calendar?.calendar_type;
                let calendarYear = employeeData?.data?.calendar?.start_date?.slice(0, 4)
                let empCode = employeeData?.data?.employee_code;
                // let employeeComment = employeeData.data.employee_previous_submission.comments;
                // console.log(employeeComment,"employeeComment")
                let tempSubjectInfo = EMPLOYEE_ACCEPTS_PA_INFO?.subject;
                tempSubjectInfo = tempSubjectInfo?.replace("[year]", `${calendarYear}`);
                tempSubjectInfo = tempSubjectInfo?.replace("[calendar name]", `${calendarName}`);
                tempSubjectInfo = tempSubjectInfo?.replace("[employee name]", `${employeeName}`);
                tempSubjectInfo = tempSubjectInfo?.replace("[employee code]", `${empCode}`);
                tempSubjectInfo = tempSubjectInfo?.replace("[employee comment]", employeeComments ? `Employee Comments : ${employeeComments}` : "");

                let tempHtmlInfo = EMPLOYEE_ACCEPTS_PA_INFO?.html;
                tempHtmlInfo = tempHtmlInfo?.replace("[year]", `${calendarYear}`);
                tempHtmlInfo = tempHtmlInfo?.replace("[calendar name]", `${calendarName}`);
                tempHtmlInfo = tempHtmlInfo?.replace("[employee name]", `${employeeName}`);
                tempHtmlInfo = tempHtmlInfo?.replace("[employee code]", `${empCode}`);
                tempHtmlInfo = tempHtmlInfo?.replace("[employee comment]", employeeComments ? `Employee Comments :${employeeComments}` : "");



                let appraiserEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.appraiser_code)?.email
                let reviewerEmail: string = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.reviewer_code)?.email
                let email = EMPLOYEE_ACCEPTS_PA_INFO?.to;
                email = email?.replace("[email]", `${appraiserEmail}`);
                sendEmailNotification(
                    {
                        to: email,
                        cc: reviewerEmail,
                        subject: tempSubjectInfo,
                        html: tempHtmlInfo
                    }
                )
            } else {
                updateLoggedRole({
                    pa_action_by: `${res.error} : ${new Date()}`,
                    id: employee_id
                })
                setMessage("Something Went Wrong.")
                setOpenAlertOk(true)
            }
        })
    }

    // start : one to one meeting alert
    const handleOneToOneMeetingAlert = () => {
        saveAsDraftValidation()
        setHadOneToOneMeeting(true);
        setOpenOneToOneMeetingAlert(false);
        if (oneToOneMeetingDate == "" || oneToOneMeetingDate == undefined) {
            setOpenAlertOk(true);
            setMessage("Please select One-to-One Meeting Date in the overall feedback.");
            setTempTabValue(1);
        } else {
            if (!reviewedOverallFeedback) {
                setOpenOverallFeedbackAlert(true);
                setMessage("Have you reviewed the overall feedback of the performance appraisal?")
            } else if ((employeePerformanceGoals?.length == 0 || (employeePerformanceGoals?.filter((performanceGoalsItem: any) =>
                performanceGoalsItem?.description !== "")?.length == 0))) {
                setOpenPerformanceGoalsAlert(true)
                setMessage("Would you like to add performance goals for the next period?")
            }
        }
    }

    const handleCloseOneToOneMeetingAlert = () => {
        saveAsDraftValidation()
        setOpenOneToOneMeetingAlert(false);
        setOpenAlertOk(true);
        setMessage("You cannot proceed with the performance appraisal. Please make sure to meet with the Appraiser in a one-on-one meeting to discuss your performance appraisal.")
    }
    // end : one to one meeting alert


    // start : overallFeedback alert
    const handleOverallFeedbackAlert = () => {
        saveAsDraftValidation();
        setReviewedOverallFeedback(true);
        setOpenOverallFeedbackAlert(false);
        if ((employeePerformanceGoals?.length == 0 || (employeePerformanceGoals?.filter((performanceGoalsItem: any) =>
            performanceGoalsItem?.description !== "")?.length == 0))) {
            setOpenPerformanceGoalsAlert(true)
            setMessage("Would you like to add performance goals for the next period?")
        } else {
            acceptPA()
        }

    }

    const handleCloseOverallFeedbackAlert = () => {
        saveAsDraftValidation()
        setOpenOverallFeedbackAlert(false);
        setMessage("");
        setTabValue(1);
        setDisableButtons(false);
    }
    // end : overallFeedback alert


    // start : performance goals alert
    const handlePerformanceGoalsAlert = () => {
        saveAsDraftValidation();
        setOpenPerformanceGoalsAlert(false);
        setMessage("");
        setTabValue(2);
        setDisableButtons(false)
    }

    const handleClosePerformanceGoalsAlert = () => {
        saveAsDraftValidation();
        setOpenPerformanceGoalsAlert(false);
        setMessage("");
        setDisableButtons(false);
        acceptPA()
    }
    // end : performance goals alert



    const acceptHandler = () => {
        setDisableButtons(true);
        const { status, Message, TabValue } = saveAsDraftValidation();
        const { submitStatus, submitMessage, submitTabValue } = acceptRejectValidations();
        if (status == true) {
            setOpenAlertOk(status);
            setMessage(Message);
            setTempTabValue(TabValue);
        }
        else {
            if (submitStatus) {
                setOpenAlertOk(submitStatus);
                setMessage(submitMessage);
                setTempTabValue(submitTabValue);
            } else {
                if (!hadOneToOneMeeting && employeeData?.data?.appraisal?.status !== "rejected" && (oneToOneMeetingDate == "" || oneToOneMeetingDate == undefined)) {
                    setOpenOneToOneMeetingAlert(true);
                    setMessage("Did you have One-to-One meeting with the Appraiser?");
                } else if (!reviewedOverallFeedback) {
                    setOpenOverallFeedbackAlert(true);
                    setMessage("Have you reviewed the overall feedback of the performance appraisal?")
                } else if ((employeePerformanceGoals?.length == 0 || (employeePerformanceGoals?.filter((performanceGoalsItem: any) =>
                    performanceGoalsItem?.description !== "")?.length == 0))) {
                    setOpenPerformanceGoalsAlert(true)
                    setMessage("Would you like to add performance goals for the next period?")
                } else {
                    acceptPA();
                }
            }
        }

    }

    // start : one to one meeting reject alert
    const handleOneToOneMeetingRejectAlert = () => {
        saveAsDraftHandler();
        setOpenOneToOneMeetingRejectAlert(false);
        setMessage("");
        setOpenAgreedWithRatingAlert(true)
    }

    const handleCloseOneToOneMeetingRejectAlert = () => {
        saveAsDraftHandler();
        setOpenOneToOneMeetingRejectAlert(false);
        setOpenAlertOk(true);
        setMessage("You cannot proceed with the performance appraisal. Please make sure to meet with the Appraiser in a one-on-one meeting to discuss your performance appraisal.")
    }
    // end : one to one meeting reject alert


    // start : performance goals alert
    const handlePerformanceGoalsRejectAlert = () => {
        saveAsDraftHandler();
        setOpenPerformanceGoalsRejectAlert(false);
        setMessage("");
        setDisableButtons(false);
        setTabValue(2);
    }

    const handleClosePerformanceGoalsRejectAlert = () => {
        saveAsDraftHandler();
        setOpenPerformanceGoalsRejectAlert(false);
        setMessage("");
        setDisableButtons(false);
        rejectPA()
    }
    // end : performance goals alert


    const handleRejectRadioChange = (event: any) => {
        if (event.target.value.toLowerCase() == "true") return setEmployeeAgreesWithAppraiser(true);
        if (event.target.value.toLowerCase() == "false") return setEmployeeAgreesWithAppraiser(false);

        // navigate(`${EMPLOYEE_REJECTS}/employee/${employee_id}`);
    };

    const handleAgreedChanges = () => {
        saveAsDraftHandler()
        setOpenAgreedWithRatingAlert(false);
        if ((employeePerformanceGoals?.length == 0 || (employeePerformanceGoals?.filter((performanceGoalsItem: any) =>
            performanceGoalsItem?.description !== "")?.length == 0))) {
            setOpenPerformanceGoalsRejectAlert(true)
            setMessage("Would you like to add performance goals for the next period?")
        } else {
            rejectPA();
        }
    }


    const acceptRejectValidations = () => {
        let submitStatus = false;
        let submitMessage = "";
        let submitTabValue = tabValue;

        if (specificAreaNotEmpty?.length > 0 || specificActionNotEmpty?.length > 0) {
            submitStatus = true;
            submitMessage = "Please add the missing details in the Areas for Improvement."
            submitTabValue = 1
        } else if (trainingRecommendationCategoryNotEmpty?.length > 0 || trainingRecommendationNameNotEmpty?.length > 0 || trainingRecommendationJustificationNotEmpty?.length > 0) {
            submitStatus = true;
            submitMessage = "Please add the missing details in the Training Recommendations."
            submitTabValue = 1
        } else if (oneToOneMeetingDate == "" || oneToOneMeetingDate == undefined) {
            submitStatus = true;
            submitMessage = "Please select One-to-One Meeting Date in the overall feedback.";
            submitTabValue = 1
        } else if (performanceGoalsCategoryNotEmpty?.length > 0 || performanceGoalsDescriptionNotEmpty?.length > 0 ||
            performanceGoalskeyResultNotEmpty?.length > 0 || performanceGoalsDueDateNotEmpty?.length > 0 ||
            performanceGoalsRemarksNotEmpty?.length > 0) {
            submitStatus = true;
            submitMessage = "Please add the missing details in the Performance Goals.";
            submitTabValue = 2
        }
        return { submitStatus, submitMessage, submitTabValue }

    }



    const rejectHandler = () => {
        // saveAsDraftHandler()
        setDisableButtons(true);
        const { status, Message, TabValue } = saveAsDraftValidation();
        const { submitStatus, submitMessage, submitTabValue } = acceptRejectValidations();
        if (status == true) {
            setOpenAlertOk(status);
            setMessage(Message);
            setTempTabValue(TabValue);
        }
        else {
            if (submitStatus) {
                setOpenAlertOk(submitStatus);
                setMessage(submitMessage);
                setTempTabValue(submitTabValue);
            } else {
                if (employeeData?.data?.appraisal?.status !== "rejected") {
                    if ((oneToOneMeetingDate == "" || oneToOneMeetingDate == undefined)) {
                        setOpenOneToOneMeetingRejectAlert(true);
                        setMessage("Did you have One-to-One meeting with the Appraiser?")
                    } else {
                        setOpenAgreedWithRatingAlert(true);
                    }
                } else if ((employeePerformanceGoals?.length == 0 || (employeePerformanceGoals?.filter((performanceGoalsItem: any) =>
                    performanceGoalsItem?.description !== "")?.length == 0))) {
                    setOpenPerformanceGoalsRejectAlert(true)
                    setMessage("Would you like to add performance goals for the next period?")
                }
                else {
                    rejectPA();
                    // setOpen3(true);
                }
            }
        }
    }


    const rejectPA = () => {
        setShowLoader(true);
        setMessage(LOADER_MESSAGE);
        updateEmployee({
            id: employee_id,
            "employee.performance_goal": performancegoalValues,
            "employee.training_recommendation": trainingRecommendationValues,
            "employee.area_of_improvement": areaValues,
            "employee.comments": employeeComments?.trim(),
            "employee.employee_agree": employeeAgreesWithAppraiser,
            "employee.one_to_one_meeting": oneToOneMeetingDate,
            "employee.selectedToReject": true, /************** to hide Accept button on landing screen if wish to reject ******************/
        }).then((res: any) => {
            if (!res.error) {
                setShowLoader(false);
                setMessage("");
                setNavPrompt(false);
                setMoveTab(false);
                setDisableButtons(false);
                navigate(`${EMPLOYEE_REJECT}/employee/${employee_id}`);
            }
        });
    }


    const backHandler = () => {
        if (moveTab == true) {
            setOpenAlertOk(true);
            setMessage("Please save the changes before leaving the page.");
            setTempTabValue(tabValue)
        } else {
            if (tabValue == 2) {
                setTabValue(1);
            } else if (tabValue == 1) {
                setTabValue(0);
            }
        }
    }

    const cancelHandler = () => {
        if (navPrompt || moveTab) {
            setOpenCancelDialog(true);
        } else if (tabValue == 1) {
            setOpenCancelDialog(false);
            setTabValue(0)
        } else if (tabValue == 2) {
            setOpenCancelDialog(false);
            setTabValue(1)
        }
    }

    const rejectCancelButtonHandler = () => {
        setOpenCancelDialog(false);
    }

    const acceptCancelButtonHandler = () => {
        setOpenCancelDialog(false);
        setMoveTab(false);
        setNavPrompt(false);
        refetchEmployeeData().then((res: any) => setTabValue(0));
    }

    return (
        <>
            <div style={{
                // fontSize: "22px",
                fontFamily: " Arial, Helvetica, sans-serif",
                // margin: "50px",
                // paddingLeft: "600px",
                display: "flex",
                alignItems: "center",
                // backgroundColor: "#FFFBF2",
                justifyContent: "center",
                // paddingTop: "33px",
                height: "125px",
                width: "100%",
                // marginLeft: "auto",
            }}>


                <>
                    {(employeeData?.data?.appraisal?.pa_status?.includes("Pending with Employee") &&
                        ((employeeData?.data?.employee?.employee_status == "pending" ||
                            employeeData?.data?.employee?.employee_status == "draft"))) ?

                        (<div>
                            {tabValue !== 0 && (
                                <Button
                                    disabled={disableButtons}
                                    variant="outlined"
                                    style={{
                                        borderRadius: "4px",
                                        textTransform: "none",
                                        fontSize: "15px",
                                        fontFamily: "Arial",
                                        borderColor: "#3E8CB5",
                                        color: "#3E8CB5",
                                        height: "35px",
                                        marginRight: "16px",
                                        background: "transparent",
                                        ...((disableButtons) && {
                                            cursor: "not-allowed",
                                            borderColor: "#ccc",
                                            color: "#ccc",
                                        }),
                                    }}
                                    onClick={() => {
                                        saveAsDraftHandler()
                                    }}
                                >
                                    Save as Draft
                                </Button>
                            )}
                            {/* {employeeData?.data?.appraisal?.status !== "rejected" && ( */}
                            {
                                <>
                                    {(employeeData?.data?.employee?.selectedToReject !== true &&
                                        employeeData?.data?.appraisal?.status !== "rejected"
                                    ) && (
                                            <Button
                                                disabled={disableButtons}
                                                style={{
                                                    textTransform: "none",
                                                    fontSize: "15px",
                                                    fontFamily: "Arial",
                                                    borderColor: "#3E8CB5",
                                                    marginRight: "16px",
                                                    background: "transparent",
                                                    width: "70px",
                                                    height: "35px",
                                                    ...((disableButtons) && {
                                                        cursor: "not-allowed",
                                                        borderColor: "#ccc",
                                                        color: "#ccc",
                                                    }),

                                                }}
                                                variant="outlined"
                                                onClick={acceptHandler}
                                            >
                                                {" "}
                                                Accept
                                            </Button>
                                        )}

                                    {employeeData?.data?.appraisal?.status && employeeData?.data?.appraisal?.status !== "rejected" && (
                                        <Button
                                            disabled={disableButtons}
                                            style={{
                                                textTransform: "none",
                                                fontSize: "15px",
                                                fontFamily: "Arial",
                                                borderColor: "#3E8CB5",
                                                color: "#3e8cb5",
                                                marginRight: "16px",
                                                background: "transparent",
                                                width: "70px",
                                                height: "35px",
                                                ...((disableButtons) && {
                                                    cursor: "not-allowed",
                                                    borderColor: "#ccc",
                                                    color: "#ccc",
                                                }),

                                            }}
                                            variant="outlined"
                                            onClick={rejectHandler}
                                        >
                                            Reject
                                        </Button>
                                    )}

                                    {employeeData?.data?.appraisal?.status && employeeData?.data?.appraisal?.status === "rejected" && (
                                        <Button
                                            disabled={disableButtons}
                                            style={{
                                                textTransform: "none",
                                                fontSize: "15px",
                                                fontFamily: "Arial",
                                                borderColor: "#3E8CB5",
                                                color: "#3e8cb5",
                                                marginRight: "16px",
                                                background: "transparent",
                                                // width: "70px",
                                                height: "35px",
                                                ...((disableButtons) && {
                                                    cursor: "not-allowed",
                                                    borderColor: "#ccc",
                                                    color: "#ccc",
                                                }),

                                            }}
                                            variant="outlined"
                                            onClick={rejectHandler}
                                        >
                                            Re-submit
                                        </Button>
                                    )}

                                    {tabValue !== 0 && (<Button
                                        disabled={disableButtons}
                                        style={{
                                            textTransform: "none",
                                            fontSize: "15px",
                                            fontFamily: "Arial",
                                            borderColor: "#3E8CB5",
                                            color: "#3E8CB5",
                                            background: "transparent",
                                            height: "35px",
                                            marginRight: "16px",
                                            width: "70px",
                                            ...((disableButtons) && {
                                                cursor: "not-allowed",
                                                borderColor: "#ccc",
                                                color: "#ccc",
                                            }),

                                        }}
                                        variant="outlined"
                                        // onClick={handleClickOpen3}
                                        onClick={() => {
                                            backHandler();
                                            // setValue(0);
                                            // saveRecommendationsHandler();
                                        }}
                                    >
                                        Back
                                    </Button>)}

                                    {tabValue !== 0 && (
                                        <Button
                                            disabled={disableButtons}
                                            style={{
                                                textTransform: "none",
                                                fontSize: "15px",
                                                fontFamily: "Arial",
                                                borderColor: "#3E8CB5",
                                                color: "#3E8CB5",
                                                background: "transparent",
                                                height: "35px",

                                                width: "70px",
                                                ...((disableButtons) && {
                                                    cursor: "not-allowed",
                                                    borderColor: "#ccc",
                                                    color: "#ccc",
                                                }),

                                            }}
                                            variant="outlined"
                                            // onClick={handleClickOpen3}
                                            onClick={() => {
                                                // handleClose1();
                                                // setValue(0);
                                                // moveTab(false);
                                                cancelHandler();

                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                </>
                            }
                        </div>
                        )
                        :
                        (employeeData && employeeData?.data?.appraisal?.status == "completed" &&
                            employeeData?.data?.normalizer?.normalizer_status == "re-normalized") ?
                            // ("Your final performance appraisal rating after mediation was finalized by the HR Normalizer.") : (
                            ("Your final overall rating was re-normalized.") : (
                                employeeData?.data?.appraisal?.status == "completed" ?
                                    "The performance appraisal was completed."
                                    :
                                    (employeeData?.data?.appraisal?.status && employeeData?.data?.appraisal?.status !== "completed" && <div>
                                        "The performance appraisal was submitted."
                                    </div>)
                            )

                    }


                    <DialogWithOkButton
                        isAlertOpen={openAlertOk}
                        handleAlertClose={handleCloseAlertOk}
                    >
                        {message}
                    </DialogWithOkButton>

                    {/*start : Alert for Accept PA  */}
                    <AlertYesNo
                        isAlertOpen={openOneToOneMeetingAlert}
                        handleAlertYes={handleOneToOneMeetingAlert}
                        handleAlertClose={handleCloseOneToOneMeetingAlert}
                    >
                        {message}
                    </AlertYesNo>

                    <AlertYesNo
                        isAlertOpen={openOverallFeedbackAlert}
                        handleAlertYes={handleOverallFeedbackAlert}
                        handleAlertClose={handleCloseOverallFeedbackAlert}
                    >
                        {message}
                    </AlertYesNo>

                    <AlertYesNo
                        isAlertOpen={openPerformanceGoalsAlert}
                        handleAlertYes={handlePerformanceGoalsAlert}
                        handleAlertClose={handleClosePerformanceGoalsAlert}
                    >
                        {message}
                    </AlertYesNo>
                    {/*end : Alert for Accept PA  */}

                    {/*start : Alert for Reject PA  */}
                    <AlertYesNo
                        isAlertOpen={openOneToOneMeetingRejectAlert}
                        handleAlertYes={handleOneToOneMeetingRejectAlert}
                        handleAlertClose={handleCloseOneToOneMeetingRejectAlert}
                    >
                        {message}
                    </AlertYesNo>

                    <AlertYesNo
                        isAlertOpen={openPerformanceGoalsRejectAlert}
                        handleAlertYes={handlePerformanceGoalsRejectAlert}
                        handleAlertClose={handleClosePerformanceGoalsRejectAlert}
                    >
                        {message}
                    </AlertYesNo>

                    <Dialog
                        fullScreen={fullScreen}
                        open={openAgreedWithRatingAlert}
                        // onClose={handleClose4}
                        aria-labelledby="responsive-dialog-title"
                        // sx={{
                        //   "& .MuiDialog-container": {
                        //     "& .MuiPaper-root": {
                        //       width: "40%",
                        //       margin: "auto",
                        //       textAlign: "center",
                        //       minHeight: "25%",
                        //       // paddingTop: "25px",
                        //     },
                        //   },
                        // }}
                        // BackdropProps={{ style: { background: "#333333 !important", opacity: "10%" } }}
                        PaperProps={{
                            style: {
                                // borderColor:'blue',
                                //border:'1px solid',
                                boxShadow: "none",
                                borderRadius: "6px",
                                //marginTop: "155px",
                                maxWidth: "-1px",
                                minWidth: "33%",
                                // maxWidth: "0px",
                                // minWidth: "26%",
                                margin: "0px",
                                padding: "6px",
                                // padding :"30px"
                                // maxHeight:"30%"
                                // display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                                // textAlign: "center",
                            },
                        }}
                    >
                        <DialogContent>
                            <DialogContentText
                                style={{
                                    color: "#333333",
                                    fontSize: "14px",
                                    fontFamily: "Arial",
                                    // whiteSpace: "nowrap",
                                    // paddingBottom: "12px",
                                    // paddingRight: "10px",
                                    // paddingLeft: "10px",
                                    whiteSpace: "nowrap",
                                    // overflow: "hidden",
                                    // textOverflow: "ellipsis",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "justify",
                                    wordBreak: "break-word",
                                    // height: "100px",
                                    alignItems: "center",
                                    // overflowY: "hidden",
                                }}
                            >
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={true}
                                        name="radio-buttons-group"
                                        onChange={(e) => {
                                            handleRejectRadioChange(e);
                                        }}
                                    >
                                        <FormControlLabel
                                            // style={{
                                            //   color: "#333333",
                                            //   fontSize: "14px",
                                            //   fontFamily: "Arial",
                                            // }}
                                            sx={{
                                                "& .MuiFormControlLabel-label": {
                                                    fontSize: "14px",
                                                    color: "#333333",
                                                    fontFamily: "Arial",
                                                },
                                            }}
                                            value={true}
                                            control={<Radio size="small" />}
                                            label="I met the Appraiser and agreed on changing the ratings"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                "& .MuiFormControlLabel-label": {
                                                    fontSize: "14px",
                                                    color: "#333333",
                                                    fontFamily: "Arial",
                                                },
                                            }}
                                            value={false}
                                            // onChange={(e) => {
                                            //   handleRejectRadioChange(e);
                                            // }}
                                            control={<Radio size="small" />}
                                            label="I met the Appraiser and disagreed on the ratings"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </DialogContentText>
                        </DialogContent>
                        <div style={{ alignItems: "center" }}>
                            <DialogActions
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    style={{
                                        textTransform: "none",
                                        fontSize: "15px",
                                        fontFamily: "Arial",
                                        borderColor: "#3E8CB5",
                                        color: "#3e8cb5",
                                        // marginRight: "10px",
                                        background: "transparent",
                                        width: "70px",
                                        height: "35px",
                                    }}
                                    variant="outlined"
                                    autoFocus
                                    onClick={handleAgreedChanges}
                                >
                                    Ok
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>

                    {/*end : Alert for Reject PA  */}

                    <AlertDialogOkCancelForUnsavedChanges
                        isAlertOpen={openCancelDialog}
                        handleAlertClose={rejectCancelButtonHandler}
                        handleAlertIdClose={acceptCancelButtonHandler}
                    >
                    </AlertDialogOkCancelForUnsavedChanges>

                    <DialogCircularProgressLoader
                        isAlertOpen={showLoader}>
                        {message}
                    </DialogCircularProgressLoader>


                    <Snackbar
                        className={classes.customSnackbar}
                        open={successAlertTriger}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        <Alert
                            className={classes.customAlert}
                            onClose={handleCloseSnackbar}
                            sx={{ width: '100%' }}
                            icon={false}
                        >
                            <b>{successAlertTrigerMSG}</b>
                        </Alert>
                    </Snackbar>
                </>
            </div>
        </>
    )
}

export default EmployeeLandingFooterButtons