import { Box, Grid, IconButton, MenuItem, Popover, Select, Stack, Typography } from '@mui/material';
import React from 'react'
import Infoicon from "../../../assets/Images/Infoicon.svg";


const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 200,
            fontSize: "14px !important",
            fontFamily: "arial !important",
            color: "#333333 !important",
        },
    },
};

const PotentialEdit = (props: any) => {
    const { employeeDataIsLoading, employeeDataIsFetching,  potential, setPotential, disableTextAfterSubmission, setMoveTab, setNavPrompt, showPotentialEdit, nineBoxData } = props;

    //popover
    const [anchorE, setAnchorE] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorE);
    const id = open ? "simple-popover" : undefined;

    const handlePotentialClose = () => {
        setAnchorE(null);
    };

    const handleClickPotential = (event: React.MouseEvent<HTMLButtonElement>) => {
        //setnavPrompt(true);
        setAnchorE(event.currentTarget);
    };


    //functions
    const handleChangePotential = (event: any) => {
        setPotential(event.target.value as string);
        setMoveTab(true);
        setNavPrompt(true);
    };


    return (
        <>
            {showPotentialEdit && (
                <Grid item xs={4}>
                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <Typography color="#3e8cb5" fontFamily="arial" fontSize="17px" >
                            <IconButton style={{ padding: "4px" }} onClick={handleClickPotential} >
                                <img style={{ width: "12px" }} src={Infoicon} alt="icon" />
                            </IconButton>
                            Potential Level

                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorE}
                                onClose={handlePotentialClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                PaperProps={{
                                    style: {
                                        backgroundColor: "FEFCF8",
                                        boxShadow: "none",
                                        maxWidth: "450px",
                                        borderRadius: "5px",
                                    },
                                }}
                                sx={{
                                    "& .MuiPopover-paper": {
                                        border: "1px solid #3e8cb5",
                                        backgroundColor: "#ffffff",
                                    },
                                }}
                            >
                                <div
                                    style={{
                                        padding: "10px",
                                        fontSize: "134px",
                                        lineHeight: "20px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: "14px",
                                            // color: "#3e8cb5",
                                            fontFamily: "Arial",
                                            paddingBottom: "5px",
                                            // borderBottom: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <b>High:</b>
                                        <span
                                            style={{
                                                fontSize: "14px",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            {nineBoxData?.data[0]?.potential_definitions?.high}
                                        </span>
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: "14px",
                                            //color: "#3e8cb5",
                                            fontFamily: "Arial",
                                            paddingBottom: "5px",
                                            paddingTop: "5px",
                                            //borderBottom: "1px solid #d9d9d9",
                                        }}
                                    >
                                        <b>Moderate:</b>

                                        <span
                                            style={{
                                                fontSize: "14px",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            {nineBoxData?.data[0]?.potential_definitions
                                                ?.moderate}{" "}
                                        </span>
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: "14px",
                                            //color: "#3e8cb5",
                                            fontFamily: "Arial",
                                            paddingTop: "5px",
                                        }}
                                    >
                                        <b>Low:</b>

                                        <span
                                            style={{
                                                fontSize: "14px",
                                                color: "#333333",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            {nineBoxData?.data[0]?.potential_definitions
                                                ?.low}{" "}
                                        </span>
                                    </Typography>
                                </div>
                            </Popover>
                        </Typography>


                        <>
                            <Select
                                sx={{
                                    width: "84%",
                                    "& .MuiInputBase-input": {
                                        fontSize: "14px",
                                        textTransform: "none",
                                        fontFamily: "Arial",
                                        color: "#333333",
                                    },
                                }}
                                MenuProps={MenuProps}
                                size="small"
                                value={potential}
                                disabled={employeeDataIsLoading || employeeDataIsFetching}
                                onChange={(e) => {
                                    handleChangePotential(e);
                                }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: "14px",
                                        fontFamily: "arial",
                                        color: "#333333",
                                    }}
                                    value="High"
                                    disabled={disableTextAfterSubmission}
                                >
                                    High
                                </MenuItem>
                                <MenuItem
                                    style={{
                                        fontSize: "14px",
                                        fontFamily: "arial",
                                        color: "#333333",
                                    }}
                                    value="Moderate"
                                    disabled={disableTextAfterSubmission}
                                >
                                    Moderate
                                </MenuItem>
                                <MenuItem
                                    style={{
                                        fontSize: "14px",
                                        fontFamily: "arial",
                                        color: "#333333",
                                    }}
                                    value="Low"
                                    disabled={disableTextAfterSubmission}
                                >
                                    Low
                                </MenuItem>
                            </Select>
                        </>

                    </Stack>
                </Grid>
            )}

        </>
    )
}

export default PotentialEdit