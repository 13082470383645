import { TextField, Box, Typography, styled } from '@mui/material'
import React from 'react'

const Tf11 = styled("div")({
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "arial",
        fontWeight: "400",
        textTransform: "none",
        // padding: "4px",
        textAlign: "left"
    },
});

const ReviewerCommentsView = (props: any) => {
    const { showReviewerComments, reviewerComments } = props;
    return (
        <>
            <>
                <div style={{ marginBottom: "20px" }}>
                    <Typography
                        style={{
                            fontSize: "16px",
                            color: "#717171",
                            marginBottom: "10px",
                            fontFamily: "Arial"
                        }}
                    >
                        <b>Reviewer Comments</b>
                    </Typography>
                    <Tf11>
                        {/* <TextField
                                    fullWidth
                                    InputProps={{ readOnly: true, }}
                                    multiline
                                    inputProps={{ maxLength: 500 }}
                                    size="small"
                                    value={reviewerComments}
                                /> */}
                        <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px", }}>
                            <div style={{
                                color: "#333333",
                                fontSize: "14px",
                                fontFamily: "arial",
                                fontWeight: "400",
                                textTransform: "none",
                                // padding: "8px",
                                textAlign: "left",
                                lineHeight: "23px"
                            }}>
                                {reviewerComments}
                            </div>
                        </Box>
                    </Tf11>
                </div>
            </>
        </>
    )
}

export default ReviewerCommentsView