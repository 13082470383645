import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { Box, MenuItem, FormControl, Menu, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import Searchlensreview from "../../../../components/reviewerMain/Reviewericons/Searchlensreview.svg"
import Newexcel from "../../../../components/reviewerMain/Reviewericons/Newexcel.svg"
import * as XLSX from "xlsx";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import {
  useGetActiveCalenderQuery,
} from "../../../../service";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import { ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExportToexcel from "../../../../components/ExportToExcel/ExporttoExcel";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",

  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },
}));
const Searchfeild = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "28px",
    width: "144px",
    borderRadius: "15px",
    background: "#F2F6F8",
  },
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#306D8F",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "13px",
    color: "#306D8F",
  },
});
const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
  "& .ScrollbarsCustom-Track": {
  },
});
const Names = styled("div")({
  color: "#333333",
});
const TableHeadings = styled("div")({
});
export default function OverallDashboardExpandTableChild(props: any) {
  const classes = useStyles();
  const {
    employeeData1,
    appCalId,
    tabValue,
    Filteredemployeedata
  } = props;
  // const { data: user } = useLoggedInUser();
  const listInnerRef = useRef<any>();
  const navigate = useNavigate();
  const [users, setUsers] = useState<any>([]);
  const [enteredName, setenteredName] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tablecount, settablecount] = React.useState<any>(0);
  const [pageNumber, setPageNumber] = useState(0)
  //Data for populating the calendar name
  const { data: activecalendardata } = useGetActiveCalenderQuery('')
  let CalendarName = activecalendardata?.data[0]?.name
  useEffect(() => {
    setUsers(Filteredemployeedata);
  }, [Filteredemployeedata]);

  // function for pending action status
  const getPAStatus = (j: any) => {
    if (
      j.appraisal?.objective_description &&
      j.appraisal?.objective_description.length === 0
    )
      return " PA Not Started";
    else if (j?.appraisal?.status == "completed") return "-";
    else if (j?.appraisal?.appraiser_status === "pending")
      return " Pending with Appraiser";
    else if (j?.appraisal?.status === "normalized")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status?.includes("draft")) return " Pending with Appraiser (Draft)";
    else if (
      j?.appraisal?.appraiser_status === "submitted" &&
      (j?.reviewer?.reviewer_status == "pending" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status === "accepted" &&
      (j?.reviewer?.reviewer_status == "pending" || j?.reviewer?.reviewer_status == "appraiser-accepted" ||
        j?.reviewer?.reviewer_status == "draft")
    )
      return " Pending with Reviewer";
    else if (j?.reviewer?.reviewer_status == "appraiser-rejected") {
      return " Pending with Reviewer";
    } else if (
      // j.appraisal.appraiser_status === "submited" &&
      j?.reviewer?.reviewer_status == "accepted" &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "reviewer-rejected")
      return " Pending with Appraiser (Reviewer Rejection)";
    else if (j?.appraisal?.appraiser_status === "normalizer-rejected")
      return " Pending with Appraiser (Normalizer Rejection)";
    else if (j?.appraisal?.appraiser_status === "appraiser-rejected-employee")
      return " Pending with Employee";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser (Employee Rejection)";
    else if (
      j?.reviewer.reviewer_status == "accepted" &&
      j?.normalizer.normalizer_status == "employee-rejected"
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "accepted")
      return " Pending with Employee";
    else if (j?.normalizer?.normalizer_status === "draft")
      return " Pending with HR Normalizer";
    else if (
      j?.reviewer?.reviewer_status == "rejected" &&
      j?.reviewer?.rejection_count == 3 &&
      (j?.normalizer?.normalizer_status == "pending" || j?.normalizer?.normalizer_status == "draft")
    )
      return " Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status == "employee-rejected")
      return " Pending with HR Normalizer";
    // 5
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser";
    // 2
    else if (
      j?.reviewer?.reviewer_status == "normalizer-rejected" &&
      j?.reviewer?.reviewer_status == "appraiser-rejected"
    )
      return "Pending with Reviewer";
    // 1
    else if (
      j?.appraisal?.appraiser_status == "normalizer-rejected" &&
      j?.appraisal?.appraiser_status == "accepted"
    )
      return "Pending with Reviewer";
    else return "-";
  };
  const getStatus = (status: any) => {
    if (status == "in-progress") {
      return "In progress"
    } else if (status == "not-started") {
      return "Not started"
    } else if (status == "normalized") {
      return "Normalized"
    } else if (status == "rejected") {
      return "Rejected "
    } else if (status == "completed") {
      return "Completed "
    }
  }
  const getpaStatus = (status: any) => {
    if (status == "Completed" || status == "excepted") {
      return "-"
    } else {
      return status
    }
  }
  // getpaStatus
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  // function for export to excel drawer function 
  const handleExportFunction = () => {
    setisDrawerOpen(true);
  };
  // function for nextpage
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //Data for populating the table
  //filter
  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 400,
      },
    },
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPageNumber(pageNumber + 1);
      }
    }
  };
  //sorting
  const [empsections, setempsections] = React.useState("");
  //populating filter dropdown
  const [gradesArray, setgradesArray] = React.useState<any>([]);
  const [positionArray, setpositionArray] = React.useState<any>([]);
  const [sectionArray, setsectionArray] = React.useState<any>([]);
  const [positionsFilter, setpositionsFilter] = React.useState<string[]>([]);
  const [GradesFilter, setGradesFilter] = React.useState<string[]>([]);
  React.useEffect(() => {
    let grades = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.grade - b?.grade;
      })
      ?.map((i: any) => {
        return i?.grade;
      });
    // positionsFilter
    //for filtering graades options
    if (positionsFilter.length > 0) {
      grades = users
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade - b?.grade;
        })
        ?.filter((i: any) => {
          return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    else if (enteredName?.length > 0) {
      grades = users
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.grade?.localeCompare(
            b?.grade
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const enteredTerms = enteredName.toLowerCase().split(" ");
            return enteredTerms.every(term =>
              i?.grade
                ?.toLowerCase()
                .includes(term)
            ) || enteredTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            ) || enteredTerms.every((term: any) =>
              i?.appraisal?.pa_status?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.grade;
        });
    }
    const gradeContents = grades?.filter((c: any, index: any) => {
      return grades?.indexOf(c) === index && c != null && c != undefined;
    });
    setgradesArray(gradeContents);
    let position = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.position_long_description?.localeCompare(b?.position_long_description);
      })
      ?.map((i: any) => {
        return i?.position_long_description;
      });
    // 
    if (GradesFilter.length > 0) {
      position = users
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description - b?.position_long_description;
        })
        ?.filter((i: any) => {
          return !!GradesFilter?.find(item2 => i?.grade === item2)
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    else if (enteredName?.length > 0) {
      position = users
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.position_long_description?.localeCompare(
            b?.position_long_description
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const enteredTerms = enteredName.toLowerCase().split(" ");
            return enteredTerms.every(term =>
              i?.position_long_description
                ?.toLowerCase()
                .includes(term)
            ) || enteredTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            ) || enteredTerms.every((term: any) =>
              i?.appraisal?.pa_status?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.position_long_description;
        });
    }
    const positionContents = position?.filter((c: any, index: any) => {
      return position?.indexOf(c) === index && c != null && c != undefined;
    });
    setpositionArray(positionContents);
    let section = users
      ?.slice()
      ?.sort(function (a: any, b: any) {
        return a?.section?.localeCompare(b?.section);
      })
      ?.map((i: any) => {
        return i.section;
      });
    if (GradesFilter.length > 0) {
      section = users
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.section - b?.section;
        })
        ?.filter((i: any) => {
          return !!GradesFilter?.find(item2 => i?.grade === item2)
        })
        ?.map((i: any) => {
          return i?.section;
        });
    } else if (positionsFilter.length > 0) {
      section = users
        ?.slice()
        ?.sort(function (a: any, b: any) {
          return a?.section - b?.section;
        })
        ?.filter((i: any) => {
          return !!positionsFilter?.find(item2 => i?.position_long_description === item2)
        })
        ?.map((i: any) => {
          return i?.section;
        });
    }
    else if (enteredName?.length > 0) {
      section = users
        .slice()
        ?.sort(function (a: any, b: any) {
          return a?.section?.localeCompare(
            b?.section
          );
        })
        ?.filter((i: any) => {
          if (enteredName.length > 0) {
            const enteredTerms = enteredName.toLowerCase().split(" ");
            return enteredTerms.every(term =>
              i?.position_long_description
                ?.toLowerCase()
                .includes(term)
            ) || enteredTerms.every(term =>
              i?.grade?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.position_long_description?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.section?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.employee_code?.toLowerCase().includes(term)
            ) || enteredTerms.every(term =>
              i?.legal_full_name?.toLowerCase().includes(term)
            ) || enteredTerms.every((term: any) =>
              i?.appraisal?.pa_status?.toLowerCase().includes(term)
            )
          } else {
            return true;
          }
        })
        ?.map((i: any) => {
          return i?.section;
        });
    }
    const sectionContents = section?.filter((c: any, index: any) => {
      return section?.indexOf(c) === index && c != null && c != undefined;
    });
    setsectionArray(sectionContents);
    console.log(sectionContents, "contents");
  }, [users, positionsFilter, GradesFilter, empsections, enteredName]);
  //populating filter dropdown
  //For multislect options
  const isAllGradesFilter =
    gradesArray?.length > 0 && GradesFilter?.length === gradesArray?.length;
  const newsection = gradesArray?.length == GradesFilter?.length
  const handleChangeSelectGrades = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setGradesFilter(GradesFilter?.length === gradesArray?.length ? [] : gradesArray);
      return;
    }
    setGradesFilter(value);
    setPage(0);
    setPage(0);
  };
  const isAllpositionsFilter =
    positionArray?.length > 0 && positionsFilter?.length === positionArray?.length;
  const handleChangeSelectPositions = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setpositionsFilter(positionsFilter?.length === positionArray?.length ? [] : positionArray);
      return;
    }
    setpositionsFilter(value);
    setPage(0);
  };
  //For multislect options
  //Section
  const [anchorElnewSection, setAnchorElnewSection] = React.useState<null | HTMLElement>(
    null
  );
  const openSection = Boolean(anchorElnewSection);
  const handleClickSection = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElnewSection(event.currentTarget);
  };
  const handleCloseSection = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElnewSection(null);
  };
  const handleTargetSection = (event: any) => {

    setempsections(event?.target?.getAttribute("value"));


    setAnchorElnewSection(null);
    setPage(0);
  };



  // function for pagination
  useEffect(() => {
    const Paginate = users
      ?.filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      ?.filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      ?.filter((j: any) => {
        if (empsections === "None" || empsections === "") {
          return j;
        } else {
          return j.section
            .toLocaleLowerCase() === empsections?.toLocaleLowerCase();
        }
      })
      ?.filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (
          (j?.employee_code !== undefined &&
            j?.employee_code
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.grade !== undefined &&
            j?.grade
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.section !== undefined &&
            j?.section
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.appraisal?.pa_status !== undefined &&
            j?.appraisal?.pa_status
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              )) ||
          (j?.appraisal?.status !== undefined &&
            j?.appraisal?.status
              ?.toLocaleLowerCase()
              ?.includes(
                enteredName?.toLocaleLowerCase()
              ))
        ) {
          return j;
        }
      })
    settablecount(Paginate?.length)
  }, [users, enteredName, empsections, positionsFilter, GradesFilter])
  //  Filter icon
  const [icon2, setIcon2] = React.useState<any>([]);
  const [icon3, setIcon3] = React.useState<any>([]);
  const [icon5, setIcon5] = React.useState<any>([]);
  useEffect(() => {
    if (positionsFilter?.length == 0) {
      setIcon2(false);
    } else {
      setIcon2(true);
    }
  }, [positionsFilter])
  React.useEffect(() => {
    if (GradesFilter?.length == 0) {
      setIcon3(false);
    } else {
      setIcon3(true);
    }
  }, [GradesFilter])
  React.useEffect(() => {
    if (empsections === "None" || empsections === "") {
      setIcon5(false);
    } else {
      setIcon5(true);
    }
  }, [empsections])
  //drawer functions
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  // export to excel drawer checkbox state
  const [columnHeaders, setcolumnHeaders] = useState<any>({
    Ecode: true,
    Ename: true,
    Eposition: true,
    Firstname: true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    OverallRating: true,
    employeerating: true,
    appraiserRating: true,
    reviewerRating: true,
    normalizerRating: true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    appraiserCode: true,
    reviewerCode: true,
    normalizerCode: true,
    status: true,
    ServiceReferenceDate: true,
    Function: true,
    SupervisoryRole: true,
    EmailID: true,
    CalendarName: true,
    SelectAll: true,
    PendingAction:true,
  })
  // .... checked count and totalcount used for Select All checkbox on choosefields//
  const checkedCount = Object.keys(columnHeaders).reduce((count: any, key: any) => {
    if (key !== 'SelectAll') {
      return count + (columnHeaders[key] ? 1 : 0);
    }
    return count;
  }, 0);
  const totalCount = Object.keys(columnHeaders).length - 1;
  console.log(checkedCount, totalCount, "totalCount")
  // .... checked count and totalcount used for Select All checkbox on choosefields//
  // Function for export to excel Close button
  const handleCloseGrade = () => {
    setisDrawerOpen(false);
    // reset checkbox
    setcolumnHeaders({
      Ecode: true,
    Ename: true,
    Eposition: true,
    Firstname: true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    OverallRating: true,
    employeerating: true,
    appraiserRating: true,
    reviewerRating: true,
    normalizerRating: true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    appraiserCode: true,
    reviewerCode: true,
    normalizerCode: true,
    status: true,
    ServiceReferenceDate: true,
    Function: true,
    SupervisoryRole: true,
    EmailID: true,
    CalendarName: true,
    SelectAll: true,
    PendingAction:true,
    })
  };

  // Export to excel drawer state checkbox
  const [heading1Dis, setheading1Dis] = React.useState(true);
  const [heading2Dis, setheading2Dis] = React.useState(true);
  const [heading3Dis, setheading3Dis] = React.useState(true);
  const [heading1, setheading1] = React.useState(true);
  const [firstName, setfirstName] = React.useState(true);
  const [appraiserRating, setAppraiserRating] = React.useState(true);
  const [reviewerRating, setReviewerRating] = React.useState(true);
  const [normalizeRating, setNormalizeRating] = React.useState(true);
  const [employeerating, setemployeerating] = React.useState(true);
  const [headingOfSection, setheadingOfSection] = React.useState(true);
  const [headingOfPAStatus, setheadingOfPAStatus] = React.useState(true);
  const [headingOfDivision, setheadingOfDivision] = React.useState(true);
  const [headingOfSubSection, setheadingOfSubSection] = React.useState(true);
  const [headingOfAppraiserName, setheadingOfAppraiserName] = React.useState(true);
  const [headingOfReviewerName, setheadingOfReviewerName] = React.useState(true);
  const [headingOfNormalizerName, setheadingOfNormalizerName] = React.useState(true);
  const [headingOfOverallRating, setheadingOfOverallRating] = React.useState(true);
  const [headingOfPreviousRating, setheadingOfPreviousRating] = React.useState(true);
  const [headingOfPotentialLevel, setheadingOfPotentialLevel] = React.useState(true);
  const [headingOfTalentCategory, setheadingOfTalentCategory] = React.useState(true);
  const [headingOfWorkLocation, setheadingOfWorkLocation] = React.useState(true);
  const [headingOfManagerCode, setheadingOfManagerCode] = React.useState(true);
  const [headingOfManagerName, setheadingOfManagerName] = React.useState(true);
  const [headingOfManagerPosition, setheadingOfManagerPosition] = React.useState(true);
  const [ServiceReferenceDateData, setServiceReferenceDateData] = React.useState(true);
  const [FunctionData, setFunctionData] = React.useState(true);
  const [SupervisoryRoleData, setSupervisoryRoleData] = React.useState(true);
  const [EmailIDData, setEmailIDData] = React.useState(true);
  const [heading2, setheading2] = React.useState(true);
  const [heading3, setheading3] = React.useState(true);
  const [appCodes, setappCodes] = React.useState(true);
  const [revCodes, setrevCodes] = React.useState(true);
  const [norCodes, setnorCodes] = React.useState(true);
  const [statusValue, setstatusValue] = React.useState(true);
  const handleServiceReferenceDateData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceReferenceDateData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingOfPendingAction, setheadingOfPendingAction] = React.useState(true);

  const handlePendingAction = (event: React.ChangeEvent<HTMLInputElement>) => {
      setheadingOfPendingAction(event.target.checked);
      const { name, checked } = event.target;
      setcolumnHeaders((prevColumnHeaders: any) => ({
        ...prevColumnHeaders,
        [name]: checked,
        SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
      }));
    };
  const handleFunctionData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSupervisoryRoleData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupervisoryRoleData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleEmailIDData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailIDData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingOfEcode, setheadingOfEcode] = React.useState(true);
  const handleEmployeeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfEcode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading1(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading2(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading3(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleDivision = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfDivision(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSubSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSubSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfAppraiserName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [headingcalendar, setheadingcalendar] = React.useState(true);
  const handleheadingCalendar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingcalendar(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  }
  const handlefirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfirstName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfReviewerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfNormalizerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));

  };
  const handleOverallRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfOverallRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleemployeerating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemployeerating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppraiserRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstatusValue(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setappCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleRevCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setrevCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNorCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnorCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlependingAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePreviousRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPreviousRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSelectAll = (selectAll: any) => {
    const updatedColumnHeaders = { ...columnHeaders };
    Object.keys(updatedColumnHeaders).forEach((key) => {
      updatedColumnHeaders[key] = selectAll;
    });
    setcolumnHeaders(updatedColumnHeaders);
  };
  const handlePotentialLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPotentialLevel(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleTalentCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfTalentCategory(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleWorkLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfWorkLocation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerPosition(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  // function for export to excel Apply button
  const handleheadingSortAccept = () => {
    setisDrawerOpen(false);
    handleExport();
  };
  //console.log(heading1,headingSortAccept,heading1Dis, "h,hsa,hd");
  const [filData, setfilData] = React.useState<any>([]);

  const handleExport = () => {
    const mapped = users &&
      users
      ?.filter((item1: any) => {
        if (positionsFilter.includes("None") || positionsFilter.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      ?.filter((item1: any) => {
        if (GradesFilter.includes("None") || GradesFilter.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      ?.filter((j: any) => {
        if (empsections === "None" || empsections === "") {
          return j;
        } else {
          return j.section
            .toLocaleLowerCase() === empsections?.toLocaleLowerCase();
        }
      })
        ?.filter((j: any) => {
          if (enteredName === "") {
            return j;
          } else if (
            (j?.employee_code !== undefined &&
              j?.employee_code
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.legal_full_name !== undefined &&
              j?.legal_full_name
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.grade !== undefined &&
              j?.grade
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.position_long_description !== undefined &&
              j?.position_long_description
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.first_name !== undefined &&
              j?.first_name
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.service_reference_date !== undefined &&
              j?.service_reference_date
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.position_code !== undefined &&
              j?.position_code
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.division !== undefined &&
              j?.division
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.section !== undefined &&
              j?.section
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.ManagerCode !== undefined &&
              j?.ManagerCode
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.ManagerName !== undefined &&
              j?.ManagerName
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.ManagerPosition !== undefined &&
              j?.ManagerPosition
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.WorkLocation !== undefined &&
              j?.WorkLocation
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.grade_set !== undefined &&
              j?.grade_set
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.job_code !== undefined &&
              j?.job_code
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.job_title !== undefined &&
              j?.job_title
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                )) ||
            (j?.job_level !== undefined &&
              j?.job_level
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                ))
          ) {
            return j;
          }
        })
        ?.map((j: any, emp: any, employee: any) => {
          let inputDate = j?.service_reference_date
          const dateParts = inputDate?.split("-");
          console.log(inputDate, dateParts, "inputDate")
          let date = new Date(inputDate);
          const year = date?.getFullYear();
          const month = date?.toLocaleString("default", { month: "short" });
          const day = date?.getDate();
          const formattedDate = `${day}-${month}-${year}`;
          let exportData: any = {}
          if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = CalendarName
          if (columnHeaders["Ecode"] == true) exportData["Ecode"] = j?.employee_code
          if (columnHeaders["Ename"] == true) exportData["Employee Name"] = j?.legal_full_name
          if (columnHeaders["Firstname"] == true) exportData["Known As"] = j?.first_name
          if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = formattedDate
          if (columnHeaders["Eposition"] == true) exportData["Position"] = j?.position_long_description
          if (columnHeaders["EGrade"] == true) exportData["Grade"] = j?.grade
          if (columnHeaders["Function"] == true) exportData["Function"] = j?.function
          if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
          if (columnHeaders["EmailID"] == true) exportData["Email ID"] = j?.email
          if (columnHeaders["EDivision"] == true) exportData["Division"] = j?.division
          if (columnHeaders["ESection"] == true) exportData["Section"] = j?.section
          if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = j?.sub_section
          if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = j?.work_location
          if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = j?.appraiser_code
          if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = j?.appraiser_name
          if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = j?.reviewer_code
          if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = j?.reviewer_name
          if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = j?.normalizer_code
          if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = j?.normalizer_name
          if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = j?.employee?.employee_rating?.toFixed(2)
          if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = j?.appraisal?.appraiser_rating?.toFixed(2)
          if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = j?.reviewer?.reviewer_rating?.toFixed(2)
          if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = j?.normalizer?.normalizer_rating?.toFixed(2)
          if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = (j?.current_rating?.overall_rating == 0 || j?.appraisal?.status !== "completed") ? "-" : j?.current_rating?.overall_rating?.toFixed(2)
          if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = j?.previous_rating?.toFixed(2)
          if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = (j?.normalizer?.normalized_overallRating !== 0 ? j?.appraisal?.potential : "")
          if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = j?.talent_category
          if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = j?.manager_code
          if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = j?.manager_name
          if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = j?.manager_position
          if (columnHeaders["status"] == true) exportData["Status"] = getStatus(j?.appraisal?.status)
            if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] = j.appraisal?.status == "not-started" ? "Pending with Appraiser" :j.appraisal?.status == "completed" ? ("-") : j.appraisal.pa_status

          return exportData
        });
    const a = [1]
    const Emptymapped = a.map((j: any) => {
      let exportData: any = {}
      if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = ""
      if (columnHeaders["Ecode"] == true) exportData["Ecode"] = ""
      if (columnHeaders["Ename"] == true) exportData["Employee Name"] = ""
      if (columnHeaders["Firstname"] == true) exportData["Known As"] = ""
      if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = ""
      if (columnHeaders["Eposition"] == true) exportData["Position"] = ""
      if (columnHeaders["EGrade"] == true) exportData["Grade"] = ""
      if (columnHeaders["Function"] == true) exportData["Function"] = ""
      if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = ""
      if (columnHeaders["EmailID"] == true) exportData["Email ID"] = ""
      if (columnHeaders["EDivision"] == true) exportData["Division"] = ""
      if (columnHeaders["ESection"] == true) exportData["Section"] = ""
      if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = ""
      if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = ""
      if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = ""
      if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = ""
      if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = ""
      if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = ""
      if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = ""
      if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = ""
      if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = ""
      if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = ""
      if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = ""
      if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = ""
      if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = ""
      if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = ""
      if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = ""
      if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = ""
      if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = ""
      if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = ""
      if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = ""
      if (columnHeaders["status"] == true) exportData["Status"] = ""
      if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] ="" 

      return exportData
    })
    console.log(mapped, "mapped")
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(mapped?.length === 0 ? Emptymapped : mapped);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "MyExcel.xlsx");
  }
  // function for searchbar
  const maxLengthForSearch = 30;
  const handleSearchBar = (e: any) => {
    if (e.target.value.length > maxLengthForSearch) {
      e.target.value = e.target.value.slice(0, maxLengthForSearch);
    }
    setenteredName(e.target.value);
    setPage(0);
  }
  // function for no data to display shown while no employees
  const tableDataFilterdLength = users
    ?.filter((item1: any) => {
      if (positionsFilter.includes("None") || positionsFilter.length === 0) {
        return item1;
      } else {
        return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
      }
    })
    ?.filter((item1: any) => {
      if (GradesFilter.includes("None") || GradesFilter.length === 0) {
        return item1;
      } else {
        return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
      }
    })
    ?.filter((j: any) => {
      if (empsections === "None" || empsections === "") {
        return j;
      } else {
        return j.section
          .toLocaleLowerCase() === empsections?.toLocaleLowerCase();
      }
    })
    ?.filter((j: any) => {
      if (enteredName === "") {
        return j;
      } else if (
        (j?.employee_code !== undefined &&
          j?.employee_code
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.legal_full_name !== undefined &&
          j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.grade !== undefined &&
          j?.grade
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.position_long_description !== undefined &&
          j?.position_long_description
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.section !== undefined &&
          j?.section
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.appraisal?.pa_status !== undefined &&
          j?.appraisal?.pa_status
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            )) ||
        (j?.appraisal?.status !== undefined &&
          j?.appraisal?.status
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            ))

      ) {
        return j;
      }
    })

  return (
    <>

      <Box
        style={{
          marginLeft: "25px",
          marginRight: "25px",
          background: "#ffffff",
          padding: "20px",
          height: "auto",
          marginBottom: "25px"
        }}
      >
        <div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingBottom="10px"
          >
            <Typography
              gutterBottom
              component="div"
              sx={{ fontSize: "20px", fontFamily: "Arial", color: "#333333" }}
            >
            </Typography>
            <div>
              <Stack direction="row" spacing={2} alignItems="center">
                <Searchfeild>
                  <div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Search Here..."
                      autoComplete="off"
                      inputProps={{ maxLength: 256 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={Searchlensreview} alt="icon" />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearchBar}
                    />
                    <img
                      src={Newexcel}
                      alt="icon"
                      style={{ marginLeft: "15px", marginTop: "5px", cursor: "pointer" }}
                      onClick={handleExportFunction}
                    />
                  </div>
                </Searchfeild>
              </Stack>
            </div>
            <ExportToexcel
              isDrawerOpen={isDrawerOpen}
              setisDrawerOpen={setisDrawerOpen}
              handleSelectAll={handleSelectAll}
              checkedCount={checkedCount}
              totalCount={totalCount}
              columnHeaders={columnHeaders}
              handleheadingCalendar={handleheadingCalendar}
              handleEmployeeCode={handleEmployeeCode}
              handleheading1={handleheading1}
              handlefirstName={handlefirstName}
              handleServiceReferenceDateData={handleServiceReferenceDateData}
              handleheading2={handleheading2}
              handleheading3={handleheading3}
              handleFunctionData={handleFunctionData}
              handleSupervisoryRoleData={handleSupervisoryRoleData}
              handleEmailIDData={handleEmailIDData}
              handleDivision={handleDivision}
              handleSection={handleSection}
              handleSubSection={handleSubSection}
              handleWorkLocation={handleWorkLocation}
              handleAppCodes={handleAppCodes}
              handleAppraiserName={handleAppraiserName}
              handleRevCodes={handleRevCodes}
              handleReviewerName={handleReviewerName}
              handleNorCodes={handleNorCodes}
              handleNormalizerName={handleNormalizerName}
              handleemployeerating={handleemployeerating}
              handleAppraiserRating={handleAppraiserRating}
              handleReviewerRating={handleReviewerRating}
              handleNormalizerRating={handleNormalizerRating}
              handleOverallRating={handleOverallRating}
              handlePreviousRating={handlePreviousRating}
              handlePotentialLevel={handlePotentialLevel}
              handleTalentCategory={handleTalentCategory}
              handleManagerCode={handleManagerCode}
              handleManagerName={handleManagerName}
              handleManagerPosition={handleManagerPosition}
              handleStatus={handleStatus}
              handleheadingSortAccept={handleheadingSortAccept}
              handleCloseGrade={handleCloseGrade}
              handlePendingAction={handlePendingAction}
            />
          </Stack>
          <TableHeadings>
            <TableContainer>
              <Scroll>
                <Table size="small" aria-label="simple table">
                  <TableHead
                    style={{ position: "sticky", zIndex: "1000", top: "0px" }}
                  >
                    <TableRow
                      sx={{ bgcolor: "#eaeced", }}>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                          maxWidth: "60px"
                        }}
                      >
                        Ecode
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Employee Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                          <Stack direction="row">
                            <span>Position</span>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              size="small"
                              sx={{
                                width: "25px", fontSize: "0rem",
                                "& .MuiSvgIcon-root": {
                                  color: "#3e8cb5 !important"
                                },

                              }}
                              disableUnderline
                              variant="standard"
                              MenuProps={MenuProps}
                              multiple
                              value={positionsFilter}
                              onChange={handleChangeSelectPositions}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  padding: "0px",
                                  //paddingLeft: "37px",
                                }}
                                key="all"
                                value="all"
                                classes={{
                                  root: isAllpositionsFilter ? classes.selectedAll : "",
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "14px !important",
                                      },
                                    }}
                                    size="small"
                                    style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                    classes={{
                                      indeterminate: classes.indeterminateColor,
                                    }}
                                    checked={isAllpositionsFilter}
                                    indeterminate={
                                      positionsFilter?.length > 0 &&
                                      positionsFilter?.length < positionArray?.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "14px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }}
                                  classes={{ primary: classes.selectAllText }}
                                  primary="Select All"
                                />
                              </MenuItem>
                              {positionArray?.map((option: any) => (
                                <MenuItem
                                  style={{
                                    fontSize: "12px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    padding: "0px",
                                  }}
                                  key={option}
                                  value={option}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "14px !important",
                                        },
                                      }}
                                      size="small"
                                      style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                      checked={positionsFilter.indexOf(option) > -1}
                                    />
                                  </ListItemIcon>

                                  <ListItemText sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }} primary={option} />
                                </MenuItem>

                              ))}
                            </Select>
                            {icon2 && (
                              <FilterAltTwoToneIcon />)}
                          </Stack>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                          maxWidth: "60px"
                        }}
                      >
                        <FormControl sx={{ m: 0, width: 80, height: "0" }}>
                          <Stack direction="row">
                            <span>Grade</span>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              size="small"
                              sx={{
                                width: "25px", fontSize: "0rem",
                                "& .MuiSvgIcon-root": {
                                  color: "#3e8cb5 !important"
                                },
                              }}
                              disableUnderline
                              variant="standard"
                              MenuProps={MenuProps}
                              multiple
                              value={GradesFilter}
                              onChange={handleChangeSelectGrades}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  padding: "0px",
                                }}
                                key="all"
                                value="all"
                                classes={{
                                  root: isAllGradesFilter ? classes.selectedAll : "",
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "14px !important",
                                      },
                                    }}
                                    size="small"
                                    style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                    classes={{
                                      indeterminate: classes.indeterminateColor,
                                    }}
                                    checked={isAllGradesFilter}
                                    indeterminate={
                                      GradesFilter?.length > 0 &&
                                      GradesFilter?.length < gradesArray?.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }}
                                  classes={{ primary: classes.selectAllText }}
                                  primary="Select All"
                                />
                              </MenuItem>
                              {gradesArray?.map((option: any) => (
                                <MenuItem
                                  style={{
                                    fontSize: "12px",
                                    color: "#333333",
                                    fontFamily: "Arial",
                                    padding: "0px",
                                  }}
                                  key={option}
                                  value={option}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "14px !important",
                                        },
                                      }}
                                      size="small"
                                      style={{ padding: "0px", paddingLeft: "14px", height: "0px" }}
                                      checked={GradesFilter.indexOf(option) > -1}
                                    />
                                  </ListItemIcon>
                                  <ListItemText sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "13px",
                                      fontFamily: "Arial",
                                      color: "#333333",
                                      paddingRight: "10px"
                                    },
                                  }} primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                            {icon3 && (
                              <FilterAltTwoToneIcon />)}
                          </Stack>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        <Stack direction="row" alignItems="center" justifyContent="center">
                          <div
                            aria-controls={openSection ? "fade-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openSection ? "true" : undefined}
                            onClick={handleClickSection}
                          >
                            <Stack direction="row" alignItems="center">
                              Section
                              <ArrowDropDownOutlinedIcon
                                style={{ cursor: "pointer" }} />
                            </Stack>
                          </div>
                          <Menu
                            MenuListProps={{
                              "aria-labelledby": "fade-button",
                            }}
                            sx={{
                              height: "200px",
                            }}
                            anchorEl={anchorElnewSection}
                            open={openSection}
                            onClose={handleCloseSection}
                          >
                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Arial",
                                color: "#333333",
                                padding: "0px 10px 2px 17px",
                              }}
                              key="None"
                              value="None"
                              onClick={handleTargetSection}
                            >Clear Filter
                            </MenuItem>
                            {sectionArray?.map((name: any) => (
                              <MenuItem
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "Arial",
                                  color: "#333333",
                                  padding: "0px 10px 2px 17px",
                                  backgroundColor: empsections === name ? "#EAECED" : "",
                                }}
                                key={name}
                                value={name}
                                onClick={handleTargetSection}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Menu>
                          {icon5 && <FilterAltTwoToneIcon />}
                        </Stack>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Pending Action
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Appraiser Rating
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Reviewer Rating
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        HR Normalizer Rating
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Employee Rating
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Arial",
                          color: "#3E8CB5",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Overall Rating
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {tableDataFilterdLength?.length > 0 ? (
                    <TableBody
                      ref={listInnerRef}
                      onScroll={onScroll}
                    >
                      {users
                        ?.slice()
                        ?.sort(
                          (a: any, b: any) =>
                            b?.current_rating?.overall_rating - a?.current_rating?.overall_rating // Sort by pa_rating in descending order
                        )
                        ?.filter((item1: any) => {
                          if (positionsFilter.includes("None") || positionsFilter.length === 0) {
                            return item1;
                          } else {
                            return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
                          }
                        })
                        ?.filter((item1: any) => {
                          if (GradesFilter.includes("None") || GradesFilter.length === 0) {
                            return item1;
                          } else {
                            return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
                          }
                        })
                        ?.filter((j: any) => {
                          if (empsections === "None" || empsections === "") {
                            return j;
                          } else {
                            return j.section
                              .toLocaleLowerCase() === empsections?.toLocaleLowerCase();
                          }
                        })
                        ?.filter((j: any) => {
                          if (enteredName === "") {
                            return j;
                          } else if (
                            (j?.employee_code !== undefined &&
                              j?.employee_code
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.legal_full_name !== undefined &&
                              j?.legal_full_name
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.grade !== undefined &&
                              j?.grade
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.position_long_description !== undefined &&
                              j?.position_long_description
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.section !== undefined &&
                              j?.section
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.appraisal?.pa_status !== undefined &&
                              j?.appraisal?.pa_status
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                )) ||
                            (j?.appraisal?.status !== undefined &&
                              j?.appraisal?.status
                                ?.toLocaleLowerCase()
                                ?.includes(
                                  enteredName?.toLocaleLowerCase()
                                ))
                          ) {
                            return j;
                          }
                        })
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )?.map((j: any) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderColor: "lightgrey",
                                },
                                whiteSpace: "nowrap"
                              }}
                            >
                              <TableCell
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                                align="center"
                              >
                                {j.employee_code}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                <Names>
                                  {j.legal_full_name}
                                </Names>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {j.position_long_description}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                  paddingRight: "40px"
                                }}
                              >
                                {j.grade}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                }}
                              >
                                {j?.section}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                }}
                              >
                                {getStatus(j?.appraisal?.status)}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {getpaStatus(j?.appraisal?.pa_status)}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  wordBreak: "break-word",
                                  /******************Appraiser rating should be red if appraiser rejected : should be blue only if there
                                  * is change in the rating that is ,if it is different from the normalized rating else it should be black*******************/
                                  color:
                                    j.appraisal.appraiser_PA_rejected == true ? "red" :
                                      (j.appraisal.status == "rejected" && j.appraisal.appraiser_PA_accepted == true &&
                                        (j.appraisal.appraiser_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333"
                                }}
                              >
                                {j.appraisal?.appraiser_rating == 0 ? (
                                  <span> - </span>
                                ) : (
                                  j.appraisal?.appraiser_rating?.toFixed(2)
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  /******************Reviewer rating should be red if Reviewer rejected : should be blue only if there
                                  * is change in the rating that is ,if it is different from the normalized rating else it should be black*******************/
                                  color:
                                    (j?.reviewer?.reviewer_PA_rejected === true) ? "red" :
                                      (j.appraisal.status == "rejected" && j?.reviewer?.reviewer_PA_accepted == true &&
                                        (j.reviewer.reviewer_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {
                                  (j.reviewer.reviewer_rating == 0) ? (
                                    <span> - </span>
                                  ) : (
                                    j.reviewer.reviewer_rating?.toFixed(2)
                                  )}
                              </TableCell>

                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  /******************Normalizer rating should be red if Normalizer rejected : should be blue only if there
                                  * is change in the rating that is ,if it is different from the normalized rating else it should be black*******************/
                                  color:
                                    (j?.normalizer?.normalizer_PA_rejected === true) ? "red" :
                                      (j.appraisal.status == "completed" && j?.normalizer.normalizer_status == "re-normalized" &&
                                        (j?.normalizer?.normalizer_rating !== j.normalizer?.normalized_overallRating)) ? "#1976d2" : "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {(
                                  j?.normalizer?.normalizer_rating == 0) ? (
                                  <span> - </span>
                                ) : (
                                  j?.normalizer?.normalizer_rating?.toFixed(2)
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "Arial",
                                  borderColor: "lightgrey",
                                  fontSize: "14px",
                                  color: (j?.appraisal?.status == "rejected" || j.employee.employee_status === "rejected") ? "red" : "#333333",
                                  wordBreak: "break-word",
                                }}
                              >
                                {(j?.employee?.employee_rating == 0) ? (
                                  <span> - </span>
                                ) : (
                                  j?.employee?.employee_rating?.toFixed(2)
                                )}
                              </TableCell>
                              <TableCell align="center" style={{
                                fontSize: "14px",
                                fontFamily: "Arial",
                                color: "#333333",
                              }} >
                                {/******************** If overall rating is changed with respect to the normalized rating then 
                                  * rating will be in blue else black***************************/}
                                {(j?.appraisal?.status === "completed" && j?.employee?.employee_status === "rejected" &&
                                  (j?.normalizer?.normalizer_rating !== j.normalizer?.normalized_overallRating)) ? (
                                  <span style={{ color: "#0099FF" }}>{j?.current_rating?.overall_rating?.toFixed(2)}</span>
                                ) : (j?.appraisal?.status === "completed") ? (<span>{j?.current_rating?.overall_rating?.toFixed(2)}</span>) :
                                  "-"
                                }
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          align="left"
                          style={{ fontWeight: '500', border: "none", color: "#808080", fontSize: "18px", fontFamily: "arial", paddingTop: "10px" }}
                        >
                          No data to display
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </Scroll>
            </TableContainer>
          </TableHeadings>
          {tableDataFilterdLength?.length > 0 && <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
        </div>
      </Box>
    </>
  );
}