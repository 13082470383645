import { Grid, Stack, TextField, styled } from '@mui/material';
import React, { useState } from 'react'
import AlertDialogSuccess from '../../UI/DialogSuccess';
import DialogWithOkButton from '../../UI/DialogWithOkButton';

const FurtherRecommendationHeader = styled("div")({
    marginBottom: "10px",
    color: "#717171",
    fontSize: "16px",
    fontFamily: "arial",
});

const Contain = styled("div")({
    // marginLeft: "25px",
    // marginRight: "20px",
    marginTop: "10px",
    width: "100%",
    paddingTop: "0px",
});

const Labels = styled("div")({
    fontSize: "14px",
    color: "#333333",
    marginLeft: "5px",
    fontFamily: "arial"
});

const TextField1 = styled(TextField)({
    width: "100%", // This sets the default width
    // Add an explicit width to override the default
    // width: "500px", // Adjust this value as needed
    marginTop: "10px",
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "arial",
        fontWeight: "400",
        textTransform: "none",
    },
});


const AppraiserFurtherRecommendationEdit = (props: any) => {

    const { appraiserFurtherRecommendation,
        setAppraiserFurtherRecommendation,
        appraiserFurtherRecommendationOthers,
        setAppraiserFurtherRecommendationOthers,
        appraiserFurtherRecommendationOthersCheckbox,
        setAppraiserFurtherRecommendationOthersCheckbox,
        disableTextAfterSubmission,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        employeeDataIsFetching,
        employeeDataIsLoading } = props;
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    let disableFurtherRecommendationOther = appraiserFurtherRecommendation?.filter((j: any) => j.isChecked === true)?.length > 0

    // functions 

    const validateOtherRecommendation = (selectedOtherRecommendation: any, isChecked: any) => {
        const tempUser = appraiserFurtherRecommendation.map((OtherData: any) => {
            return OtherData._id === selectedOtherRecommendation ? { ...OtherData, isChecked: false } : OtherData
        });
        setAppraiserFurtherRecommendation(tempUser);
    }

    const handleOnCheck = (e: any) => {
        setNavPrompt(true)
        setAppraiserFurtherRecommendationOthersCheckbox(false);
        const { name, checked } = e.target;

        const tempUser = appraiserFurtherRecommendation.map((OtherData: any) => {
            const dash =
                OtherData._id === name
                    ? { ...OtherData, isChecked: checked }
                    : OtherData;
            return dash;
        });
        setAppraiserFurtherRecommendation(tempUser);
        let othervalues = tempUser?.filter((j: any) => j.isChecked === true).map((k: any) => k?.name?.toLowerCase())
        if (othervalues.includes("demotion") && othervalues.includes("promotion")) {
            setShowToast(true);
            setToastMessage("Promotion and demotion cannot be selected together.");
            validateOtherRecommendation(name, checked)
        }
        else if ((othervalues.includes("salary increase") || othervalues.includes("promotion")) && othervalues.filter((item: any) => item.includes("termination")).length > 0) {
            setShowToast(true);
            setToastMessage("Termination cannot be selected with salary increase / promotion.");
            validateOtherRecommendation(name, checked)
        }
        else if ((othervalues.includes("salary increase") || othervalues.includes("salary review")) && othervalues.filter((item: any) => item.includes("demotion")).length > 0) {
            setShowToast(true);
            setToastMessage("Demotion cannot be selected with salary increase or salary review.");
            validateOtherRecommendation(name, checked)
        }
        else if (tempUser.filter((j: any) => j.isChecked === true).length > 1 && othervalues.includes("pip")) {
            setShowToast(true);
            setToastMessage("PIP cannot be selected with other options.");
            validateOtherRecommendation(name, checked)
        }
    }

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setShowToast(false);
    };

    const toggleOthersCheckboxes = () => {
        setMoveTab(true)
        setAppraiserFurtherRecommendationOthersCheckbox(!appraiserFurtherRecommendationOthersCheckbox);
        const updatedOtherRecommendation = appraiserFurtherRecommendation?.map((item: any) => ({
            ...item,
            isChecked: false, // Uncheck all items in appraiserFurtherRecommendation
        }));
        setAppraiserFurtherRecommendation(updatedOtherRecommendation);
    };


    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <FurtherRecommendationHeader>
                    <b> Further Recommendations</b>
                </FurtherRecommendationHeader>


                <Contain>
                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {appraiserFurtherRecommendation &&
                            appraiserFurtherRecommendation
                                .sort((a: any, b: any) => a.sort_value - b.sort_value)
                                .map((OtherData: any, index: any) => {
                                    return (
                                        <Grid display="flex" alignItems="center" item xs={2} sm={4} md={3} key={index}>
                                            <input
                                                disabled={appraiserFurtherRecommendationOthersCheckbox || disableTextAfterSubmission || employeeDataIsFetching || employeeDataIsLoading}
                                                name={OtherData._id}
                                                checked={OtherData?.isChecked || false}
                                                onChange={e => {
                                                    handleOnCheck(e)
                                                    setNavPrompt(true);
                                                    setMoveTab(true)
                                                    // setMoveTab(true);
                                                }}
                                                type="checkbox"
                                            />
                                            <Labels>
                                                <label style={{ overflowWrap: 'break-word' }}> {OtherData?.name}</label>{" "}
                                            </Labels>
                                        </Grid>
                                    );
                                })}
                    </Grid>
                </Contain>

                <Contain>
                    <div>
                        <Stack direction="row" >
                            <input
                                disabled={disableFurtherRecommendationOther || disableTextAfterSubmission  || employeeDataIsFetching || employeeDataIsLoading }
                                checked={appraiserFurtherRecommendationOthersCheckbox}
                                // onChange={handleOthersCheckbox}
                                onChange={toggleOthersCheckboxes}
                                type="checkbox"
                            />
                            <Labels>
                                <label> Others</label>{" "}
                            </Labels>
                        </Stack>
                    </div>

                    <div
                    //  style={{ marginRight: "33px" }}
                    >
                        <TextField1
                            disabled={!appraiserFurtherRecommendationOthersCheckbox || employeeDataIsFetching || employeeDataIsLoading}
                            size="small"
                            multiline
                            placeholder="Add"
                            autoComplete="off"                          
                            value={appraiserFurtherRecommendationOthers}
                            onChange={(e: any) => {
                                setAppraiserFurtherRecommendationOthers(e.target.value);
                                setNavPrompt(true);
                                setMoveTab(true);
                            }}
                            InputProps={{ readOnly: disableTextAfterSubmission }}
                        />
                    </div>

                    <DialogWithOkButton
                        isAlertOpen={showToast}
                        handleAlertClose={handleClose}
                    >
                        {toastMessage}
                    </DialogWithOkButton>
                </Contain>
            </div>

        </>
    )
}

export default AppraiserFurtherRecommendationEdit