import { IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Select, styled, MenuItem, Popover, Typography, TextField, Tooltip, Button, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Infoicon from "../../../assets/Images/Infoicon.svg";
import Blueminus from "../../../assets/Images/Blueminus.svg";
import Blueadd from "../../../assets/Images/Blueadd.svg";


const Train = styled("div")({
    "& .MuiTextField-root": {
        // color: "rgb(51 51 51/50%)",
        fontSize: "14px",
        color: "#333333",
        textTransform: "none",
        fontFamily: "Arial",
        backgroundColor: "#f8f8f8",
        borderRadius: "5px",
        padding: "8px",
        // paddingRight:"0px",
        width: "96%"


    },
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "Arial",
        textAlign: "left",
        //  paddingRight:"8px"

    },
    "& .MuiInputBase-root": {
        backgroundColor: "#f8f8f8",
        padding: "3px"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#f8f8f8",
        },
        "&:hover fieldset": {
            borderColor: "#f8f8f8",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#f8f8f8",
        },
    },
});

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
            fontSize: "14px !important",
            fontFamily: "arial !important",
            color: "#333333 !important"
        },
    },
};

const Tf3 = styled("div")({
    borderRadius: "5px",
    backgroundColor: "#f8f8f8",

    "& .MuiInputBase-input": {
        fontSize: "14px",
        fontWeight: "400",
        textTransform: "none",
        fontFamily: "Arial",
        color: "#333333",
        padding: "8px",
        textAlign: "left"
    },
});

const TrainingRecommendationEdit = (props: any) => {
    const {
        showIfEmployee,
        showIfAppraiserOverview,
        trainingRecommendation,
        setTrainingRecommendation,
        appraisalTemplateTrainingRecommendation,
        disableTextAfterSubmission,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        showIfAppraiserresubmission,
        employeeDataIsFetching,
        employeeDataIsLoading
    } = props
    const [popoverIndex, setPopoverIndex] = useState<any>("");
    

    // functions
    const handleTrainingChange = (i: any, e: any) => {
        setNavPrompt(true)
        const { name, value } = e.target;
        setTrainingRecommendation((prevState: any) => {
            const updatedTrainingRecommendation = [...prevState];
            updatedTrainingRecommendation[i] = {
                ...updatedTrainingRecommendation[i],
                [name]: value
            };
            return updatedTrainingRecommendation;
        });
    };

    const addTrainingFields = () => {       
        setTrainingRecommendation([
            ...trainingRecommendation,
            { name: "", training_name: "", justification: "" },
        ]);
    };

    const removeTrainingFields = (i: any) => {
        setMoveTab(true)
        setNavPrompt(true)
        const newFormValues = [...trainingRecommendation];
        newFormValues?.splice(i, 1);
        setTrainingRecommendation(newFormValues);
    };

    // popovers 
    const [anchorTrainingCategoryInfo, setAnchorTrainingCategoryInfo] = React.useState<HTMLButtonElement | null>(null);
    const openTrainingCategoryInfo = Boolean(anchorTrainingCategoryInfo);
    const idTrainingCategoryInfo = openTrainingCategoryInfo ? "simple-popover" : undefined;

    const handleOpenTrainingCategoryInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorTrainingCategoryInfo(event.currentTarget);
    };

    const handleCloseTrainingCategoryInfo = () => {
        setAnchorTrainingCategoryInfo(null);
    };


    // useEffects
    useEffect(() => {
        if (trainingRecommendation && trainingRecommendation?.length == 0) {
            addTrainingFields();
            //  setShowTrainingRecommendations(true);
        }
    }, [trainingRecommendation])

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                    height="20px"
                    marginBottom="10px"
                >
                    <div
                        style={{
                            color: "#717171",
                            fontSize: "16px",
                            fontFamily: "Arial",
                        }}
                    >
                        {(showIfAppraiserOverview  || showIfAppraiserresubmission)&& (<b> Training Recommendations </b>)}
                        {showIfEmployee && (<b> Training Recommendations (Employee) </b>)}
                    </div>
                </Stack>

                <Table size="small" >
                    <TableHead>
                        <TableRow
                            sx={{
                                "& td, & th": {
                                    border: "1px solid #e0e0e0",
                                    bgcolor: "#eaeced",
                                },
                            }}
                        >
                            <TableCell
                                align="center"
                                width="33.3%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Training Category
                            </TableCell>
                            <TableCell
                                align="center"
                                width="33.3%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Training Name
                            </TableCell>
                            <TableCell
                                align="center"
                                width="33.3%"
                                style={{
                                    border: "1px solid #e0e0e0",
                                    fontFamily: "Arial",
                                    color: "#3E8CB5",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                Justification
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trainingRecommendation?.map((item: any, index: any) => {
                            return (
                                <>
                                    <TableRow

                                        sx={{
                                            "& td, & th": {
                                                border: "1px solid #e0e0e0",
                                            },
                                        }}
                                    >
                                        <TableCell>
                                            <Train>
                                                <Stack
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                    direction={"row"}
                                                    spacing={2}>

                                                    {item?.name != "" && (
                                                        <IconButton
                                                            // aria-describedby={id7}
                                                            onClick={(e: any) => {
                                                                handleOpenTrainingCategoryInfo(e)
                                                                setPopoverIndex(index);
                                                            }}
                                                        // style={{marginRight:"5px"}}
                                                        >
                                                            <img width="12px" src={Infoicon} alt="icon" />
                                                        </IconButton>
                                                    )}
                                                    <Select
                                                        sx={{
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                            background: "f8f8f8"
                                                        }}
                                                        fullWidth
                                                        autoComplete="off"
                                                        placeholder="Select"
                                                        size="small"
                                                        displayEmpty
                                                        name="name"
                                                        value={item?.name || ""}
                                                        renderValue={
                                                            item?.name !== ""
                                                                ? undefined
                                                                : () => <div style={{ color: "#aaa" }}>Select</div>
                                                        }
                                                        MenuProps={MenuProps}
                                                        onChange={(e) => {
                                                            handleTrainingChange(index, e);
                                                            setMoveTab(true);
                                                        }}
                                                    >
                                                        {appraisalTemplateTrainingRecommendation?.map(
                                                            (training: any) => {
                                                                return (
                                                                    <MenuItem
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            fontFamily: "arial",
                                                                            color: "#333333"
                                                                        }}
                                                                        value={training?.name?._id}
                                                                        disabled={disableTextAfterSubmission || employeeDataIsFetching || employeeDataIsLoading}
                                                                    >
                                                                        {training?.name?.title}
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                    <Popover
                                                        id={idTrainingCategoryInfo}
                                                        open={(popoverIndex === index) && openTrainingCategoryInfo}
                                                        anchorEl={anchorTrainingCategoryInfo}
                                                        onClose={handleCloseTrainingCategoryInfo}
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                backgroundColor: "FEFCF8",
                                                                boxShadow: "none",
                                                                maxWidth: "400px",
                                                                borderRadius: "5px",
                                                            },
                                                        }}
                                                        sx={{
                                                            // width: "60%",
                                                            "& .MuiPopover-paper": {
                                                                border: "1px solid #3e8cb5",
                                                                backgroundColor: "#ffffff",
                                                                // width:"30%"
                                                            },
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: "12px",
                                                                fontFamily: "arial",
                                                                padding: "5px",
                                                            }}
                                                        >
                                                            {appraisalTemplateTrainingRecommendation?.map((TrainingData: any) => {


                                                                return (
                                                                    <>
                                                                        {trainingRecommendation &&
                                                                            trainingRecommendation[index]?.name === TrainingData?.name?._id && TrainingData?.name?.definition}
                                                                    </>
                                                                );
                                                            })}


                                                        </Typography>
                                                    </Popover>
                                                </Stack>
                                            </Train>
                                        </TableCell>
                                        <TableCell>
                                            <Tf3>
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    autoComplete="off"
                                                    placeholder="Add"
                                                    name="training_name"
                                                    disabled={employeeDataIsFetching || employeeDataIsLoading}
                                                    value={item?.training_name || ""}
                                                    inputProps={{ maxLength: 500 }}
                                                    onChange={(e) => {
                                                        handleTrainingChange(index, e);
                                                        setMoveTab(true)
                                                    }
                                                    }
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true, // <== added this
                                                        readOnly: disableTextAfterSubmission
                                                    }}
                                                />
                                            </Tf3>
                                        </TableCell>
                                        <TableCell>
                                            <Tf3>
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    autoComplete="off"
                                                    placeholder="Add"
                                                    name="justification"
                                                    id="outlined-select-select"
                                                    disabled={employeeDataIsFetching || employeeDataIsLoading}
                                                    value={item?.justification || ""}
                                                    inputProps={{ maxLength: 500 }}
                                                    onChange={(e) => {
                                                        handleTrainingChange(index, e);
                                                        setMoveTab(true)
                                                    }}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true, // <== added this
                                                        readOnly: disableTextAfterSubmission
                                                    }}
                                                >
                                                </TextField>
                                            </Tf3>
                                        </TableCell>

                                        <TableCell style={{ borderColor: "#ffffff" }}>
                                            {trainingRecommendation?.length !== 0 && (
                                                <Tooltip title="Delete">
                                                    <Button
                                                        size="small"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "right",
                                                            alignItems: "right",
                                                            textTransform: "none",
                                                            height: "20px",
                                                            minWidth: "0px",
                                                            textDecoration: "underline",
                                                            color: "#3e8cb5",
                                                            fontSize: "14px",
                                                            marginTop: "6px",
                                                        }}
                                                        disabled={disableTextAfterSubmission}
                                                        onClick={() => removeTrainingFields(index)}
                                                    >
                                                        <img src={Blueminus} alt="icon" style={{ cursor: disableTextAfterSubmission ? "default" : "pointer" }} />
                                                    </Button>
                                                </Tooltip>
                                            )}

                                            {trainingRecommendation?.length - 1 === index &&
                                                trainingRecommendation?.length < 6 && (
                                                    <Tooltip title="Add">
                                                        <Button
                                                            size="small"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "right",
                                                                alignItems: "right",
                                                                textTransform: "none",
                                                                height: "20px",
                                                                minWidth: "0px",
                                                                textDecoration: "underline",
                                                                color: "#3e8cb5",
                                                                fontSize: "14px",
                                                                marginTop: "6px",
                                                            }}
                                                            disabled={disableTextAfterSubmission}
                                                            onClick={() => {
                                                                addTrainingFields()
                                                                setNavPrompt(true);
                                                                 setMoveTab(true);
                                                            }
                                                            }
                                                        >
                                                            <img src={Blueadd} alt="icon" style={{ cursor: disableTextAfterSubmission ? "default" : "pointer" }} />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                        </TableCell>

                                    </TableRow >
                                </>
                            )
                        })}
                    </TableBody >
                </Table>
            </div >
        </>
    )
}

export default TrainingRecommendationEdit