import React from "react";
import { useEffect, useState } from "react";
import { styled, width } from "@mui/system";
import { IconButton, Popover, Typography, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField, } from "@mui/material";
import Infoicon from "../../assets/Images/Infoicon.svg";


const PerformanceGoalsHeader = styled("div")({
    // marginLeft: "25px",
    marginBottom: "10px",
    color: "#717171",
    fontSize: "16px",
    fontFamily: "arial",
    //opacity: 0.85
});

const Root = styled("div")`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ddd;
    text-align: center;
    // padding: 7px;
  }

  th {
    background-color: #eaeced;
    fontsize: 12px;
    fontfamily: "Arial";
  }
  td {
  }
`;

const Tablecontainer = styled("div")({
    marginTop: "10px",
});

const PerformanceGoalsAppraiser = (props: any) => {

    const { employeeData, NAVIGATION_FROM } = props;

    /*********************** states ***************************************************/
    const [performanceGoalsFieldValues, setPerformanceGoalsFieldValues] = useState([]); /****************** these are the name property of the fields ****************/
    const [anchorEls, setAnchorEls] = React.useState<HTMLButtonElement | null>(null);
    const openGoalsCategoryInfo = Boolean(anchorEls);
    const openGoalsCategoryInfoId = openGoalsCategoryInfo ? "simple-popover" : undefined;
    const [popoverIndex, setPopoverIndex] = useState<any>("");
    const [performanceGoalsAppraiserShow, setperformanceGoalsAppraiserShow] = useState(false)


    useEffect(() => {
        let tempData = NAVIGATION_FROM == "VIEWPA" ? employeeData?.data?.appraisal_previous_submission?.performance_goal :
            employeeData?.data?.appraisal?.performance_goal
        if (employeeData && NAVIGATION_FROM == "previousViewpa") {
            setPerformanceGoalsFieldValues(employeeData?.employee?.appraisal?.performance_goal)
        }
        // This condition we are setting for Design Issue on Employee screen
         else if (NAVIGATION_FROM == "EmployeeLanding" ||NAVIGATION_FROM == "Employee" ){
            setPerformanceGoalsFieldValues(tempData)
            setperformanceGoalsAppraiserShow(true)
        }else if (employeeData && tempData) {
            setPerformanceGoalsFieldValues(tempData)
        }
    }, [ NAVIGATION_FROM,employeeData])

    console.log(performanceGoalsFieldValues,performanceGoalsAppraiserShow,NAVIGATION_FROM, 'checklength')


    const handleClickOpenGoalCategoryInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEls(event.currentTarget);
    };

    const handleClickCloseGoalCategoryInfo = () => {
        setAnchorEls(null);
    };

    return (
        <>
          {NAVIGATION_FROM == "EmployeeLanding" || NAVIGATION_FROM == "Employee" ?
    <div style={{ marginBottom: "50px" , marginTop: "30px" }}> 
           {performanceGoalsFieldValues?.length > 0 && (<div style={{ marginBottom: "50px", marginTop: "30px" }}>
                <PerformanceGoalsHeader>
                    <b>
                        Performance Goals (Appraiser)
                    </b>
                </PerformanceGoalsHeader>


                <Tablecontainer >
                {performanceGoalsAppraiserShow == true ?
                //   <Root sx={{ width: "100%",marginRight:"63px" }}>
                    <div style={{ marginRight:"63px" }}>
                        <table style={{width:"100%",marginRight:"63px",
                     }}>
                            <thead>
                                <tr>
                                    <th style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#3E8CB5",
                                        fontFamily: "arial",
                                        width: "200px",
                                        padding: "6px 16px"
                                    }}
                                    >
                                        Goal Category
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            width: "200px",
                                            padding: "6px 16px"
                                        }}
                                    >
                                        Description
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Key Result/Target
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Due Date
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Remarks
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {performanceGoalsFieldValues?.map((item: any, index: any) => {
                                    /************* to convert date dd/mm/yyyy format to mm/dd/yyyy  **************/
                                    const date = new Date(item?.due_date?.slice(0, 10));
                                    const dueDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                                    return (
                                        <tr>
                                            <td
                                                width="225px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            > <IconButton
                                                onClick={(e: any) => {
                                                    handleClickOpenGoalCategoryInfo(e)
                                                    setPopoverIndex(index);
                                                }}
                                            >
                                                    <img width="12px" src={Infoicon} alt="icon" />
                                                </IconButton>
                                                {item.goal_category?.title}

                                                <Popover
                                                    id={openGoalsCategoryInfoId}
                                                    open={(popoverIndex === index) && openGoalsCategoryInfo}
                                                    anchorEl={anchorEls}
                                                    onClose={handleClickCloseGoalCategoryInfo}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: "FEFCF8",
                                                            boxShadow: "none",
                                                            maxWidth: "350px",
                                                            borderRadius: "5px",
                                                        },
                                                    }}
                                                    sx={{
                                                        // width: "60%",
                                                        "& .MuiPopover-paper": {
                                                            border: "1px solid #3e8cb5",
                                                            backgroundColor: "#ffffff",
                                                            // width:"30%"
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            fontFamily: "arial",
                                                            padding: "5px",
                                                        }}
                                                    >

                                                        {item?.goal_category?.definition}


                                                    </Typography>
                                                </Popover>
                                            </td>
                                            <td
                                                width="300px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.description}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.keyresult}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {dueDate}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.remarks}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        </div>
                    // </Root>
                    :
                    // <Root sx={{ width: "100%", }}>
                    <div>
                        <table style={{width:"100%",marginRight:"63px",}}>
                            <thead>
                                <tr>
                                    <th style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#3E8CB5",
                                        fontFamily: "arial",
                                        width: "200px",
                                        padding: "6px 16px"
                                    }}
                                    >
                                        Goal Category
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            width: "200px",
                                            padding: "6px 16px"
                                        }}
                                    >
                                        Description
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Key Result/Target
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Due Date
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Remarks
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {performanceGoalsFieldValues?.map((item: any, index: any) => {
                                    /************* to convert date dd/mm/yyyy format to mm/dd/yyyy  **************/
                                    const date = new Date(item?.due_date?.slice(0, 10));
                                    const dueDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                                    return (
                                        <tr>
                                            <td
                                                width="225px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            > <IconButton
                                                // aria-describedby={id2}
                                                onClick={(e: any) => {
                                                    handleClickOpenGoalCategoryInfo(e)
                                                    setPopoverIndex(index);
                                                }}
                                            >
                                                    <img width="12px" src={Infoicon} alt="icon" />
                                                </IconButton>
                                                {item.goal_category?.title}

                                                <Popover
                                                    id={openGoalsCategoryInfoId}
                                                    open={(popoverIndex === index) && openGoalsCategoryInfo}
                                                    anchorEl={anchorEls}
                                                    onClose={handleClickCloseGoalCategoryInfo}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: "FEFCF8",
                                                            boxShadow: "none",
                                                            maxWidth: "350px",
                                                            borderRadius: "5px",
                                                        },
                                                    }}
                                                    sx={{
                                                        // width: "60%",
                                                        "& .MuiPopover-paper": {
                                                            border: "1px solid #3e8cb5",
                                                            backgroundColor: "#ffffff",
                                                            // width:"30%"
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            fontFamily: "arial",
                                                            padding: "5px",
                                                        }}
                                                    >

                                                        {item?.goal_category?.definition}


                                                    </Typography>
                                                </Popover>
                                            </td>
                                            <td
                                                width="300px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.description}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.keyresult}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {dueDate}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.remarks}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        </div>
                    // </Root>
                    }
                </Tablecontainer>
            </div>)}
            </div>
  :
    <div>
           {performanceGoalsFieldValues?.length > 0 && (<div style={{ marginBottom: "50px", marginTop: "30px" }}>
                <PerformanceGoalsHeader>
                    <b>
                        Performance Goals (Appraiser)
                    </b>
                </PerformanceGoalsHeader>


                <Tablecontainer >
                {performanceGoalsAppraiserShow == true ?
                  <Root sx={{ width: "100%",marginRight:"63px" }}>
                    <div style={{ marginRight:"63px" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#3E8CB5",
                                        fontFamily: "arial",
                                        width: "200px",
                                        padding: "6px 16px"
                                    }}
                                    >
                                        Goal Category
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            width: "200px",
                                            padding: "6px 16px"
                                        }}
                                    >
                                        Description
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Key Result/Target
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Due Date
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Remarks
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {performanceGoalsFieldValues?.map((item: any, index: any) => {
                                    /************* to convert date dd/mm/yyyy format to mm/dd/yyyy  **************/
                                    const date = new Date(item?.due_date?.slice(0, 10));
                                    const dueDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                                    return (
                                        <tr>
                                            <td
                                                width="225px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            > <IconButton
                                                onClick={(e: any) => {
                                                    handleClickOpenGoalCategoryInfo(e)
                                                    setPopoverIndex(index);
                                                }}
                                            >
                                                    <img width="12px" src={Infoicon} alt="icon" />
                                                </IconButton>
                                                {item.goal_category?.title}

                                                <Popover
                                                    id={openGoalsCategoryInfoId}
                                                    open={(popoverIndex === index) && openGoalsCategoryInfo}
                                                    anchorEl={anchorEls}
                                                    onClose={handleClickCloseGoalCategoryInfo}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: "FEFCF8",
                                                            boxShadow: "none",
                                                            maxWidth: "350px",
                                                            borderRadius: "5px",
                                                        },
                                                    }}
                                                    sx={{
                                                        // width: "60%",
                                                        "& .MuiPopover-paper": {
                                                            border: "1px solid #3e8cb5",
                                                            backgroundColor: "#ffffff",
                                                            // width:"30%"
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            fontFamily: "arial",
                                                            padding: "5px",
                                                        }}
                                                    >

                                                        {item?.goal_category?.definition}


                                                    </Typography>
                                                </Popover>
                                            </td>
                                            <td
                                                width="300px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.description}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.keyresult}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {dueDate}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.remarks}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        </div>
                    </Root>
                    :
                    <Root sx={{ width: "100%", }}>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#3E8CB5",
                                        fontFamily: "arial",
                                        width: "200px",
                                        padding: "6px 16px"
                                    }}
                                    >
                                        Goal Category
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            width: "200px",
                                            padding: "6px 16px"
                                        }}
                                    >
                                        Description
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Key Result/Target
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Due Date
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#3E8CB5",
                                            fontFamily: "arial",
                                            padding: "6px 16px",
                                            width: "400px",
                                        }}
                                    >
                                        Remarks
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {performanceGoalsFieldValues?.map((item: any, index: any) => {
                                    /************* to convert date dd/mm/yyyy format to mm/dd/yyyy  **************/
                                    const date = new Date(item?.due_date?.slice(0, 10));
                                    const dueDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                                    return (
                                        <tr>
                                            <td
                                                width="225px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            > <IconButton
                                                // aria-describedby={id2}
                                                onClick={(e: any) => {
                                                    handleClickOpenGoalCategoryInfo(e)
                                                    setPopoverIndex(index);
                                                }}
                                            >
                                                    <img width="12px" src={Infoicon} alt="icon" />
                                                </IconButton>
                                                {item.goal_category?.title}

                                                <Popover
                                                    id={openGoalsCategoryInfoId}
                                                    open={(popoverIndex === index) && openGoalsCategoryInfo}
                                                    anchorEl={anchorEls}
                                                    onClose={handleClickCloseGoalCategoryInfo}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: "FEFCF8",
                                                            boxShadow: "none",
                                                            maxWidth: "350px",
                                                            borderRadius: "5px",
                                                        },
                                                    }}
                                                    sx={{
                                                        // width: "60%",
                                                        "& .MuiPopover-paper": {
                                                            border: "1px solid #3e8cb5",
                                                            backgroundColor: "#ffffff",
                                                            // width:"30%"
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            fontFamily: "arial",
                                                            padding: "5px",
                                                        }}
                                                    >

                                                        {item?.goal_category?.definition}


                                                    </Typography>
                                                </Popover>
                                            </td>
                                            <td
                                                width="300px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.description}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.keyresult}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {dueDate}
                                            </td>
                                            <td
                                                // width="600px"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontFamily: "arial",
                                                    color: "#333333",
                                                    wordBreak: "break-word",
                                                    padding: "6px 16px",
                                                    lineHeight: "23px"
                                                }}
                                            >
                                                {item.remarks}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        </div>
                    </Root>
                    }
                </Tablecontainer>
            </div>)}
            </div> }


        </>
    )
}


export default PerformanceGoalsAppraiser