import React from 'react'
import FeedbackQuestionnaireView from '../overallFeedback/FeedbackQuestionnaireView'
import AreaOfImprovementView from '../overallFeedback/AreaOfImprovementView'
import TrainingRecommendationView from '../overallFeedback/TrainingRecommendationView'
import { Typography } from '@mui/material'
import AppraiserMessageForEmployeeView from '../overallFeedback/AppraiserMessageForEmployeeView'
import NormalizerCommentsView from '../overallFeedback/NormalizerCommentsView'
import ReviewerRejectionReasonView from '../overallFeedback/ReviewerRejectionReasonView'
import ReviewerCommentsView from '../overallFeedback/ReviewerCommentsView'
import NormalizerRejectionReasonView from '../overallFeedback/NormalizerRejectionReasonView'
import NormalizerDiscussedRatingView from '../overallFeedback/NormalizerDiscussedRatingView'
import NormalizerMeetingNotesView from '../overallFeedback/NormalizerMeetingNotesView'
import NormalizerAttachmentView from '../overallFeedback/NormalizerAttachmentView'
import EmployeeCommentsView from '../overallFeedback/EmployeeCommentsView'
import OneToOneMeetingDateView from '../overallFeedback/OneToOneMeetingDateView'
import PerformanceGoals from './PerformanceGoals'
import AppraiserFurtherRecommendationView from '../overallFeedback/AppraiserFurtherRecommendationView'

const OverallFeedbackTab = (props: any) => {
  const { employeeData, appraiserFeedbackQuestionnaire, showAppraiserAreaOfImprovement,
    appraiserAreaOfImprovement, showAppraiserTrainingRecommendation,
    appraiserTrainingRecommendation, showAppraiserMessageForEmployee,appraiserMessageForEmployee, showNormalizerComments,
    showReviewerRejectionReason, reviewerRejectionReason, showreviewerComments, reviewerComments,
    normalizerComments, showEmployeeAreaOfImprovement, employeeAreaOfImprovement, showEmployeeComments,
    showEmployeeTrainingRecommendation, employeeTrainingRecommendation, employeeComments,
    normalizerRejectionReason, shownormalizerRejectionReason, showoneToOneMeetingDate,
    shownormalizerdiscussedrating, employeeChecked, reviewerChecked, appraiserChecked, furtherRecommendation, furtherRecommendationOthers,
    normalizerMeetingNotes, shownormalizermeetingnotes, normalizerMeetingAttachment, oneToOneMeetingDate,
    showPerformanceGoalsFieldValues,showNormalizerMeetingAttachment, performanceGoalsFieldValues, performanceGoalsFieldValuesEmployee, showPerformanceGoalsFieldValuesEmployee
  } = props;
  let showIfAppraiser = true;
  let showIfEmployee = true
  console.log(employeeData,normalizerMeetingAttachment,normalizerMeetingNotes,"normalizerMeetingAttachment")
  return (
    <>
      <Typography
        style={{
          marginTop: "20px",
          fontSize: "20px",
          color: "#3E8CB5",
          fontFamily: "Arial",
          marginBottom: "20px",
        }}
      >
        Performance Appraisal Summary
      </Typography>
      <div 
      // style={{ marginRight: "33px", }}
      >
        <FeedbackQuestionnaireView
          employeeData={employeeData}
          appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire} />

        {(showAppraiserAreaOfImprovement && showIfAppraiser &&
          <AreaOfImprovementView
            showIfAppraiser={showIfAppraiser}
            showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
            areaOfImprovement={appraiserAreaOfImprovement} />
        )}
        {(showEmployeeAreaOfImprovement && showIfEmployee &&
          <AreaOfImprovementView
            showIfEmployee={showIfEmployee}
            showEmployeeAreaOfImprovement={showEmployeeAreaOfImprovement}
            areaOfImprovement={employeeAreaOfImprovement} />
        )}
        {showAppraiserTrainingRecommendation && showIfAppraiser && (
          <TrainingRecommendationView
            showIfAppraiser={showIfAppraiser}
            showTrainingRecommendation={showAppraiserTrainingRecommendation}
            trainingRecommendation={appraiserTrainingRecommendation} />
        )}
        {showEmployeeTrainingRecommendation && showIfEmployee && (
          <TrainingRecommendationView
            showIfEmployee={showIfEmployee}
            showTrainingRecommendation={showEmployeeTrainingRecommendation}
            trainingRecommendation={employeeTrainingRecommendation} />
        )}
      </div>
      <AppraiserFurtherRecommendationView
        furtherRecommendation={furtherRecommendation}
        furtherRecommendationOthers={furtherRecommendationOthers} />
      {showAppraiserMessageForEmployee && (
        <div>
          <AppraiserMessageForEmployeeView
          appraiserMessageForEmployee={appraiserMessageForEmployee}
            employeeData={employeeData} />
        </div>
      )}
      {showReviewerRejectionReason &&
        <ReviewerRejectionReasonView
          reviewerRejectionReason={reviewerRejectionReason}
          showReviewerRejectionReason={showReviewerRejectionReason} />
      }
      {showreviewerComments &&
        <ReviewerCommentsView
          showReviewerComments={showreviewerComments}
          reviewerComments={reviewerComments} />
      }
      {showNormalizerComments && (
        <NormalizerCommentsView
          showNormalizerComments={showNormalizerComments}
          normalizerComments={normalizerComments} />
      )}
      {(shownormalizerRejectionReason &&
        <NormalizerRejectionReasonView
          normalizerRejectionReason={normalizerRejectionReason}
        />
      )}
      {showEmployeeComments &&
        <EmployeeCommentsView
          employeeComments={employeeComments} />
      }
      {showoneToOneMeetingDate &&
        <OneToOneMeetingDateView oneToOneMeetingDate={oneToOneMeetingDate} />
      }
      {shownormalizerdiscussedrating &&
        <NormalizerDiscussedRatingView
          appraiserChecked={appraiserChecked}
          reviewerChecked={reviewerChecked}
          employeeChecked={employeeChecked}
          shownormalizerdiscussedrating={shownormalizerdiscussedrating} />
      }
      {shownormalizermeetingnotes &&
        <NormalizerMeetingNotesView
        shownormalizermeetingnotes={shownormalizermeetingnotes}
          normalizerMeetingNotes={normalizerMeetingNotes} />
      }
      {showNormalizerMeetingAttachment &&
      <NormalizerAttachmentView
        normalizerMeetingAttachment={normalizerMeetingAttachment}
      />
     }
      {/* performace goal added here reason is pdf export downloaded wrongly */}
      <PerformanceGoals
        showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
        showIfAppraiser={showIfAppraiser}
        performanceGoalsFieldValues={performanceGoalsFieldValues}
        performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
        showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
        showIfEmployee={showIfEmployee} />
    </>
  )
}
export default OverallFeedbackTab

