import React from 'react'
import { Scrollbar } from "react-scrollbars-custom";
import { styled } from "@mui/material/styles";
import { Typography } from '@mui/material';
import FeedbackQuestionnaireView from '../overallFeedback/FeedbackQuestionnaireView';
import AreaOfImprovementView from '../overallFeedback/AreaOfImprovementView';
import TrainingRecommendationView from '../overallFeedback/TrainingRecommendationView';
import AppraiserFurtherRecommendationView from '../overallFeedback/AppraiserFurtherRecommendationView';
import AppraiserMessageForEmployeeView from '../overallFeedback/AppraiserMessageForEmployeeView';
import ReviewerCommentsEdit from '../overallFeedback/ReviewerCommentsEdit';
import NormalizerCommentsView from '../overallFeedback/NormalizerCommentsView';
import EmployeeCommentsView from '../overallFeedback/EmployeeCommentsView';
import OneToOneMeetingDateView from '../overallFeedback/OneToOneMeetingDateView';

const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
});
function OverallFeedbackTab(props: any) {
  const { employeeData, appraiserFeedbackQuestionnaire, setReviewerComments, showAppraiserAreaOfImprovement,
    appraiserAreaOfImprovement, appraiserTrainingRecommendation, setMoveTab, setNavPrompt, showAppraiserTrainingRecommendation,
    disableTextAfterSubmission, appraiserMessageForEmployee, showAppraiserMessageForEmployee, showNormalizerComments,
    reviewerComments, normalizerComments, showIfReviewerApprove, furtherRecommendation, furtherRecommendationOthers,
    employeeComments, showEmployeeComments, oneToOneMeetingDate, showoneToOneMeetingDate,
    employeeDataIsFetching,
    employeeDataIsLoading
  } = props
  let showIfAppraiser = true


  return (
    <>
      <Typography
        style={{
          marginTop: "20px",
          fontSize: "20px",
          color: "#3E8CB5",
          fontFamily: "Arial",
          marginBottom: "20px",
        }}
      >
        Performance Appraisal Summary
      </Typography>
      <div>
        <FeedbackQuestionnaireView
          employeeData={employeeData}
          appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire} />
        {(showAppraiserAreaOfImprovement &&
          <AreaOfImprovementView
            showIfAppraiser={showIfAppraiser}
            showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
            areaOfImprovement={appraiserAreaOfImprovement} />
        )}
        {showAppraiserTrainingRecommendation && (
          <TrainingRecommendationView
            showIfAppraiser={showIfAppraiser}
            trainingRecommendation={appraiserTrainingRecommendation} />
        )}

        {employeeData?.data?.appraisal?.status !== "rejected" &&  
        employeeData?.data?.employee?.employee_rejection !== true && (
          <AppraiserFurtherRecommendationView
            furtherRecommendation={furtherRecommendation}
            furtherRecommendationOthers={furtherRecommendationOthers} />
        )}

        {showAppraiserMessageForEmployee && (
          <div>
            <AppraiserMessageForEmployeeView
              appraiserMessageForEmployee={appraiserMessageForEmployee}
              employeeData={employeeData} />
          </div>
        )}
        {showIfReviewerApprove &&
          <ReviewerCommentsEdit setMoveTab={setMoveTab}
            setNavPrompt={setNavPrompt} reviewerComments={reviewerComments}
            setReviewerComments={setReviewerComments}
            disableTextAfterSubmission={disableTextAfterSubmission}
            employeeDataIsFetching={employeeDataIsFetching}
            employeeDataIsLoading={employeeDataIsLoading} />
        }
        {showNormalizerComments && <NormalizerCommentsView
          showNormalizerComments={showNormalizerComments}
          normalizerComments={normalizerComments}
        />
        }
        {showEmployeeComments &&
          <EmployeeCommentsView
            employeeComments={employeeComments} />
        }
        {showoneToOneMeetingDate &&
          <OneToOneMeetingDateView oneToOneMeetingDate={oneToOneMeetingDate} />
        }
      </div>
    </>
  )
}

export default OverallFeedbackTab