import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DialogWithOkButton from '../../../UI/DialogWithOkButton';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Uploadatt from "../../../../assets/Images/Uploadatt.svg";
import Removeattnew from "../../../../assets/Images/Removeattnew.svg";
import { useAttachmentsRejectionAppraiserMutation } from '../../../../service';
import { useCreateAzureBlobMutation } from '../../../../service/azureblob';

const Item1 = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const Item2 = styled("div")(({ theme }) => ({
    // padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "450px",
    margin: "1rem",
    marginBottom: "0px",
}));

const Contain = styled("div")({
    "& .MuiButtonBase-root": {
        color: "#333333",
        backgroundColor: "#FFFFFF",
        height: "34px",
        width: "34px",
        boxShadow: "0px 0px 1px 1px #D4D4D4",
    },

    "& .MuiButton-root": {
        border: ` 1px solid `,
        borderColor: "#D4D4D4",
        minWidth: "0px",
        borderRadius: "50px",
        width: "25px",
        height: "25px",
        "&:focus": {
            // borderColor: '#3C8BB5',
        },
    },
});

const AppraiserResubmissionRatingSlider = (props: any) => {
    const { accept,
        title,
        ratingData,
        employeeData,
        appraiserNewRatingId,
        setAppraiserNewRating,
        previousRating,
        appraiserNewRating,
        setAppraiserNewRatingId,
        comments,
        setComments,
        setNavPrompt,
        resubmissionComments,
        setResubmissionComments,
        name,
        setName,
        showWithdraw,
        setShowWithdraw,
        setIsDrawerOpen,
        setOpen,
        objectiveDescription,
        activeObjectiveDescriptionName,
        employee_id,
        updateMutation,
        potential,
        activeObjectiveDescription,
        normalizedRating,
        createMutation

    } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [fileSelected, setFileSelected] = useState<any>("");
    const [hideAttachment, setHideAttachment] = useState<any>(false);

    // mutation
    const [rejectionAttachmentsAppraiser] = useAttachmentsRejectionAppraiserMutation()
    const [sendItem, { data }] = useCreateAzureBlobMutation();


    // function to close Alert
    const handleSliderDialogClose = () => {
        setOpenAlert(false);
        setMessage("");
    };

    // function to change Attachment
    const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        // if (!fileList) return;
        //@ts-ignore
        setName(fileList[0].name);
        // setFileSelected(fileList[0]);
        let reader = new FileReader();
        //@ts-ignore
        reader.readAsDataURL(fileList[0]);
        reader.onload = (e) => {
            setFileSelected(e.target?.result);
        };
    };

    const handleClickOpenAttachment = (e: any) => {
        document.getElementById("photo")?.click();
    };

    const imageClick = () => {
        const newData = {
            // token:tokens,
            // newstitle: newsTitle,
            // newsdesc: convertedContent,
            newspic: fileSelected,
            newspicname: name,
        };

        sendItem(newData).then((res: any) => {
            rejectionAttachmentsAppraiser({
                attachments: {
                    url: name,
                    objective_description: activeObjectiveDescriptionName,
                },
                id: employee_id
            }).then((res: any) => {
                console.log(res, 'resssssssssssss')
            });
        });
    };

    //  to hide attachment if attachment is not there
    useEffect(() => {
        if (fileSelected !== "" && name !== "") {
            setHideAttachment(true);
        } else {
            setHideAttachment(false);
        }
    }, [name, fileSelected]);

    //   function to set attachment empty
    const resetFileInput = () => {
        setFileSelected("");
        setName("");
    };

    // function to save the ratings 
    const saveAsDraftHandler = () => {
        setFileSelected("")
        if (appraiserNewRating === null ||
            appraiserNewRating === "" ||
            appraiserNewRating === undefined) {
            setMessage("Please select a rating to reject.");
            setOpenAlert(true);
        }
        else if (employeeData?.data?.employee?.employee_status !== "rejected" && (previousRating == appraiserNewRating)) {
            setMessage(
                "You cannot select the same rating as the previous rating. Please change the rating."
            );
            setOpenAlert(true);
        }
        else if ((resubmissionComments?.trim() == "" || resubmissionComments?.trim() == undefined) && ((appraiserNewRating < 3) || (appraiserNewRating >= 4))) {
            setMessage("It is mandatory to provide a justification for the rating below 3, and 4 and above.");
            setOpenAlert(true);
        }
        else {
            //setnavPrompt(true) 
            closeDrawer();
            setOpen(false);
            // setMessage("");
            let normalizerRatingValue =
                employeeData?.data?.normalizer?.objective_description
                    .filter((i: any) => i?.name?._id == activeObjectiveDescriptionName)
                    .map((j: any) => {
                        if (j?.ratings?.rating !== appraiserNewRatingId) {
                            return true
                        } else return false
                    })[0]

            let current_objective_description = employeeData?.data?.current_rating?.objective_description?.map(
                (item: any) => {
                    if (item.name._id === activeObjectiveDescriptionName) {
                        return {
                            ...item,
                            ratings: appraiserNewRatingId,
                        }
                    } else return item
                }
            )

            //// to save all appraiser comments in every save function
            let mappedObjectiveDescription = objectiveDescription?.map((item: any) => {
                if (item.name._id === activeObjectiveDescriptionName) {
                    return {
                        ...item,
                        ratings: appraiserNewRatingId,
                        rejection_reason: comments?.trim(),
                        //  comments: item.rating_resubmitted ?resubmissionComments : item.comments ,
                        comments: resubmissionComments?.trim(),
                        rating_rejected: true,
                        action_performed: true,
                        rating_accepted: false,
                        // rating_resubmitted: normalizerRatingValue,
                    }
                } else return item
            })

            let pendingStatus = "";
            if (employeeData?.data?.reviewer?.reviewer_status == "rejected") {
                pendingStatus = "Pending with Appraiser (Reviewer Rejection)"
            } else if (employeeData?.data?.normalizer?.normalizer_status == "rejected") {
                pendingStatus = "Pending with Appraiser (HR Normalizer Rejection)"
            } else {
                pendingStatus = "Pending with Appraiser"
            }

            updateMutation({
                "appraisal.objective_description": mappedObjectiveDescription,
                "appraisal.pa_status": pendingStatus,
                "appraisal.appraiser_status": "draft",
                // "appraisal.appraiser_rejected": true,
                "current_rating.objective_description": current_objective_description,
                "appraisal.potential": potential,
                id: employee_id,
            })
            if (name && fileSelected) {
                return imageClick();
            }
            // setRatingAppraiser("");
            // setComments()
        }
    };


    // function to withdraw ratings
    const withdrawHandler = () => {
        closeDrawer();
        let previousRatingData = employeeData?.data?.appraisal_previous_submission?.objective_description
            ?.filter((i: any) => i.name === activeObjectiveDescriptionName)
            ?.map((k: any) => {               
                return {
                    ratings: k.ratings,
                    rating_rejected: k.rating_rejected,
                    rejection_reason: k.rejection_reason ? k.rejection_reason : "",
                    rating_resubmitted: k.rating_resubmitted ? k.rating_resubmitted : false,
                }
            })[0];


        let appraiserObjectiveDescription = employeeData?.data?.reviewer?.objective_description;
        const getRatings = (id: any) => {
            let rating = appraiserObjectiveDescription?.find((item: any) => item.name._id == id)
            return rating.ratings
        }
        let current_objective_description = employeeData?.data?.current_rating?.objective_description
            .map((item: any) => {
                return item.name._id == activeObjectiveDescriptionName ?
                    {
                        ...item,
                        ratings: getRatings(item.name._id)
                    } :
                    item
            })

            createMutation({
            objective_description: activeObjectiveDescription,
            objective_description_name: activeObjectiveDescriptionName,
            ratings: previousRatingData.ratings,
            rating_rejected: previousRatingData.rating_rejected,
            // rating_resubmitted: previousRatingData.rating_resubmitted,
            action_performed: false,
            // comments: tempComments,
            rejection_reason: previousRatingData.rejection_reason,
            rating_comments: "",
            current_objective_description: current_objective_description,
            id: employee_id,
        });
        setNavPrompt(false);
        setAppraiserNewRatingId("");
        setAppraiserNewRating("")
        setComments("");
    };

    //  function to close drawer
    const closeDrawer = () => {
        setIsDrawerOpen(false);
        setAppraiserNewRatingId("");
        setAppraiserNewRating("");
    };

    // funtion to display alert if same rating is selected
    const ratingAlertHandler = (j: any) => {
        console.log(j, "jjjjjjj");
        if (employeeData?.data?.employee?.employee_status !== "rejected" && (previousRating === j.rating)) {
            setOpenAlert(true);
            setMessage(
                // "You cannot put the same rating as the Reviewer. Please change the rating."
                "You cannot select the same rating as the previous rating. Please change the rating."
            );

        } else {
            if (j) setAppraiserNewRatingId(j._id);
            // setratingSelection(true);
        }
    };


    return (
        <>
            <div
                style={{
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    backgroundColor: "#ebf2f4",
                    color: "#3E8CB5",
                    fontSize: "20px",
                }}
            >
                Appraiser Action
            </div>

            <DialogWithOkButton
                isAlertOpen={openAlert}
                handleAlertClose={handleSliderDialogClose}>
                {message}
            </DialogWithOkButton>


            {accept === "Reject" && (
                <>
                    <Item2 sx={{ width: "fitContent" }}>
                        <Typography
                            style={{
                                paddingLeft: "8px",
                                // paddingTop: "16px",
                                paddingBottom: "16px",
                                fontFamily: "arial",
                                //paddingBottom: "10px",
                                //backgroundColor: "#ebf2f4",
                                color: "#3E8CB5",
                                fontSize: "17px",
                                // width:"20%"
                                textAlign: "left",
                                wordBreak: "break-word"
                            }}
                        >
                            {" "}
                            {title}
                        </Typography>
                        <div
                            style={{
                                textAlign: "left",
                                paddingLeft: "10px",
                                fontSize: "12px",
                                fontFamily: "arial",
                                paddingBottom: "7px",
                                color: "#7A7A7A",
                            }}
                        >
                            New Rating
                        </div>

                        <>
                            <Stack
                                style={{ paddingLeft: "10px" }}
                                direction="row"
                                height="30px"
                                spacing={1.7}
                            >
                                {ratingData &&
                                    ratingData?.data
                                        ?.slice()
                                        ?.sort(function (a: any, b: any) {
                                            return a.rating - b.rating;
                                        })
                                        ?.map((ratings: any, _id: any) => (
                                            <Item1
                                                sx={{
                                                    marginLeft: "2px",
                                                    padding: "0px",
                                                    justifyContent: "center",

                                                    position: "relative",
                                                }}
                                            >
                                                <Contain>
                                                    <Button
                                                        onClick={() => {
                                                            ratingAlertHandler(ratings);
                                                            setAppraiserNewRating(ratings.rating);
                                                            setAppraiserNewRatingId(ratings._id)
                                                        }}
                                                        disabled={employeeData?.data?.employee?.employee_status == "rejected" && (ratings?.rating < normalizedRating)}
                                                        style={{
                                                            //@ts-ignore
                                                            borderColor: appraiserNewRatingId === ratings._id && "#3C8BB5",
                                                            backgroundColor: appraiserNewRatingId === ratings._id ? "rgb(123 210 255 / 29%)" : "",
                                                            cursor: (employeeData?.data?.employee?.employee_status == "rejected" && (ratings?.rating < normalizedRating)) ? "default" : "pointer"
                                                        }}
                                                        size="small"
                                                    >
                                                        <p
                                                            style={{
                                                                fontSize: "11px",
                                                                color: "#333333",
                                                                opacity: "80%",
                                                            }}
                                                        >
                                                            {ratings?.rating}
                                                        </p>
                                                    </Button>
                                                </Contain>
                                            </Item1>
                                        ))}
                            </Stack>
                            {/* This one commented {Removed} for cilent feedback on 19/01/2023 sprint sheet No 162*/}
                            {/*again added on 2/3/2023 for the validation that appraiser must not select same rating as of previous rating  */}
                            <p
                                style={{
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                    fontSize: "12px",
                                    fontFamily: "arial",
                                    color: "#7A7A7A",
                                }}
                            >
                                Previous Rating
                            </p>
                            <Item1
                                sx={{
                                    marginLeft: "2px",
                                    padding: "0px",
                                    justifyContent: "center",
                                }}
                            >
                                <Contain>
                                    <Stack
                                        direction="column"
                                        position="relative"
                                        marginLeft="10px"
                                    >
                                        <Button
                                            style={{
                                                //@ts-ignore
                                                borderColor: "#3C8BB5",
                                            }}
                                            size="small"
                                        >
                                            <p
                                                style={{
                                                    fontSize: "11px",
                                                    color: "#333333",
                                                    opacity: "80%",
                                                }}
                                            >
                                                {previousRating}
                                            </p>
                                        </Button>
                                    </Stack>
                                </Contain>
                            </Item1>

                        </>
                    </Item2>

                    <div
                        style={{
                            textAlign: "left",
                            paddingLeft: "27px",
                            fontSize: "12px",
                            paddingBottom: "7px",
                            paddingTop: "16px",
                            color: "#7A7A7A",
                            fontFamily: "arial"
                        }}
                    >
                        Rejection Reason<span style={{
                            fontSize: "20px",
                        }}></span>
                    </div>
                    <TextField
                        autoComplete="off"
                        sx={{
                            "& .MuiInputBase-input": {
                                fontSize: "14px",
                                fontFamily: "Arial",
                                color: "#333333",
                            },
                        }}
                        size="small"
                        style={{ paddingLeft: "27px", width: "75%" }}
                        // fullWidth
                        multiline
                        value={comments}
                        inputProps={{ maxLength: 500 }}
                        onChange={(e) => {
                            // setComments("")
                            setComments(e.target.value);
                            setNavPrompt(true);
                        }}
                    />

                    <div
                        style={{
                            textAlign: "left",
                            paddingLeft: "27px",
                            fontSize: "12px",
                            paddingBottom: "7px",
                            paddingTop: "16px",
                            color: "#7A7A7A",
                            fontFamily: "arial"
                        }}
                    >
                        Appraiser Comments<span style={{
                            fontSize: "20px",
                        }}></span>
                    </div>
                    <TextField
                        autoComplete="off"
                        sx={{
                            "& .MuiInputBase-input": {
                                fontSize: "14px",
                                fontFamily: "Arial",
                                color: "#333333",
                            },
                        }}
                        size="small"
                        style={{ paddingLeft: "27px", width: "75%" }}
                        // fullWidth
                        multiline
                        value={resubmissionComments}
                        inputProps={{ maxLength: 500 }}
                        onChange={(e) => {
                            // setComments("")
                            setResubmissionComments(e.target.value);
                            setNavPrompt(true);
                        }}
                    />

                    <div
                        style={{
                            textAlign: "left",
                            paddingLeft: "28px",
                            fontSize: "12px",
                            paddingBottom: "10px",
                            paddingTop: "16px",
                            color: "#7A7A7A",
                            fontFamily: "arial"
                        }}
                    >
                        Attachment
                    </div>{" "}
                    <div >

                        <Stack direction="row" alignItems="center" spacing={3}>
                            <span>
                                <input
                                    id="photo"
                                    // style={styles.colors}
                                    name="photo"
                                    type="file"
                                    multiple={false}
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        handleImageChange(e);
                                        // setMoveTab(true)
                                    }} />
                            </span>

                            <div>

                                <IconButton >
                                    <img src={Uploadatt}
                                        onClick={(e: any) => {
                                            handleClickOpenAttachment(e);
                                        }}
                                    />
                                </IconButton>
                                <>
                                    <label
                                        style={{
                                            fontSize: "14px",
                                            fontFamily: "Arial",
                                            color: "#7A7A7A",
                                        }}
                                    >{name}
                                    </label>
                                </>

                            </div>
                            {
                                hideAttachment && (
                                    <IconButton onClick={() => {
                                        resetFileInput();
                                    }}
                                    >
                                        <img src={Removeattnew} alt='icon' />
                                    </IconButton>
                                )
                            }
                        </Stack>
                    </div>

                    <Stack
                        alignItems="left"
                        direction="row"
                        paddingLeft="28px"
                        paddingTop="16px"
                        spacing={2}
                    >
                        <Button
                            style={{
                                textTransform: "none",
                                fontSize: "15px",
                                fontFamily: "Arial",
                                borderColor: "#3E8CB5",
                                color: "#3E8CB5",
                                background: "transparent",
                                height: "35px",
                                // width: "70px",
                            }}
                            variant="outlined"
                            onClick={() => {
                                saveAsDraftHandler();
                                setNavPrompt(false);
                            }}
                        >
                            {" "}
                            Save as Draft
                        </Button>
                        {showWithdraw && (
                            <Button
                                style={{
                                    textTransform: "none",
                                    fontSize: "15px",
                                    fontFamily: "Arial",
                                    borderColor: "#3E8CB5",
                                    color: "#3E8CB5",
                                    background: "transparent",
                                    height: "35px",
                                    width: "74px",
                                }}
                                variant="outlined"
                                onClick={() => {
                                    withdrawHandler();
                                }}
                            >
                                {" "}
                                WithDraw{""}
                            </Button>
                        )}
                        <Button
                            style={{
                                textTransform: "none",
                                fontSize: "15px",
                                fontFamily: "Arial",
                                borderColor: "#3E8CB5",
                                color: "#3E8CB5",
                                background: "transparent",
                                height: "35px",
                                width: "70px",
                            }}
                            variant="outlined"
                            onClick={closeDrawer}
                        >
                            {" "}
                            Cancel{" "}
                        </Button>
                    </Stack>
                </>
            )}
        </>
    )
}

export default AppraiserResubmissionRatingSlider