import * as React from "react";
import { useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Badge, FormControl, Grid, MenuItem, BadgeProps, Drawer, FormGroup, FormControlLabel, Tooltip, Popover } from "@mui/material";
import { Stack, Tab, Tabs, Box, Typography, Menu } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { TextField } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, Navigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import Scrollbar from "react-scrollbars-custom";
import Collapse from "@mui/material/Collapse";
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import {
  useAcceptNormalizerMutation, useGetActiveCalenderQuery, useGetEmployeeByFilterQuery,
  useGetEmployeeByStatusQuery,
  useGetEmployeeQuery,
  useRejectNormalizerAppraisalEmployeeMutation,
} from "../../../../../service";
import { useEffect } from "react";
import {
  CREATE_APPRAISAL,
  EMPLOYEE_DOWNLOAD,
  NORMALIZER_ACTION,
  NORMALIZER_APPROVE,
  NORMALIZER_ACCEPTANCE,
  NORMALIZER_PAGE,
  NORMALIZER_REJECTION,
  NORMALIZER_REJECT,
  NORMALIZER_VIEW_PA,
  REVIEWER_PAGE,
  VIEW_PA,
  VIEW_PREVIOUS_PAA,
  NORMALIZER_ACTION_SCREEN,
} from "../../../../../constants/routes/Routing";
import UpDown from "../../../../../assets/Images/UpDown.svg";
import Opennew from "../../../../../assets/Imaages/Opennew.svg";
import Application from "../../../../../assets/Images/Application.svg";
import Closeicon from "../../../../../../assets/Images/Closeicon.svg";
// import Eye from "../Reviewericons/Eyeicon.svg";
import Eye from "../../../../reviewer/Dashboard/Reviewericons/Eyeicon.svg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import Expand from "../../../../../components/reviewerMain/Reviewericons/Expand.svg";
import Newexcel from "../../../../../components/reviewerMain/Reviewericons/Newexcel.svg";
import Searchlensreview from "../../../../../components/reviewerMain/Reviewericons/Searchlensreview.svg";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import TablePagination from "@mui/material/TablePagination";
import * as XLSX from "xlsx";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AlertDialogSuccess from "../../../../../components/UI/DialogSuccess";
import { getRequestStatusFlags } from "@reduxjs/toolkit/dist/query/core/apiState";
import { useAcceptNormalizerGradeExceptionBulkMutation, useAcceptNormalizerGradeExceptionMutation } from "../../../../../service/employee/appraisal/appraisal";
import { ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MyTeamTable from "./TeamTableUINormalizer";
import { useGetEmailIdsBulkMutation, useSendEmailNotificationMutation } from "../../../../../service/email/emailNotification";
import { useLoggedInUser } from "../../../../../hooks/useLoggedInUser";
import { NORMALIZER_ACCEPTS_PA_INFO, NORMALIZER_ACCEPTS_PA_INFO_GRADE_EXCEPTION } from "../../../../../constants/AppraisalEmailContents/NotificationForInfo/Normalizer";
import { NORMALIZER_ACCEPTS_PA } from "../../../../../constants/AppraisalEmailContents/NotificationForAction/Normalizer";
import { useCheckRoleLogsMutation } from "../../../../../service/employee/employee";
import GenerateReport from "../../../../../components/ExportToExcel/GenerateReport";
import ExportToexcel from "../../../../../components/ExportToExcel/ExporttoExcel";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    // margin: theme?.spacing(1),
    width: 140,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Arial",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
    fontSize: "13px !important",
    fontFamily: "Arial",
    color: "#333333",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    fontSize: "12px !important",
    color: "#333333",
    fontFamily: "Arial",
  },
}));
const Mytable = styled("div")({
  background: "#FFFFFF",
  marginLeft: "25px",
  marginRight: "25px",
  position: "relative",
  marginBottom: "25px"
});
const Tabstyles = styled("div")({
  "& .MuiButtonBase-root": {
    color: "#999999",
    textTransform: "none",
    fontWeight: 400,
  },
  "& .Mui-selected": {
    color: "#004C75",
  },
  "&.MuiTabs-indicator": {
    backgroundColor: "#004C75",
  },
  display: "flex",
});
const Heading = styled("div")({
  fontSize: "18px",
  color: "#3e8cb5",
  fontFamily: "Arial",
});
const Searchfeild = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "28px",
    width: "144px",
    borderRadius: "15px",
    background: "#F2F6F8",
  },
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#306D8F",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "13px",
    color: "#306D8F",
  },
});
const TableHeadings = styled("div")({
  "& .MuiTableRow-head ": {
    background: "#eaeced",
  },
  "& .MuiTableCell-root": {
    padding: "0px 10px",
  },
});
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -17,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const userData = [
  { id: 0, reason: "rating", isChecked: false, value: "rating" },
  {
    id: 1,
    reason: "Feedback questionnaire",
    isChecked: false,
    value: "Feedback_questionnaire",
  },
  {
    id: 2,
    reason: "area of improvement",
    isChecked: false,
    value: "area_of_improvement",
  },
  {
    id: 3,
    reason: "Training recommendation(s)",
    isChecked: false,
    value: "training_recommendation(s)",
  },
  {
    id: 4,
    reason: "Other recommendation(s)",
    isChecked: false,
    value: "other_recommendation(s)",
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//@ts-ignore
const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};
interface Data {
  empty: any;
  position: any;
  status: any;
  grade: any;
  pastatus: any;
  name: any;
  protein: any;
  Appraiser_Rating: any;
  Reviewer_Rating: any;
  Normalizer_Rating: any;
}

function createData(
  name: string,
  position: string,
  grade: any,
  status: any,
  pastatus: any,
  protein: any,
  empty: any,
  Appraiser_Rating: any,
  Reviewer_Rating: any,
  Normalizer_Rating: any
): Data {
  return {
    empty,
    name,
    position,
    grade,
    pastatus,
    status,
    protein,
    Appraiser_Rating,
    Reviewer_Rating,
    Normalizer_Rating,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "empty",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Employee",
  },
  {
    id: "position",
    numeric: false,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "grade",
    numeric: true,
    disablePadding: false,
    label: "Grade",
  },
  {
    id: "pastatus",
    numeric: false,
    disablePadding: false,
    label: "Pending Action ",
  },
  {
    id: "Appraiser_Rating",
    numeric: true,
    disablePadding: false,
    label: "Appraiser Rating",
  },
  {
    id: "Reviewer_Rating",
    numeric: true,
    disablePadding: false,
    label: "Reviewer Rating",
  },
  {
    id: "Normalizer_Rating",
    numeric: true,
    disablePadding: false,
    label: "Normalizer Rating",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Overall Status",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "View PA",
  },
];

function EnhancedTableHead(props: any) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };



  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: "30px" }}
          >
            <TableSortLabel
              // active={orderBy === headCell.id}
              active={false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const NormalizerTeamTable = (props: any) => {
  const [bulkupdate, setbulkupdate] = React.useState<HTMLElement | null>(null);
  const classes = useStyles();
  const { data: activecalendardata } = useGetActiveCalenderQuery('')
  let CalendarName = activecalendardata?.data[0]?.name
  const [opendialog, setOpendialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [bulkOpen, setBulkOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { startAppraisal, employeeData, statusSort,GradeFilterr, SectionFilterr, setSectionFilter, subSectionFilterr, setSubSectionFilter, divisionFilterr, valueOfCard,setvalueOfCard,CalenderName } = props;
  const navigate = useNavigate();
  const [updateLoggedRole] = useCheckRoleLogsMutation()

  //const{employee_id} = useParams()
  const CustomScrollbar = Scrollbar as any;

  console.log(employeeData, "emplpoyeeeeee")
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDialogOpen = () => {
    setOpendialog(true);
  };

  const handleDialogClose = () => {
    setOpendialog(false);
    setIsOpen(false);
  };

  const handleCloseBulk = () => {
    setBulkOpen(false);
    setMessage("");
  }

  const handleDialogNo = () => {
    setOpendialog(false);
    setIsOpen(true);
  };

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("position");
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const { startAppraisal,employeeData } = props;
  const { data: user } = useLoggedInUser();
  const [acceptNormalizer] = useAcceptNormalizerMutation()
  const [acceptNormalizerGradeExceptionBulk] = useAcceptNormalizerGradeExceptionBulkMutation()
  const [rejectNormalizer] = useRejectNormalizerAppraisalEmployeeMutation();
  const [tabValue, setTabValue] = React.useState<any>(0);
  const [message, setMessage] = useState("")
  const [openAlertOk, setOpenAlertOk] = useState<any>(false)

  const [getEmailIds] = useGetEmailIdsBulkMutation();
  const [sendEmailNotification] = useSendEmailNotificationMutation();
  const [emailIdsForEmployees, setEmailIdsForEmployees] = useState<any>({})
  const [enteredName, setenteredName] = useState("");
  const handleAlertClose = () => {
    setOpenAlertOk(false);
    setMessage("")
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log("handle change run");
    console.log(newValue, "index check");
    setTabValue(newValue);
    setenteredName("");
  };
  const [checkboxUser, setcheckboxUser] = React.useState<any>([]);
  const [error, setError] = React.useState(false);
  const [zeroselect, setzeroselect] = React.useState(false);
  const [reasonSelection, setreasonSelection] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [users, setUsers] = useState<any>([]);
  const [filter, setFilter] = React.useState("");
  const [employee, setEmployee] = React.useState([]);
  const [checkedEmployeeid, setcheckedEmployeeid] = React.useState("");
  const [approved, setApproved] = React.useState(false);
  const [count, setCount] = React.useState<any>(100);
  const [showReNormalize, setShowReNormalize] = useState(false)
  console.log(users, "users");
  console.log(checkedEmployeeid, "checkedempid");
  const [tablecount, settablecount] = React.useState<any>(0);
  const [tablecount1, settablecount1] = React.useState<any>(0);
  console.log(tablecount1?.length, "tablecount1");
  //multiselect
  const [GradesFilter, setGradesFilter] = React.useState<string[]>([]);
  const [positionsFilter, setpositionsFilter] = React.useState<string[]>([]);
  const [positionFilter, setpositionFilter] = React.useState<string[]>([]);
  const [acceptButton, setacceptButton] = React.useState(false);
  const [rejectButton, setrejectButton] = React.useState(false);
  const [GradeFilter, setGradeFilter] = React.useState<string[]>([]);
  console.log(positionFilter, "positionFilter");
  const [gradesArray, setgradesArray] = React.useState<any>([]);
  const [positionArray, setpositionArray] = React.useState<any>([]);

  const sum = React.useMemo(() => {

      setUsers(employeeData?.filter((j: any) => j?.appraisal?.status != "excepted"));
  }, [employeeData]);
// valueofcard used for expand views breadcrumbs
  useEffect(() => {
    if (valueOfCard === "") {
      return setTabValue(0);
    } else if (valueOfCard === "Not-Started") {
      return setTabValue(2);
    } else if (valueOfCard === "In Progress") {
      return setTabValue(3);
    } else if (valueOfCard === "Normalized") {
      return setTabValue(3);
    } else if (valueOfCard === "Rejected") {
      return setTabValue(4);
    } else if (valueOfCard === "Completed") {
      return setTabValue(5);
    }
  }, [valueOfCard]);

  const acceptHandler = () => {
    const approvefilter = users?.filter((i: any) => {
      return i?.normalizerIsChecked === true && i?.normalizerIsDisabled === false;
    });
    /**********************If no employee is selected****************/
    if (approvefilter?.length == 0) {
      setOpenAlertOk(true);
      setMessage("Please select atleast one employee.")
    }
    /*************If more than one employee is selected************/
    else if (approvefilter?.length > 1) {
      setOpenAlertOk(true);
      setMessage("Please use the Bulk Accept option to accept the rating of selected employees.")
    } else {
      let currentUser = approvefilter[0];
      if (currentUser?.appraisal?.status == "rejected") {
        navigate(`${NORMALIZER_ACTION_SCREEN}/employee/${checkboxIdHandler(checkboxHandler(users))}`);
      } else {
        navigate(`${NORMALIZER_ACCEPTANCE}/employee/${checkboxIdHandler(checkboxHandler(users))}`)
      }
    }
  }

  const handleReject = () => {
    const checkedfilter = checkboxUser
      .filter((i: any) => {
        console.log(i.isChecked, "hhhhhhhhhhhhhhhhhhhhhhhh");
        return i.isChecked === true || i.isChecked === false;
      })
      .map((j: any) => {
        return {
          value: j.value,
          isChecked: true,
        };
      });
    const rejectfilter = users?.filter((i: any) => {
      console.log(i?.normalizerIsChecked, "filter");
      return i?.normalizerIsChecked === true && i?.normalizerIsDisabled === false;
    });
    const normalizerValue = rejectfilter?.map(
      (j: any) => j?.appraisal?.status
    )[0];
    if (rejectfilter?.length > 1) {
      setError(true);
      setOpenAlert(true);
    } else if (rejectfilter?.length === 0) {
      setzeroselect(true);
      setOpenAlert(true);
    } else {
      let checkedEmp = rejectfilter?.map((j: any) => j._id);
      if (normalizerValue == ("rejected")) {
        let id = rejectfilter[0]._id;
        // rejectNormalizer({
        //   value: checkedfilter,
        //   id: checkedEmp[0],
        // }).then((data: any) => {
        //   navigate(`${NORMALIZER_ACTION_SCREEN}/employee/` + id);
        // });
        navigate(`${NORMALIZER_ACTION_SCREEN}/employee/` + id);
      } else {
        rejectNormalizer({
          value: checkedfilter,
          id: checkedEmp[0],
        }).then((data: any) => {
          navigate(`${NORMALIZER_REJECT}/employee/${checkedEmp[0]}`);
        });
      }
    }
  };
  // const rejectHandler = () => {
  //   const rejectfilter = users?.filter((i: any) => {
  //     console.log(i?.normalizerIsChecked, "filter");
  //     return i?.normalizerIsChecked === true && i?.normalizerIsDisabled === false;
  //   });

  //   const normalizerValue = rejectfilter?.map(
  //     (j: any) => j?.normalizer?.normalizer_status
  //   )[0];

  //   if (rejectfilter?.length > 1) {
  //     setError(true);
  //   } else if (normalizerValue == "employee-rejected") {
  //     let id = rejectfilter[0]._id;
  //     navigate("/appraisal/employee-normalizer/employee/" + id);
  //   } else if (
  //     (rejectfilter?.length === 1 && normalizerValue === "pending") ||
  //     normalizerValue === "draft"
  //   ) {
  //     return (
  //       setError(false),
  //       setzeroselect(false),
  //       handleDialogOpen(),
  //       setcheckedEmployeeid(() => {
  //         return rejectfilter?.map((j: any) => j._id);
  //       })
  //     );
  //   } else if (rejectfilter?.length === 0) {
  //     setzeroselect(true);
  //   }
  // };
  const getStatus = (status: any) => {
    if (status == "in-progress") {
      return "In progress"
    } else if (status == "not-started") {
      return "Not started"
    } else if (status == "normalized") {
      return "Normalized"
    } else if (status == "rejected") {
      return "Rejected "
    } else if (status == "completed") {
      return "Completed "
    }

  }
  const handleSubmit = (e: any) => {
    const checkedfilter = checkboxUser
      .filter((i: any) => {
        console.log(i?.isChecked, "hhhhhhhhhhhhhhhhhhhhhhhh");
        return i?.isChecked === true || i?.isChecked === false;
      })
      .map((j: any) => {
        return {
          value: j?.value,
          isChecked: j?.isChecked,
        };
      });
    console.log(checkedfilter, "ids");
    if (checkedfilter.filter((k: any) => k?.isChecked === true)?.length === 0) {
      setreasonSelection(true);
    } else if (checkedfilter?.length > 0) {
      return (
        setreasonSelection(false),
        handleDialogClose(),
        rejectNormalizer({
          value: checkedfilter,
          id: checkedEmployeeid[0],
        }),
        navigate(`${NORMALIZER_REJECT}/employee/${checkedEmployeeid[0]}`)
      );
    }

    //{`${MIDYEAR_PERFORMANCE}/employee/${employee_id}`}
  };


  useEffect(() => {
    setcheckboxUser(userData);
  }, []);
  console.log(checkboxUser, "ccccccccccccc");
  const handlecheckbox = (e: any) => {
    const { name, checked } = e.target;
    console.log(name, " values");
    if (name === "allselect") {
      let userReason = checkboxUser.map((reasons: any) => {
        return { ...reasons, isChecked: checked };
      });
      setcheckboxUser(userReason);
    } else {
      let userReason = checkboxUser.map((reasons: any) =>
        reasons?.reason === name ? { ...reasons, isChecked: checked } : reasons
      );
      setcheckboxUser(userReason);
    }
  };

  const checkboxHandler = (checkbox: any) => {
    if (checkbox) {
      const res = checkbox?.filter((i: any) => {
        return i?.normalizerIsChecked === true && !i?.normalizerIsDisabled;
      });
      console.log(res, "check22");
      return res;
    }
  };

  /*
    Disable checkbox  if employee apraisal was approved and saved
    Filter all the emoloyee who were appraoved 
    Create a state and stote the value of appraoved employees
    Then disable checkbox for the employee in the state

    */

  const checkboxIdHandler = (res: any) => {
    if (res) {
      const check = res?.map((i: any) => {
        return i?._id;
      });
      console.log(check, "check");
      return check;
    }
  };
  const handleOnCheck = (e: any) => {
    const { name, checked } = e.target;
    console.log(name, checked, "checked");
    const tempUser = users?.map((j: any) => {
      console.log(j?.normalizerIsChecked, "jjjjjjjjjjj");
      return j?._id === name ? { ...j, normalizerIsChecked: checked } : j;
    });

    let tempChecked = tempUser.filter((item: any) => item.normalizerIsChecked == true && item.normalizerIsDisabled == false)
    // let newchecked =(tempChecked.length > 1)
    if (tempChecked.length > 1) {
      setacceptButton(true);
      setrejectButton(true);
    } else {
      setacceptButton(false);
      setrejectButton(false);
    }
    console.log(tempChecked?.length, "newlength")
    console.log(tempChecked, "tempChecked")
    let tempRejectedLength = (tempChecked.length == 1) && tempChecked.filter((item: any) => item.appraisal.status == "rejected").length
    if (tempRejectedLength == 1) {
      setUsers(tempUser);
      setShowReNormalize(true)
    } else {
      setUsers(tempUser);
      setShowReNormalize(false)
    }
    // ststus check for rejected and inprogres  employee vaidation for bulk accept
    let Checkemployee = tempChecked?.map((j: any) => {
      return j?.appraisal?.status
    })
    if (Checkemployee?.includes("rejected") && Checkemployee?.includes("in-progress")) {
      setBulkOpen(true);
      setMessage("Please deselect performance appraisal for re-normalization to proceed further.")
    }

    // ststus check for one or more rejected employee vaidation for renormalize
    const filteredStatus = Checkemployee.filter((status: any) => status === "rejected");

    if (filteredStatus.length === 2) {
      setBulkOpen(true);
      setMessage("Please select only one rejected employee for re-normalization to proceed further.")
    }
    // console.log(Checkemployee,filteredStatus, "Checkemployee");
  };

  const approvedSuccessfully = () => {
    return setApproved(true);
  };

  const formatDate = (date: any) => {
    const day = date ? parseInt(date[2]) : null;
    const month = date ? new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(date)) : null;
    const year = date ? parseInt(date[0]) : null;
    return date ? `${day} ${month} ${year}` : ''
  }

  //Function to send email notification for each employee selected in bulk accept
  const sendEmailNotificationOnBulkAccept = (employee: any) => {

    //variables for email notification
    let appraiserName = employee?.appraiser_name;
    let normalizerName = employee?.normalizer_name;
    let employeeName = employee?.first_name;
    let calendarName = employee?.calendar?.calendar_type;
    let calendarYear = employee?.calendar?.start_date?.slice(0, 4);
    let Employee_Due_Date = employee?.calendar?.end_date_employee_acknowledgement?.slice(0, 10).split("-");// Split the date into an array [year, month, day]
    let formatted_Employee_Due_Date = formatDate(Employee_Due_Date); // Concatenate the parts in the desired format
    let employeeCode = employee?.employee_code
    let employeeFirstName = employee?.first_name;

    // Notification action to Employee after Normalizer accepts PA (for Employee)
    let tempSubject = NORMALIZER_ACCEPTS_PA?.subject;
    tempSubject = tempSubject?.replace("[year]", `${calendarYear}`);
    tempSubject = tempSubject?.replace("[calendar name]", `${calendarName}`);

    let tempHtml = NORMALIZER_ACCEPTS_PA?.html;
    tempHtml = tempHtml?.replace("[year]", `${calendarYear}`);
    tempHtml = tempHtml?.replace("[calendar name]", `${calendarName}`);
    tempHtml = tempHtml?.replace("[Appraiser name]", `${appraiserName}`);
    tempHtml = tempHtml?.replace("[dd/mm/yy]", `${formatted_Employee_Due_Date}`);
    tempHtml = tempHtml?.replace("[employee code]", `${employeeCode}`);
    tempHtml = tempHtml?.replace("[Employee FirstName]", `${employeeFirstName}`);

    let employeeEmail = NORMALIZER_ACCEPTS_PA?.to
    let email = employee?.email
    email = email?.replace("[email]", `${employeeEmail}`);

    sendEmailNotification(
      {
        to: email,
        subject: tempSubject,
        html: tempHtml
      }
    )

    // Notification info after Normalizer accepts PA (for Appraiser / Reviewer)

    let tempSubjectInfo = NORMALIZER_ACCEPTS_PA_INFO?.subject;
    tempSubjectInfo = tempSubjectInfo?.replace("[year]", `${calendarYear}`);
    tempSubjectInfo = tempSubjectInfo?.replace("[calendar name]", `${calendarName}`);
    tempSubjectInfo = tempSubjectInfo?.replace("[employee name]", `${employeeName}`);
    tempSubjectInfo = tempSubjectInfo?.replace("[employee code]", `${employeeCode}`);

    let tempHtmlInfo = NORMALIZER_ACCEPTS_PA_INFO?.html;
    tempHtmlInfo = tempHtmlInfo?.replace("[year]", `${calendarYear}`);
    tempHtmlInfo = tempHtmlInfo?.replace("[calendar name]", `${calendarName}`);
    tempHtmlInfo = tempHtmlInfo?.replace("[employee name]", `${employeeName}`);
    tempHtmlInfo = tempHtmlInfo?.replace("[Normalizer name]", `${normalizerName}`);
    tempHtmlInfo = tempHtmlInfo?.replace("[employee code]", `${employeeCode}`);

    // tempHtmlInfo = tempHtmlInfo.replace("[dd/mm/yy]", `${dueDate}`);

    let appraiserEmail = emailIdsForEmployees?.employeeData?.find((item: any) => item.employee_code === employee?.appraiser_code)?.email
    let reviewerEmail = emailIdsForEmployees?.employeeData?.find((item: any) => item.employee_code === employee?.reviewer_code)?.email
    let emailInfo = NORMALIZER_ACCEPTS_PA_INFO?.to;
    // const recipientEmails = [`${appraiserEmail}`, `${reviewerEmail}`]
    emailInfo = emailInfo?.replace("[email]", `${appraiserEmail}`);
    const ccemail = [`${reviewerEmail}`]
    sendEmailNotification(
      {
        to: emailInfo,
        cc: ccemail,
        subject: tempSubjectInfo,
        html: tempHtmlInfo
      }
    )

  }
  const sendEmailNotificationOnBulkAcceptGradeException = (employee: any) => {

    //variables for email notification
    let appraiserName = employee?.appraiser_name;
    let normalizerName = employee?.normalizer_name;
    let employeeName = employee?.legal_full_name;
    let calendarName = employee?.calendar?.calendar_type;
    let calendarYear = employee?.calendar?.start_date?.slice(0, 4);
    let Employee_Due_Date = employee?.calendar?.end_date_employee_acknowledgement?.slice(0, 10).split("-");// Split the date into an array [year, month, day]
    let formatted_Employee_Due_Date = `${Employee_Due_Date[2]}/${Employee_Due_Date[1]}/${Employee_Due_Date[0]}`; // Concatenate the parts in the desired format
    let employeeCode = employee?.employee_code
    let employeeFirstName = employee?.first_name;

    // Normalizer accepts Reviewer PA (for Appraiser/Reviewer) for Grade 6 to 10

    let tempSubjectInfo = NORMALIZER_ACCEPTS_PA_INFO_GRADE_EXCEPTION?.subject;
    tempSubjectInfo = tempSubjectInfo?.replace("[year]", `${calendarYear}`);
    tempSubjectInfo = tempSubjectInfo?.replace("[calendar name]", `${calendarName}`);
    tempSubjectInfo = tempSubjectInfo?.replace("[employee name]", `${employeeName}`);
    tempSubjectInfo = tempSubjectInfo?.replace("[employee code]", `${employeeCode}`);

    let tempHtmlInfo = NORMALIZER_ACCEPTS_PA_INFO_GRADE_EXCEPTION?.html;
    tempHtmlInfo = tempHtmlInfo?.replace("[year]", `${calendarYear}`);
    tempHtmlInfo = tempHtmlInfo?.replace("[calendar name]", `${calendarName}`);
    tempHtmlInfo = tempHtmlInfo?.replace("[employee name]", `${employeeName}`);
    tempHtmlInfo = tempHtmlInfo?.replace("[Normalizer name]", `${normalizerName}`);
    tempHtmlInfo = tempHtmlInfo?.replace("[employee code]", `${employeeCode}`);

    // tempHtmlInfo = tempHtmlInfo.replace("[dd/mm/yy]", `${dueDate}`);

    let appraiserEmail = emailIdsForEmployees?.employeeData?.find((item: any) => item.employee_code === employee?.appraiser_code)?.email
    let reviewerEmail = emailIdsForEmployees?.employeeData?.find((item: any) => item.employee_code === employee?.reviewer_code)?.email
    let emailInfo = NORMALIZER_ACCEPTS_PA_INFO_GRADE_EXCEPTION?.to;
    // const recipientEmails = [`${appraiserEmail}`, `${reviewerEmail}`]
    emailInfo = emailInfo?.replace("[email]", `${appraiserEmail}`);
    const ccemail = [`${reviewerEmail}`]
    sendEmailNotification(
      {
        to: emailInfo,
        cc: ccemail,
        subject: tempSubjectInfo,
        html: tempHtmlInfo
      }
    )

  }
  const bulkApproval = () => {
    console.log(users, 'userssssssssssssss');
    // let myAppraisalsCode = myAppraisals.map((item: any) => item.employee_code)
    // let myReviewalusers = users?.filter(
    //   (item: any) => !item?.normalizerIsDisabled && !item.normalizerIsChecked
    //     && item.appraisal.status !== "rejected" && item.reviewer.reviewer_status !== "rejected"
    // );
    // let bulkUsers = myReviewalusers.map((user: any) => user?._id)
    // const tempUser = users?.map((j: any) => {
    //   //console.log(j.reviewerIsChecked, "jjjjjjjjjjj");
    //   return bulkUsers.includes(j?._id) ? { ...j, normalizerIsChecked: true } : j;
    // });

    let date: any = new Date()
    const normalized_Date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    let myReviewalusers = users.filter((employee: any) => employee?.normalizerIsChecked == true && employee?.normalizerIsDisabled == false)


    myReviewalusers && myReviewalusers.forEach((employee: any) => {
      let reviewerObjectiveDescription = employeeData?.data?.reviewer?.objective_description
        .map((employee: any) => {
          return {
            ...employee,
            rejection_reason: ""
          }
        })
      if (employee.isGradeException == true) {
        acceptNormalizerGradeExceptionBulk({
          // talentCategory: talentCategory?.data,
          potential: employee?.appraisal?.potential,
          // current_overallRating: employee?.current_rating?.overall_rating,
          current_overallRating: employee?.current_rating?.overall_rating,
          normalized_overallRating: employee?.current_rating?.overall_rating,
          id: employee._id,
        }).then((res: any) => {
          if (!res?.error) {
            updateLoggedRole({
              pa_action_by: `Normalizer (Bulk Accepted - Grade Exception) : ${new Date()}`,
              id: user?._id
            });
          sendEmailNotificationOnBulkAcceptGradeException(employee)         
          } else {
            updateLoggedRole({
              pa_action_by: `Normalizer (Bulk Accepted - Grade Exception) : ${res?.error} ${new Date()}`,
              id: user?._id
            });
          }
        });
      }
      else {
        let reviewerObjectiveDescription = employeeData?.data?.reviewer?.objective_description
          .map((employee: any) => {
            return {
              ...employee,
              rejection_reason: ""
            }
          })

        console.log(reviewerObjectiveDescription, "reviewerObjectiveDescription")
        acceptNormalizer({
          current_overallRating: employee?.current_rating?.overall_rating,
          reviewerObjectiveDescription: reviewerObjectiveDescription,
          normalized_Date: normalized_Date,
          // current_previous_submission: employee?.current_rating?.objective_description,
          current_previous_submission: reviewerObjectiveDescription,
          normalized_overallRating: employee?.current_rating?.overall_rating,
          id: employee?._id,
        }).then((res: any) => {
          if (!res?.error) {
            updateLoggedRole({
              pa_action_by: `Normalizer (Bulk Accepted) : ${new Date()}`,
              id: user?._id
            });
          sendEmailNotificationOnBulkAccept(employee)
          } else {
            updateLoggedRole({
              pa_action_by: `Normalizer (Bulk Accepted) : ${res?.error} : ${new Date()}`,
              id: user?._id
            });
          }
         
        });
      }
      // setUsers(tempUser);
      // console.log(myReviewalusers);
      // console.log(checkboxIdHandler(myReviewalusers));
      // acceptNormalizer({ id: checkboxIdHandler(myReviewalusers) });
    })
    setMessage("The performance appraisal was normalized.")
    approvedSuccessfully();
  }

  const getPAStatus = (j: any) => {
    if (
      j?.appraisal?.objective_description &&
      j?.appraisal?.objective_description?.length === 0
    )
      return " PA Not Started";
    else if (j?.appraisal?.status == "completed") return " -";
    else if (j?.appraisal?.appraiser_status === "pending" || j?.appraisal?.appraiser_status === "draft")
      return " Pending with Appraiser";

    else if (j?.appraisal?.status === "normalized")
      return " Pending with Employee";
    else if ((j?.reviewer?.reviewer_status == "pending" || j?.reviewer?.reviewer_status == "draft"))
      return " Pending with Reviewer";
    else if (
      j?.reviewer?.reviewer_status == "accepted" &&
      j?.normalizer?.normalizer_status == "pending"
    )
      return "Pending with HR Normalizer";
    else if (
      j?.reviewer?.reviewer_status == "accepted-employee" &&
      j?.normalizer?.normalizer_status == "pending"
    )
      return "Pending with HR Normalizer";
    else if (
      j?.reviewer?.reviewer_status == "accepted" &&
      j?.normalizer?.normalizer_status == "employee-rejected"
    )
      return "Pending with HR Normalizer";
    else if (j?.normalizer?.normalizer_status?.includes("draft")) return " Pending with HR Normalizer(Draft)";

    else if (j?.normalizer?.normalizer_status === "rejected")
      return "Pending with Appraiser";
    else if (
      j?.reviewer?.reviewer_status == "rejected" &&
      j?.reviewer?.rejection_count == 3 &&
      j?.normalizer?.normalizer_status == "pending"
    )
      return "Pending with HR Normalizer (Reviewer Rejected)";
    else if (j?.normalizer?.normalizer_status == "employee-rejected")
      return " Pending with HR Normalizer";
    else if (j?.appraisal?.appraiser_status === "reviewer-rejected")
      return " Pending with Appraiser";
    else if (j?.appraisal?.appraiser_status === "normalizer-rejected")
      return " Pending with Appraiser";
    else if (j?.appraisal?.appraiser_status === "employee-rejected")
      return " Pending with Appraiser";
    else if (
      j?.reviewer?.reviewer_status == "normalizer-rejected" ||
      j?.reviewer?.reviewer_status == "appraiser-rejected"
    )
      return "Pending with Reviewer";
    else if (
      j?.appraisal?.appraiser_status == "normalizer-rejected" &&
      j?.appraisal?.appraiser_status == "accepted"
    )
      return "Pending with Reviewer";
    else {
      return "-"
    }
  };
  //drawer functions
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  const [isDrawerOpen1, setisDrawerOpen1] = React.useState(false);
  const [GenerateReportOpen, setGenerateReportOpen] = React.useState(false);
  const handleGenerateReportOpen = () =>{
   setGenerateReportOpen(false)
  }
  // Export to excel opendrawer functions
  const handleExportFunction = () => {
    setisDrawerOpen(true);
  };
  // function for Generate Report Export to excel opendrawer 
  const handleExportFunction1 = () => {
    const filteredEmployeeData = users?.filter((item: any) => {
      return item?.appraisal?.pa_status === "Completed";
    });
    console.log(filteredEmployeeData?.length,"length")
    if(filteredEmployeeData?.length == 0){
      setGenerateReportOpen(true)
    }else{
      setGenerateReportOpen(false)
      setisDrawerOpen1(true);
    }
  };
  const [columnHeaders, setcolumnHeaders] = useState<any>({
    Ecode: true,
    Ename: true,
    Eposition: true,
    Firstname: true,
    ESection: true,
    EGrade: true,
    EDivision: true,
    ESubSection: true,
    AppraiserName: true,
    ReviewerName: true,
    NormalizerName: true,
    OverallRating: true,
    employeerating: true,
    appraiserRating: true,
    reviewerRating: true,
    normalizerRating: true,
    PreviousRating: true,
    PotentialLevel: true,
    TalentCategory: true,
    WorkLocation: true,
    ManagerCode: true,
    ManagerName: true,
    ManagerPosition: true,
    // Rating: true,
    // PAStatus: true,
    // pendingAction: true,
    appraiserCode: true,
    reviewerCode: true,
    normalizerCode: true,
    status: true,
    // email: true,
    // rejectionStatus: true,
    ServiceReferenceDate: true,
    Function: true,
    SupervisoryRole: true,
    EmailID: true,
    CalendarName: true,
    SelectAll: true,
    PendingAction:true,
  })
  // Checkedcount and totalcount used for Drawer selectAll function
  const checkedCount = Object.keys(columnHeaders).reduce((count:any, key:any) => {
    if (key !== 'SelectAll') {
      return count + (columnHeaders[key] ? 1 : 0);
    }
    return count;
  }, 0);
  const totalCount = Object.keys(columnHeaders).length - 1; 
  console.log(checkedCount,totalCount,"totalCount")

  const [columnHeadersDisplay, setcolumnHeadersDisplay] = useState<any>({
    TrainingRecommendation:true,
    Areaofimprovement:true,
    furtherrecommendation:true
  })
  //---------------------------------new drawer function(need to replace)
  const [ServiceReferenceDateData, setServiceReferenceDateData] = React.useState(true);
  const [FunctionData, setFunctionData] = React.useState(true);
  const [SupervisoryRoleData, setSupervisoryRoleData] = React.useState(true);
  const [EmailIDData, setEmailIDData] = React.useState(true);
  const [rejstatusValue, rejsetstatusValue] = React.useState(true);
  const [firstName, setfirstName] = React.useState(true);
  const [heading1, setheading1] = React.useState(true);
  const [heading2, setheading2] = React.useState(true);
  const [heading3, setheading3] = React.useState(true);
  const [heading4, setheading4] = React.useState(true);
  const [appraiserRating, setAppraiserRating] = React.useState(true);
  const [reviewerRating, setReviewerRating] = React.useState(true);
  const [normalizeRating, setNormalizeRating] = React.useState(true);
  const [employeerating, setemployeerating] = React.useState(true);
  const [headingOfEcode, setheadingOfEcode] = React.useState(true);
  const [headingOfSection, setheadingOfSection] = React.useState(true);
  const [headingOfPAStatus, setheadingOfPAStatus] = React.useState(true);
  const [headingOfDivision, setheadingOfDivision] = React.useState(true);
  const [headingOfSubSection, setheadingOfSubSection] = React.useState(true);
  const [headingOfAppraiserName, setheadingOfAppraiserName] = React.useState(true);
  const [headingOfReviewerName, setheadingOfReviewerName] = React.useState(true);
  const [headingOfNormalizerName, setheadingOfNormalizerName] = React.useState(true);
  const [headingOfOverallRating, setheadingOfOverallRating] = React.useState(true);
  const [headingOfPreviousRating, setheadingOfPreviousRating] = React.useState(true);
  const [headingOfPotentialLevel, setheadingOfPotentialLevel] = React.useState(true);
  const [headingOfTalentCategory, setheadingOfTalentCategory] = React.useState(true);
  const [headingOfWorkLocation, setheadingOfWorkLocation] = React.useState(true);
  const [headingOfManagerCode, setheadingOfManagerCode] = React.useState(true);
  const [headingOfManagerName, setheadingOfManagerName] = React.useState(true);
  const [headingOfManagerPosition, setheadingOfManagerPosition] = React.useState(true);
  const [appCodes, setappCodes] = React.useState(true);
  const [revCodes, setrevCodes] = React.useState(true);
  const [norCodes, setnorCodes] = React.useState(true);
  const [statusValue, setstatusValue] = React.useState(true);
  const [headingcalendar, setheadingcalendar] = React.useState(true);
  const handleheadingCalendar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingcalendar(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  }
  const [headingOfPendingAction, setheadingOfPendingAction] = React.useState(true);

  const handlePendingAction = (event: React.ChangeEvent<HTMLInputElement>) => {
      setheadingOfPendingAction(event.target.checked);
      const { name, checked } = event.target;
      setcolumnHeaders((prevColumnHeaders: any) => ({
        ...prevColumnHeaders,
        [name]: checked,
        SelectAll: checked ? prevColumnHeaders.SelectAll : checked,
      }));
    };
  const handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstatusValue(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [Areaofimprovement, setAreaofimprovement] = React.useState(true);
  const handleAreaofimprovement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAreaofimprovement(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [TrainingRecommendation, setTrainingRecommendation] = React.useState(true);
  const handleTrainingRecommendation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrainingRecommendation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const [furtherrecommendation, setfurtherrecommendation] = React.useState(true);
  const handlefurtherrecommendation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfurtherrecommendation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeadersDisplay((prevColumnHeaders:any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setappCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleRevCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setrevCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));

  };
  const handleNorCodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnorCodes(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));

  };
  const handleEmployeeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfEcode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));

  };
  const handleheading1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading1(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading2(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading3(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleheading4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheading4(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePAStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPAStatus(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleDivision = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfDivision(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSubSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfSubSection(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleAppraiserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfAppraiserName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfReviewerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfNormalizerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleOverallRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfOverallRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePreviousRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPreviousRating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlePotentialLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfPotentialLevel(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleTalentCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfTalentCategory(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleWorkLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfWorkLocation(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerCode(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleManagerPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    setheadingOfManagerPosition(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleemployeerating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemployeerating(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSelectAll = (selectAll:any) => {
    const updatedColumnHeaders = { ...columnHeaders };
    Object.keys(updatedColumnHeaders).forEach((key) => {
      updatedColumnHeaders[key] = selectAll;
    });
    setcolumnHeaders(updatedColumnHeaders);
  };
  const handleAppraiserRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppraiserRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handlefirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfirstName(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleRejectionStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    rejsetstatusValue(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleServiceReferenceDateData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceReferenceDateData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleFunctionData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleSupervisoryRoleData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupervisoryRoleData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleEmailIDData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailIDData(event.target.checked);
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleReviewerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewerRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
  const handleNormalizerRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNormalizeRating(event.target.checked)
    const { name, checked } = event.target;
    setcolumnHeaders((prevColumnHeaders: any) => ({
      ...prevColumnHeaders,
      [name]: checked,
    }));
  };
//Function for Export to Excel 
  const handleExport = () => {
    const mapped = EmployeeData
      ?.filter((item1: any) => {
        if (positionsFilter?.includes("None") || positionsFilter?.length === 0) {
          return item1;
        } else {
          return !!positionsFilter?.find((item2: any) => item1?.position_long_description === item2)
        }
      })
      ?.filter((item1: any) => {
        if (GradesFilter?.includes("None") || GradesFilter?.length === 0) {
          return item1;
        } else {
          return !!GradesFilter?.find((item2: any) => item1?.grade === item2)
        }
      })
      ?.filter((j: any) => {
        if (enteredName === "") {
          return j;
        } else if (
          (j.employee_code !== undefined &&
            j.employee_code
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase())) ||
          (j?.legal_full_name !== undefined &&
            j?.legal_full_name
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase())) ||
          (j?.grade !== undefined &&
            j?.grade
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase())) ||
          (j?.position_long_description !== undefined &&
            j?.position_long_description
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase())) ||
          (j?.appraisal?.status !== undefined &&
            j?.appraisal?.status
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase())) ||
          (getPAStatus(j) !== undefined &&
            getPAStatus(j)
              ?.toLocaleLowerCase()
              ?.includes(enteredName?.toLocaleLowerCase()))
        ) {
          return j;
        }
      })
      ?.map((j: any, emp: any, employee: any) => {
        let inputDate = j?.service_reference_date
        const dateParts = inputDate?.split("-");
        console.log(inputDate, dateParts, "inputDate")
        let date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        const formattedDate = `${day}-${month}-${year}`;
        let exportData: any = {}
        if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = CalendarName
        if (columnHeaders["Ecode"] == true) exportData["Ecode"] = j?.employee_code
        if (columnHeaders["Ename"] == true) exportData["Employee Name"] = j?.legal_full_name
        if (columnHeaders["Firstname"] == true) exportData["Known As"] = j?.first_name
        if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date"] = formattedDate
        if (columnHeaders["Eposition"] == true) exportData["Position"] = j?.position_long_description
        if (columnHeaders["EGrade"] == true) exportData["Grade"] = j?.grade
        if (columnHeaders["Function"] == true) exportData["Function"] = j?.function
        if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] =  j?.isSupervisor != true ? "N-SP" : "SP"
        if (columnHeaders["EmailID"] == true) exportData["Email ID"] = j?.email
        if (columnHeaders["EDivision"] == true) exportData["Division"] = j?.division
        if (columnHeaders["ESection"] == true) exportData["Section"] = j?.section
        if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = j?.sub_section
        if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = j?.work_location
        if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = j?.appraiser_code
        if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = j?.appraiser_name
        if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = j?.reviewer_code
        if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = j?.reviewer_name
        if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = j?.normalizer_code
        if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = j?.normalizer_name
        if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = j?.employee?.employee_rating?.toFixed(2)
        if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = j?.appraisal?.appraiser_rating?.toFixed(2)
        if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = j?.reviewer?.reviewer_rating?.toFixed(2)
        if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = j?.normalizer?.normalizer_rating?.toFixed(2)
        if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = (j?.current_rating?.overall_rating == 0 || j?.appraisal?.status !== "completed") ? "-" : j?.current_rating?.overall_rating?.toFixed(2)
        if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = j?.previous_rating?.toFixed(2)
        if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] =(j?.normalizer?.normalized_overallRating !== 0 ? j?.appraisal?.potential : "")
        if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = j?.talent_category
        if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = j?.manager_code
        if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = j?.manager_name
        if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = j?.manager_position
        if (columnHeaders["status"] == true) exportData["Status"] = getStatus(j?.appraisal?.status)
          if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] = j.appraisal?.status == "not-started" ? "Pending with Appraiser" :j.appraisal?.status == "completed" ? ("-") : j.appraisal.pa_status

        return exportData
      });
    const a = [1]
    const Emptymapped = a.map((j: any) => {
      let exportData: any = {}
      if (columnHeaders["CalendarName"] == true) exportData["Calendar Name"] = ""
      if (columnHeaders["Ecode"] == true) exportData["Ecode"] = ""
      if (columnHeaders["Ename"] == true) exportData["Employee Name"] = ""
      if (columnHeaders["Firstname"] == true) exportData["Known As"] = ""
      if (columnHeaders["ServiceReferenceDate"] == true) exportData["Service Reference Date "] = ""
      if (columnHeaders["Eposition"] == true) exportData["Position"] = ""
      if (columnHeaders["EGrade"] == true) exportData["Grade"] = ""
      if (columnHeaders["Function"] == true) exportData["Function"] = ""
      if (columnHeaders["SupervisoryRole"] == true) exportData["Supervisory Role"] = ""
      if (columnHeaders["EmailID"] == true) exportData["Email ID"] = ""
      if (columnHeaders["EDivision"] == true) exportData["Division"] = ""
      if (columnHeaders["ESection"] == true) exportData["Section"] = ""
      if (columnHeaders["ESubSection"] == true) exportData["Sub-section"] = ""
      if (columnHeaders["WorkLocation"] == true) exportData["Work Location"] = ""
      if (columnHeaders["appraiserCode"] == true) exportData["Appraiser Code"] = ""
      if (columnHeaders["AppraiserName"] == true) exportData["Appraiser Name"] = ""
      if (columnHeaders["reviewerCode"] == true) exportData["Reviewer Code"] = ""
      if (columnHeaders["ReviewerName"] == true) exportData["Reviewer Name"] = ""
      if (columnHeaders["normalizerCode"] == true) exportData["HR Normalizer Code"] = ""
      if (columnHeaders["NormalizerName"] == true) exportData["HR Normalizer Name"] = ""
      if (columnHeaders["employeerating"] == true) exportData["Employee Rating "] = ""
      if (columnHeaders["appraiserRating"] == true) exportData["Appraiser Rating"] = ""
      if (columnHeaders["reviewerRating"] == true) exportData["Reviewer Rating"] = ""
      if (columnHeaders["normalizerRating"] == true) exportData["HR Normalizer Rating"] = ""
      if (columnHeaders["OverallRating"] == true) exportData["Overall Rating"] = ""
      if (columnHeaders["PreviousRating"] == true) exportData["Previous Period Rating"] = ""
      if (columnHeaders["PotentialLevel"] == true) exportData["Potential Level"] = ""
      if (columnHeaders["TalentCategory"] == true) exportData["Talent Category"] = ""
      if (columnHeaders["ManagerCode"] == true) exportData["Manager Code"] = ""
      if (columnHeaders["ManagerName"] == true) exportData["Manager Name"] = ""
      if (columnHeaders["ManagerPosition"] == true) exportData["Manager Position"] = ""
      if (columnHeaders["status"] == true) exportData["Status"] = ""
      if (columnHeaders["PendingAction"] == true) exportData["Pending Action"] ="" 

      return exportData
    });
    // Use Emptymapped when the filtered data is empty
    if (mapped.length === 0) {
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(Emptymapped);
    } else {
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(mapped);
    }
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "MyExcel.xlsx");
  }
  const [headingSortAccept, setheadingSortAccept] = React.useState(false);
  const [openGrade, setOpenGrade] = React.useState(false);
  // function for Excelreport drawer close function
  const handleCloseGrade = () => {
    setisDrawerOpen(false);
    setheadingSortAccept(false);
      // making checkbox activate as true while closing the drawer
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Eposition: true,
      Firstname: true,
      ESection: true,
      EGrade: true,
      EDivision: true,
      ESubSection: true,
      AppraiserName: true,
      ReviewerName: true,
      NormalizerName: true,
      OverallRating: true,
      employeerating: true,
      appraiserRating: true,
      reviewerRating: true,
      normalizerRating: true,
      PreviousRating: true,
      PotentialLevel: true,
      TalentCategory: true,
      WorkLocation: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      Rating: true,
      PAStatus: true,
      pendingAction: true,
      appraiserCode: true,
      reviewerCode: true,
      normalizerCode: true,
      status: true,
      email: true,
      rejectionStatus: true,
      ServiceReferenceDate: true,
      Function: true,
      SupervisoryRole: true,
      EmailID: true,
      SelectAll: true,
      PendingAction:true,
    })
  };
  // function for generate report drawer close function
  const handleCloseGrade1 = () => {
    setisDrawerOpen1(false);
  // making checkbox activate as true while closing the drawer
    setcolumnHeadersDisplay({
      TrainingRecommendation:true,
      Areaofimprovement:true,
      furtherrecommendation:true
    })
  }
  // Excel report drawer Apply button
  const handleheadingSortAccept = () => {
    setisDrawerOpen(false);
    handleExport();
    setcolumnHeaders({
      Ecode: true,
      Ename: true,
      Eposition: true,
      Firstname: true,
      ESection: true,
      EGrade: true,
      EDivision: true,
      ESubSection: true,
      AppraiserName: true,
      ReviewerName: true,
      NormalizerName: true,
      OverallRating: true,
      employeerating: true,
      appraiserRating: true,
      reviewerRating: true,
      normalizerRating: true,
      PreviousRating: true,
      PotentialLevel: true,
      TalentCategory: true,
      WorkLocation: true,
      ManagerCode: true,
      ManagerName: true,
      ManagerPosition: true,
      // Rating: true,
      // PAStatus: true,
      // pendingAction: true,
      appraiserCode: true,
      reviewerCode: true,
      normalizerCode: true,
      status: true,
      // email: true,
      // rejectionStatus: true,
      ServiceReferenceDate: true,
      Function: true,
      SupervisoryRole: true,
      EmailID: true,
      SelectAll: true,
      PendingAction:true,
    })
  };
  // Generate report drawer Apply button
  const handleheadingSortAccept1 = () => {
    setisDrawerOpen1(false);
    handleExport3(columnHeadersDisplay)
    setcolumnHeadersDisplay({
      TrainingRecommendation:true,
      Areaofimprovement:true,
      furtherrecommendation:true
    })
  }
  // Badge
  const [All, setAll] = React.useState<any>(0);
  const [completedemp, setcompletedemp] = React.useState<any>(0);
  const [inProgressemp, setinProgressemp] = React.useState<any>(0);
  const [notStartedemp, setnotStartedemp] = React.useState<any>(0);
  const [emprejected, setemprejected] = React.useState<any>(0);
  const [mypendingActions, setmypendingActions] = React.useState<any>(0);
  const [EmployeeData, setEmployeeData] = React.useState<any>([])

  useEffect(() => {
    setAll(users?.length);
    let badge = users?.filter((j: any) => {
      return j?.appraisal?.status === "completed";
    });
    setcompletedemp(badge?.length);
    let badge2 = users?.filter((j: any) => {
      return j?.appraisal?.status === "in-progress" || j?.appraisal?.status === "normalized";
    });
    setinProgressemp(badge2?.length);
    let badge3 = users?.filter((j: any) => {
      return j?.appraisal?.status === "not-started"
    });
    let Notstarted = users?.filter((j: any) => {
      return j?.appraisal?.status === "not-started"
    });
    setnotStartedemp(badge3?.length);
    let badge4 = users?.filter((j: any) => {
      return j?.appraisal?.status === "rejected";
    });
    setemprejected(badge4?.length);
    let badge5 = users?.filter((j: any) => {
      return j?.appraisal?.pa_status?.includes("Pending with HR Normalizer");
    });
    setmypendingActions(badge5?.length);
    if (tabValue === 0) {
      setEmployeeData(users)
      setPage(0)
    } else if (tabValue === 1) {
      setEmployeeData(badge5)
      setPage(0)
    } else if (tabValue === 2) {
      setEmployeeData(Notstarted)
      setPage(0)
    } else if (tabValue === 3) {
      setEmployeeData(badge2)
      setPage(0)
    } else if (tabValue === 4) {
      setEmployeeData(badge4)
      setPage(0)
    } else if (tabValue === 5) {
      setEmployeeData(badge)
      setPage(0)
    }
  }, [users, tabValue]);
  
  const handleExport3 = (columnHeadersDisplay: any) => {
    const filteredEmployeeData = users?.filter((item: any) => {
      return item?.appraisal?.pa_status === "Completed";
    });
    let lastEmployeeCode: string | null = null;
    var wb = XLSX.utils.book_new();
    var wsData: any[][] = [[]];
    wsData.push(
      ["Ecode",
        "Employee Name",
        "Known As",
        "Service Reference Date",
        "Position",
        "Grade",
        "Division",
        "Overall Rating",
        "Potential Level",
        "Talent Category",
        "Section",
        "Sub-section",
        "Appraiser Code",
        "Appraiser Name",
        "Training Category(Appraiser)",
        "Training Name(Appraiser)",
        "Justification(Appraiser)",
        "Training Category(Employee)",
        "Training Name(Employee)",
        "Justification(Employee)",
      ]
    );
    filteredEmployeeData.forEach((employee: any) => {
      const employeeCode = employee?.employee_code
      let inputDate = employee?.service_reference_date;
      const dateParts = inputDate?.split("-");
      const date = new Date(inputDate);
      const year = date?.getFullYear();
      const month = date?.toLocaleString("default", { month: "short" });
      const day = date?.getDate();
      const formattedDate = `${day}-${month}-${year}`;
      if (lastEmployeeCode !== employeeCode) {
        if (lastEmployeeCode !== null) {
          const appraisalTraining = employee?.appraisal?.training_recommendation[0]
          const employeeTraining = employee?.employee?.training_recommendation[0]
        }
        const maxTrainingRecommendations = Math.max(employee?.appraisal?.training_recommendation.length, employee?.employee?.training_recommendation.length);
        const appraisalTraining = employee?.appraisal?.training_recommendation[0]
        const employeeTraining = employee?.employee?.training_recommendation[0]
        wsData.push([
          employeeCode,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.current_rating?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.name?.title,
          appraisalTraining?.training_name,
          appraisalTraining?.justification,
          employeeTraining?.name?.title,
          employeeTraining?.training_name,
          employeeTraining?.justification,
        ]);
        lastEmployeeCode = employeeCode;
      }
      // Update maxTrainingRecommendations for the current employee
      const maxTrainingRecommendations = Math.max(employee?.appraisal?.training_recommendation.length, employee?.employee?.training_recommendation.length);
      // Add training recommendation details for the subsequent rows (excluding the first row)
      for (let i = 1; i < maxTrainingRecommendations; i++) {
        const appraisalTraining = employee?.appraisal?.training_recommendation[i] || {};
        const employeeTraining = employee?.employee?.training_recommendation[i] || {};
        wsData.push([
          employeeCode || "",
          employee?.legal_full_name || "",
          employee?.first_name || "",
          formattedDate,
          employee?.position_long_description || "",
          employee?.grade || "",
          employee?.division || "",
          employee?.current_rating?.overall_rating?.toFixed(2) || "",
          employee?.appraisal?.potential || "",
          employee?.talent_category || "",
          employee?.section || "",
          employee?.sub_section || "",
          employee?.appraiser_code || "",
          employee?.appraiser_name || "", // Empty columns for employee details
          appraisalTraining?.name?.title || "",
          appraisalTraining?.training_name || "",
          appraisalTraining?.justification || "",
          employeeTraining?.name?.title || "",
          employeeTraining?.training_name || "",
          employeeTraining?.justification || "",
        ]);
      }
    });
    const sheet2Data = filteredEmployeeData
      ?.map((j: any, emp: any, employee: any) => {
        let inputDate = j?.service_reference_date
        const dateParts = inputDate?.split("-");
        console.log(inputDate, dateParts, "inputDate")
        let date = new Date(inputDate);
        const year = date?.getFullYear();
        const month = date?.toLocaleString("default", { month: "short" });
        const day = date?.getDate();
        //const day = dateParts[2]?.slice(0, 2)
        const formattedDate = `${day}-${month}-${year}`;
        let exportData: any = {}
        exportData["Ecode"] = j?.employee_code
        exportData["Employee Name"] = j?.legal_full_name
        exportData["Known As"] = j?.first_name
        exportData["Service Reference Date"] = formattedDate
        exportData["Position"] = j?.position_long_description
        exportData["Grade"] = j?.grade
        exportData["Function"] = j?.function
        exportData["Supervisory Role"] = j?.isSupervisor != true ? "N-SP" : "SP"
        exportData["Overall Rating"] = j?.current_rating?.overall_rating?.toFixed(2)
        exportData["Potential Level"] = (j?.appraisal?.potential)
        exportData["Division"] = j?.division
        exportData["Section"] = j?.section
        exportData["Sub-section"] = j?.sub_section
        exportData["Appraiser Code"] = j?.appraiser_code
        exportData["Appraiser Name"] = j?.appraiser_name
        exportData["Further Recommendations"] = j?.appraisal?.other_recommendation_others ? j?.appraisal?.other_recommendation_others : j?.appraisal?.other_recommendation?.map((rec: any) => rec?.name?.name).join(', ')
        return exportData
      });
    var wsData1: any[][] = [[]];
    wsData1.push(
      ["Ecode",
        "Employee name",
        "Known As",
        "Service Reference Date",
        "Position",
        "Grade",
        "Division",
        "Overall Rating",
        "Potential Level",
        "Talent Category",
        "Section",
        "Sub-section",
        "Appraiser Code",
        "Appraiser Name",
        "Specific Area(Appraiser)",
        "Specific Action(Appraiser)",
        "Specific Area(Employee)",
        "Specific Action(Employee)",
      ]
    )

    filteredEmployeeData.forEach((employee: any) => {
      const employeeCode = employee?.employee_code
      let inputDate = employee?.service_reference_date;
      const dateParts = inputDate?.split("-");
      const date = new Date(inputDate);
      const year = date?.getFullYear();
      const month = date?.toLocaleString("default", { month: "short" });
      const day = date?.getDate();
      const formattedDate = `${day}-${month}-${year}`;
      // if (lastEmployeeCode !== employeeCode) {
        if (lastEmployeeCode !== null) {
        }
        // const maxTrainingRecommendations = Math.max(employee?.appraisal?.training_recommendation.length, employee?.employee?.training_recommendation.length);
        const appraisalTraining = (employee?.appraisal?.area_of_improvement || [])[0] || {};
        const employeeTraining = (employee?.employee?.area_of_improvement || [])[0] || {};
        const appraisalSpecificAction = (appraisalTraining?.specific_actions || [])[0]?.value || "";
        const employeeSpecificAction = (employeeTraining?.specific_actions || [])[0]?.value || "";
        wsData1.push([
          employeeCode,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.current_rating?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.value,
          appraisalSpecificAction || "",
          employeeTraining?.value,
          employeeSpecificAction || "",
        ]);
        lastEmployeeCode = employeeCode;
      // }
      // Update maxTrainingRecommendations for the current employee
      const maxTrainingRecommendations = Math.max(employee?.appraisal?.area_of_improvement?.length, employee?.employee?.area_of_improvement?.length);
      // Add training recommendation details for the subsequent rows (excluding the first row)
      for (let i = 1; i < maxTrainingRecommendations; i++) {
        const appraisalTraining = employee?.appraisal?.area_of_improvement[i] || {};
        const employeeTraining = employee?.employee?.area_of_improvement[i] || {};
        wsData1.push([
          employeeCode,
          employee?.legal_full_name,
          employee?.first_name,
          formattedDate,
          employee?.position_long_description,
          employee?.grade,
          employee?.division,
          employee?.current_rating?.overall_rating?.toFixed(2),
          employee?.appraisal?.potential,
          employee?.talent_category,
          employee?.section,
          employee?.sub_section,
          employee?.appraiser_code,
          employee?.appraiser_name,
          appraisalTraining?.value || "",
          (appraisalTraining?.specific_actions || [])[0]?.value || "",
          employeeTraining?.value || "",
          (employeeTraining?.specific_actions || [])[0]?.value || "",
        ]);
      }
    });
    wsData.shift();
    wsData1.shift();
    const sheet3 = XLSX.utils.aoa_to_sheet(wsData1);
    const sheet1 = XLSX.utils.aoa_to_sheet(wsData);
    const sheet2 = XLSX.utils.json_to_sheet(sheet2Data, { header: Object.keys(sheet2Data[0]) });
    if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.furtherrecommendation && columnHeadersDisplay.Areaofimprovement) {
      XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
      XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
      XLSX.utils.book_append_sheet(wb, sheet2, 'Further Recommendation');
    } else if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.furtherrecommendation) {
      XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
      XLSX.utils.book_append_sheet(wb, sheet2, 'Further Recommendation');
    } else if (columnHeadersDisplay.TrainingRecommendation && columnHeadersDisplay.Areaofimprovement) {
      XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
      XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
    } else if (columnHeadersDisplay.furtherrecommendation && columnHeadersDisplay.Areaofimprovement) {
      XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
      XLSX.utils.book_append_sheet(wb, sheet2, 'Further Recommendation');
    } else if (columnHeadersDisplay.TrainingRecommendation) {
      XLSX.utils.book_append_sheet(wb, sheet1, 'Training');
    } else if (columnHeadersDisplay.furtherrecommendation) {
      XLSX.utils.book_append_sheet(wb, sheet2, 'Further Recommendation');
    } else if (columnHeadersDisplay.Areaofimprovement) {
      XLSX.utils.book_append_sheet(wb, sheet3, 'Areas for Improvement');
    }
    XLSX.writeFile(wb, `Performance Appraisal Report -${CalenderName}.xlsx`);
  }

  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  const MenuPropsGrade = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 70,
      },
    },
  };
 

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen1(false);
    setMessage("")
  };
  const handleClickOpen = () => {
    let checkedLength: any = users?.filter((item: any) => item?.normalizerIsChecked == true && item?.normalizerIsDisabled == false)
    if (checkedLength?.length > 0) {
      let myReviewalusers = checkedLength && checkedLength?.filter((rejectedItem: any) => rejectedItem?.appraisal?.status == "rejected"
        || rejectedItem?.normalizer?.normalizer_PA_rejected == true
      )
      let employeecodes = checkedLength.map((data: any) => {
        return [data?.employee_code, data?.appraiser_code, data?.reviewer_code, data?.normalizer_code]
      })
      let OverallRating = checkedLength.map((item: any) => {
        return item?.current_rating?.overall_rating
      })
      console.log(OverallRating, "OverallRating")
      let hasInvalidRating = false;
      employeecodes = employeecodes?.flat()?.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
      for (const rating of OverallRating) {
        console.log(rating < 3 || rating >= 4, "trueee");
        if (rating < 3 || rating >= 4) {
          hasInvalidRating = true;
          break;
        }
      }
      console.log(checkedLength, myReviewalusers, 'checkReviewerrating')
      if (myReviewalusers && myReviewalusers.length > 0) {
        setBulkOpen(true);
        setMessage("Rejected performance appraisal cannot be accepted in bulk.")
      }
      else if (hasInvalidRating) {
        setBulkOpen(true);
        setMessage("You cannot include employees with overall ratings below 3 and 4 and above in Bulk Accept. Please exclude them from the list to proceed further.");
      }
      else {
        getEmailIds({ "ecodes": employeecodes }).then((res: any) => {
          console.log(res, "EmailMutationresponse")
          //open dialog to confirm bulk accept
          setEmailIdsForEmployees(res?.data)
          setOpen(true);
        })
      }
    }
    else {
      setBulkOpen(true);
      setMessage("Please select atleast one employee.")
    }
  };
  const [open1, setOpen1] = React.useState(false);

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const reNormalizeHandler = () => {
    navigate(
      `${NORMALIZER_ACTION_SCREEN}/employee/${checkboxIdHandler(
        checkboxHandler(users)
      )}`
    );
  }
  const [show, setShow] = React.useState<any>(0);
  
  const [errorApprove, seterrorApprove] = React.useState(false);
  const [zeroselectApprove, setzeroselectApprove] = React.useState(false);
  const handleApprove = () => {
    const rejectfilter = users.filter((i: any) => {
      console.log(i.normalizerIsChecked, "filter");
      return i.normalizerIsChecked === true && i.normalizerIsDisabled === false;
    });
    if (rejectfilter?.length > 1) {
      seterrorApprove(true);
      setOpenAlert(true);
    } else if (rejectfilter?.length === 0) {
      setzeroselectApprove(true);
      setOpenAlert(true);
    } else {
      if (checkboxHandler(users)[0]?.appraisal?.status === "in-progress") {
        console.log("`````````````````````````````````````");
        return (
          acceptNormalizer({
            id: checkboxIdHandler(checkboxHandler(users)),
          }),
          approvedSuccessfully()
        );
      } else if (checkboxHandler(users)[0]?.appraisal?.status === "rejected") {
        navigate(
          `${NORMALIZER_ACTION_SCREEN}/employee/${checkboxIdHandler(
            checkboxHandler(users)
          )}`
        );
      }
    }
  };
  //Approve dialog
  //Alerts to dialogs
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setError(false);
    setzeroselectApprove(false);
    seterrorApprove(false);
    setzeroselect(false);
  };

  const [filterOptions, setfilterOptions] = useState<any>([]);
  const maxLengthForSearch = 30;
  const handleSearchBar = (e: any) => {
    if (e.target.value.length > maxLengthForSearch) {
      e.target.value = e.target.value.slice(0, maxLengthForSearch);
    }
    setenteredName(e.target.value);
    setPage(0);
  }
  // BULK ACCEPT BUTTON HOVER FUCNTION
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setbulkupdate(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setbulkupdate(null);
  };

  const openBulkHover = Boolean(bulkupdate);
  return (
    <Mytable>
      <Stack
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="30px"
        paddingBottom="10px"
      >
        <Heading> My Team</Heading>
        <AlertDialogSuccess 
         isAlertOpen={GenerateReportOpen}
            handleAlertClose={handleGenerateReportOpen}
        >
          Unable to Generate Report: No employees have completed appraisals.
        </AlertDialogSuccess>
        <GenerateReport 
        isDrawerOpen1={isDrawerOpen1}
        setisDrawerOpen1={setisDrawerOpen1}
        handleTrainingRecommendation={handleTrainingRecommendation}
        handlefurtherrecommendation={handlefurtherrecommendation}
        handleAreaofimprovement={handleAreaofimprovement}
        handleheadingSortAccept1={handleheadingSortAccept1}
        handleCloseGrade1={handleCloseGrade1}
        columnHeadersDisplay={columnHeadersDisplay}
        />
        <AlertDialogSuccess
          isAlertOpen={openAlertOk}
          handleAlertClose={handleAlertClose}>
          {message}
        </AlertDialogSuccess>
        {approved && (
          <Dialog
            open={open1}
            onClose={handleClose2}
            BackdropProps={{ style: { background: "#333333 !important", opacity: "10%" } }}
            PaperProps={{
              style: {
                boxShadow: "none",
                borderRadius: "6px",
                maxWidth: "0px",
                minWidth: "26%",
                margin: "0px",
                padding: "30px",
              },
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  color: "#333333",
                  fontSize: "14px",
                  fontFamily: "Arial",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  wordBreak: "break-word",
                  alignItems: "center",
                  overflowY: "hidden",
                }}
              >{message}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  height: "35px",
                  width: "70px"
                }}
                variant="outlined"
                onClick={handleClose2}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          PaperProps={{
            style: {
              boxShadow: "none",
              borderRadius: "6px",
              maxWidth: "0px",
              minWidth: "26%",
              margin: "0px",
              padding: "30px",
            },
          }}
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "#333333",
                fontSize: "14px",
                fontFamily: "Arial",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                wordBreak: "break-word",
                alignItems: "center",
                overflowY: "hidden",
              }}
            >
              {error && (
                <div>
                  Multiple employee cannot be rejected - select one employee.
                </div>
              )}
              {zeroselect && (
                <div>
                  {" "}
                  Please select atleast one employee.
                </div>
              )}
              {errorApprove && (
                <div>
                  Multiple employee cannot be rejected - select one employee.
                </div>
              )}
              {zeroselectApprove && (
                <div>
                  {" "}
                  Please select atleast one employee.
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <div style={{ alignItems: "center" }}>
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  height: "35px",
                  width: "70px"
                }}
                variant="outlined"
                autoFocus
                onClick={handleCloseAlert}
              >
                Ok
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <div>
          {showReNormalize && tabValue == 1 && (
            <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                marginRight: "20px",
                background: "transparent",
                height: "35px",
                color: "#3e8cb5"
              }}
              variant="outlined"
              onClick={() => {
                reNormalizeHandler()
              }}
            >
              Re-Normalize
            </Button>
          )}

          {!showReNormalize && tabValue != 0 && tabValue != 2 && tabValue != 3 && tabValue != 5 && (
            <>
              {tabValue == 1 &&
              <Button
                aria-owns={openBulkHover ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  marginRight: "20px",
                  background: "transparent",
                  height: "35px",
                  color: "#3e8cb5"
                }}
                variant="outlined"
                onClick={handleClickOpen}
              >
                Bulk Accept
              </Button>
            }
              <Popover
                id="mouse-over-popover"
                open={openBulkHover}
                anchorEl={bulkupdate}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                  style: {
                    boxShadow: "none",
                    maxWidth: "125px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  pointerEvents: 'none',
                  "& .MuiPopover-paper": {
                    border: "1px solid #3e8cb5",
                    backgroundColor: "#ffffff",
                    marginTop: "5px",
                  },
                }}
              >
                <Typography
                  style={{
                    padding: "3px",
                    fontSize: "9px",
                    color: "#333333",
                    fontFamily: "Arial",
                    textAlign: "justify"
                  }}>Bulk accept is used for the PA rating acceptance without reviewing the PA details.</Typography>
              </Popover>
              {tabValue == 1 &&
              <>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  marginRight: "20px",
                  background: "transparent",
                  height: "35px",
                  width: "10px",
                  color: "#3e8cb5",
                }}
                variant="outlined"
                onClick={() => {
                  acceptHandler()
                }}
              >
                Accept
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  marginRight: "20px",
                  background: "transparent",
                  height: "35px",
                  width: "70px",
                  color: "#3e8cb5",
                }}
                variant="outlined"
                onClick={handleReject}
              >
                Reject
              </Button>
              </>
              }
            </>
          )}
          <Button
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "Arial",
                  borderColor: "#3E8CB5",
                  color: "#3E8CB5",
                  background: "transparent",
                  height: "35px",
                }}
                variant="outlined"
                onClick={handleExportFunction1}
              >
                Generate  Report
              </Button>

          <AlertDialogSuccess
            isAlertOpen={bulkOpen}
            handleAlertClose={handleCloseBulk}>
            {message}
          </AlertDialogSuccess>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                boxShadow: "none",
                borderRadius: "6px",
                maxWidth: "0px",
                minWidth: "26%",
                margin: "0px",
                padding: "30px",
              },
            }}
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  color: "#333333",
                  fontSize: "14px",
                  fontFamily: "Arial",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  wordBreak: "break-word",
                  alignItems: "center",
                  overflowY: "hidden",
                }}
              >
                Are you sure you wish to accept selected performance appraisal?
              </DialogContentText>
            </DialogContent>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    borderColor: "#3E8CB5",
                    marginRight: "10px",
                    background: "transparent",
                    height: "35px",
                    width: "70px",
                    color: "#3e8cb5"
                  }}
                  variant="outlined"
                  autoFocus
                  onClick={() => {
                    bulkApproval();
                    handleClose();
                    handleClickOpen1();
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    borderColor: "#3E8CB5",
                    background: "transparent",
                    height: "35px",
                    width: "70px",
                    color: "#3e8cb5"
                  }}
                  variant="outlined"
                  onClick={handleClose}
                >
                  No
                </Button>
              </DialogActions>
            </Stack>
          </Dialog>
        </div>
      </Stack>
      <Stack
        marginLeft="24px"
        marginRight="24px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderBottom: 1, borderColor: "#E3E3E3", }}
      >
        <Tabstyles>
          <Box sx={{ maxWidth: { xs: 460, md: 730, lg: 1000 } }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label={
                  <StyledBadge badgeContent={All} color="primary" max={999}>
                    All
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "200px" }}
                label={
                  <StyledBadge
                    badgeContent={mypendingActions}
                    color="primary"
                    max={999}
                  >
                    My pending actions
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "142px" }}
                label={
                  <StyledBadge
                    badgeContent={notStartedemp}
                    color="primary"
                    max={999}
                  >
                    Not started
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "155px" }}
                label={
                  <StyledBadge
                    badgeContent={inProgressemp}
                    color="primary"
                    max={999}
                  >
                    In progress
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "190px" }}
                label={
                  <StyledBadge badgeContent={emprejected} color="primary" max={999}>
                    Employees rejection
                  </StyledBadge>
                }
              />
              <Tab
                sx={{ width: "125px" }}
                label={
                  <StyledBadge
                    badgeContent={completedemp}
                    color="primary"
                    max={999}
                  >
                    Completed
                  </StyledBadge>
                }
              />
            </Tabs>
          </Box>
        </Tabstyles>
        <div>
          <Stack direction="row" alignItems="flex-start" >
            <Searchfeild>
              <TextField
                id="outlined-basic"
                placeholder="Search Here..."
                autoComplete="off"
                value={enteredName}
                onChange={handleSearchBar}
                inputProps={{ maxLength: 256 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Searchlensreview} alt="icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </Searchfeild>
            <div>
              <img
                src={Newexcel}
                onClick={handleExportFunction}
                alt="icon"
                style={{ marginLeft: "15px", marginTop: "5px", cursor: "pointer" }}
              />
            </div>
            <Link to="/myteamtableexpandviewofNormalizer"
              state={{
                tab: tabValue,
                range: { EmployeeData },
              }} >
              <img
                src={Expand}
                alt="icon"
                style={{ marginLeft: "15px", marginTop: "5px" }}
              />
            </Link>
         <ExportToexcel 
          isDrawerOpen={isDrawerOpen}
          setisDrawerOpen={setisDrawerOpen}
          checkedCount={checkedCount}
          totalCount={totalCount}
          columnHeaders={columnHeaders}
          handleSelectAll={handleSelectAll}
          handleheadingCalendar={handleheadingCalendar}
          handleEmployeeCode={handleEmployeeCode}
          handleheading1={handleheading1}
          handlefirstName={handlefirstName}
          handleServiceReferenceDateData={handleServiceReferenceDateData}
          handleheading2={handleheading2}
          handleheading3={handleheading3}
          handleFunctionData={handleFunctionData}
          handleSupervisoryRoleData={handleSupervisoryRoleData}
          handleEmailIDData={handleEmailIDData}
          handleDivision={handleDivision}
          handleSection={handleSection}
          handleSubSection={handleSubSection}
          handleWorkLocation={handleWorkLocation}
          handleAppCodes={handleAppCodes}
          handleAppraiserName={handleAppraiserName}
          handleRevCodes={handleRevCodes}
          handleReviewerName={handleReviewerName}
          handleNorCodes={handleNorCodes}
          handleNormalizerName={handleNormalizerName}
          handleemployeerating={handleemployeerating}
          handleAppraiserRating={handleAppraiserRating}
          handleReviewerRating={handleReviewerRating}
          handleNormalizerRating={handleNormalizerRating}
          handleOverallRating={handleOverallRating}
          handlePreviousRating={handlePreviousRating}
          handlePotentialLevel={handlePotentialLevel}
          handleTalentCategory={handleTalentCategory}
          handleManagerCode={handleManagerCode}
          handleManagerName={handleManagerName}
          handleManagerPosition={handleManagerPosition}
          handleStatus={handleStatus}
          handleheadingSortAccept={handleheadingSortAccept}
          handleCloseGrade={handleCloseGrade}
          handlePendingAction={handlePendingAction}
          />   
          </Stack>
        </div>
      </Stack>
      <TableHeadings>
        <TabPanel value={tabValue} index={0}>
          <MyTeamTable
            users={EmployeeData}
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            tabValue={tabValue}
            tablecount={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            handleOnCheck={handleOnCheck}
            enteredName={enteredName}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPAStatus={getPAStatus}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MyTeamTable
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            users={EmployeeData}
            tabValue={tabValue}
            tablecount={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            handleOnCheck={handleOnCheck}
            enteredName={enteredName}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPAStatus={getPAStatus}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <MyTeamTable
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            users={EmployeeData}
            tabValue={tabValue}
            tablecount={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            handleOnCheck={handleOnCheck}
            enteredName={enteredName}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPAStatus={getPAStatus}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <MyTeamTable
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            users={EmployeeData}
            tabValue={tabValue}
            tablecount={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            handleOnCheck={handleOnCheck}
            enteredName={enteredName}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPAStatus={getPAStatus}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <MyTeamTable
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            users={EmployeeData}
            tabValue={tabValue}
            tablecount={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            handleOnCheck={handleOnCheck}
            enteredName={enteredName}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPAStatus={getPAStatus}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <MyTeamTable
            GradesFilter={GradesFilter}
            setGradesFilter={setGradesFilter}
            positionsFilter={positionsFilter}
            setpositionsFilter={setpositionsFilter}
            users={EmployeeData}
            tabValue={tabValue}
            tablecount={tablecount}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            handleOnCheck={handleOnCheck}
            enteredName={enteredName}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPAStatus={getPAStatus}
          />
        </TabPanel>
      </TableHeadings>
      <Dialog
        fullWidth
        maxWidth="md"
        open={opendialog}
        BackdropProps={{ style: { background: "#333333 !important", opacity: "10%" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "6px",
            maxWidth: "0px",
            minWidth: "26%",
            margin: "0px",
            padding: "30px",
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {reasonSelection && (
          <Alert severity="error">
            Please select atleast one employee.
          </Alert>
        )}
        <DialogContent style={{ marginTop: "30px" }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#333333",
              fontSize: "14px",
              fontFamily: "Arial",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              wordBreak: "break-word",
              alignItems: "center",
              overflowY: "hidden",
            }}
          >
            Please select rejection reason(s)
            <div style={{ textAlign: "center", marginLeft: "37%" }}>
              <Stack sx={{ textAlign: "left", marginTop: "15px" }} spacing={1}>
                <div>
                  <input
                    type="checkbox"
                    name="allselect"
                    onChange={handlecheckbox}
                    checked={
                      checkboxUser.filter(
                        (reasons: any) => reasons?.isChecked !== true
                      )?.length < 1
                    }
                  />
                  <label style={{ color: "#1976d2" }}>Select all</label>
                </div>
                {checkboxUser.map((reasons: any) => (
                  <div>
                    <input
                      type="checkbox"
                      name={reasons.reason}
                      checked={reasons?.isChecked || false}
                      onChange={handlecheckbox}
                    />
                    <label>{reasons.reason}</label>
                  </div>
                ))}
              </Stack>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            paddingBottom: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              backgroundColor: "#004D77",
              fontSize: "15px",
              color: "#FFFFFF",
              fontFamily: "regular",
              borderRadius: "5px",
            }}
            onClick={(e) => {
              handleSubmit(e);
            }}
            autoFocus
          >
            Submit
          </Button>
          <Button
            style={{
              width: "92px",
              height: "35px",
              textTransform: "none",
              fontSize: "15px",
              fontFamily: "regular",
              color: "#1976d2",
              opacity: "80%",
              padding: "4px 22px",
              border: "1px solid #1976d2",
              borderRadius: "5px",
            }}
            onClick={handleDialogNo}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Mytable>
  );
};
export default NormalizerTeamTable;