import { Alert,Stack,Button,Snackbar } from '@mui/material'
import React ,{useState} from 'react'
import { makeStyles } from '@mui/styles';
import AlertYesNo from '../../../UI/DialogYesNo'
import { Link, useNavigate } from "react-router-dom";
import AlertDialogOkCancelForUnsavedChanges from "../../../UI/DialogforunsavedChangesAlert"
import DialogWithOkButton from '../../../UI/DialogWithOkButton'
import { REVIEWER_REJECTS_PA_3RD_TIME_INFO } from '../../../../constants/AppraisalEmailContents/NotificationForInfo/Reviewer';
import { REVIEWER_REJECTS_PA, REVIEWER_REJECTS_PA_AFTER_EMPLOYEE_REJECTION } from '../../../../constants/AppraisalEmailContents/NotificationForAction/Reviewer';
import { ARE_YOU_SURE_WISH_TO_REJECT,PENDING_WITH_REVIEWER,PERFORMANCE_APPRAISAL_SUBMITTEDTO_APPRAISER,
   CHANGES_SAVED_SUCCESSFULLY, PLEASE_ADD_REJECTION_REASON,PENDING_WITH_APPRAISER_REVIEWERREJECIION,
    PLEASE_SAVE_CHANGES, 
    PENDING_WITH_REVIEWER_EMPLOYEE_REJECTION,
    LOADER_MESSAGE} from '../../../../constants/DialogContents/DialogContents';
import DialogCircularProgressLoader from '../../../UI/DialogCircularProgressLoader';

//styles for snackbar
const useStyles = makeStyles((theme) => ({
  customAlert: {
    backgroundColor: '#3e8cb5',
    color: "white",
    height: '60px !important',
    alignItems: "center",
    fontSize: "1rem"
  },
  customSnackbar: {
    paddingBottom: '16px',
    paddingRight: '16px',
  },
}));
function EmployeeReviewerRejectFooterButtons(props:any) {
    const{employeeData,updateMutation,reviewerOverallFeedComments,
        setDisableButtons,employee_id,setMoveTab,setNavPrompt,
      disableButtons,moveTab,navPrompt,setTabValue,tabValue,fetchCancel,sendEmailNotification,
      updateLoggedRole,emailData,
    } =props
    const classes = useStyles();
    const navigate = useNavigate();
    const [openYes1, setOpenYes1] = useState(false);
    const [openYes2, setOpenYes2] = useState(false);
    const [Message, setMessage] = useState("")
    const [successAlertTriger, setSuccessAlertTriger] = useState(false);
    const [successAlertTrigerMSG, setSuccessAlertTrigerMSG] = useState(CHANGES_SAVED_SUCCESSFULLY)
    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
    const [openOk, setOpenOk] = useState(false);
    const [showLoader , setShowLoader] = useState(false);

  {/* function for Closing snackbar */}
    const handleCloseSnackbar = () => {
      setSuccessAlertTriger(false)
      setSuccessAlertTrigerMSG("")
    }
  {/* function for yes button on the  Save and submit  popup  */}
    const rejectionHandler = (count: any) => {
      setShowLoader(true);
      setMessage(LOADER_MESSAGE);
      let reviewerObjDesc = employeeData?.data?.reviewer?.objective_description?.map(
        (i: any) => {
          return {
            ...i,
            action_performed: false,
          };
        }
      );
      updateMutation({
        "reviewer.objective_description": reviewerObjDesc,
        "reviewer.rejection_count": count == undefined ? 1 : count + 1,
        "reviewerIsChecked": true,
        "reviewerIsDisabled": true,
        "reviewer.reviewer_status": 'rejected',
        "reviewer.reviewer_rating": employeeData?.data?.current_rating?.overall_rating,
        "reviewer_previous_submission.objective_description": reviewerObjDesc,
        "reviewer_previous_submission.reviewer_status": 'rejected',
        "reviewer_previous_submission.reviewer_rating": employeeData?.data?.reviewer?.reviewer_rating,
        "appraiserIsDisabled": false,
        "appraisal.appraiser_status": "reviewer-rejected-employee",
        "appraisal.pa_status":PENDING_WITH_APPRAISER_REVIEWERREJECIION,
        "appraisal.pa_rating": employeeData?.data?.current_rating?.overall_rating,
        "appraisal.status": "rejected",
        "appraisal.show_reviewer": true,
        "appraisal_previous_submission.pa_status":PENDING_WITH_APPRAISER_REVIEWERREJECIION,
        "reviewer.reviewer_overall_feedback": reviewerOverallFeedComments?.trim(),
        "current_previous_submission.objective_description": employeeData?.data?.current_rating.objective_description,
        "appraisal_previous_submission.objective_description": employeeData?.data?.appraisal?.objective_description,
        "reviewer.reviewer_PA_rejected": true,
        "reviewer.reviewer_comments": "",
        "reviewer.reviewer_comments_draft": "",
        "reviewer_previous_submission.reviewer_overall_feedback": reviewerOverallFeedComments?.trim(),
        "reviewer_previous_submission.reviewer_comments": "",
        id: employee_id,
      }).then((res: any) => {
        updateLoggedRole({
          pa_action_by : `Reviewer (Rejected)(Employee Rejection) : ${new Date()}`,
          id : employee_id
        });
        setDisableButtons(false);
        setSuccessAlertTrigerMSG(PERFORMANCE_APPRAISAL_SUBMITTEDTO_APPRAISER)
        setSuccessAlertTriger(true)
        setShowLoader(false);
        setMessage("");
        setNavPrompt(false);
      })
  
      let appraiserName = employeeData?.data?.appraiser_name;
      let reviewerName = employeeData?.data?.reviewer_name;
      let employeeName = employeeData?.data?.first_name;
      let calendarName = employeeData?.data?.calendar?.calendar_type;
      let calendarYear = employeeData?.data?.calendar?.start_date?.slice(0, 4);
      let dueDate = employeeData?.data?.calendar?.end_date_employee_acknowledgement?.slice(0, 10);
      let employeeCode = employeeData?.data?.employee_code
      let appraiserFirstName = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.appraiser_code)?.firstName;
  
      // Notification action Reviewer rejects Employee rejected PA (overall rating difference greater than 0.3) (For Appraiser)
      let tempSubject = REVIEWER_REJECTS_PA_AFTER_EMPLOYEE_REJECTION?.subject;
      tempSubject = tempSubject?.replace("[year]", `${calendarYear}`);
      tempSubject = tempSubject?.replace("[calendar name]", `${calendarName}`);
      tempSubject = tempSubject?.replace("[employee name]", `${employeeName}`);
      tempSubject = tempSubject?.replace("[employee code]", `${employeeCode}`);
  
      let tempHtml = REVIEWER_REJECTS_PA_AFTER_EMPLOYEE_REJECTION?.html;
      tempHtml = tempHtml?.replace("[year]", `${calendarYear}`);
      tempHtml = tempHtml?.replace("[calendar name]", `${calendarName}`);
      tempHtml = tempHtml?.replace("[employee name]", `${employeeName}`);
      tempHtml = tempHtml?.replace("[employee code]", `${employeeCode}`);
      tempHtml = tempHtml?.replace("[Reviewer name]", `${reviewerName}`);
      tempHtml = tempHtml?.replace("[Appraiser FirstName]", `${appraiserFirstName}`);
  
  
      let appraiserEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.appraiser_code)?.email
      let email = REVIEWER_REJECTS_PA_AFTER_EMPLOYEE_REJECTION?.to;
      email = email?.replace("[email]", `${appraiserEmail}`);
  
      sendEmailNotification(
        {
          to: email,
          subject: tempSubject,
          html: tempHtml
        }
      )
    }

    
    {/* function for Save as Draft */}
    const saveHandler = () => {
        setDisableButtons(true);
        // setMoveTab(false);
        updateMutation({
          "reviewer.reviewer_status": 'draft',
          "reviewer.reviewer_overall_feedback": reviewerOverallFeedComments?.trim(),
          "appraisal.pa_status": PENDING_WITH_REVIEWER_EMPLOYEE_REJECTION,
          id: employee_id,
        }).then((res : any) => {
          setMoveTab(false);
          setNavPrompt(false);
          setDisableButtons(false);
          //new alert 
          setSuccessAlertTrigerMSG(CHANGES_SAVED_SUCCESSFULLY)
          setSuccessAlertTriger(true)
        })
      }
      {/* function for Back Button */}
      const backHandler = () => {
        if (moveTab === true) {
          setOpenOk(true);
          setMessage(PLEASE_SAVE_CHANGES)
        }
        else {
          setTabValue(0);
        }
      }
      {/* function for Cancel Button */}
      const CancelButtonHnadler = () => {
        if (navPrompt == true || moveTab == true) {
          setOpenCancelDialog(true);
        }else if (!navPrompt || !moveTab && tabValue == 0){
          navigate(`/reviewer`, { state: { from: `${1}` } })
        }
        else if (!navPrompt && !moveTab && tabValue == 2){
          setTabValue(1);
        }else if (!navPrompt && !moveTab && tabValue == 1) {
          setOpenCancelDialog(false);
          setTabValue(0);
        }
      }
      const handleCancel = () => {
        if (navPrompt == true || moveTab == true) {
          setOpenCancelDialog(true);
        } else {
          setOpenCancelDialog(false);
          if (tabValue == 2) {
            setTabValue(1)
          } else if (tabValue == 1) {
            setTabValue(0)
          } else {
            navigate(`/reviewer`, { state: { from: `${1}` } })
          }
        }
      }
      
      const cancelRatingChanges = () => {
        setMoveTab(false);
        setNavPrompt(false);
        setOpenCancelDialog(false);
        fetchCancel().then((res: any) => {
          navigate(`/reviewer`, { state: { from: `${1}` } })
        });
      }
    
      const cancelOverallFeedbackChanges = () => {
        setMoveTab(false);
        setNavPrompt(false);
        setOpenCancelDialog(false);
        fetchCancel().then((res: any) => setTabValue(0))
      }
    
      const cancelPerformanceGoalsChanges = () => {
        setMoveTab(false);
        setNavPrompt(false);
        setOpenCancelDialog(false);
        fetchCancel().then((res: any) => setTabValue(1))
      }
        {/* function for Cancel Button alert popup Yes */ }
      const handleToAcceptCancel = () => {
        if (tabValue == 0) { cancelRatingChanges() }
        if (tabValue == 1) { cancelOverallFeedbackChanges() }
        if (tabValue == 2) { cancelPerformanceGoalsChanges() }
      }
      {/* function for Cancel Button alert popup No */}
      const rejectCancelButtonHandler = () => {
        setOpenCancelDialog(false);
      }
      {/* function for Cancel Button alert popup Yes */}
      const acceptCancelButtonHandler = () => {
        setMoveTab(false);
        setNavPrompt(false);
        setOpenCancelDialog(false);
        if(tabValue == 0){
        fetchCancel().then((res:any) =>  navigate(`/reviewer`, { state: { from: 1 } }) );
        }else{
        fetchCancel()
        setTabValue(0);
        }
      }
        {/* function for Save as Draft */}
         const saveAsDraftReviewerDetails = () => {
        updateMutation({
            "reviewer.reviewer_overall_feedback": reviewerOverallFeedComments?.trim(),
            id: employee_id,
            });
            setMoveTab(false);
          setNavPrompt(false);   
        }
      {/* function for Save and Submit */}
      const submitHandler = () => {
        setDisableButtons(true);
        if (reviewerOverallFeedComments?.trim() == undefined || reviewerOverallFeedComments?.trim() == null || reviewerOverallFeedComments?.trim() == "") {
        //   setOpenYes(false);
          setOpenYes1(false);
          setOpenYes2(true);
          setMessage(PLEASE_ADD_REJECTION_REASON)
        }
        else {
          saveAsDraftReviewerDetails();
          setOpenYes1(true);
          setMessage(ARE_YOU_SURE_WISH_TO_REJECT)
        }
      }
      {/* function for Ok button on please save the changes */}
      const handleAlertClose = () => {
        setOpenOk(false);
        setMessage("")
      }
      {/* function for no button for Save and submit popup */}
      const handleAlertClose1 = () => {
        setOpenYes1(false);
        setMessage("");
        setDisableButtons(false);
      }
      {/* function for yes button on the  Save and submit  popup  */}
      const handleAlertYes1 = () => {
        setOpenYes1(false);
        rejectionHandler(employeeData?.data?.reviewer?.rejection_count);       
      }
      {/* function for Plese add rejection reason popup ok buttton */}
      const handleAlertClose2 = () => {
        setOpenYes2(false);
        setMessage("");
        setDisableButtons(false);
      }
  return (
    <>
      <Stack justifyContent="center" spacing={2} direction="row">
    {employeeData?.data?.appraisal?.pa_status?.includes(PENDING_WITH_REVIEWER) &&
          <>
            <Button
              variant="outlined"
              disabled = {disableButtons}
              style={{
                backgroundColor: "Transparent",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "none",
                color: "#3e8cb5",
                borderColor: "#3E8CB5",
                height: "35px",
                fontFamily: "arial",
                ...((disableButtons ) && {
                  cursor: "not-allowed",
                  borderColor: "#ccc",
                  color:  "#ccc",
                }),
              }}
              onClick={() => {
                saveHandler()

              }}
            >
              Save as Draft
            </Button>
            <Button
              variant="outlined"
              disabled={disableButtons}
              style={{
                height: "35px",
                fontSize: "15px",
                textTransform: "none",
                fontFamily: "Arial",
                backgroundColor: "Transparent",
                color: "#3e8cb5",
                borderColor: "#3E8CB5",
                ...((disableButtons ) && {
                  cursor: "not-allowed",
                  borderColor: "#ccc",
                  color:  "#ccc",
                }),
              }}
              onClick={() => {
                submitHandler();

              }}
            >
              Save and Submit
            </Button>
            {tabValue >0 &&
            <Button
            disabled = {disableButtons}
              variant="outlined"
              style={{
                backgroundColor: "Transparent",
                fontSize: "15px",
                textTransform: "none",
                color: "#3e8cb5",
                borderColor: "#3E8CB5",
                height: "35px",
                width: "70px",
                fontWeight: 400,
                fontFamily: "arial",
                ...((disableButtons ) && {
                  cursor: "not-allowed",
                  borderColor: "#ccc",
                  color:  "#ccc",
                }),
              }}
              onClick={() => { backHandler() }}
            >
              Back
            </Button>
            }
            <Button
               disabled = {disableButtons}
              variant="outlined"
              style={{
                backgroundColor: "Transparent",
                fontSize: "15px",
                textTransform: "none",
                color: "#3e8cb5",
                borderColor: "#3E8CB5",
                height: "35px",
                width: "70px",
                fontWeight: 400,
                fontFamily: "arial",
                ...((disableButtons ) && {
                  cursor: "not-allowed",
                  borderColor: "#ccc",
                  color:  "#ccc",
                }),
              }}
              onClick={() => { handleCancel() }}
            >
              Cancel

            </Button>
          </>
        }
         <Snackbar
          className={classes.customSnackbar}
          open={successAlertTriger}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            className={classes.customAlert}
            onClose={handleCloseSnackbar}
            sx={{ width: '100%' }}
            icon={false}
          >
            <b>{successAlertTrigerMSG}</b>
          </Alert>
        </Snackbar>
        {/* Alert for Are you sure you wish to cancel the changes */}
        <AlertDialogOkCancelForUnsavedChanges
          isAlertOpen={openCancelDialog}
          handleAlertClose={rejectCancelButtonHandler}
          handleAlertIdClose={handleToAcceptCancel}
        >
        </AlertDialogOkCancelForUnsavedChanges>
        {/*Alert for please add rejection reason */}
        <DialogWithOkButton
        isAlertOpen={openYes2}
        handleAlertClose={handleAlertClose2}>
        {Message}
      </DialogWithOkButton>
        {/* please save the changes while clicking back button */}
      <DialogWithOkButton
        isAlertOpen={openOk}
        handleAlertClose={handleAlertClose}>
        {Message}
      </DialogWithOkButton>
      {/*Alert for Are you sure you wish to Reject the performance Appraisal? */}
        <AlertYesNo
          isAlertOpen={openYes1}
          handleAlertClose={handleAlertClose1}
          handleAlertYes={handleAlertYes1}>
          {Message}
        </AlertYesNo>

        <DialogCircularProgressLoader 
        isAlertOpen = {showLoader}>
          {Message}
        </DialogCircularProgressLoader>
        </Stack>
    </> 
  )
}

export default EmployeeReviewerRejectFooterButtons