import { TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import CircularProgress from "@mui/material/CircularProgress";

// Define common styles for TableCell
const tableCellStyle = {
    fontFamily: "Arial",
    borderColor: "#F7F9FB",
    color: "#3E8CB5",
    fontSize: "14px",
    fontWeight: "600",
};

const RatingsHeader = (props: any) => {
    const { visiblityColumnData, isUploadingAttachment, isLoadingAction, isLoadingRejection } = props;
    return (
        <>
            <TableHead>
                <TableRow
                    sx={{
                        "& td, & th": {
                            bgcolor: "#eaeced",
                        },
                    }}>
                    <TableCell
                        sx={tableCellStyle}
                        align="center"
                    >
                        Objective <br></br>Type
                    </TableCell>
                    <TableCell
                        sx={tableCellStyle}
                        align="center"
                    >
                        Objective <br></br>Title
                    </TableCell>
                    <TableCell
                        sx={{
                            ...tableCellStyle,
                            padding: "0px 8px"
                        }}
                        align="center"
                    >
                        Objective <br></br>Level
                    </TableCell>
                    <TableCell
                        sx={{
                            ...tableCellStyle,
                            padding: "0px 8px"
                        }}
                        align="center"
                    >
                        Appraiser <br /> Rating
                    </TableCell>
                    {visiblityColumnData?.showAppraiserComments && (
                        <TableCell
                            sx={tableCellStyle}
                            align="center"
                        >
                            Appraiser <br></br>Comments
                        </TableCell>
                    )}
                    {visiblityColumnData?.showAppraiserAttachments && (
                        <TableCell
                            sx={tableCellStyle}
                        >
                            Attachment
                            {isUploadingAttachment && (
                                <CircularProgress size={15} thickness={7} />
                            )}
                        </TableCell>
                    )}
                    {visiblityColumnData?.showAppraiserRejectionReason && (
                        <TableCell
                            sx={tableCellStyle}
                            align="center"
                        >
                            Appraiser <br></br>Rejection/Change Reason
                        </TableCell>
                    )}
                    
                    {visiblityColumnData?.showEmployeeRating && (
                        <>
                            <TableCell
                                sx={{
                                    ...tableCellStyle,
                                    padding: "0px 8px"
                                }}
                                align="center"
                            >
                                Employee<br /> Rating
                            </TableCell>

                            <TableCell
                                sx={tableCellStyle}
                                align="center"
                            >
                                Employee<br /> Rejection Reason
                            </TableCell>
                        </>
                    )}
                    {visiblityColumnData?.showAppraiserAction && (
                        <TableCell
                            sx={tableCellStyle}
                            align="center"
                        >
                            Appraiser<br></br> Actions
                            {isLoadingAction && (
                                <CircularProgress size={15} thickness={7} />
                            )}
                        </TableCell>
                    )}
                   {visiblityColumnData?.showEmployeeAction && ( <TableCell
                        sx={{
                            fontFamily: "Arial",
                            borderColor: "#F7F9FB",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",
                            padding: "0px 8px"
                        }}
                        align="center"
                    >
                        Employee <br />
                        Actions
                        {isLoadingRejection && (
                            <CircularProgress size={15} thickness={7} />
                        )}
                    </TableCell>)}
                    {visiblityColumnData?.showNormalizerRating && (
                        <>
                            <TableCell
                                sx={{
                                    ...tableCellStyle,
                                    padding: "0px 8px"
                                }}
                                align="center"
                            >
                                Re-normalized<br /> Rating
                            </TableCell>
                            {visiblityColumnData?.showNormalizerRating && (
                                <TableCell
                                    sx={tableCellStyle}
                                    align="center"
                                >
                                    HR Normalizer<br /> Comments
                                </TableCell>
                            )}
                        </>
                    )}
                    {visiblityColumnData?.showNormalizerAction && (
                        <TableCell
                            sx={tableCellStyle}
                            align="center"
                        >
                            HR Normalizer<br /> Action
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>

        </>
    )
}

export default RatingsHeader