import React from 'react'
import FeedbackQuestionnaireEdit from '../overallFeedback/FeedbackQuestionnaireEdit'
import { Typography } from '@mui/material';
import AreaOfImprovementEdit from '../overallFeedback/AreaOfImprovementEdit';
import TrainingRecommendationEdit from '../overallFeedback/TrainingRecommendationEdit';
import AppraiserFurtherRecommendationEdit from '../overallFeedback/AppraiserFurtherRecommendationEdit';
import AppraiserMessageForEmployeeEdit from '../overallFeedback/AppraiserMessageForEmployeeEdit';
import FeedbackQuestionnaireView from '../overallFeedback/FeedbackQuestionnaireView';
import AreaOfImprovementView from '../overallFeedback/AreaOfImprovementView';
import AppraiserFurtherRecommendationView from '../overallFeedback/AppraiserFurtherRecommendationView';
import TrainingRecommendationView from '../overallFeedback/TrainingRecommendationView';
import ReviewerRejectionReasonView from '../overallFeedback/ReviewerRejectionReasonView';
import OneToOneMeetingDateView from '../overallFeedback/OneToOneMeetingDateView';
import ReviewerCommentsView from '../overallFeedback/ReviewerCommentsView';
import NormalizerCommentsView from '../overallFeedback/NormalizerCommentsView';
import NormalizerRejectionReasonView from '../overallFeedback/NormalizerRejectionReasonView';
import EmployeeCommentsView from '../overallFeedback/EmployeeCommentsView';

const OverallFeedbackTab = (props: any) => {
    const { showIfAppraiserOverview,
        showIfAppraiserresubmission,
        showIfAppraiserAction,
        disableTextAfterSubmission,
        setDisableTextAfterSubmission,
        setFeedbackQuestionnaire,
        feedbackQuestionnaire,
        appraiserFeedbackQuestionnaire,
        setAppraiserFeedbackQuestionnaire,
        feedbackQuestionnaireData,
        moveTab,
        setMoveTab,
        navPrompt,
        setNavPrompt,
        areaOfImprovement,
        employeeAreaOfImprovement,
        appraiserAreaOfImprovement,
        setAppraiserAreaOfImprovement,
        employeeTrainingRecommendation,
        appraiserTrainingRecommendation,
        setAppraiserTrainingRecommendation,
        appraisalTemplateTrainingRecommendation,
        appraiserFurtherRecommendation,
        setAppraiserFurtherRecommendation,
        appraiserMessageForEmployee,
        setAppraiserMessageForEmployee,
        showAppraiserMessageForEmployee,
        appraiserFurtherRecommendationOthers,
        setAppraiserFurtherRecommendationOthers,
        appraiserFurtherRecommendationOthersCheckbox,
        setAppraiserFurtherRecommendationOthersCheckbox,
        showAppraiserTrainingRecommendation,
        showemployeeTrainingRecommendation,
        showAppraiserAreaOfImprovement,
        showEmployeeAreaOfImprovement,
        showreviewerComments,
        reviewerComments,
        showNormalizerComments,
        normalizerComments,
        normalizerRejectionReason,
        shownormalizerRejectionReason,
        employeeComments,
        showEmployeeComments,
        showoneToOneMeetingDate,
        oneToOneMeetingDate,
        employeeData,
        furtherRecommendation,
        TrainingRecommendation,
        employeeDataIsLoading,
        employeeDataIsFetching
    } = props;
    let showIfAppraiser = true
    let showIfEmployee = true
    let showIfAppraiserResubmissionEmployeeRejection = employeeData?.data?.appraisal?.status == "rejected";
    console.log(showAppraiserAreaOfImprovement,showEmployeeAreaOfImprovement, "employeeAreaOfImprovement")
    return (
        <>
            <Typography
                style={{
                    marginTop: "20px",
                    fontSize: "20px",
                    color: "#3E8CB5",
                    fontFamily: "Arial",
                    marginBottom: "20px",
                    // marginLeft: "36px"
                }}
            >
                Performance Appraisal Summary
            </Typography>

            <div
            // style={{ marginRight: "65px", marginLeft: "36px" }}
            >
                {((showIfAppraiserOverview) || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status !== "rejected")) &&
                    <FeedbackQuestionnaireEdit
                        appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
                        setAppraiserFeedbackQuestionnaire={setAppraiserFeedbackQuestionnaire}
                        feedbackQuestionnaireData={feedbackQuestionnaireData}
                        feedbackQuestionnaire={feedbackQuestionnaire}
                        setFeedbackQuestionnaire={setFeedbackQuestionnaire}
                        disableTextAfterSubmission={disableTextAfterSubmission}
                        setDisableTextAfterSubmission={setDisableTextAfterSubmission}
                        moveTab={moveTab}
                        setMoveTab={setMoveTab}
                        navPrompt={navPrompt}
                        setNavPrompt={setNavPrompt}
                        employeeDataIsLoading={employeeDataIsLoading}
                        employeeDataIsFetching={employeeDataIsFetching}
                    />}
                {((showIfAppraiserAction) || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected")) &&
                    <FeedbackQuestionnaireView
                        employeeData={employeeData}
                        appraiserFeedbackQuestionnaire={feedbackQuestionnaire}
                    />
                }
                {(showAppraiserAreaOfImprovement && (showIfAppraiserAction || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected"))) &&
                    <AreaOfImprovementView
                        showIfAppraiser={showIfAppraiser}
                        areaOfImprovement={areaOfImprovement}
                    />}
                {(showEmployeeAreaOfImprovement && (showIfAppraiserAction || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected"))) &&
                    <AreaOfImprovementView
                        showIfEmployee={showIfEmployee}
                        areaOfImprovement={employeeAreaOfImprovement}
                    />}
                {(showAppraiserTrainingRecommendation && (showIfAppraiserAction || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected"))) &&
                    <TrainingRecommendationView
                        showIfAppraiser={showIfAppraiser}
                        trainingRecommendation={TrainingRecommendation} />
                }
                {(showemployeeTrainingRecommendation && (showIfAppraiserAction || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected"))) &&
                    <TrainingRecommendationView
                        showIfEmployee={showIfEmployee}
                        trainingRecommendation={employeeTrainingRecommendation} />
                }
                {(showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected") &&
                    <AppraiserFurtherRecommendationView
                        furtherRecommendation={furtherRecommendation}
                        furtherRecommendationOthers={appraiserFurtherRecommendationOthers} />
                }
                {((showIfAppraiserAction && showreviewerComments) || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected" && showreviewerComments)) &&
                    <ReviewerCommentsView
                        showReviewerComments={showreviewerComments}
                        reviewerComments={reviewerComments} />
                }
                {(showIfAppraiserAction && showNormalizerComments) && (
                    <NormalizerCommentsView
                        showNormalizerComments={showNormalizerComments}
                        normalizerComments={normalizerComments} />
                )}
                {((showIfAppraiserAction && shownormalizerRejectionReason) || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected" && shownormalizerRejectionReason)) &&
                    (
                        <NormalizerRejectionReasonView
                            normalizerRejectionReason={normalizerRejectionReason}
                        />
                    )}
                {(showIfAppraiserAction && showEmployeeComments || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected")) &&
                    <EmployeeCommentsView
                        employeeComments={employeeComments} />
                }
                {(showIfAppraiserAction && showoneToOneMeetingDate || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status == "rejected")) &&
                    <OneToOneMeetingDateView oneToOneMeetingDate={oneToOneMeetingDate} />
                }
                {(showIfAppraiserOverview || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status !== "rejected")) &&
                    <AreaOfImprovementEdit
                        showIfAppraiserresubmission={showIfAppraiserresubmission}
                        showIfAppraiserOverview={showIfAppraiserOverview}
                        areaOfImprovement={appraiserAreaOfImprovement}
                        setAreaOfImprovement={setAppraiserAreaOfImprovement}
                        moveTab={moveTab}
                        setMoveTab={setMoveTab}
                        navPrompt={navPrompt}
                        setNavPrompt={setNavPrompt}
                        disableTextAfterSubmission={disableTextAfterSubmission}
                        employeeDataIsLoading={employeeDataIsLoading}
                        employeeDataIsFetching={employeeDataIsFetching} />
                }
                {(showIfAppraiserOverview || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status !== "rejected")) &&
                    <TrainingRecommendationEdit
                        showIfAppraiserresubmission={showIfAppraiserresubmission}
                        showIfAppraiserOverview={showIfAppraiserOverview}
                        trainingRecommendation={appraiserTrainingRecommendation}
                        setTrainingRecommendation={setAppraiserTrainingRecommendation}
                        appraisalTemplateTrainingRecommendation={appraisalTemplateTrainingRecommendation}
                        disableTextAfterSubmission={disableTextAfterSubmission}
                        moveTab={moveTab}
                        setMoveTab={setMoveTab}
                        navPrompt={navPrompt}
                        setNavPrompt={setNavPrompt}
                        employeeDataIsLoading={employeeDataIsLoading}
                        employeeDataIsFetching={employeeDataIsFetching} />
                }
                {(showIfAppraiserOverview || (showIfAppraiserresubmission && employeeData?.data?.appraisal?.status !== "rejected")) &&
                    <AppraiserFurtherRecommendationEdit
                        appraiserFurtherRecommendation={appraiserFurtherRecommendation}
                        setAppraiserFurtherRecommendation={setAppraiserFurtherRecommendation}
                        disableTextAfterSubmission={disableTextAfterSubmission}
                        moveTab={moveTab}
                        setMoveTab={setMoveTab}
                        navPrompt={navPrompt}
                        setNavPrompt={setNavPrompt}
                        appraiserFurtherRecommendationOthers={appraiserFurtherRecommendationOthers}
                        setAppraiserFurtherRecommendationOthers={setAppraiserFurtherRecommendationOthers}
                        appraiserFurtherRecommendationOthersCheckbox={appraiserFurtherRecommendationOthersCheckbox}
                        setAppraiserFurtherRecommendationOthersCheckbox={setAppraiserFurtherRecommendationOthersCheckbox}
                        employeeDataIsLoading={employeeDataIsLoading}
                        employeeDataIsFetching={employeeDataIsFetching} />
                }
                <AppraiserMessageForEmployeeEdit
                    disableTextAfterSubmission={disableTextAfterSubmission}
                    moveTab={moveTab}
                    setMoveTab={setMoveTab}
                    navPrompt={navPrompt}
                    setNavPrompt={setNavPrompt}
                    appraiserMessageForEmployee={appraiserMessageForEmployee}
                    setAppraiserMessageForEmployee={setAppraiserMessageForEmployee}
                    showAppraiserMessageForEmployee={showAppraiserMessageForEmployee}
                    employeeDataIsLoading={employeeDataIsLoading}
                    employeeDataIsFetching={employeeDataIsFetching}
                />
            </div>

        </>
    )
}

export default OverallFeedbackTab