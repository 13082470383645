
const PLEASE_SAVE_CHANGES = "Please save the changes before leaving the page."
const CHANGES_SAVED_SUCCESSFULLY = "Changes were successfully saved."
const PROMPT ="Any changes you have made will not be saved if you leave the page."
const ARE_YOU_SURE_WISH_TO_REJECT = "Are you sure you wish to reject the performance appraisal?"
const ARE_YOU_SURE_WISH_TO_SUBMIT ="Are you sure you wish to accept the performance appraisal?"
const PERFORMANCE_APPRAISAL_SUBMITTEDTO_APPRAISER ="The performance appraisal was submitted to the Appraiser."
const PERFORMANCE_APPTAISAL_SUBMITTEDTO_NORMALIZER="The performance appraisal was submitted to the HR Normalizer."
const PERFORMANCE_APPTAISAL_SUBMITTEDTO_EMPLOYEE ="The performance appraisal was submitted to the Employee."
const NORMALIZED_AND_COMPLETED ="The performance appraisal was normalized and completed."
// const MISSING_DETAILS_IN_AREA_OF_IMPROVEMENT = 
const PLEASE_ADD_REJECTION_REASON ="Please add rejection reason."

const PENDING_WITH_APPRAISER_NORMALIZERREJECIION="Pending with Appraiser (HR Normalizer Rejection)"
const PENDING_WITH_APPRAISER_REVIEWERREJECIION ="Pending with Appraiser (Reviewer Rejection)"
const PEDNING_WITH_NORMALIZER ="Pending with HR Normalizer"
const PENDING_WITH_REVIEWER_EMPLOYEE_REJECTION ="Pending with Reviewer(Employee Rejection)"
const PENDING_WITH_REVIEWER ="Pending with Reviewer"
const PENDING_WITH_APPRAISER ="Pending with Appraiser"
const PERFORMANCE_GOAL_VALUE="Performance Goals value is empty"
const LOADER_MESSAGE = "Changes are being saved. Please don't close or navigate to a different screen."
export {
    PROMPT,
    ARE_YOU_SURE_WISH_TO_SUBMIT,
    ARE_YOU_SURE_WISH_TO_REJECT,
    PLEASE_SAVE_CHANGES,
    CHANGES_SAVED_SUCCESSFULLY,
    PLEASE_ADD_REJECTION_REASON,
    PENDING_WITH_APPRAISER_NORMALIZERREJECIION,
    PERFORMANCE_APPRAISAL_SUBMITTEDTO_APPRAISER,
    PERFORMANCE_APPTAISAL_SUBMITTEDTO_EMPLOYEE,
    PERFORMANCE_APPTAISAL_SUBMITTEDTO_NORMALIZER,
    NORMALIZED_AND_COMPLETED,
    PENDING_WITH_APPRAISER_REVIEWERREJECIION,
    PEDNING_WITH_NORMALIZER,
    PENDING_WITH_REVIEWER,
    PENDING_WITH_APPRAISER,
    PERFORMANCE_GOAL_VALUE,
    PENDING_WITH_REVIEWER_EMPLOYEE_REJECTION,
    LOADER_MESSAGE
}