import React, { useEffect, useState } from 'react'
import { useEmployeeContext } from '../../../../context/appraisal/EmployeeContext'
import Header from '../../header/Header'
import { styled } from "@mui/material/styles";
import { Box, Grid, IconButton, Popover, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import Infoicon from "../../../../assets/Images/Infoicon.svg";
import Infowhiteicon from "../../../../assets/Images/Infowhiteicon.svg";
import { useCreateEmployeeAppraisalMutation, useGetRatingScaleQuery } from '../../../../service';
import { Scrollbar } from "react-scrollbars-custom";
import RatingsTab from '../../ratings/RatingsTab';
import DialogWithOkButton from '../../../UI/DialogWithOkButton';
import { PENDING_WITH_APPRAISER, PLEASE_SAVE_CHANGES } from '../../../../constants/DialogContents/DialogContents';
import { useAppraiserContext } from '../../../../context/appraisal/AppraiserContext';
import OverallFeedbackTab from '../OverallFeedbackTab';
import PotentialEdit from '../../potential/potentialEdit';
import PerformanceAppraisalPeriod from '../../performanceAppraisalPeriod/PerformanceAppraisalPeriod';
import AppraiserSubmissionRatings from '../appraiserRatings/AppraiserSubmissionRatings';
import PerformanceGoalsTab from '../PerformanceGoalsTab';
import AppraiserFooterButton from '../../viewPA/AppraiserFooterButton';
import AppraiserOverviewFooterButtons from '../footerButtons/AppraiserOverviewFooterButtons';
import AppraiserResubmissionRatings from '../appraiserRatings/AppraiserResubmissionRatings';
import AppraiserResubmissionFooterButtons from '../footerButtons/AppraiserResubmissionFooterButtons';
import ReviewerRejectionReasonView from '../../overallFeedback/ReviewerRejectionReasonView';
import BreadCrumbs from '../../breadcrumbs/Breadcrumbs';
import NormalizerRejectionReasonView from '../../overallFeedback/NormalizerRejectionReasonView';
import { VIEWPA } from '../../../../constants/routes/Routing';
import { useNavigate } from 'react-router-dom';
// import OverallFeedBackTab from './OverallFeedBackTab';
// import PerformanceGoalsTab from './PerformanceGoalsTab';
import _ from 'lodash';

const Heading1 = styled("div")({
  fontSize: "24px",
  fontWeight: 400,
  color: "#004C75",
  marginLeft: "25px",
  // marginTop: "20px",
  fontFamily: "Arial",
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanel2(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
});

const AppraiserResubmission = (props: any) => {
  const {
    employee_id,
    PAStatus,
    objectiveDescription,
    setObjectiveDescription,
    TrainingRecommendation,
    employeeTrainingRecommendation,
    appraisalTemplateTrainingRecommendation,
    appraiserAreaOfImprovement,
    appraiserFeedbackQuestionnaire,
    appraiserFurtherRecommendation,
    appraiserFurtherRecommendationOthers,
    appraiserFurtherRecommendationOthersCheckbox,
    appraiserMessageForEmployee,
    appraiserTrainingRecommendation,
    colorarray,
    disableTextAfterSubmission,
    employeeData,
    employeeDetails,
    feedbackQuestionnaire,
    feedbackQuestionnaireData,
    message,
    moveTab,
    navPrompt,
    nineBoxData,
    overallRating,
    potential,
    ratingData,
    ratingDefinition,
    ratingScaleDefinition,
    setAppraiserAreaOfImprovement,
    setAppraiserFeedbackQuestionnaire,
    setAppraiserFurtherRecommendation,
    setAppraiserFurtherRecommendationOthers,
    setAppraiserFurtherRecommendationOthersCheckbox,
    setAppraiserMessageForEmployee,
    setAppraiserTrainingRecommendation,
    setDisableTextAfterSubmission,
    setFeedbackQuestionnaire,
    setMessage,
    setMoveTab,
    setNavPrompt,
    setPotential,
    setTabValue,
    showAppraiserMessageForEmployee,
    appraiserPerformanceGoals,
    setAppraiserPerformanceGoals,
    showPotentialEdit,
    tabValue,
    goalsCategoryData,
    attachedFiles,
    setAttachedFiles,
    appraiserRatingPreviousSubmission,
    disableButtons,
    setDisableButtons,
    refetchEmployeeData,
    employeeDataIsFetching,
    employeeDataIsLoading,
    updateMutation,
    employeeDataIsUpdating,
    reviewerRejectionReason,
    showReviewerRejectionReason,
    feedbackQuestionnaireeData,
    showreviewerComments,
    reviewerComments,
    normalizerComments,
    showNormalizerComments,
    shownormalizerRejectionReason,
    normalizerRejectionReason,
    employeeComments,
    showEmployeeComments,
    oneToOneMeetingDate,
    showoneToOneMeetingDate,
    createMutation,
    performanceGoalsFieldValuesEmployee,
    showPerformanceGoalsFieldValuesEmployee,
    showPerformanceGoalsFieldValues,
    performanceGoalsFieldValues
  } = useAppraiserContext()
  let showIfAppraiserOverview = true;
  let navigate = useNavigate()
  let NAVIGATION_FROM = "Appraiser-submission";
  const [visiblityColumnData, setVisiblityColumnData] = useState({
    showAppraiserComments: false,
    showAppraiserAction: false,
    showAppraiserRejectionReason: false,
  });

  const CustomScrollbar = Scrollbar as any;
  const [openAlertOK, setOpenAlertOK] = useState(false);


  // mutations
  const [updateAction, { isLoading: isLoadingAction }] = useCreateEmployeeAppraisalMutation();


  // Popovers
  const [anchorRatingInfo, setAnchorRatingInfo] = React.useState<HTMLButtonElement | null>(null);
  const openRatingInfo = Boolean(anchorRatingInfo);
  const idRatingInfo = openRatingInfo ? "simple-popover" : undefined;
  const [showAppraiserAreaOfImprovement, setShowAppraiserAreaOfImprovement] = useState(false);
  const [areaOfImprovement, setAreaOfImprovement] = useState([]);
  const [employeeAreaOfImprovement, setEmployeeAreaOfImprovement] = useState([]);
  const [showEmployeeAreaOfImprovement, setShowEmployeeAreaOfImprovement] = useState(false);

  const handleClickOpenRatingInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingInfo(event.currentTarget);
  };

  const handleCloseRatingInfo = () => {
    setAnchorRatingInfo(null);
  };
    // group Area of Improvement by specific Area
    const groupSpecificAreaHandler = (area: any) => {
      if (area) {
          let tempArea = area?.filter((area: any) => {
              return area[0] !== "" && area[0] !== undefined
          })
          if (tempArea && tempArea?.length > 0) {
              return {
                  showArea: true,
                  area: area,
              }
          } else {
              return {
                  showArea: false
              }
          }
      }
  };
  // Functions
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (moveTab === true) {
      //open alert for saving 
      setOpenAlertOK(true);
      setMessage(PLEASE_SAVE_CHANGES);
    } else {
      setTabValue(newValue);
    }
  };

  const handleAlertOKClose = () => {
    setOpenAlertOK(false);
    setMessage("");
  }

  // useEffect 
  useEffect(() => {
    setVisiblityColumnData({
      ...visiblityColumnData,
      showAppraiserComments: (employeeData?.data?.appraisal?.objective_description?.filter((item: any) =>
        item.comments !== "" && item.comments !== undefined)?.length > 0),
      showAppraiserAction: employeeData?.data?.appraisal?.pa_status?.includes("Pending with Appraiser"),
      showAppraiserRejectionReason: (employeeData?.data?.appraisal?.objective_description?.filter
        ((item: any) => ((item?.rating_rejected == true || item?.rating_resubmitted == true) && (item.rejection_reason !== "" && item.rejection_reason !== undefined)))?.length > 0),

    })
    let tempAppraiserAreaofImprovement = employeeData?.data?.appraisal?.area_of_improvement;
        const groupBySpecificAppraiser = _.groupBy(tempAppraiserAreaofImprovement, "value");
        const groupHandlerAppraiser = groupSpecificAreaHandler(Object.entries(groupBySpecificAppraiser));
        if (groupHandlerAppraiser?.showArea) {
          setShowAppraiserAreaOfImprovement(true);
          setAreaOfImprovement(groupHandlerAppraiser?.area)
        } else {
          setShowAppraiserAreaOfImprovement(false);
        }
        let tempEmployeeAreaofImprovement = employeeData?.data?.employee?.area_of_improvement;
        const groupBySpecificEmployee= _.groupBy(tempEmployeeAreaofImprovement, "value");
        const groupHandlerEmployee= groupSpecificAreaHandler(Object.entries(groupBySpecificEmployee));
        if (groupHandlerEmployee?.showArea) {
            setShowEmployeeAreaOfImprovement(true);
            setEmployeeAreaOfImprovement(groupHandlerAppraiser?.area)
        } else {
            setShowEmployeeAreaOfImprovement(false);
        }
  }, [employeeData])

  useEffect(() => {
    if (employeeData) {
      if (!employeeData?.data?.appraisal?.pa_status?.includes(PENDING_WITH_APPRAISER)) {
        navigate(`${VIEWPA}/employee/${employee_id}`)
      }
    }
  }, [employeeData])

console.log(employeeData,"employeeData")
  return (
    <>
      <div
        style={{
          backgroundColor: "#F1F1F1",
          height: "auto",
          minHeight: "100px",
          overflow: "hidden",
        }}>
        <BreadCrumbs
          showIfAppraiser={showIfAppraiserOverview}
          employeeData={employeeData} />
        <Box
          style={{ padding: "35px", marginRight: "25px", marginLeft: "25px", background: "#fff", marginBottom: "25px" }}
        >
          <Box
            style={{
              paddingBottom: "0px"
            }}
          >
            <div>
              <Header
              NAVIGATION_FROM={NAVIGATION_FROM}
              employee_id={employee_id}
               showIfAppraiser={showIfAppraiserOverview}
                ratingData={ratingData}
                employeeDetails={employeeDetails}
                overallRating={overallRating}
                PAStatus={PAStatus}
                employeeData={employeeData}
                ratingDefinition={ratingDefinition}
                ratingScaleDefinition={ratingScaleDefinition} />
            </div>
            <div>
              <Box
                sx={{
                  paddingTop: "20px",
                  // paddingRight: "33px"
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                >
                  <PerformanceAppraisalPeriod
                    employeeDetails={employeeDetails} />

                  <PotentialEdit
                    potential={potential}
                    setPotential={setPotential}
                    disableTextAfterSubmission={disableTextAfterSubmission}
                    setDisableTextAfterSubmission={setDisableTextAfterSubmission}
                    moveTab={moveTab}
                    setMoveTab={setMoveTab}
                    navPrompt={navPrompt}
                    setNavPrompt={setNavPrompt}
                    showPotentialEdit={showPotentialEdit}
                    nineBoxData={nineBoxData}
                    employeeDetails={employeeDetails}
                    refetchEmployeeData={refetchEmployeeData}
                    employeeDataIsFetching={employeeDataIsFetching}
                    employeeDataIsLoading={employeeDataIsLoading} />
                </Stack>
              </Box>
            </div>
          </Box>

          <>
            <Box
            // sx={{ marginLeft: "36px", marginRight: "37px", }}
            >
              <ReviewerRejectionReasonView
                reviewerRejectionReason={reviewerRejectionReason}
                showReviewerRejectionReason={showReviewerRejectionReason} />
              {shownormalizerRejectionReason &&
                <NormalizerRejectionReasonView
                  normalizerRejectionReason={normalizerRejectionReason}
                />}
            </Box>

            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                // marginLeft: "36px",
                // marginRight: "66px",
                paddingTop: "12px",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    // display: "none",
                    backgroundColor: "#3e8cb5",
                  }
                }}
              >
                <Tab
                  sx={{
                    "&.Mui-selected": {
                      color: "#ffffff",
                      background: "#3e8cb5",
                    },
                    textTransform: "capitalize",
                    fontSize: "16px",
                    fontFamily: "Arial",
                    fontWeight: "700",
                    border: "1px solid #3e8cb59e",
                    maxHeight: "0px",
                    minHeight: "48px",
                    paddingRight: "15px",
                    paddingLeft: "20px"
                  }}
                  label="Ratings"
                  icon={
                    <>
                      <TabPanel2 value={tabValue} index={1}>
                        <IconButton
                          sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                        >
                          <img
                            style={{ width: "12px" }}
                            src={Infoicon}
                            alt="icon"
                          />
                        </IconButton>
                      </TabPanel2>
                      <TabPanel2 value={tabValue} index={2}>
                        <IconButton
                          sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                        >
                          <img
                            style={{ width: "12px" }}
                            src={Infoicon}
                            alt="icon"
                          />
                        </IconButton>
                      </TabPanel2>
                      <TabPanel2 value={tabValue} index={0}>
                        <IconButton
                          sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                          aria-describedby={idRatingInfo}
                          onClick={handleClickOpenRatingInfo}
                        >
                          <img
                            style={{ width: "12px" }}
                            src={Infowhiteicon}
                            alt="icon"
                          />

                        </IconButton>
                      </TabPanel2>
                    </>
                  }
                  iconPosition="start"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    "&.Mui-selected": {
                      color: "#ffffff",
                      background: "#3e8cb5",

                    },
                    textTransform: "capitalize",
                    fontSize: "16px",
                    fontFamily: "Arial",
                    fontWeight: "700",
                    border: "1px solid #3e8cb59e",
                    paddingLeft: "20px",
                    paddingRight: "20px"
                  }}
                  label="Overall Feedback"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    "&.Mui-selected": {
                      color: "#ffffff",
                      background: "#3e8cb5",

                    },
                    textTransform: "capitalize",
                    fontSize: "16px",
                    fontFamily: "Arial",
                    fontWeight: "700",
                    border: "1px solid #3e8cb59e",
                    paddingLeft: "20px",
                    paddingRight: "20px"
                  }}
                  label="Performance Goals"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <AppraiserResubmissionRatings
                attachedFiles={attachedFiles}
                setAttachedFiles={setAttachedFiles}
                visiblityColumnData={visiblityColumnData}
                employee_id={employee_id}
                employeeData={employeeData}
                objectiveDescription={objectiveDescription}
                setObjectiveDescription={setObjectiveDescription}
                colorarray={colorarray}
                disableTextAfterSubmission={disableTextAfterSubmission}
                ratingData={ratingData}
                setDisableTextAfterSubmission={setDisableTextAfterSubmission}
                moveTab={moveTab}
                setMoveTab={setMoveTab}
                navPrompt={navPrompt}
                setNavPrompt={setNavPrompt}
                refetchEmployeeData={refetchEmployeeData}
                employeeDataIsFetching={employeeDataIsFetching}
                employeeDataIsLoading={employeeDataIsLoading}
                updateAction={updateAction}
                isLoadingAction={isLoadingAction}
                updateMutation={updateMutation}
                employeeDataIsUpdating={employeeDataIsUpdating}
                potential={potential}
                createMutation={createMutation}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <OverallFeedbackTab
               showreviewerComments={showreviewerComments}
               reviewerComments={reviewerComments}
               showNormalizerComments={showNormalizerComments}
               normalizerComments={normalizerComments}
               normalizerRejectionReason={normalizerRejectionReason}
               shownormalizerRejectionReason={shownormalizerRejectionReason}
               employeeComments={employeeComments}
               showEmployeeComments={showEmployeeComments}
               showoneToOneMeetingDate={showoneToOneMeetingDate}
               oneToOneMeetingDate={oneToOneMeetingDate}
              employeeTrainingRecommendation={employeeTrainingRecommendation}
              TrainingRecommendation={TrainingRecommendation}
              employeeAreaOfImprovement={employeeAreaOfImprovement}
              areaOfImprovement={areaOfImprovement}
              feedbackQuestionnaireeData={feedbackQuestionnaireeData}
              employeeData={employeeData}
                showIfAppraiserresubmission={showIfAppraiserOverview}
                appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
                setAppraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
                feedbackQuestionnaireData={feedbackQuestionnaireData}
                feedbackQuestionnaire={feedbackQuestionnaire}
                setFeedbackQuestionnaire={setFeedbackQuestionnaire}
                disableTextAfterSubmission={disableTextAfterSubmission}
                setDisableTextAfterSubmission={setDisableTextAfterSubmission}
                moveTab={moveTab}
                setMoveTab={setMoveTab}
                navPrompt={navPrompt}
                setNavPrompt={setNavPrompt}
                appraiserAreaOfImprovement={appraiserAreaOfImprovement}
                setAppraiserAreaOfImprovement={setAppraiserAreaOfImprovement}
                appraiserTrainingRecommendation={appraiserTrainingRecommendation}
                setAppraiserTrainingRecommendation={setAppraiserTrainingRecommendation}
                appraisalTemplateTrainingRecommendation={appraisalTemplateTrainingRecommendation}
                appraiserFurtherRecommendation={appraiserFurtherRecommendation}
                setAppraiserFurtherRecommendation={setAppraiserFurtherRecommendation}
                appraiserMessageForEmployee={appraiserMessageForEmployee}
                setAppraiserMessageForEmployee={setAppraiserMessageForEmployee}
                showAppraiserMessageForEmployee={showAppraiserMessageForEmployee}
                appraiserFurtherRecommendationOthers={appraiserFurtherRecommendationOthers}
                setAppraiserFurtherRecommendationOthers={setAppraiserFurtherRecommendationOthers}
                appraiserFurtherRecommendationOthersCheckbox={appraiserFurtherRecommendationOthersCheckbox}
                setAppraiserFurtherRecommendationOthersCheckbox={setAppraiserFurtherRecommendationOthersCheckbox}
                refetchEmployeeData={refetchEmployeeData}
                employeeDataIsFetching={employeeDataIsFetching}
                employeeDataIsLoading={employeeDataIsLoading}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <PerformanceGoalsTab
              performanceGoalsFieldValues={performanceGoalsFieldValues}
              performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
              showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
              showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
              employeeData={employeeData}
              showIfAppraiserresubmission={showIfAppraiserOverview}
                performanceGoals={appraiserPerformanceGoals}
                setPerformanceGoals={setAppraiserPerformanceGoals}
                moveTab={moveTab}
                setMoveTab={setMoveTab}
                navPrompt={navPrompt}
                setNavPrompt={setNavPrompt}
                disableTextAfterSubmission={disableTextAfterSubmission}
                goalsCategoryData={goalsCategoryData}
                // showIfAppraiserOverview={showIfAppraiserOverview}
                refetchEmployeeData={refetchEmployeeData}
                employeeDataIsFetching={employeeDataIsFetching}
                employeeDataIsLoading={employeeDataIsLoading} />
            </TabPanel>
          </>

          <Box>
            <AppraiserResubmissionFooterButtons
              attachedFiles={attachedFiles}
              tabValue={tabValue}
              setTabValue={setTabValue}
              ratingData={ratingData}
              employee_id={employee_id}
              moveTab={moveTab}
              setMoveTab={setMoveTab}
              navPrompt={navPrompt}
              setNavPrompt={setNavPrompt}
              objectiveDescription={objectiveDescription}
              setObjectiveDescription={setObjectiveDescription}
              appraiserRatingPreviousSubmission={appraiserRatingPreviousSubmission}
              disableTextAfterSubmission={disableTextAfterSubmission}
              employeeData={employeeData}
              disableButtons={disableButtons}
              setDisableButtons={setDisableButtons}
              refetchEmployeeData={refetchEmployeeData}
              employeeDataIsFetching={employeeDataIsFetching}
              employeeDataIsLoading={employeeDataIsLoading} />
          </Box>


          <Popover
            id={idRatingInfo}
            open={openRatingInfo}
            anchorEl={anchorRatingInfo}
            onClose={handleCloseRatingInfo}
            PaperProps={{
              style: { width: "260px", marginTop: "55px" },
            }}
          >
            <TableContainer sx={{ overflowX: "hidden" }}>
              <Scroll>
                <CustomScrollbar style={{ width: "100%", height: "225px" }}>
                  <Table
                    sx={{
                      minWidth: 200,
                      '& th, & td': {
                        borderBottom: 'none', // Remove the bottom border for th and td
                      },
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#eaeced" }}>
                        <TableCell
                          align="center"
                          sx={{
                            maxWidth: "30%",
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600", // Adjust the maxWidth as needed
                          }}
                        >
                          Ratings
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            maxWidth: "70%",
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",  // Adjust the maxWidth as needed
                          }}
                        >
                          Rating Title
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Map through your data and render rows */}
                      {ratingData &&
                        ratingData.data
                          .slice()
                          .sort((a: any, b: any) => a.rating - b.rating)
                          .map((row: any, index: any) => (
                            <TableRow key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderColor: "lightgrey",
                                },
                              }}>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.rating}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.rating_scale}
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </CustomScrollbar>
              </Scroll>
            </TableContainer>
          </Popover>

          <DialogWithOkButton
            isAlertOpen={openAlertOK}
            handleAlertClose={handleAlertOKClose}>
            {message}
          </DialogWithOkButton>
        </Box>
      </div >
    </>
  )
}

export default AppraiserResubmission