import { Button, Link, Stack } from '@mui/material'
import React from 'react'
import { REVIEWER_APPROVE,REVIEWER_REJECTION,REVIEWER_ACCEPTANCE,REVIEWER_APPROVE_APPRAISER_EMPLOYEEREJECTION, REVIEWER_REJECT_APPRAISER_EMPLOYEEREJECTION, EMPLOYEE_REVIEWER_ACCEPTANCE, EMPLOYEE_REVIEWER_REJECT } from '../../../constants/routes/Routing'
import { useParams, useNavigate } from "react-router-dom";

const ReviewerFooterButton = (props: any) => {
  const { employeeData } = props
  const navigate = useNavigate();
  const { employee_id } = useParams();

  const handleReject = () => {
    if (employeeData?.data?.appraisal?.status == "rejected") {
      navigate(`${EMPLOYEE_REVIEWER_REJECT}/employee/${employee_id}`)
    } else {
      navigate(`${REVIEWER_REJECTION}/employee/${employee_id}`)
    }
  }
  const handleAccept = () => {
    if (employeeData?.data?.appraisal?.status == "rejected") {
      navigate(`${EMPLOYEE_REVIEWER_ACCEPTANCE}/employee/${employee_id}`)
    } else {
      navigate(`${REVIEWER_ACCEPTANCE}/employee/${employee_id}`)
    }
  }
  const handleCancel = () =>{
    navigate('/reviewer', { state: { from: 1 } });

  }
  return (
    <>
      <Stack justifyContent="center" spacing={2} paddingTop="30px" direction="row">
        <>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "Transparent",
              fontSize: "15px",
              fontWeight: 400,
              textTransform: "none",
              color: "#3e8cb5",
              borderColor: "#3E8CB5",
              height: "35px",
              fontFamily: "Arial",
              width: "70px",
            }}
            onClick={() => { handleAccept() }}
          >
            Accept
          </Button>

          <Button
            variant="outlined"
            style={{
              backgroundColor: "Transparent",
              fontSize: "15px",
              fontWeight: 400,
              textTransform: "none",
              color: "#3e8cb5",
              borderColor: "#3E8CB5",
              height: "35px",
              fontFamily: "Arial",
              width: "70px",
            }}
            onClick={() => { handleReject() }}

          >
            Reject
          </Button>

            <Button
              variant="outlined"
              style={{
                backgroundColor: "Transparent",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "none",
                color: "#3e8cb5",
                borderColor: "#3E8CB5",
                height: "35px",
                fontFamily: "Arial",
                width: "70px",
              }}
              onClick ={() => {handleCancel()}}
            >
              Cancel
            </Button>
        </>
      </Stack>
    </>
  )
}

export default ReviewerFooterButton