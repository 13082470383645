import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { Grid, Box, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import GlobeChart from "./GlobeChart";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";


const EmployeeRejection = (props: any) => {
  const { completedCount, inprogressCount, inMediaton, range1, range2,
    range3, range4, TotalEmp, CompletedEmp, linkofAppraiser, checklengthinRangesH, checklengthinRangesL, checklengthinRangesA, checklengthinRangesG, checkAppraisalStatusCompleted
    , checkAppraisalStatusInmediation, checkAppraisalStatusRenormalization, navigationFrom, LOCATION_FROM, navigationRole, valueOfActiveCalender, appCalId } = props;
  const [TotalEmpCount, setTotalEmpCount] = React.useState<any>(TotalEmp);
  const [CompletedEmpCount, setCompletedEmpCount] = React.useState<any>(CompletedEmp);
  React.useEffect(() => {
    setTotalEmpCount(TotalEmp);
  }, [TotalEmp]);
  const series = [range1, range2, range3, range4];
  const options: ApexOptions = {
    colors: ["#FF0000", "#ff884d", "#39ac73", "#33cccc"],
    chart: {
      type: "donut",
    },
    labels: [
      "Low Performers",
      "Average Performers",
      "Good Performers",
      "High Performers",
    ],
    legend: {
      show: false,
    },
    responsive: [
      {
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      formatter: function (value: any, { seriesIndex, dataPointIndex, w }) {
        return w.config.series[seriesIndex]
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '16px',
              fontFamily: ' Arial',
              color: '#333333',
              formatter: () => `${CompletedEmp}/${TotalEmp}`
            },
          }
        },
      }
    }
  };
  //chart when count is zero
  const zeroSeries = [0, 0, 0, 1];
  const zeroOptions: ApexOptions = {
    colors: ["#abb0b0"],
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
      formatter: function (value: any, { seriesIndex, dataPointIndex, w }) {
        console.log(w.config.series[seriesIndex], "series")
        return w.config.series[seriesIndex]
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '16px',
              fontFamily: ' Arial',
              color: '#333333',
              formatter: () => `${CompletedEmp}/${TotalEmp}`
            },
          }
        },
      }
    }
  };
  return (
    <Grid>
      <Grid sx={{ marginLeft: "0px", width: "calc(100% + 0px)" }} container spacing={2} >
        <Grid item xs={12} md={6}
          sx={{
            borderRight: "1px solid #e6e6e6",
            '@media (max-width: 900px)': {
              borderRight: "none",
            },
            paddingLeft: "16px"
          }}>
          <Typography
            style={{
              fontSize: "20px",
              fontFamily: "Arial",
              color: "#3e8cb5"
            }}
          >
            Ratings Distribution
          </Typography>
          <Stack
            direction="row"
            style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}
          >
            <div>
              <Stack
                sx={{
                  gap: "15px",
                  fontSize: "12px",
                  color: "#808080"
                }}
              >
                <Link
                  to={linkofAppraiser}
                  state={{
                    from: "High Performers",
                    LOCATION_FROM: LOCATION_FROM,
                    Detail: { appCalId },
                    name: { valueOfActiveCalender },
                    navigationFrom: navigationFrom,
                    range: { checklengthinRangesH },
                  }}
                >
                  <Box style={{
                    display: "flex",
                  }} >
                    <span
                      style={{
                        width: "18px",
                        height: "18px",
                        display: "inline-block",
                        backgroundColor: "#33cccc",
                        verticalAlign: "middle",
                        marginRight: "8px",
                        border: "1px solid #333333",
                        borderRadius: "2px"
                      }}
                    ></span>
                    <label style={{ fontSize: "12px", color: "#014d76", fontFamily: "arial" }}> High Performers</label>
                  </Box>
                </Link>
                <Link
                  to={linkofAppraiser}
                  state={{
                    from: "Good Performers",
                    LOCATION_FROM: LOCATION_FROM,
                    Detail: { appCalId },
                    name: { valueOfActiveCalender },
                    navigationFrom: navigationFrom,
                    range: { checklengthinRangesG }
                  }}
                >
                  <Box style={{
                    display: "flex",
                  }}>
                    <span
                      style={{
                        width: "18px",
                        height: "18px",
                        display: "inline-block",
                        backgroundColor: "#39ac73",
                        verticalAlign: "middle",
                        marginRight: "8px",
                        border: "1px solid #333333",
                        borderRadius: "2px"
                      }}
                    ></span>
                    <label style={{ fontSize: "12px", color: "#014d76", fontFamily: "arial" }}> Good Performers</label>
                  </Box>
                </Link>
                <Link
                  to={linkofAppraiser}
                  state={{
                    from: "Average Performers",
                    LOCATION_FROM: LOCATION_FROM,
                    Detail: { appCalId },
                    name: { valueOfActiveCalender },
                    navigationFrom: navigationFrom,
                    range: { checklengthinRangesA }
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        width: "18px",
                        height: "18px",
                        display: "inline-block",
                        backgroundColor: "#ff884d",
                        verticalAlign: "middle",
                        marginRight: "8px",
                        border: "1px solid #333333",
                        borderRadius: "2px"
                      }}
                    ></span>
                    <label style={{ fontSize: "12px", color: "#014d76", fontFamily: "arial" }}> Average Performers</label>
                  </Box>
                </Link>
                <Link
                  to={linkofAppraiser}
                  state={{
                    from: "Low Performers",
                    LOCATION_FROM: LOCATION_FROM,
                    Detail: { appCalId },
                    name: { valueOfActiveCalender },
                    navigationFrom: navigationFrom,
                    range: { checklengthinRangesL }
                  }}
                >
                  <Box style={{
                    display: "flex",
                  }}>
                    <span
                      style={{
                        width: "18px",
                        height: "18px",
                        display: "inline-block",
                        backgroundColor: "#FF0000",
                        verticalAlign: "middle",
                        marginRight: "8px",
                        border: "1px solid #333333",
                        borderRadius: "2px"
                      }}
                    ></span>
                    <label style={{ fontSize: "12px", color: "#014d76", fontFamily: "arial" }}> Low Performers</label>
                  </Box>
                </Link>
              </Stack>
            </div>
            <div>
              {(range1 === 0 && range2 === 0 && range3 === 0 && range4 === 0) &&
                <ReactApexChart
                  options={zeroOptions}
                  series={zeroSeries}
                  type="donut"
                  width={270}
                />
              }
              {(range1 !== 0 || range2 !== 0 || range3 !== 0 || range4 !== 0) && TotalEmp && CompletedEmp &&
                <ReactApexChart
                  key={`${CompletedEmp}-${TotalEmp}`}
                  options={options}
                  series={series}
                  type="donut"
                  width={270}
                />
              }
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            fontSize="20px"
            fontFamily="Arial"
            color="#3e8cb5"
          >
            Employees Rejection</Typography>
          <Stack
            direction="row"
            style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}
          >
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  color: "#808080",
                  marginBottom: "15px",
                }}
              > <Stack
                sx={{
                  gap: "15px",
                }}
              >
                  <Link
                    to={linkofAppraiser}
                    state={{
                      from: "Completed",
                      LOCATION_FROM: LOCATION_FROM,
                      Detail: { appCalId },
                      name: { valueOfActiveCalender },
                      navigationFrom: navigationFrom,
                      status: { checkAppraisalStatusCompleted }
                    }}
                  >
                    <Box style={{ display: "flex", }}>
                      <span
                        style={{
                          width: "18px",
                          height: "18px",
                          display: "inline-block",
                          backgroundColor: "#39ac73",
                          verticalAlign: "middle",
                          marginRight: "8px",
                          border: "1px solid #333333",
                          borderRadius: "2px"
                        }}
                      ></span>{" "}
                      <label style={{ fontSize: "12px", color: "#014d76", fontFamily: "arial" }}> Completed</label>
                    </Box>
                  </Link>
                  <Link
                    to={linkofAppraiser}
                    state={{
                      from: "In Re-normalization",
                      LOCATION_FROM: LOCATION_FROM,
                      Detail: { appCalId },
                      name: { valueOfActiveCalender },
                      navigationFrom: navigationFrom,
                      status: { checkAppraisalStatusRenormalization }
                    }}
                  >
                    <Box style={{ display: "flex", }}>
                      <span
                        style={{
                          width: "18px",
                          height: "18px",
                          display: "inline-block",
                          backgroundColor: "#ff884d",
                          verticalAlign: "middle",
                          marginRight: "8px",
                          border: "1px solid #333333",
                          borderRadius: "2px"
                        }}
                      ></span>
                      <label style={{ fontSize: "12px", color: "#014d76", fontFamily: "arial" }}>   In Re-normalization  </label>
                    </Box>
                  </Link>
                  <Link
                    to={linkofAppraiser}
                    state={{
                      from: "In Mediation",
                      LOCATION_FROM: LOCATION_FROM,
                      Detail: { appCalId },
                      name: { valueOfActiveCalender },
                      navigationFrom: navigationFrom,
                      status: { checkAppraisalStatusInmediation }
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                      }}
                    > <span
                      style={{
                        width: "18px",
                        height: "18px",
                        display: "inline-block",
                        backgroundColor: "#FF0000",
                        verticalAlign: "middle",
                        marginRight: "8px",
                        border: "1px solid #333333",
                        borderRadius: "2px"
                      }}
                    ></span>{" "}
                      <label style={{ fontSize: "12px", color: "#014d76", fontFamily: "arial" }}>    In Mediation    </label>
                    </Box>
                  </Link>
                </Stack>
              </Box>
            </Box>
            <Box >
              <GlobeChart
                navigationRole={navigationRole}
                completedCount={completedCount}
                inprogressCount={inprogressCount}
                inMediaton={inMediaton}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EmployeeRejection;

