import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAcceptNormalizerMutation, useGetEmployeeAppraisalQuery, useGetFeedBackQuery, useGetObjectiveTitleQuery, useGetRatingScaleQuery, useUpdateEmployeeAppraisalMutation } from '../../service';
import { useGetPerformanceGoalsQuery } from '../../service/performanceGoals/performanceGoals';
import { useGetNineboxQuery, useGetTalentCategoryQuery } from '../../service/ninebox/ninebox';
import _ from "lodash";
import { useCheckRoleLogsMutation } from '../../service/employee/employee';
import { useAcceptNormalizerGradeExceptionMutation, useAcceptReviewerEmployeeRejectionMutation, useAcceptReviewerMutation } from '../../service/employee/appraisal/appraisal';
import { useGetEmailIdsQuery, useSendEmailNotificationMutation } from '../../service/email/emailNotification';


// Create the context
const ReviewerContext = createContext<any>(undefined);

// Custom hook to access the context
export function useReviewerContext() {
    return useContext(ReviewerContext);
}

// Custom hook to provide data to the context
function useReviewerDataContext() {
    const { employee_id } = useParams();
    const { data: employeeData ,refetch:fetchCancel,isLoading: employeeDataIsLoading, isFetching: employeeDataIsFetching } = useGetEmployeeAppraisalQuery(employee_id)
    const { data: nineBoxData } = useGetNineboxQuery("");
    const { data: ratingData } = useGetRatingScaleQuery("");
    const { data: feedbackQuestionnaireData } = useGetFeedBackQuery('')
    const { data: objectiveTitleData } = useGetObjectiveTitleQuery("");
    const [updateMutation] = useUpdateEmployeeAppraisalMutation();
    const {data: emailData } = useGetEmailIdsQuery({appraiser_code : employeeData?.data?.appraiser_code,reviewer_code : employeeData?.data?.reviewer_code, normalizer_code: employeeData?.data?.normalizer_code})
    const [acceptReviewer] = useAcceptReviewerMutation();
    const [acceptEmployeeReviewer] = useAcceptReviewerEmployeeRejectionMutation();
    const [updateLoggedRole] = useCheckRoleLogsMutation()
    const [sendEmailNotification] = useSendEmailNotificationMutation();
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [overallRating, setOverallRating] = useState(0)
    const [ratingDefinition, setRatingDefinition] = useState<any>();
    const [ratingScaleDefinition, setRatingScaleDefinition] = useState<any>();
    const [PAStatus, setPAStatus] = useState("")
    const [appraiserFeedbackQuestionnaire, setAppraiserFeedbackQuestionnaire] = useState<any>([]);
    const [appraiserAreaOfImprovement, setAppraiserAreaOfImprovement] = useState([]);
    const [appraiserTrainingRecommendation, setAppraiserTrainingRecommendation] = useState([])
    const [appraisalTemplateTrainingRecommendation, setAppraisalTemplateTrainingRecommendation] = useState([]);
    const [appraiserMessageForEmployee, setAppraiserMessageForEmployee] = useState("")
    const [showAppraiserMessageForEmployee, setShowAppraiserMessageForEmployee] = useState(false)
    const [showAppraiserTrainingRecommendation, setShowAppraiserTrainingRecommendation] = useState(false);
    const [showAppraiserAreaOfImprovement, setShowAppraiserAreaOfImprovement] = useState(false);
    const [normalizerComments, setNormalizerComments] = useState<any>("");
    const [showNormalizerComments, setShowNormalizerComments] = useState(false);
    const [appraiserFurtherRecommendationOthersCheckbox, setAppraiserFurtherRecommendationOthersCheckbox] = useState<any>(false)
    const [reviewerRejectionReason, setReviewerRejectionReason] = useState<any>("");
    const [normalizerRejectionReason, setNormalizerRejectionReason] = useState<any>("");
    const [reviewerComments, setReviewerComments] = useState<any>("");
    const [performanceGoalsFieldValues, setPerformanceGoalsFieldValues] = useState<any>("");
    const [performanceGoalsFieldValuesEmployee, setPerformanceGoalsFieldValuesEmployee] = useState<any>("");
    const [showPerformanceGoalsFieldValues, setShowPerformanceGoalsFieldValues] = useState<any>("");
    const [showPerformanceGoalsFieldValuesEmployee, setShowPerformanceGoalsFieldValuesEmployee] = useState<any>("");
    const [showPotential, setShowPotential] = useState(false);
    const [potential, setPotential] = useState("")
    const [objectiveDescription, setObjectiveDescription] = useState<any>([]);
    const [colorarray, setColorarray] = useState<any>("");
    const [employeecode, setemployeecode] = useState<any>([])
    const [furtherRecommendationOthers, setFurtherRecommendationOthers] = useState<any>("");
    const [furtherRecommendation, setFurtherRecommendation] = useState<any>("");
    const [tabValue, setTabValue] = useState(0); // set the tab value
    const [moveTab, setMoveTab] = useState(false)
    const [message, setMessage] = useState("");
    const [navPrompt, setNavPrompt] = useState(false); // Allow to navigate between screens
    const [ disableTextAfterSubmission , setDisableTextAfterSubmission] = useState(false)
    const [disableButtons , setDisableButtons] = useState(false)
    const [talentPotential, setTalentPotential] = useState<any>("")
    const [talentRating, setTalentRating] = useState<any>(0)
    const { data: talentCategory } = useGetTalentCategoryQuery({ overall_rating: talentRating, potential: talentPotential });
    const [currentOverAllRating, setCurrentOverAllRating] = useState<any>(0)
    const [currentObjectiveDescription, setCurrentObjectiveDescription] = useState<any>([])
    const [objectiveType, setObjectiveType] = useState<any>([])
    const [employeeComments, setEmployeeComments] = useState("");
    const [showEmployeeComments, setShowEmployeeComments] = useState(false);
    const [oneToOneMeetingDate, setOneToOneMeetingDate] = useState("");
    const [showoneToOneMeetingDate, setShowoneToOneMeetingDate] = useState(false);


    const [visiblityColumnData, setVisiblityColumnData] = useState({
        showPreviousRating: false,
        showAppraiserComments: false,
        showAppraiserRejectionReason: false,
        showReviewerRating: false,
        showReviewerRejectionReason: false,
        showEmployeeRating: false,
        showEmployeeRejectionReason: false,
        showRenormalizedRating: false,
        showHRNormalizerComments: false
    });
    const Colors = [
        "#B6E9EE",
        "#C9FBEA",
        "#B1EDEE",
        "#B9E9D0",
        "#BDE3E2",
        "#B1F3F2",
        "#B7E6F7",
        "#B8EFEF",
        "#BFFBE7",
        "#B7E6F7",
        "#B1F1F0",
        "#BEECF5",
    ]

    // to find objective title by Id
    const findObjectiveTitleById = (id: any) => {
        if (objectiveTitleData) {
            //console.log(id, "objectiveTitleData");
            return objectiveTitleData.data.find((item: any) => item._id === id);
        }
    };
    // to find objective type by Id
    const findObjectiveTypeById = (id: any) => {
        if (employeeData) {
            return employeeData?.data?.reviewer?.objective_type?.find(
                (item: any) => item?.name?._id === id
            );
        }
    };
    // to find Previous Rating
    const findPreviousRating = (id: any) => {
        let temp = (employeeData?.data?.appraisal_previous_rating?.objective_description?.filter(
            (i: any) =>
                i?.name === id
        ).map((k: any) => {
            if (ratingData) {
                let temp = ratingData?.data?.find((item: any) => k.ratings == item._id)
                return temp?.rating
            }
        })[0]);
        return temp
    }
    //  to find employee rating
    const findEmployeeRating = (id: any) => {
        let temp = (employeeData?.data?.employee?.objective_description
            .filter(
                (i: any) =>
                    i?.name?._id === id
            )
            .map((k: any) => {
                if (k?.ratings && k.rating_rejected == true)
                    return {
                        rating_rejected: k.rating_rejected,
                        ratings: k.ratings,
                        rejection_reason: k.rejection_reason
                    }
                else return ""
            })[0]);
        return temp
    }
    //  to find Normalizer Rating
    const findNormalizerRating = (id: any) => {
        let temp = (employeeData?.data?.normalizer?.objective_description
            .filter(
                (i: any) =>
                    i?.name?._id === id
            )
            .map((k: any) => {
                return {
                    rating_resubmitted: k.rating_resubmitted,
                    ratings: k?.ratings,
                    rejection_reason: k?.rejection_reason
                }
            })[0]);
        return temp
    }
    // group Area of Improvement by specific Area
    const groupSpecificAreaHandler = (area: any) => {
        if (area) {
            let tempArea = area?.filter((area: any) => {
                return area[0] !== "" && area[0] !== undefined
            })
            if (tempArea && tempArea?.length > 0) {
                return {
                    showArea: true,
                    area: area,
                }
            } else {
                return {
                    showArea: false
                }
            }
        }
    };
    const date = new Date(employeeData?.data?.employee?.one_to_one_meeting?.slice(0, 10));
    const One_To_One_Meeting_Date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    useEffect(() => {
        setPotential(employeeData?.data?.appraisal?.potential);
        setEmployeeDetails(() => {
            return {
                employee_code: employeeData?.data?.employee_code,
                previousRating: employeeData?.data?.previous_rating,
                profile_image_url: employeeData?.data?.profile_image_url,
                fullName: employeeData?.data?.legal_full_name,
                firstName: employeeData?.data?.first_name,
                position: employeeData?.data?.position_long_description,
                grade: employeeData?.data?.grade,
                status: employeeData?.data?.appraisal?.status,
                calendarName: employeeData?.data.calendar?.name,
            }
        });
        setObjectiveType(employeeData?.data?.appraisal?.objective_type);
        setObjectiveDescription(() => {
            return employeeData?.data?.appraisal?.objective_description?.map(
                (i: any) => {
                    return {
                        ...i,
                        comments: i.comments,
                        rejection_reason: i.rejection_reason,
                        rating: i.ratings,
                        previous_rating: findPreviousRating(i.name?._id),
                        objective_title: findObjectiveTitleById(i?.name?.objective_title),
                        objective_type: findObjectiveTypeById(i?.name?.objective_type),
                        employee_rating: findEmployeeRating(i.name?._id),
                        normalizer_rating: findNormalizerRating(i.name?._id),
                    };
                }
            );
        });
        setCurrentObjectiveDescription(employeeData?.data?.current_rating?.objective_description?.map((j: any) => {
            return {
              ...j,
              objective_type: findObjectiveTypeById(j.name?.objective_type)
      
            }
          }))
        setFurtherRecommendation(() => {
            let otherRecommendation = employeeData?.data?.appraisal?.other_recommendation?.length > 0 ?
              employeeData?.data?.appraisal?.other_recommendation :
              employeeData?.data?.appraisal?.other_recommendation;
            return employeeData?.data?.appraisal?.other_recommendation?.map(
              (i: any) => {
                return {
                  ...i,
                  name: i?.name,
                };
              }
            );
          });
        
        setVisiblityColumnData({
            ...visiblityColumnData,
            // show the previous rating popover if any previous rating data is present.
            showPreviousRating: (employeeData?.data?.appraisal_previous_rating?.objective_description?.filter((i: any) => i.ratings)?.length > 0) ? true : false,
            // show appraiser comments column if any of the rating has appraiser comments.
            showAppraiserComments: (employeeData?.data?.appraisal?.objective_description.filter((item: any) => item.comments !== "" && item.comments !== undefined)?.length > 0) ? true : false,
            /**show appraiser rejection reason if appraiser has rejected any rating and added rejection reason and also if 
                     if the PA is not renormalized or completed *****/
            showAppraiserRejectionReason: (employeeData?.data?.appraisal?.objective_description?.filter((item: any) =>
            (item.rating_rejected == true || item.rating_resubmitted == true) && (item.rejection_reason !== "" && item.rejection_reason !== undefined))?.length > 0) ? true : false,
           
        })
        let objectiveType = employeeData?.data?.appraisal?.objective_type?.map((item: any, index: number) => {
            return {
                objective_type: item?.name?.name,
                color: Colors[index]
            }
        });
        setEmployeeComments(employeeData?.data?.employee?.comments)
        setShowEmployeeComments(employeeData?.data?.employee?.comments !== undefined &&
          employeeData?.data?.employee?.comments !== "")
        setOneToOneMeetingDate(One_To_One_Meeting_Date)
        setShowoneToOneMeetingDate(employeeData?.data?.employee?.one_to_one_meeting !== "" &&
          employeeData?.data?.employee?.one_to_one_meeting !== null &&
          employeeData?.data?.employee?.one_to_one_meeting !== undefined)

        setDisableTextAfterSubmission(!employeeData?.data?.appraisal?.pa_status?.includes("Pending with Reviewer"))
        setColorarray(objectiveType)
        setemployeecode(employeeData?.data?.employee_code)
        setShowPotential(employeeData?.data?.appraisal_template?.potential === true);
        setPAStatus(employeeData?.data?.appraisal?.pa_status);
        setOverallRating(employeeData?.data?.current_rating?.overall_rating?.toFixed(2));
        setAppraiserFeedbackQuestionnaire(employeeData?.data?.appraisal?.feedback_questions)
        setAppraiserAreaOfImprovement(employeeData?.data?.appraisal?.area_of_improvement);
        setAppraiserTrainingRecommendation(employeeData?.data?.appraisal?.training_recommendation);
        setNormalizerComments(employeeData?.data?.normalizer?.reason_for_rejection)
        if (employeeData?.data?.normalizer?.reason_for_rejection &&
            employeeData?.data?.normalizer?.reason_for_rejection != "" &&
            employeeData?.data?.normalizer?.reason_for_rejection != undefined &&
            employeeData?.data?.normalizer?.normalizer_PA_rejected !== true) {
            setShowNormalizerComments(true)
          } else {
            setShowNormalizerComments(false)
          }
        setReviewerComments(employeeData?.data?.reviewer?.reviewer_comments_draft)
        setReviewerRejectionReason(employeeData?.data?.reviewer?.reviewer_overall_feedback)
        setNormalizerRejectionReason(employeeData?.data?.normalizer?.normalizer_overall_feedback)
        setPerformanceGoalsFieldValues(employeeData?.data?.appraisal?.performance_goal)
        setPerformanceGoalsFieldValuesEmployee(employeeData?.data?.employee?.performance_goal)
        let performancegoalvaluesappraiser = employeeData?.data?.appraisal?.performance_goal
        let performancegoalvaluesemployee = employeeData?.data?.employee?.performance_goal
        if (performancegoalvaluesappraiser?.length == 0) {
            setShowPerformanceGoalsFieldValues(false)
        } else {
            setShowPerformanceGoalsFieldValues(true)
        }
        if (performancegoalvaluesemployee?.length == 0) {
            setShowPerformanceGoalsFieldValuesEmployee(false)
        } else {
            setShowPerformanceGoalsFieldValuesEmployee(true)
        }
        let tempTraining = employeeData?.data?.appraisal?.training_recommendation?.filter((item: any) => {
            return item?.name?.title !== "" || item?.name?.title !== undefined
        });

        if (tempTraining && tempTraining?.length > 0) {
            setShowAppraiserTrainingRecommendation(true);
        } else {
            setShowAppraiserTrainingRecommendation(false)
        }
        setAppraisalTemplateTrainingRecommendation(employeeData?.data?.appraisal_template?.training_recommendation);
        setFurtherRecommendation(() => {
            let otherRecommendation = employeeData?.data?.appraisal?.other_recommendation?.length > 0 ?
                employeeData?.data?.appraisal?.other_recommendation :
                employeeData?.data?.appraisal?.other_recommendation;
            return employeeData?.data?.appraisal?.other_recommendation?.map(
                (i: any) => {
                    return {
                        ...i,
                        name: i?.name,
                    };
                }
            );
        });
        // to get Appraiser Area of Improvement
        let tempAppraiserAreaofImprovement = employeeData?.data?.appraisal?.area_of_improvement;
        const groupBySpecificAppraiser = _.groupBy(tempAppraiserAreaofImprovement, "value");
        const groupHandlerAppraiser = groupSpecificAreaHandler(Object.entries(groupBySpecificAppraiser));
        if (groupHandlerAppraiser?.showArea) {
            setShowAppraiserAreaOfImprovement(true);
            setAppraiserAreaOfImprovement(groupHandlerAppraiser?.area)
        } else {
            setShowAppraiserAreaOfImprovement(false);
        }
        setFurtherRecommendationOthers(employeeData?.data?.appraisal?.other_recommendation_others);
        setAppraiserFurtherRecommendationOthersCheckbox(employeeData?.data?.appraisal?.others_checkbox);
        setAppraiserMessageForEmployee(employeeData?.data?.appraisal?.appraiser_overall_feedback);
        if(employeeData?.data?.appraisal?.appraiser_overall_feedback !== null &&
            employeeData?.data?.appraisal?.appraiser_overall_feedback !== ""){
                setShowAppraiserMessageForEmployee(true)
            }else{
                setShowAppraiserMessageForEmployee(false)
            }
        const Overall_rating = employeeData?.data?.current_rating?.overall_rating;
        const RatingScale = ratingData?.data?.map((j: any) => ({
            rating: j?.rating,
            definition: j?.definition,
            rating_titile: j?.rating_scale,
        }));
        const filterRatingScale = (item: any, minRating: any, maxRating: any) => {
            return (item?.rating >= minRating && item?.rating <= maxRating) && (Overall_rating >= minRating && Overall_rating <= maxRating);
        }
        const FilteredRatingScale = RatingScale?.filter((item: any) => {
            if (filterRatingScale(item, 1, 1.99) ||
                filterRatingScale(item, 2, 2.49) ||
                filterRatingScale(item, 2.5, 2.99) ||
                filterRatingScale(item, 3, 3.49) ||
                filterRatingScale(item, 3.5, 3.99) ||
                filterRatingScale(item, 4, 4.49) ||
                filterRatingScale(item, 4.5, 4.99) ||
                filterRatingScale(item, 5.0, 5.0)) {
                return {
                    ratingScale: item?.rating_titile,
                    definition: item?.definition,
                };
            }
        });
        if (FilteredRatingScale && FilteredRatingScale.length > 0) {
            setRatingDefinition(FilteredRatingScale[0]?.definition);
            setRatingScaleDefinition(FilteredRatingScale[0]?.rating_titile);
        } else {
            // Handle the case when FilteredRatingScale is empty
            // setratingdefenition("No rating definition found");
        }
        setTalentPotential(employeeData?.data?.appraisal?.potential);
        setTalentRating(employeeData?.data?.current_rating?.overall_rating)
  
    }, [ratingData, feedbackQuestionnaireData, employeeData])

    useEffect(() => {
        if (currentObjectiveDescription?.length > 0 && employeeData) {
          const currentObjectiveDescriptionMapped = currentObjectiveDescription?.map((i: any) => {
            // if (i.ratings) {
            // const sum = (i.value * i.objective_type) / 10000
            const sum = (i.value * i.objective_type?.value) / 10000
            const newSum = sum * i?.ratings?.rating
            // console.log(sum, 'newSum')
            // return newSum
            return newSum ? newSum : 0
            // }
    
    
          })
          setCurrentOverAllRating(() => {
            return _.sum(currentObjectiveDescriptionMapped)?.toFixed(2)
          });
    
        }
      }, [currentObjectiveDescription, employeeData, objectiveType])
      useEffect(() => {
        if (currentOverAllRating) {
          let int_CurrentOverAllRating = parseInt(currentOverAllRating);
          if (int_CurrentOverAllRating !== 0) {
            updateMutation({
              "current_rating.overall_rating": currentOverAllRating,
              id: employee_id
            })
          }
        }
      }, [currentOverAllRating])

    return {
        employeeData, employee_id, employeeDetails, overallRating,
        PAStatus, showPotential, potential, ratingScaleDefinition, ratingDefinition, setMoveTab, moveTab, disableButtons,
        ratingData, nineBoxData, objectiveTitleData, tabValue, setTabValue, message, setMessage, employeeDataIsFetching, sendEmailNotification,
        employeeDataIsLoading, appraiserFeedbackQuestionnaire, showAppraiserAreaOfImprovement, showNormalizerComments,
        appraiserAreaOfImprovement, showAppraiserTrainingRecommendation, appraiserTrainingRecommendation, appraiserFurtherRecommendationOthersCheckbox,
        appraisalTemplateTrainingRecommendation, appraiserMessageForEmployee, showAppraiserMessageForEmployee, setReviewerComments, normalizerComments,
        performanceGoalsFieldValues, updateMutation, setDisableButtons,setReviewerRejectionReason,reviewerRejectionReason,normalizerRejectionReason,
        performanceGoalsFieldValuesEmployee, showPerformanceGoalsFieldValuesEmployee, acceptReviewer, updateLoggedRole,
        showPerformanceGoalsFieldValues, reviewerComments, visiblityColumnData, objectiveDescription, Colors, colorarray, disableTextAfterSubmission,
        furtherRecommendation, furtherRecommendationOthers, emailData, fetchCancel,setNavPrompt,navPrompt,
        acceptEmployeeReviewer,employeeComments,showEmployeeComments,oneToOneMeetingDate,showoneToOneMeetingDate,
        talentCategory,
    };
}

// Provider component to provide the context to its children
export default function ProvidedReviewerContextProvider({ children }: { children: React.ReactNode }) {
    const data = useReviewerDataContext();

    return (
        <ReviewerContext.Provider value={data}>
            {children}
        </ReviewerContext.Provider>
    );
}
