import React, { useState } from 'react'
import OverallFeedback from '../OverallFeedbackTab'
import { Box, Stack, TableContainer, Popover, Table, TableCell, TableHead, TableBody, TableRow, IconButton, Typography, Tabs, Tab, Breadcrumbs, Link } from '@mui/material';
import Header from '../../header/Header'
import PotentialView from '../../potential/potentialView'
import Infoicon from "../../../../assets/Images/Infoicon.svg"
import { Scrollbar } from "react-scrollbars-custom";
import { styled } from "@mui/material/styles";
import RatingsTab from '../../ratings/RatingsTab';
import { PENDING_WITH_REVIEWER, PLEASE_SAVE_CHANGES, PROMPT } from '../../../../constants/DialogContents/DialogContents';
import Infowhiteicon from "../../../../assets/Images/Infowhiteicon.svg"
import OverallFeedbackTab from '../OverallFeedbackTab';
import PerformanceGoal from '../PerformanceGoal';
import PerformanceAppraisalPeriod from '../../performanceAppraisalPeriod/PerformanceAppraisalPeriod';
import ReviewerRejectionReasonEdit from '../../overallFeedback/ReviewerRejectionReasonEdit';
import NormalizerRejectionReasonView from '../../overallFeedback/NormalizerRejectionReasonView';
import DialogWithOkButton from '../../../UI/DialogWithOkButton';
import {  REVIEWER_VIEW_PA, VIEWPA } from '../../../../constants/routes/Routing';
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../breadcrumbs/Breadcrumbs"
import { useReviewerContext } from '../../../../context/appraisal/ReviewerContext';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useContext, useCallback } from 'react';
import EmployeeReviewerRejectFooterButtons from './EmployeeReviewerRejectFooterButtons';

const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Popovers
function TabPanel2(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function useBlocker(blocker: any, when = true) {

  const { navigator } = useContext(NavigationContext);
  interface navigator {
    block: {

      any: any
    }
  }
  React.useEffect(() => {
    if (!when) return;
    // @ts-ignore
    const unblock = navigator.block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {

          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: any, when = true) {
  const blocker = useCallback(
    (tx:any) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);
}
function EmployeeReviewerReject(props: any) {
  const { employeeData, employee_id, employeeDetails, overallRating,
    PAStatus, showPotential, potential, ratingScaleDefinition, ratingDefinition, setMoveTab, moveTab, disableButtons,
    ratingData, nineBoxData, objectiveTitleData, tabValue, setTabValue, message, setMessage, employeeDataIsFetching, sendEmailNotification,
    employeeDataIsLoading, appraiserFeedbackQuestionnaire, navPrompt, setNavPrompt, showAppraiserAreaOfImprovement, showNormalizerComments,
    appraiserAreaOfImprovement, showAppraiserTrainingRecommendation, appraiserTrainingRecommendation, appraiserFurtherRecommendationOthersCheckbox,
    appraisalTemplateTrainingRecommendation, appraiserMessageForEmployee, showAppraiserMessageForEmployee, setReviewerComments, normalizerComments,
    reviewerRejectionReason, performanceGoalsFieldValues, updateMutation, setDisableButtons,
    performanceGoalsFieldValuesEmployee, showPerformanceGoalsFieldValuesEmployee, updateLoggedRole,
    showPerformanceGoalsFieldValues, reviewerComments, visiblityColumnData, objectiveDescription, Colors, colorarray, disableTextAfterSubmission,
    furtherRecommendation, furtherRecommendationOthers, emailData, fetchCancel, shownormalizerRejectionReason, normalizerRejectionReason,
    setReviewerRejectionReason,employeeComments,showEmployeeComments,oneToOneMeetingDate,showoneToOneMeetingDate } = useReviewerContext()
  const CustomScrollbar = Scrollbar as any;
  let NAVIGATION_FROM ="Employee-Reviewer-rejection"
  const navigate = useNavigate();
  const formIsDirty = navPrompt;
  usePrompt(
    PROMPT,
    formIsDirty);
  const [openAlertOK, setOpenAlertOK] = useState(false);
  let showIfAppraiser = true;
  let showIfEmployee = true
  let showIfReviewerReject = true

  // Popovers
  const [anchorRatingInfo, setAnchorRatingInfo] = React.useState<HTMLButtonElement | null>(null);
  const openRatingInfo = Boolean(anchorRatingInfo);
  const idRatingInfo = openRatingInfo ? "simple-popover" : undefined;

  const handleClickOpenRatingInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingInfo(event.currentTarget);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (moveTab === true) {
      //open alert for saving 
      setOpenAlertOK(true);
      setMessage(PLEASE_SAVE_CHANGES);
    } else {
      setTabValue(newValue);
    }
  };
  const handleAlertOKClose = () => {
    setOpenAlertOK(false);
    setMessage("");
  }
  const handleCloseRatingInfo = () => {
    setAnchorRatingInfo(null);
  };
  React.useEffect(() => {
    if (employeeData) {
      if (!employeeData?.data?.appraisal?.pa_status?.includes(PENDING_WITH_REVIEWER)) {
        navigate(`${VIEWPA}/employee/${employee_id}`)
      }
    }
  }, [employeeData])
  return (
    <>
    <BreadCrumbs 
    showIfReviewer={showIfReviewerReject}
    employeeData={employeeData}/>
    <div
        style={{
          backgroundColor: "#F1F1F1",
          height: "auto",
          minHeight: "100px",
          overflow: "hidden",
        }}>
      <Box
        style={{
          padding: "35px",
          marginRight: "25px",
          marginLeft: "25px",
          background: "#fff",
          marginBottom: "25px"
        }}>
        <Box
          style={{
            // padding: "35px",
            paddingBottom: "35px"
          }}
        >
          <Header
          NAVIGATION_FROM={NAVIGATION_FROM}
          employee_id={employee_id}
            ratingData={ratingData}
            employeeDetails={employeeDetails}
            overallRating={overallRating}
            PAStatus={PAStatus}
            employeeData={employeeData}
            ratingDefinition={ratingDefinition}
            ratingScaleDefinition={ratingScaleDefinition}
            showIfReviewer={showIfReviewerReject}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            <div style={{
              marginTop: "10px",
              marginBottom: "10px"
            }}>
              <PerformanceAppraisalPeriod
                employeeDetails={employeeDetails} />
            </div>
            <div style={{
              marginTop: "10px",
              marginBottom: "10px"
            }}>
              <PotentialView
                potential={potential}
                nineBoxData={nineBoxData}
                showPotentialView={showPotential}
              />
            </div>
          </Stack>
          <div style={{marginTop:"10px"}}>
          <ReviewerRejectionReasonEdit 
          setMoveTab ={setMoveTab}
          setNavPrompt={setNavPrompt}
          reviewerRejectionReason={reviewerRejectionReason}
          setReviewerRejectionReason ={setReviewerRejectionReason}
          disableTextAfterSubmission={disableTextAfterSubmission}
          employeeDataIsFetching = {employeeDataIsFetching}
          employeeDataIsLoading = {employeeDataIsLoading}
          />
          {shownormalizerRejectionReason &&
          <NormalizerRejectionReasonView
          normalizerRejectionReason={normalizerRejectionReason} />
          }
          </div>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              // marginRight: "66px",
              paddingTop: "12px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#3e8cb5",
                }
              }}
            >
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "#ffffff",
                    background: "#3e8cb5",
                  },
                  textTransform: "capitalize",
                  fontSize: "16px",
                  fontFamily: "Arial",
                  fontWeight: "700",
                  border: "1px solid #3e8cb59e",
                  maxHeight: "0px",
                  minHeight: "48px",
                  paddingRight: "15px",
                  paddingLeft: "20px"
                }}
                label="Ratings"
                icon={
                  <>
                    <TabPanel2 value={tabValue} index={1}>
                      <IconButton
                        sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                      >
                        <img
                          style={{ width: "12px" }}
                          src={Infoicon}
                          alt="icon"
                        />
                      </IconButton>
                    </TabPanel2>
                    <TabPanel2 value={tabValue} index={2}>
                      <IconButton
                        sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                      >
                        <img
                          style={{ width: "12px" }}
                          src={Infoicon}
                          alt="icon"
                        />
                      </IconButton>
                    </TabPanel2>
                    <TabPanel2 value={tabValue} index={0}>
                      <IconButton
                        sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                        aria-describedby={idRatingInfo}
                        onClick={handleClickOpenRatingInfo}
                      >
                        <img
                          style={{ width: "12px" }}
                          src={Infowhiteicon}
                          alt="icon"
                        />

                      </IconButton>
                    </TabPanel2>
                  </>
                }
                iconPosition="start"
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "#ffffff",
                    background: "#3e8cb5",

                  },
                  textTransform: "capitalize",
                  fontSize: "16px",
                  fontFamily: "Arial",
                  fontWeight: "700",
                  border: "1px solid #3e8cb59e",
                  paddingLeft: "20px",
                  paddingRight: "20px"
                }}
                label="Overall Feedback"
                {...a11yProps(1)}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "#ffffff",
                    background: "#3e8cb5",

                  },
                  textTransform: "capitalize",
                  fontSize: "16px",
                  fontFamily: "Arial",
                  fontWeight: "700",
                  border: "1px solid #3e8cb59e",
                  paddingLeft: "20px",
                  paddingRight: "20px"
                }}
                label="Performance Goals"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <RatingsTab
              employee_id={employee_id}
              employeeData={employeeData}
              objectiveDescription={objectiveDescription}
              objectiveTitleData={objectiveTitleData}
              Colors={Colors}
              colorarray={colorarray}
              visiblityColumnData={visiblityColumnData}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <OverallFeedbackTab
             employeeData={employeeData}
            showoneToOneMeetingDate={showoneToOneMeetingDate}
            oneToOneMeetingDate={oneToOneMeetingDate}
            showEmployeeComments={showEmployeeComments}
            employeeComments={employeeComments}
              reviewerComments={reviewerComments}
              normalizerComments={normalizerComments}
              showNormalizerComments={showNormalizerComments}
              setReviewerComments={setReviewerComments}
              disableTextAfterSubmission={disableTextAfterSubmission}
              showAppraiserTrainingRecommendation={showAppraiserTrainingRecommendation}
              showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
              appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
              setAppraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
              moveTab={moveTab}
              setMoveTab={setMoveTab}
              navPrompt={navPrompt}
              setNavPrompt={setNavPrompt}
              appraiserAreaOfImprovement={appraiserAreaOfImprovement}
              appraiserTrainingRecommendation={appraiserTrainingRecommendation}
              appraisalTemplateTrainingRecommendation={appraisalTemplateTrainingRecommendation}
              furtherRecommendation={furtherRecommendation}
              appraiserMessageForEmployee={appraiserMessageForEmployee}
              showAppraiserMessageForEmployee={showAppraiserMessageForEmployee}
              furtherRecommendationOthers={furtherRecommendationOthers}
              appraiserFurtherRecommendationOthersCheckbox={appraiserFurtherRecommendationOthersCheckbox}
              employeeDataIsFetching={employeeDataIsFetching}
              employeeDataIsLoading={employeeDataIsLoading} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
          <PerformanceGoal
              showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
              showIfAppraiser={showIfAppraiser}
              performanceGoalsFieldValues={performanceGoalsFieldValues}
              performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
              showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
              showIfEmployee={showIfEmployee} 
              />

          </TabPanel>
          
          <div style={{
            marginTop: "20px"
          }}>
            <EmployeeReviewerRejectFooterButtons 
            tabValue={tabValue}
          employeeData={employeeData}
          updateMutation={updateMutation}
          reviewerOverallFeedComments={reviewerRejectionReason}
          setDisableButtons={setDisableButtons}
          employee_id={employee_id} setMoveTab={setMoveTab}
          setNavPrompt={setNavPrompt} moveTab={moveTab}
          disableButtons={disableButtons} navPrompt={navPrompt}
          setTabValue={setTabValue} fetchCancel={fetchCancel}
          sendEmailNotification={sendEmailNotification}
          updateLoggedRole={updateLoggedRole}
          emailData={emailData}
          />
          </div>

          <Popover
            id={idRatingInfo}
            open={openRatingInfo}
            anchorEl={anchorRatingInfo}
            onClose={handleCloseRatingInfo}
            PaperProps={{
              style: { width: "260px", marginTop: "55px" },
            }}
          >
            <TableContainer sx={{ overflowX: "hidden" }}>
              <Scroll>
                <CustomScrollbar style={{ width: "100%", height: "225px" }}>
                  <Table
                    sx={{
                      minWidth: 200,
                      '& th, & td': {
                        borderBottom: 'none', // Remove the bottom border for th and td
                      },
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#eaeced" }}>
                        <TableCell
                          align="center"
                          sx={{
                            maxWidth: "30%",
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600", // Adjust the maxWidth as needed
                          }}
                        >
                          Ratings
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            maxWidth: "70%",
                            fontFamily: "Arial",
                            color: "#3E8CB5",
                            fontSize: "14px",
                            fontWeight: "600",  // Adjust the maxWidth as needed
                          }}
                        >
                          Rating Title
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ratingData &&
                        ratingData.data
                          .slice()
                          .sort((a: any, b: any) => a.rating - b.rating)
                          .map((row: any, index: any) => (
                            <TableRow key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderColor: "lightgrey",
                                },
                              }}>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.rating}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.rating_scale}
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </CustomScrollbar>
              </Scroll>
            </TableContainer>
          </Popover>
         {/* Alert for Please save the changes */}  
          <DialogWithOkButton
            isAlertOpen={openAlertOK}
            handleAlertClose={handleAlertOKClose}>
            {message}
          </DialogWithOkButton>
        </Box>
      </Box>
      </div>
    </>
  )
}

export default EmployeeReviewerReject