import React from 'react'
import FeedbackQuestionnaireView from '../overallFeedback/FeedbackQuestionnaireView'
import { Typography } from '@mui/material'
import AreaOfImprovementView from '../overallFeedback/AreaOfImprovementView';
import TrainingRecommendationView from '../overallFeedback/TrainingRecommendationView';
import AppraiserFurtherRecommendationView from '../overallFeedback/AppraiserFurtherRecommendationView';
import AppraiserMessageForEmployeeView from '../overallFeedback/AppraiserMessageForEmployeeView';
import PerformanceGoals from './PerformanceGoals';

function OverallFeedBack(props:any) {
    const {employeeData,appraiserFeedbackQuestionnaire,showAppraiserAreaOfImprovement,appraiserAreaOfImprovement,
        showAppraiserTrainingRecommendation,appraiserTrainingRecommendation,furtherRecommendation,furtherRecommendationOthers,
        showAppraiserMessageForEmployee,appraiserMessageForEmployee,performanceGoalsFieldValues,performanceGoalsFieldValuesEmployee,showPerformanceGoalsFieldValuesEmployee
    ,showPerformanceGoalsFieldValues} = props;
        let showIfAppraiser =true
        let showIfEmployee =true
        let showIfpreviousappraisal =true
  return (
    <div>
        <Typography
        style={{
          marginTop: "20px",
          fontSize: "20px",
          color: "#3E8CB5",
          fontFamily: "Arial",
          marginBottom: "20px",
        }}
      >
        Performance Appraisal Summary
      </Typography>
      <div style={{ marginRight: "33px", }}>
        <FeedbackQuestionnaireView
          employeeData={employeeData}
          appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire} />
          </div>
          {(showAppraiserAreaOfImprovement &&
          <AreaOfImprovementView
          showIfpreviousappraisal={showIfpreviousappraisal}
          showIfAppraiser={showIfAppraiser}
            showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
            areaOfImprovement={appraiserAreaOfImprovement} />
        )}
         {showAppraiserTrainingRecommendation && (
          <TrainingRecommendationView
          showIfpreviousappraisal={showIfpreviousappraisal}
          showIfAppraiser={showIfAppraiser}
            showTrainingRecommendation={showAppraiserTrainingRecommendation}
            trainingRecommendation={appraiserTrainingRecommendation} />
        )}
        <AppraiserFurtherRecommendationView
        showIfpreviousappraisal={showIfpreviousappraisal}
        furtherRecommendation={furtherRecommendation}
        furtherRecommendationOthers={furtherRecommendationOthers} />
        {showAppraiserMessageForEmployee && (
        <div>
          <AppraiserMessageForEmployeeView
          appraiserMessageForEmployee={appraiserMessageForEmployee}
            employeeData={employeeData} />
        </div>
      )}
      <PerformanceGoals 
      performanceGoalsFieldValues={performanceGoalsFieldValues}
      performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
      showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
      showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
      showIfAppraiser ={showIfAppraiser}
      showIfEmployee={showIfEmployee}/>
    </div>
  )
}

export default OverallFeedBack