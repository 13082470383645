import React from 'react'
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { useGetEmployeeAppraisalQuery } from '../../../service';
import { useParams } from "react-router-dom";


const Typo2 = styled("div")({
    // marginLeft: "58px",
    marginBottom: "10px",
    color: "#717171",
    fontFamily: "arial",
    fontSize: "16px",
    //opacity: 0.85,
});

const Tf1 = styled("div")({
    // marginLeft: "58px",
    marginTop: "5px",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    "& .MuiInputBase-input": {
        color: "#333333",
        fontSize: "14px",
        fontFamily: "arial",
        fontWeight: "400",
        textTransform: "none",
        // padding: "4px",
        textAlign: "left"
    },
});

const Contain = styled("div")({
    // marginRight: "58px",
    // marginTop: "10px",

});


const FeedbackQuestionnaireView = (props: any) => {
    // States
    const {
        showIfpreviousappraisal,
        appraiserFeedbackQuestionnaire
    } = props;

console.log(appraiserFeedbackQuestionnaire,"appraiserFeedbackQuestionnaire")
    return (
        <>
            <Grid item xs={12}>
                {appraiserFeedbackQuestionnaire?.map((j: any, mapIndex: any) => {
                    return (
                        <>
                            <div style={{ marginBottom: "20px" }}>
                                <Typo2> <b>{showIfpreviousappraisal ? j.name : j?.name?.name}</b> </Typo2>
                                <Contain>
                                    <Box>
                                        <Tf1>
{/* 
                                            <TextField
                                                fullWidth
                                                multiline
                                                InputProps={{ readOnly: true }}
                                                size='small'
                                                key={j._id}
                                                value={appraiserFeedbackQuestionnaire[mapIndex]?.value}

                                            /> */}
                                              <Box sx={{ border: "1px solid #E0E0E0", padding: "8.5px 14px", borderRadius: "5px", }}>
                                            <div style={{
                          color: "#333333",
                          fontSize: "14px",
                          fontFamily: "arial",
                          fontWeight: "400",
                          textTransform: "none",
                          // padding: "8px",
                          textAlign: "left",
                          lineHeight: "23px"
                        }}>
                          {appraiserFeedbackQuestionnaire[mapIndex]?.value}
                        </div>
                        </Box>

                                        </Tf1>
                                    </Box>
                                </Contain>
                            </div>
                        </>
                    );
                })}
            </Grid>
        </>
    )
}

export default FeedbackQuestionnaireView