import { Grid, Typography } from '@mui/material'
import React from 'react'

const PerformanceAppraisalPeriod = (props : any) => {
    const {employeeDetails} = props;
    
  return (
  <>
  <Grid item xs={4}>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#3E8CB5",
                    fontFamily: "Arial",
                  }}
                >
                  Performance Appraisal Period
                </span>
                <Typography
                  style={{
                    color: "#717171",
                    marginTop: "8px",
                    fontSize: "16px",
                    fontFamily: "Arial",
                  }}
                >
                  {employeeDetails?.calendarName}
                </Typography>
              </Grid>
  </>
  )
}

export default PerformanceAppraisalPeriod