import React, { useState } from 'react'
import { Button, Stack, Typography, styled, Box, IconButton, Popover, Grid, Tooltip, } from '@mui/material'
import { Link } from 'react-router-dom';
import { useGetEmployeeDetailsWithEmpCodeQuery } from '../../../service/employee/previousAppraisal';
import { makeStyles } from "@mui/styles";
import { } from '@mui/material';
import { EMPLOYEE_DOWNLOAD, EMPLOYEE_PREVIOUS_PAs, NORMALIZER_VIEW_PA, VIEWPA, REVIEWER_VIEW_PA } from '../../../constants/routes/Routing';
import Eye from "../../../assets/Images/Eye.svg";
import { useParams, useNavigate } from "react-router-dom";
import Downloadss from "../../../assets/Images/Downloadss.svg";
import { useGetRatingScaleQuery } from '../../../service';
import Avatar from "@mui/material/Avatar";
import Infoicon from "../../../assets/Images/Infoicon.svg";
import { useEmployeeContext } from '../../../context/appraisal/EmployeeContext';
import dayjs from "dayjs";


const useStyles = makeStyles(({
  heading: {
    color: "#3E8CB5",
    fontWeight: "400",
    fontSize: "28px",
    fontFamily: "Arial",

    // @media Querry for responsive codes
    ['@media (max-width:868px)']: {
      flexWrap: "wrap",
    }
  },
  customAlert: {
    backgroundColor: '#3e8cb5',
    color: "white",
    height: '60px !important',
    alignItems: "center",
    fontSize: "1rem"
  },
  customSnackbar: {
    paddingBottom: '16px',
    paddingRight: '16px',
  },
}));

const Overallrating = styled("div")({
  fontSize: "17px",
  fontFamily: "arial",
  fontWeight: 400,
  color: "#3e8cb5",
  //textAlign: 'left'
});

const Overallratingvalue = styled("div")({
  fontSize: "17px",
  fontFaily: "arial",
  display: "flex",
  alignItems: "center"
});

const Dividerroot = styled("div")({
  "& .MuiDivider-root": {
    marginTop: "10px",
    marginBottom: "15px",
    marginLeft: "0px",
  },
});

const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Name = styled("div")({
  fontSize: "17px",
  fontWeight: 400,
  color: "#3e8cb5",
  fontFamily: "Arial",
  textAlign: "left",
});

const Speciality = styled("div")({
  fontSize: "12px",
  fontWeight: 400,
  color: "#333333",
  opacity: 0.5,
  textAlign: "left",
});

const Pastratingvalue = styled("div")({
  fontSize: "12px",
  fontWeight: 400,
  opacity: 0.5,
  color: "#333333",
  // paddingTop:'2px',
  textAlign: "left",
  // paddingBottom: "5px",
});
type ViewPAState = {
  viewpa: string;
};
const Header = (props: any) => {
  const {
    showIfReviewer,
    showIfNormalizer,
    showIfpreviousappraisal,
    showDownload,
    showIfAppraiser,
    handleHide,
    pdfExportComponent,
    showIfEmployee,
    showIfViewPa,
    employeeDetails,
    employee_id,
    employeeData,
    PAStatus,
    overallRating,
    ratingData,
    ratingDefinition,
    ratingScaleDefinition,
    NAVIGATION_FROM,
    showIfAppraiserOverview } = props;
  const classes = useStyles();
  // let NAVIGATION_FROM = "EmployeeLanding"
  console.log(showIfReviewer, showIfNormalizer, "showIfReviewerApprove")
  // Popovers
  const [anchorRatingDefinition, setAnchorRatingDefinition] = React.useState<HTMLButtonElement | null>(null);
  const openRatingDefinition = Boolean(anchorRatingDefinition);
  const idRatingDefinition = openRatingDefinition ? "simple-popover" : undefined;
  const [anchorPreviousRatingPopOver, setAnchorPreviousRatingPopOver] = React.useState<HTMLElement | null>(null);
  const [anchorPreviousOverallRatingPopOver, setAnchorPreviousOverallRatingPopOver] = React.useState<HTMLElement | null>(null);
  const openPreviousRating = Boolean(anchorPreviousRatingPopOver);
  const openPreviousOverallRating = Boolean(anchorPreviousOverallRatingPopOver);
  const id_Previous_Rating = openPreviousRating ? "simple-popover" : undefined;
  const id_PreviousOverallRating: any = openPreviousOverallRating ? "simple-popover" : undefined;
  const navigate = useNavigate();


  // Mutations
  //Get previous pa details
  const { data: employeePA_Data } = useGetEmployeeDetailsWithEmpCodeQuery({ employeeCode: employeeData?.data?.employee_code });



  const handleOpenRatingDefinition = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingDefinition(event.currentTarget);
  };

  const handleCloseRatingDefinition = () => {
    setAnchorRatingDefinition(null);
  };

  const handleOpenPreviousOverallRatingPopOver = (event: any) => {
    if (showIfEmployee) {
      setAnchorPreviousOverallRatingPopOver(event.currentTarget);
    }
  }

  const handleClosePreviousOverallRatingPopOver = () => {
    setAnchorPreviousOverallRatingPopOver(null)
  }

  // Functions 
const viewpa="value"
  const handleViewPA = () => {
    if (showIfEmployee == true) {
      window.open(`${EMPLOYEE_DOWNLOAD}/employee/${employee_id}`, '_blank')
    } else if (showIfNormalizer || showIfReviewer || showIfAppraiser) {
      window.open(`${VIEWPA}/employee/${employee_id}?viewpa=${viewpa}`, '_blank')
      // const url = `${VIEWPA}/employee/${employee_id}`;
      // navigate(url, { state: { viewpa: viewpa } }); // Update URL in current tab
      // window.open(url, '_blank'); // Open URL in new tab
    }
  }
  return (
    <>
      <div>
        <Stack
          className={classes.heading}
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
          paddingBottom="10px"
        // marginRight="33px"
        >
          <Typography
            style={{
              color: "#3E8CB5",
              fontWeight: "400",
              fontSize: "28px",
              fontFamily: "Arial",
            }}
          >
            Welcome to Performance Appraisal
          </Typography>


          {/* <Link to={`${EMPLOYEE_LANDING}/employee/${employee_id}`,'_blank'}> */}
          <Stack
            direction="row"
            alignItems="center"
            gap={3}>
            <Typography
              style={{
                color: "#3E8CB5",
                fontSize: "17px",
                fontFamily: "Arial",
              }}
            >
              PA Status:

              <span
                style={{
                  color: "#717171",
                  marginTop: "8px",
                  fontSize: "17px",
                  fontFamily: "Arial",
                }}
              >
                {PAStatus}
              </span>
            </Typography>


            {(showIfViewPa !== true ) &&
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.8}
            >

              <Overallrating style={{ display: "flex", alignItems: "center", }} >
                Previous Rating:
                <Overallratingvalue  >
                  <span style={{ color: "#717171", fontSize: "17px", fontFamily: "Arial" }} >
                    {typeof employeeDetails?.previousRating === "number" && !isNaN(employeeDetails?.previousRating) ?
                      employeeDetails?.previousRating.toFixed(2) :
                      typeof employeeDetails?.previousRating === "string" ?
                        parseFloat(employeeDetails?.previousRating).toFixed(2) : "-"}
                  </span>
                </Overallratingvalue>

              </Overallrating>
              {/* {employeeDetails?.previousRating && employeePA_Data?.employees[0] && employeePA_Data?.employees[0]?.overall_rating !== 0 && (
                <Link
                  to={`${EMPLOYEE_PREVIOUS_PAs}/employee/${employee_id}`}
                  state={{
                    employeeCodeFromLanding: employeeDetails?.employee_code,
                    calendarTypeFrom: employeePA_Data?.employees[0]?.calendar,
                    yearFrom: employeePA_Data?.employees[0]?.createdAt?.slice(0, 4),
                    NAVIGATION_FROM: NAVIGATION_FROM
                  }}
                ><Typography style={{ marginTop: "2px" }}>
                    <img
                      src={Eye}
                      alt="Eye Icon"
                    />
                  </Typography>
                </Link>
              )} */}
              <Typography style={{ marginTop: "2px" }}> 
              {employeeDetails?.previousRating != null && employeePA_Data?.employees[0] && employeePA_Data?.employees[0]?.overall_rating !== 0 && (

                <Link to={`${EMPLOYEE_PREVIOUS_PAs}/employee/${employee_id}`}
                  state={{
                    employeeCodeFromLanding: employeeDetails?.employee_code,
                    calendarTypeFrom: employeePA_Data?.employees[0]?.calendar,
                    yearFrom: employeePA_Data?.employees[0]?.createdAt?.slice(0, 4),
                    NAVIGATION_FROM: NAVIGATION_FROM
                     }} >
                <img src={Eye} alt="Eye Icon" /> 
                </Link> 
              )}
                 </Typography>
            </Stack>}
            {showDownload && (
              <Tooltip title="Download" arrow placement="top">
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    borderColor: "#3E8CB5",
                    color: "#3E8CB5",
                  }}
                >
                  <label
                    onClick={() => {
                      handleHide()
                    }}
                  ><img style={{ width: "15px", height: "15px", cursor: "pointer", }} src={Downloadss} alt="Download" />
                  </label>
                </Button>
              </Tooltip>
            )}
            {showDownload === false && <span
              style={{
                color: "#3e8cb5",
                padding: "8px",
                height: "18px",
                border: "1px solid#3e8cb5",
                fontSize: "14px",
                fontFamily: "Arial",
                borderRadius: "4px"
              }}
            >{`${dayjs().format("DD-MMM-YYYY")}`}</span>}
            {(showIfAppraiser || showIfReviewer || showIfNormalizer || showIfEmployee) && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    borderColor: "#3E8CB5",
                    color: "#3E8CB5",
                    // marginRight: "63px",
                  }}
                  onClick={handleViewPA}
                > <label style={{
                  // paddingLeft: "5px",
                  cursor: "pointer",
                }} >
                    <img src={Downloadss} alt="Download" />

                  </label>
                </Button>
              </Stack>
            )}
          </Stack>

        </Stack>

        <Box
          sx={{
            // marginRight: "33px",
            marginTop: "0px",
            backgroundColor: "#f3fbff",
          }}>
          <div>
            <Dividerroot>
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                spacing={2}
              >
                <Item>
                  <Box>

                    <Stack direction="row" spacing={1}>

                      {employeeDetails?.profile_image_url != undefined ? (
                        <img style={{ width: "55px", borderRadius: "30px", height: "55px" }} src={employeeDetails?.profile_image_url} />
                      ) : (
                        <Avatar style={{ width: "55px", height: "55px" }}>
                          {employeeDetails?.fullName?.substring(0, 1)}
                        </Avatar>
                      )}
                      <Box>
                        <Stack direction="row" spacing={1}>
                          <Name>
                            {employeeDetails?.firstName}
                          </Name>
                        </Stack>
                        <Speciality>
                          {employeeDetails?.position}
                        </Speciality>
                        <Speciality>
                          Grade {employeeDetails?.grade}
                        </Speciality>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="baseline"
                          textAlign="center"
                        >
                          <Pastratingvalue>
                            {employeeDetails?.employee_code}
                          </Pastratingvalue>
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                </Item>


                <Item>
                  <Stack
                    direction="column"
                    alignItems="center"
                  >
                    {(
                      <>
                        <Overallrating>
                          Overall Rating

                          <Popover
                            //@ts-ignore
                            id={anchorRatingDefinition}
                            open={openRatingDefinition}
                            anchorEl={anchorRatingDefinition}
                            onClose={handleCloseRatingDefinition}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            PaperProps={{
                              style: {
                                backgroundColor: "FEFCF8",
                                boxShadow: "none",
                                maxWidth: "450px",
                                borderRadius: "5px",
                              },
                            }}
                            sx={{
                              "& .MuiPopover-paper": {
                                border: "1px solid #3e8cb5",
                                backgroundColor: "#ffffff",
                                //  left:"852px !important"
                                // width: "30%",
                              },
                            }}
                          >
                            <div
                              style={{
                                padding: "10px",
                                fontSize: "14px",
                                color: "#333333",
                                fontFamily: "Arial",
                                paddingBottom: "5px",
                                paddingTop: "5px",
                                lineHeight: "20px",
                              }}
                            >
                              <b  >{ratingScaleDefinition}</b>:
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#333333",
                                  fontFamily: "Arial",
                                }}
                              >
                                {ratingDefinition}
                              </span>
                            </div>
                          </Popover>
                        </Overallrating>



                        <Overallratingvalue>

                          {ratingDefinition?.length > 0 &&
                            <IconButton sx={{ padding: "4px" }} onClick={handleOpenRatingDefinition} >
                              <img style={{ width: "12px" }} src={Infoicon} alt="icon" />
                            </IconButton>
                          }
                          <span
                            onClick={(e: any) => { handleOpenPreviousOverallRatingPopOver(e) }}
                            style={{
                              // paddingLeft: "42px",
                              fontSize: "16px",
                              color: "#333333",
                              paddingTop: "1px",
                            }}
                          >
                            <b>{overallRating}</b> </span>
                        </Overallratingvalue>
                      </>
                    )}

                    <>
                      <Popover
                        id={id_PreviousOverallRating}
                        open={id_PreviousOverallRating}
                        anchorEl={anchorPreviousOverallRatingPopOver}
                        onClose={handleClosePreviousOverallRatingPopOver}
                        anchorOrigin={{
                          // vertical: "bottom",
                          // horizontal: "right",
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          // vertical: "bottom",
                          // horizontal: "left",
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        PaperProps={{
                          style: {
                            backgroundColor: "FEFCF8",
                            boxShadow: "none",
                            // maxWidth: "400px",
                            // top: "270px !important",
                            borderRadius: "5px",
                          },
                        }}
                        sx={{
                          "& .MuiPopover-paper": {
                            border: "1px solid #3e8cb5",
                            backgroundColor: "#ffffff",
                            // width: "30%",
                          },
                        }}
                      >
                        <div
                          style={{
                            padding: "3px",
                            fontSize: "10px",
                            color: "#333333",
                            fontFamily: "Arial",
                          }}
                        >
                          <div
                            style={{
                              // width: "200px",
                              padding: "3px",
                              fontSize: "13px",
                              color: "#333333",
                              fontFamily: "Arial",
                            }}
                          >
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Typography
                                style={{
                                  fontSize: "13px",
                                  color: "#3e8cb5",
                                  fontFamily: "Arial",
                                }}
                              >
                                Previous Rating :

                              </Typography>
                              <span style={{
                                fontSize: "13px",
                                color: "#333333",
                              }}><b>{(employeeDetails?.status == "rejected") ? (employeeData?.data?.normalizer?.normalized_overallRating?.toFixed(2)) : ("-")}</b></span>
                            </Stack>
                          </div>
                        </div>
                      </Popover>
                    </>
                  </Stack>
                </Item>
              </Stack>
            </Dividerroot>
          </div>
        </Box>
      </div>
    </>
  )
}

export default Header