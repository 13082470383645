import { Alert, Stack, Button, Snackbar } from '@mui/material'
import React, { useState } from 'react'
import { REVIEWER_ACCEPTS_PA_INFO, REVIEWER_ACCEPTS_RATING_UPTO_INFO } from '../../../../constants/AppraisalEmailContents/NotificationForInfo/Reviewer'
import { REVIEWER_ACCEPTS_PA, REVIEWER_ACCEPTS_PA_AFTER_EMPLOYEE_REJECTION, REVIEWER_ACCEPTS_PA_AFTER_NORMALIZER_REJECTION } from '../../../../constants/AppraisalEmailContents/NotificationForAction/Reviewer'
import { ARE_YOU_SURE_WISH_TO_SUBMIT, CHANGES_SAVED_SUCCESSFULLY, LOADER_MESSAGE, PENDING_WITH_REVIEWER, PERFORMANCE_APPTAISAL_SUBMITTEDTO_NORMALIZER, PLEASE_SAVE_CHANGES } from '../../../../constants/DialogContents/DialogContents'
import { makeStyles } from '@mui/styles';
import AlertYesNo from '../../../UI/DialogYesNo'
import { Link, useNavigate } from "react-router-dom";
import AlertDialogOkCancelForUnsavedChanges from "../../../UI/DialogforunsavedChangesAlert"
import DialogWithOkButton from '../../../UI/DialogWithOkButton'
import DialogCircularProgressLoader from '../../../UI/DialogCircularProgressLoader'

/************styles for snackbar****************/
const useStyles = makeStyles((theme) => ({
    customAlert: {
        backgroundColor: '#3e8cb5',
        color: "white",
        height: '60px !important',
        alignItems: "center",
        fontSize: "1rem"
    },
    customSnackbar: {
        paddingBottom: '16px',
        paddingRight: '16px',
    },
}));
function EmployeeReviewerApproveFooterButtons(props: any) {
    const { acceptEmployeeReviewer, employeeData, emailData, acceptReviewer, employee_id, navPrompt, disableButtons,
        updateMutation, reviewerComments, updateLoggedRole, setNavPrompt, setMoveTab, fetchCancel,
        setDisableButtons, sendEmailNotification, moveTab, tabValue, setTabValue, talentCategory } = props
    const [successAlertTriger, setSuccessAlertTriger] = useState(false); /***********Alert to show snackbar after saving************/
    const [successAlertTrigerMSG, setSuccessAlertTrigerMSG] = useState(CHANGES_SAVED_SUCCESSFULLY); /***********Alert message in snackbar after saving************/
    const [message, setMessage] = useState("");
    const [disableSubmit, setdisableSubmit] = React.useState(false);
    const classes = useStyles();
    const [openYes1, setOpenYes1] = useState(false);
    const navigate = useNavigate();
    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
    const [openOk, setOpenOk] = useState(false);
    const [showLoader , setShowLoader ] = useState(false);


    {/* function for no button for Save and submit popup */ }
    const handleAlertNo1 = () => {
        setOpenYes1(false);
        setDisableButtons(false);
    }
    {/* function for Please save the changes popup close button */ }
    const handleAlertClose = () => {
        setOpenOk(false);
        setMessage("")
    }
    {/* function for Save as Draft used for clicking save and submit button */ }
    const saveAsDraftReviewerDetails = () => {
        updateMutation({
            "reviewer.reviewer_comments_draft": reviewerComments?.trim(),
            id: employee_id,
        })
        setMoveTab(false);
        setNavPrompt(false);
    }
    {/* function for Save and Submit */ }
    const submithandler = () => {
        saveAsDraftReviewerDetails();
        setDisableButtons(true);
        setOpenYes1(true);
        setMessage(ARE_YOU_SURE_WISH_TO_SUBMIT)
    }
    {/* function for Save as Draft */ }
    const saveHandler = () => {
        setDisableButtons(true);
        updateMutation({
            "reviewer.reviewer_status": 'draft',
            "appraisal.pa_status": PENDING_WITH_REVIEWER,
            "reviewer.reviewer_comments_draft": reviewerComments?.trim(),
            id: employee_id,
        }).then((res: any) => {
            setMoveTab(false);
            setNavPrompt(false);
            //new alert 
            setDisableButtons(false);
            setSuccessAlertTrigerMSG(CHANGES_SAVED_SUCCESSFULLY)
            setSuccessAlertTriger(true);

        })
    }
    {/* function for yes button on the  Save and submit  popup  */ }
    const handleSubmitPA = () => {
        setShowLoader(true);
        setMessage(LOADER_MESSAGE);
        let appraisal_objective_description = employeeData?.data?.appraisal?.objective_description
            .map((item: any) => {
                return {
                    ...item,
                    rating_rejected: false,
                }
            })
        acceptEmployeeReviewer({
            appraisal_objective_description: appraisal_objective_description,
            current_overallRating: employeeData?.data?.current_rating?.overall_rating,
            current_previous_submission: employeeData?.data?.current_rating?.objective_description,
            appraisal_previous_submission: employeeData?.data?.appraisal?.objective_description, id: employee_id,
            reviewer_comments: reviewerComments?.trim(),
            reviewer_comments_draft: reviewerComments?.trim(),
            talentCategory: talentCategory?.data,
        }).then((res: any) => {
            setOpenYes1(false);
            if (Math.abs(employeeData?.data?.current_rating?.overall_rating - employeeData?.data?.normalizer.normalized_overallRating) <= 0.3) {
                //snackbar
                setSuccessAlertTrigerMSG("The overall rating was auto-renormalized. The performance appraisal is completed.")
                setSuccessAlertTriger(true)
            } else {
                setSuccessAlertTrigerMSG("The performance appraisal has been submitted to the HR Normalizer for re-normalization. The Employee’s overall PA rating changed after the PA meeting by more than 0.3 and must be re-normalized by the HR Normalizer.")
                setSuccessAlertTriger(true)
            }
            setdisableSubmit(true);
            setDisableButtons(false);
            setMoveTab(false);
            setShowLoader(false);
            setMessage("");
            setNavPrompt(false);
        })
        let appraiserName = employeeData?.data?.appraiser_name;
        let reviewerName = employeeData?.data?.reviewer_name;
        let normalizername = employeeData?.data?.normalizer_name;
        let employeeName = employeeData?.data?.first_name;
        let calendarName = employeeData?.data?.calendar?.calendar_type;
        let calendarYear = employeeData?.data?.calendar?.start_date?.slice(0, 4);
        let dueDate = employeeData?.data?.calendar?.end_date_employee_acknowledgement?.slice(0, 10)
        let previousRating = employeeData?.data?.normalizer?.normalizer_rating;
        let newRating = employeeData?.data?.current_rating?.overall_rating;
        let employeeCode = employeeData?.data?.employee_code
        let normalizerFirstName = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.normalizer_code)?.firstName;
        let employeeFirstName = employeeData?.data?.first_name;
        let employeeEmail = employeeData?.data?.email;

        if (Math.abs(employeeData?.data?.current_rating?.overall_rating - employeeData?.data?.normalizer.normalized_overallRating) <= 0.3) {
            //// Notification info Reviewer accepts Employee rejected PA (overall rating is upto 0.3) (for Employee)
            let tempSubjectInfo = REVIEWER_ACCEPTS_RATING_UPTO_INFO?.subject;
            tempSubjectInfo = tempSubjectInfo?.replace("[year]", `${calendarYear}`);
            tempSubjectInfo = tempSubjectInfo?.replace("[calendar name]", `${calendarName}`);
            tempSubjectInfo = tempSubjectInfo?.replace("[employee name]", `${employeeName}`);
            tempSubjectInfo = tempSubjectInfo?.replace("[employee code]", `${employeeCode}`);

            let tempHtmlInfo = REVIEWER_ACCEPTS_RATING_UPTO_INFO?.html;
            tempHtmlInfo = tempHtmlInfo?.replace("[year]", `${calendarYear}`);
            tempHtmlInfo = tempHtmlInfo?.replace("[calendar name]", `${calendarName}`);
            tempHtmlInfo = tempHtmlInfo?.replace("[Reviewer name]", `${reviewerName}`);
            tempHtmlInfo = tempHtmlInfo?.replace("[previous rating]", `${previousRating}`);
            tempHtmlInfo = tempHtmlInfo?.replace("[new rating]", `${newRating}`);
            tempHtmlInfo = tempHtmlInfo?.replace("[employee name]", `${employeeName}`);
            tempHtmlInfo = tempHtmlInfo?.replace("[Employee FirstName]", `${employeeFirstName}`);
            tempHtmlInfo = tempHtmlInfo?.replace("[employee code]", `${employeeCode}`);

            let appraiserEmailInfo = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.appraiser_code)?.email
            let normalizerEmailInfo = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.normalizer_code)?.email
            const recipientEmails = [`${appraiserEmailInfo}`, `${normalizerEmailInfo}`]
            let emailInfo = REVIEWER_ACCEPTS_RATING_UPTO_INFO?.to;
            emailInfo = emailInfo?.replace("[email]", `${employeeEmail}`);

            sendEmailNotification(
                {
                    to: emailInfo,
                    cc: recipientEmails,
                    subject: tempSubjectInfo,
                    html: tempHtmlInfo
                }
            )
        } else {
            // Notification action Reviewer accepts Employee rejected PA (overall rating difference greater than 0.3) (for Normalizer)
            let tempSubject = REVIEWER_ACCEPTS_PA_AFTER_EMPLOYEE_REJECTION?.subject;
            tempSubject = tempSubject?.replace("[year]", `${calendarYear}`);
            tempSubject = tempSubject?.replace("[calendar name]", `${calendarName}`);
            tempSubject = tempSubject?.replace("[employee name]", `${employeeName}`);
            tempSubject = tempSubject?.replace("[employee code]", `${employeeCode}`);

            let tempHtml = REVIEWER_ACCEPTS_PA_AFTER_EMPLOYEE_REJECTION?.html;
            tempHtml = tempHtml?.replace("[year]", `${calendarYear}`);
            tempHtml = tempHtml?.replace("[calendar name]", `${calendarName}`);
            tempHtml = tempHtml?.replace("[Reviewer name]", `${reviewerName}`);
            tempHtml = tempHtml?.replace("[Normalizer FirstName]", `${normalizerFirstName}`);
            tempHtml = tempHtml?.replace("[previous rating]", `${previousRating}`);
            tempHtml = tempHtml?.replace("[new rating]", `${newRating}`);
            tempHtml = tempHtml?.replace("[employee name]", `${employeeName}`);
            tempHtml = tempHtml?.replace("[employee code]", `${employeeCode}`);
            tempHtml = tempHtml?.replace("[Employee FirstName]", `${employeeFirstName}`);

            let normalizerEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.normalizer_code)?.email
            let email = REVIEWER_ACCEPTS_PA_AFTER_EMPLOYEE_REJECTION?.to;
            email = email?.replace("[email]", `${normalizerEmail}`);

            sendEmailNotification(
                {
                    to: email,
                    subject: tempSubject,
                    html: tempHtml
                }
            )
        }

        updateLoggedRole({
            pa_action_by: `Reviewer (Accepted)(Employee Rejection) : ${new Date()}`,
            id: employee_id
        })
    }

    {/* function for Back Button */ }
    const handleBackButton = () => {
        if (moveTab === true) {
            setOpenOk(true);
            setMessage(PLEASE_SAVE_CHANGES)
        }
        else {
            setTabValue(0);
        }
    };
    {/* function for Cancel Button */ }
    const CancelButtonHandler = () => {
        if (navPrompt || moveTab) {
            setOpenCancelDialog(true);
        } else if (!navPrompt || !moveTab && tabValue == 0) {
            navigate(`/reviewer`, { state: { from: `${1}` } })
        }
        else if (!navPrompt || !moveTab && tabValue == 2) {
            setTabValue(1);
        } else if (!navPrompt && !moveTab && tabValue == 1) {
            setOpenCancelDialog(false);
            setTabValue(0);
        }
    }
    const handleCancel = () => {
        if (navPrompt == true || moveTab == true) {
            setOpenCancelDialog(true);
        } else {
            setOpenCancelDialog(false);
            if (tabValue == 2) {
                setTabValue(1)
            } else if (tabValue == 1) {
                setTabValue(0)
            } else {
                navigate(`/reviewer`, { state: { from: `${1}` } })
            }
        }
    }

    const cancelRatingChanges = () => {
        setMoveTab(false);
        setNavPrompt(false);
        setOpenCancelDialog(false);
        fetchCancel().then((res: any) => {
            navigate(`/reviewer`, { state: { from: `${1}` } })
        });
    }

    const cancelOverallFeedbackChanges = () => {
        setMoveTab(false);
        setNavPrompt(false);
        setOpenCancelDialog(false);
        fetchCancel().then((res: any) => setTabValue(0))
    }

    const cancelPerformanceGoalsChanges = () => {
        setMoveTab(false);
        setNavPrompt(false);
        setOpenCancelDialog(false);
        fetchCancel().then((res: any) => setTabValue(1))
    }
    {/* function for Cancel Button alert popup Yes */ }
    const handleToAcceptCancel = () => {
        if (tabValue == 0) { cancelRatingChanges() }
        if (tabValue == 1) { cancelOverallFeedbackChanges() }
        if (tabValue == 2) { cancelPerformanceGoalsChanges() }
    }
    {/* function for Cancel Button alert popup No */ }
    const rejectCancelButtonHandler = () => {
        setOpenCancelDialog(false);
    }
    {/* function for Cancel Button alert popup Yes */ }
    const acceptCancelButtonHandler: any = () => {
        fetchCancel();
        setOpenCancelDialog(false);
        setMoveTab(false);
        setNavPrompt(false);
        setTabValue(0);
    }
    {/* function for Closing snackbar */ }
    const handleCloseSnackbar = () => {
        setSuccessAlertTriger(false)
        setSuccessAlertTrigerMSG("")
    }

    return (
        <>
            <Stack justifyContent="center" spacing={2} direction="row">
                {employeeData?.data?.appraisal?.pa_status?.includes(PENDING_WITH_REVIEWER) &&
                    <>
                        <Button
                            // variant="outlined"
                            disabled={disableButtons}
                            variant="outlined"
                            style={{
                                backgroundColor: "Transparent",
                                fontSize: "15px",
                                fontWeight: 400,
                                textTransform: "none",
                                color: "#3e8cb5",
                                borderColor: "#3E8CB5",
                                height: "35px",
                                fontFamily: "arial",
                                ...((disableButtons) && {
                                    cursor: "not-allowed",
                                    borderColor: "#ccc",
                                    color: "#ccc",
                                }),
                            }}
                            onClick={() => {
                                saveHandler();
                            }}
                        >
                            Save as Draft
                        </Button>
                        <Button
                            variant="outlined"
                            disabled={disableButtons || disableSubmit}
                            style={{
                                backgroundColor: "Transparent",
                                fontSize: "15px",
                                fontWeight: 400,
                                textTransform: "none",
                                color: "#3e8cb5",
                                borderColor: "#3E8CB5",
                                height: "35px",
                                width: "155px",
                                fontFamily: "arial",
                                ...((disableButtons) && {
                                    cursor: "not-allowed",
                                    borderColor: "#ccc",
                                    color: "#ccc",
                                }),
                            }}
                            onClick={() => {
                                submithandler()
                            }}
                        >
                            Save and Submit
                        </Button>
                        {tabValue !== 0 &&
                            <Button
                                disabled={disableButtons}
                                variant="outlined"
                                style={{
                                    backgroundColor: "Transparent",
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    textTransform: "none",
                                    color: "#3e8cb5",
                                    borderColor: "#3E8CB5",
                                    height: "35px",
                                    width: "70px",
                                    fontFamily: "arial",
                                    ...((disableButtons) && {
                                        cursor: "not-allowed",
                                        borderColor: "#ccc",
                                        color: "#ccc",
                                    }),
                                }}
                                onClick={() => {
                                    handleBackButton();
                                }}
                            >
                                Back
                            </Button>
                        }
                        <Button
                            disabled={disableButtons}
                            variant="outlined"
                            style={{
                                backgroundColor: "Transparent",
                                fontSize: "15px",
                                fontWeight: 400,
                                textTransform: "none",
                                color: "#3e8cb5",
                                borderColor: "#3E8CB5",
                                height: "35px",
                                width: "70px",
                                fontFamily: "arial",
                                ...((disableButtons) && {
                                    cursor: "not-allowed",
                                    borderColor: "#ccc",
                                    color: "#ccc",
                                }),
                            }}
                            onClick={() => {
                                handleCancel();
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                }

            </Stack>
            {/* Alert for The save successfully and submitted message snackbar */}
            <Snackbar
                className={classes.customSnackbar}
                open={successAlertTriger}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    className={classes.customAlert}
                    onClose={handleCloseSnackbar}
                    sx={{ width: '100%' }}
                    icon={false}
                >
                    <b>{successAlertTrigerMSG}</b>
                </Alert>
            </Snackbar>
            {/* Alert for Are you sure you wish to cancel the changes */}
            <AlertDialogOkCancelForUnsavedChanges
                isAlertOpen={openCancelDialog}
                handleAlertClose={rejectCancelButtonHandler}
                handleAlertIdClose={handleToAcceptCancel}
            >
            </AlertDialogOkCancelForUnsavedChanges>
            {/* Are you sure you wish to accept the performance appraisal  */}
            <AlertYesNo
                isAlertOpen={openYes1}
                handleAlertClose={handleAlertNo1}
                handleAlertYes={handleSubmitPA}>
                {message}
            </AlertYesNo>
            {/* please save the changes while clicking back button */}
            <DialogWithOkButton
                isAlertOpen={openOk}
                handleAlertClose={handleAlertClose}>
                {message}
            </DialogWithOkButton>

            <DialogCircularProgressLoader
            isAlertOpen = {showLoader}>
                {message}
            </DialogCircularProgressLoader>

        </>
    )
}

export default EmployeeReviewerApproveFooterButtons