import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { Container, Box, TextField, InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Expand from "../../assets/Images/Expand.svg";
import Searchicon from "../../assets/Images/Searchicon.svg";

const Searchfeild = styled("div")({
  marginTop: "8px",
  "& .MuiOutlinedInput-root": {
    height: "30px",
    width: "100%",
    borderRadius: "25px",
    background: "#F2F6F8",
  },
  "& .MuiInputLabel-root": {
    fontSize: "12px",
    color: "#D5D5D5",
    marginTop: "-10px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "12px",
    color: "#333333",
    opacity: "70%",
  },
  "& .MuiTextField-root": {
    minWidth: "100%",
  },
});
export default function SolidTalents(props: any) {
  const { indexBasedValue, indexBasedTitle, DataforInitial, setindexBasedValue, setindexBasedTitle, BaseStateValue, indexValue, handleNavigationForNineBoxEmployee } = props
  const [enteredName, setenteredName] = useState("");
  const [value, setValue] = useState("");
  const [noData, setnoData] = useState(false);
  useEffect(() => {
    let empty = indexBasedValue?.filter((j: any) => {
      if (enteredName === "") {
        return j;
      } else if (
        (j?.legal_full_name !== undefined &&
          j?.legal_full_name
            ?.toLocaleLowerCase()
            ?.includes(
              enteredName?.toLocaleLowerCase()
            ))) {
        return j;
      }
    })?.map((i: any, index: number) => {
      return (
        i
      )
    }
    )
    if (indexBasedValue?.length == 0 || empty?.length == 0) { setnoData(true) } else { setnoData(false) }
  }, [indexBasedValue, enteredName])
  useEffect(() => {
    setindexBasedTitle(BaseStateValue?.indexBasedTitle)
    setindexBasedValue(BaseStateValue?.indexBasedValue?.data)
  }, [BaseStateValue])
  useEffect(() => {
    setenteredName("")
  }, [indexValue])
  const maxLengthForSearch = 30;
  const handleSearchBar = (e: any) => {
    if (e.target.value.length > maxLengthForSearch) {
      e.target.value = e.target.value.slice(0, maxLengthForSearch);
    }
    setenteredName(e.target.value);
  }
  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{ fontSize: "20px", fontFamily: "Arial", color: "#3e8cb5" }}
        >
          {indexBasedTitle}
        </Typography>
        <img style={{ cursor: "pointer" }} src={Expand} onClick={handleNavigationForNineBoxEmployee} />
      </Stack>
      <Searchfeild>
        <TextField
          id="outlined-basic"
          autoComplete="off"
          placeholder="Search Here..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Searchicon} alt="icon" />
              </InputAdornment>
            ),
          }}
          value={enteredName}
          onChange={handleSearchBar}
        />
      </Searchfeild>
      <div
        style={{
          // paddingTop: "37px",
        }}
      >
        {noData && <Typography
          style={{
            fontSize: "14px",
            fontFamily: "Arial",
            color: "#abb0b0",
            opacity: "80%",
            textAlign: "center"
          }}
        >
          No Data Available
        </Typography>}
        {indexBasedValue?.filter((j: any) => {
          if (enteredName === "") {
            return j;
          } else if (
            (j?.legal_full_name !== undefined &&
              j?.legal_full_name
                ?.toLocaleLowerCase()
                ?.includes(
                  enteredName?.toLocaleLowerCase()
                ))) {
            return j;
          }
        })
          ?.slice(0, 10)
          ?.map((i: any, index: number) => {
            return (
              <Stack 
              marginLeft="10px"
              marginTop="21px"
              // padding="14px"
                direction="row" spacing={3}>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontFamily: "Arial",
                    color: "#333333",
                    opacity: "80%"
                  }}
                >
                  {index + 1}
                </Typography>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontFamily: "Arial",
                    wordBreak: "break-word",
                    color: "#333333",
                    opacity: "80%",
                  }}
                >
                  {i?.legal_full_name}
                </Typography>
              </Stack>
            )
          })
        }
      </div>
    </div>
  );
}


