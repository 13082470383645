import React from 'react'
import { Box, Stack, TableContainer, Popover, Table, TableCell, TableHead, TableBody, TableRow, IconButton, Typography, Tabs, Tab, Breadcrumbs, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_LANDING } from '../../../constants/routes/Routing';

function BreadCrumbs(props: any) {
  const { employee_id,loggedinuser,showIfViewPa, showIfEmployee, showIfAppraiser, showIfReviewer, showIfNormalizer, employeeData } = props
  const navigate = useNavigate()
  const handleBackviewpa = () => {
    if ((employeeData?.data?.appraiser_code == loggedinuser?.employee_code) && ( loggedinuser?.current_role == "Appraiser")) {
      navigate('/dashboardreview')
    }else if ((employeeData?.data?.reviewer_code == loggedinuser?.employee_code)&& ( loggedinuser?.current_role == "Reviewer")) {
      navigate('/reviewer', { state: { from: "toMyTeamDashboard" } })
    } else if ((employeeData?.data?.normalizer_code == loggedinuser?.employee_code)&& ( loggedinuser?.current_role == "Normalizer")) {
      navigate('/normalizer', { state: { from: "toMyTeamDashboard" } })
    }
  }
  const handleBackActionviewpa =() =>{
    if ((employeeData?.data?.appraiser_code == loggedinuser?.employee_code) && ( loggedinuser?.current_role == "Appraiser")) {
      navigate('/dashboardreview', { state: { from: `${1}` } })
    }else if ((employeeData?.data?.reviewer_code == loggedinuser?.employee_code)&& ( loggedinuser?.current_role == "Reviewer")) {
      navigate('/reviewer', { state: { from: `${1}` } })
    } else if ((employeeData?.data?.normalizer_code == loggedinuser?.employee_code)&& ( loggedinuser?.current_role == "Normalizer")) {
      navigate('/normalizer', { state: { from: `${1}` } })
    }
  }
  const handleBack = () => {
    if (showIfEmployee) {
      navigate(`${EMPLOYEE_LANDING}/employee/${employee_id}`);
    }
    else if (showIfAppraiser) {
      navigate('/dashboardreview')
    }
    else if (showIfReviewer) {
      navigate('/reviewer', { state: { from: "toMyTeamDashboard" } })
    } else if (showIfNormalizer == true) {
      navigate('/normalizer', { state: { from: "toMyTeamDashboard" } })
    }
  }
  const handleBackAction = () => {
    if (showIfAppraiser) {
      navigate('/dashboardreview', { state: { from: `${1}` } })
    }
    else if (showIfReviewer ) {
      navigate('/reviewer', { state: { from: `${1}` } })
    } else if (showIfNormalizer == true) {
      navigate('/normalizer', { state: { from: `${1}` } })
    }
  }
  return (
    <>
      {showIfEmployee &&
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          minHeight="50px"
          marginLeft="35px"
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
                cursor: "pointer"
              }}
              onClick={() => { handleBack() }}
            >
              Home
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
            >
              {employeeData && employeeData?.data?.calendar?.name}
            </Typography>
          </Breadcrumbs>
        </Stack>
      }  
        {(showIfAppraiser ||showIfReviewer || showIfNormalizer) && 
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          minHeight="50px"
          marginLeft="25px"
        >
          <Breadcrumbs aria-label="breadcrumb">

            <Typography
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
                cursor: "pointer"
              }}
              onClick={() => { handleBack() }}
            >
              My Team Dashboard
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
                cursor: "pointer",
              }}
              onClick={() => { handleBackAction() }}
            >
              My Actions
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
            >
              {employeeData && employeeData?.data?.calendar?.name}
            </Typography>
          </Breadcrumbs>
        </Stack>
      }
      {showIfViewPa && 
          <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          minHeight="50px"
          marginLeft="25px"
        >
          <Breadcrumbs aria-label="breadcrumb">

            <Typography
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
                cursor: "pointer"
              }}
              onClick={() => { handleBackviewpa() }}
            >
              My Team Dashboard
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                color: "#3e8cb5",
                fontFamily: "Arial",
                cursor: "pointer",
              }}
              onClick={() => { handleBackActionviewpa() }}
            >
              My Actions
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                color: "#333333",
                fontFamily: "Arial",
              }}
            >
              {employeeData && employeeData?.data?.calendar?.name}
            </Typography>
          </Breadcrumbs>
        </Stack>}
    </>
  )
}

export default BreadCrumbs